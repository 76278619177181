import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Contact } from '../../../../services/api/Contact/interface';
import { Trip } from '../../../../services/api/Trip/interface';
import TripService from '../../../../services/api/Trip/TripService';
import TripForm from './TripForm/TripForm';

import './Trips.scss';

interface ContainerProps {
  contact: Contact;
  onChange: (data: Trip[], canNext: boolean) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Trips: React.FC<IProps> = (props) => {
  const { t, contact, onChange } = props;
  const [trips, setTrips] = useState<Trip[]>([]);

  useEffect(() => {
    getTrips();
    onChange([], true);
  }, []);

  const getTrips = async () => {
    if (contact && contact.id_heroku__c) {
      let tripResponse = await TripService.getTrips(contact.id_heroku__c);
      let tripList = tripResponse.data;
      if (tripList.length > 0) {
        let formateList = _.map(tripList, (trip, index) => {
          return { ...trip, tripId: index + 1 };
        });
        setTrips(formateList);
      } else {
        setTrips([]);
      }
    }
  };

  const tripChange = (data: any, comp: any) => {
    let newList = _.map(trips, (o: Trip) => {
      if (o.tripId === comp.tripId) {
        return { ...comp, ...data };
      } else {
        return o;
      }
    });
    setTrips(newList);
    onChange(newList, true);
  };

  const addTrip = (e: any) => {
    let exps = [...trips];
    exps.push({ tripId: exps.length + 1 });
    setTrips(exps);
  };

  const handleDelete = (trip: Trip) => {
    if (trip.id_heroku__c) {
      //delete from bdd
      if (contact.id_heroku__c) {
        TripService.remove(contact.id_heroku__c, trip?.id_heroku__c).then(
          (res) => {
            getTrips();
          }
        );
      }
    } else {
      let tripList = [...trips];
      let index = _.findIndex(tripList, (t) => t.tripId === trip.tripId);
      tripList.splice(index, 1);
      setTrips(tripList);
      // let canNext = checkValid(tripList);
      onChange(tripList, true);
    }
  };

  return (
    <div>
      {/* <h5>{t('foreignExperience')}</h5> */}
      {contact.type_tech__c === 'Francais' && (
        <p>{t('foreignExperienceSentence')}</p>
      )}
      {trips.length > 0 &&
        _.map(trips, (trip, index) => {
          let formatedTrip = { ...trip, index: index };
          return (
            <React.Fragment key={index}>
              <h5>
                {t('experienceNumber')} N°{index + 1}
              </h5>
              <TripForm
                trip={formatedTrip}
                onChange={(data: any) => tripChange(data, formatedTrip)}
                handleDelete={handleDelete}></TripForm>
            </React.Fragment>
          );
        })}
      <div className="add-button">
        <button onClick={addTrip}>{t('addTrip')}</button>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(Trips)
);
