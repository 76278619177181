import { he } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import HappyPeople from '../../../../assets/images/junia-popup-valid-img.png';

import './ValidePayment.scss';

interface ContainerProps {
  handleClick: () => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const ValidePayment: React.FC<IProps> = (props) => {
  const { t, handleClick } = props;
  const history = useHistory();

  const routeChange = () => {
    // let path = `/application`;
    // history.push(path);
    history.push({ pathname: '/home', state: { catalogue: true } });
  };

  return (
    <div className="valide-payment-container">
      <div className="payment-left">
        <img alt="happy people" src={HappyPeople} />
      </div>
      <div className="payment-right">
        <div className="payment-txt">
          <h5>
            {t('validePayment.payTitle')}
            <span></span>
          </h5>
          <p>{t('validePayment.payText1')}</p>
          <p>{t('validePayment.payText2')}</p>
        </div>
        <div className="payment-btn">
          <button
            className="btn btn-main btn-fullwidth"
            onClick={() =>
              history.push({ pathname: '/home', state: { catalogue: true } })
            }>
            <span>{t('candidateAgain')}</span>
          </button>
          <button
            className="btn btn-outlined btn-fullwidth"
            onClick={() => history.push({ pathname: '/application' })}>
            <span>{t('done')}</span>
          </button>
        </div>
      </div>
      {/* SI BOUTON SAUVEGARDER MA CANDIDATURE EST CLIQUE
      <div className="payment-right">
        <div className="payment-txt">
          <h5>
            {t('saveApply.payTitle')}
            <span></span>
          </h5>
          <p>{t('saveApply.payText1')}</p>
        </div>
        <div className="payment-btn">
          <button className="btn btn-main btn-fullwidth" onClick={handleClick}>
            <span>{t('alright')}</span>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default withRouter(
  withTranslation(['home', 'common', 'application'])(ValidePayment)
);
