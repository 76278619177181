import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import API from '..';
import { Case } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class ContactService {
  send(form: Case): Promise<AxiosResponse> {
    console.log(
      '🚀 ~ file: CaseService.ts ~ line 9 ~ ContactService ~ send ~ form',
      form
    );
    return axios.post(`${url}/case`, form);
  }
}
export default new ContactService();
