import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import './Interview.scss';
import moment from 'moment';
import _ from 'lodash';
import { ApplicationsResponse } from '../../../../services/api/Jury/Jury';
import { me } from '../../../../services/api/Contact/interface';
import { Check, Close } from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import flagFR from '../../../../assets/images/flagFR.png';

interface ContainerProps {
  applications: ApplicationsResponse[];
  me: me;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Interview: React.FC<IProps> = (props) => {
  const { t, applications, me } = props;
  const history = useHistory();

  const openCandidature = async (id: any) => {
    history.push(`/jury/folder/${id}`);
  };

  return (
    <div>
      <div className="legend">
        <div className="status">
          <span className="proceed"></span>
          <p>{t('toTreat')}</p>
        </div>
        <div className="status">
          <span className="finish"></span>
          <p>{t('finish')}</p>
        </div>
      </div>
      <table className="folder-table">
        <thead>
          <tr>
            <td width={'5%'}>{t('status')}</td>
            <td width={'5%'}>{t('type')}</td>
            <td width={'10%'}>{t('folder')}</td>
            <td width={'10%'}>{t('lastname')}</td>
            <td width={'10%'}>{t('firstname')}</td>
            <td width={'20%'}>{t('formation')}</td>
            <td width={'10%'}>{t('interviewDate')}</td>
            <td width={'10%'}>{t('step')}</td>
            <td width={'10%'}>{t('entryDecision')}</td>
            <td width={'10%'}>{t('jurySignatureItw')}</td>
          </tr>
        </thead>
        <tbody>
          {applications?.length > 0 ? (
            _.chain(applications)
              .map((application) => {
                return (
                  <>
                    {(application?.statut__c === 'Convoqué Entretien' ||
                      application?.statut__c === 'Entretien' ||
                      application?.statut__c === 'Entretien planifié' ||
                      application?.statut__c === 'En attente' ||
                      application?.statut__c === 'Admis' ||
                      application?.statut__c === 'Admis sous réserve' ||
                      application?.statut__c === 'Non admis' ||
                      application?.statut__c === 'Abandon' ||
                      application?.statut__c ===
                        'Admis ayant accepté la proposition' ||
                      application?.statut__c === "Liste d'attente" ||
                      application?.statut__c === 'Sans suite' ||
                      application?.statut__c === 'Refus' ||
                      application?.statut__c === 'Attente jury fin d’année' ||
                      application?.statut__c === 'Attente jury fin d’année (partenaire)' ||
                      application?.statut__c === 'Etude du dossier') &&
                    (application?.membre_jury__c === me.sfid ||
                      application?.president_jury__c === me.sfid ||
                      application?.membre_jury_compl_mentaire__c ===
                        me.sfid) ? (
                      <tr
                        onClick={() =>
                          openCandidature(application?.id_heroku__c)
                        }
                        key={application.id_heroku__c}>
                        <td className="status">
                          <div
                            className={
                              (application.statut__c === 'Convoqué Entretien' ||
                                application.statut__c === 'Entretien' ||
                                application.statut__c ===
                                  'Entretien planifié') &&
                              !application.signature_signature_jury_entretien__c
                                ? 'proceed'
                                : 'finish'
                            }>
                            <span></span>
                          </div>
                        </td>
                        <td>
                          <p>
                            {application.Contact?.type_tech__c ===
                            'Francais' ? (
                              <img
                                style={{ width: '50%'}}
                                src={flagFR}
                                alt="drapeau français"
                              />
                            ) : (
                              <LanguageIcon color='primary' />
                            )}
                          </p>
                        </td>
                        <td>
                          <p>{application.name}</p>
                        </td>
                        <td>
                          <p>{application.Contact?.lastname}</p>
                        </td>
                        <td>
                          <p>{application.Contact?.firstname}</p>
                        </td>
                        <td>
                          <p>{application?.Formation?.name}</p>
                        </td>
                        <td>
                          <p>
                            {moment(application.date_jury_entretien__c).format(
                              'DD/MM/YYYY'
                            )}
                          </p>
                        </td>
                        <td>
                          <p>{application.statut__c}</p>
                        </td>
                        <td>
                          <p>{application.d_cision_entretien__c}</p>
                        </td>
                        <td>
                          <p>
                            {application.signature_signature_jury_entretien__c ? (
                              <Check color="success" />
                            ) : (
                              <Close color="warning" />
                            )}
                          </p>
                        </td>
                      </tr>
                    ) : null}
                  </>
                );
              })
              .value()
          ) : (
            <tr>
              <td>
                <i>Aucun dossier à traiter</i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(
  withTranslation(['jury', 'contact', 'common'])(Interview)
);
