/**
 * This file lists the missing elements for an application so the candidate can upload them
 */
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import LeftArrow from '../../../../assets/images/junia-icon-fleche-3.png';

import './MissingElement.scss';
import {
  FileToUpload,
  PieceComplementaire
} from '../../../../services/api/Files/interface';
import { Application_hed } from '../../../../services/api/Candidature/interface';
import { Contact, me } from '../../../../services/api/Contact/interface';
import ContactService from '../../../../services/api/Contact/ContactService';
import _ from 'lodash';
import UploadFile from '../../../../components/UploadFile/UploadFile';
import DisplayFiles from '../../../../components/DisplayFiles/DisplayFiles';
import FilesService from '../../../../services/api/Files/FilesService';
import EducationHistoryService from '../../../../services/api/EducationHistory/EducationHistoryService';
import Spinner from '../../../../utils/Spinner/Spinner';
import { EducationHistory } from '../../../../services/api/EducationHistory/interface';
import DisplayBlobFiles from '../../../../components/DisplayBlobFiles/DisplayBlobFiles';
import Tooltip from '../../../../components/Tooltip/Tooltip';

interface ContainerProps {
  previous: () => void;
  next: () => void;
  me?: me;
  applicationData: Application_hed;
  getApplication?: () => void;
  onStep: (
    application: Application_hed,
    step: number,
    files?: FileToUpload[]
  ) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const MissingElement: React.FC<IProps> = (props) => {
  const { t, previous, next, onStep, applicationData, getApplication, me } =
    props;
  const [height, setHeight] = useState<number>(0);
  const [contact, setContact] = useState<Contact>({});
  const divRef = useRef<HTMLDivElement>(null);
  const [fileList, setFileList] = useState<FileToUpload[]>([]);
  const [canNext, setCanNext] = useState<boolean>(false);
  const [bac, setBac] = useState<EducationHistory[]>([]);
  const [missings, setMissings] = useState<any[]>([]);
  const { candidate_id } = useParams<{candidate_id: string}>();

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);
  useEffect(() => {
    getContact();
  }, [me]);
  useEffect(() => {
  }, [contact]);
  useEffect(() => {
    gotAllFiles();
  }, [contact]);
  useEffect(() => {
    if (fileList.length > 0) {
      checkFileList();
    }
  }, [fileList]);

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element && element.clientHeight && element.clientHeight > 0) {
      setHeight(element.clientHeight + 8 + 70 + 50); //height + padding div + menu size
    }
  };

  const getContact = async () => {
    let contactId = candidate_id ? candidate_id : me?.id_heroku__c;
    if (contactId) {
      try {
        let contactResponse = await ContactService.getContact(contactId);
        let foundBac = contactResponse.data?.EducationHistorys.filter((educationHistory: EducationHistory) => educationHistory.type_de_diplome__c === 'Baccalauréat');
        if (
          (contactResponse.data.type_tech__c === 'Francais' ||
            contactResponse.data.type_tech__c === 'International') &&
          foundBac 
        ) {
          setBac(foundBac);
        }
        setContact(contactResponse.data);
      } catch (error) {
        console.log(
          '🚀 ~ file: MissingElement.tsx ~ line 84 ~ getContact ~ error',
          error
        );
      }
    }
  };

  const handleFileDelete = (file: any, type: string, fileType: string) => {
    let contactId = candidate_id ? candidate_id : me?.id_heroku__c;
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contactId) {
          FilesService.removeFile(file?.id_heroku__c, contactId).then(
            (res) => {
              let found = _.find(fileList, (f) => f.type === type);

              if (getApplication) {
                getApplication();
                getContact();
              }
            }
          );
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(liste, (f) => f.type === file.type);
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);

        break;
    }
  };

  const findMax = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined
  ) => {
    let foundCurrent = _.find(fileList, (f) => f.type === type);
    let foundSave = _.filter(files, (f) => f.type__c === type);
    let currentLength = foundCurrent ? foundCurrent.files.length : 0;
    let saveLength = foundSave ? foundSave.length : 0;
    return currentLength + saveLength;
  };

  const findMaxMap = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined,
    id: any
  ) => {
    if (files) {
      let foundCurrent = _.find(
        [...fileList],
        (f) => f.type === type && f.id === id
      );
      let foundSave = _.filter([...files], (f) => f.type__c === type);
      let currentLength = foundCurrent ? foundCurrent.files.length : 0;
      let saveLength = foundSave ? foundSave.length : 0;
      return currentLength + saveLength;
    } else {
      return 0;
    }
  };
  const send = async () => {
    Spinner.setSpinner(true);
    try {
      let grouped = await _.chain(fileList)
        .groupBy('object')
        .map(async (files, name) => {
          let retour;
          let finished;
          let promised;
          if (contact.id_heroku__c) {
            switch (name) {
              case 'contact':
                retour = await ContactService.patchContact(
                  contact.id_heroku__c,
                  {},
                  fileList,
                  ['resume', 'identity_card', 'scholarship']
                );
                break;
              case 'educationHistory':
                finished = await _.chain(files)
                  .groupBy('id')
                  .map(async (history, historyName) => {
                    if (contact.id_heroku__c) {
                      return await EducationHistoryService.patchContactEducationHistory(
                        contact.id_heroku__c,
                        { id_heroku__c: historyName },
                        history,
                        ['grades', 'bac_transcript']
                      );
                    }
                  })
                  .value();
                promised = await Promise.all(finished).then((list) => {
                  retour = list;
                  return list;
                });
                break;
            }
          }
          return retour;
        })
        .value();
      Promise.all(grouped).then((list) => {
        Spinner.setSpinner(false);
        onStep(applicationData, +1);
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: IncompleteFolder.tsx ~ line 160 ~ sendFiles ~ error',
        error
      );
    }
  };

  const handleFileChange = (files: File[], type: string, object: string) => {
    let list = [...fileList];
    if (files.length > 0) {
      let found = _.findIndex(list, (f) => f.type === type);
      if (found >= 0) {
        let data = [...list[found].files, ...files];
        list.splice(found, 1, { type: type, files: data, object });
      } else {
        list.push({ type: type, files: files, object });
      }

      setFileList(list);
    }
  };

  const gotAllFiles = () => {
    let gotAll = false;
    let missingFiles = [];
    let resume = contact.Files?.filter((file) => file.type__c === 'resume');
    let identity_card = contact.Files?.filter(
      (file) => file.type__c === 'identity_card'
    );
    let scholarship = contact.Files?.filter(
      (file) => file.type__c === 'scholarship'
    );

    let gotEducationHistorysFiles = true;
    _.map(contact.EducationHistorys, (educationHistory) => {
      let currentGrades = _.filter(
        educationHistory.Files,
        (f) => f.type__c === 'grades'
      );
      let currentBacTranscript = _.filter(
        educationHistory.Files,
        (f) => f.type__c === 'bac_transcript' || 'grades'
      );
      if (
        educationHistory.type_de_diplome__c === 'Baccalauréat' &&
        currentBacTranscript.length < 1
      ) {
        gotEducationHistorysFiles = false;
        if (contact.type_tech__c === 'Francais') {
          missingFiles.push({
            type: 'bac_transcript',
            object: 'educationHistory',
            id: educationHistory.id_heroku__c,
            got: false
          });
        } else if (contact.type_tech__c === 'International') {
          missingFiles.push({
            type:
              educationHistory.en_cours_ou_validee__c === 'Validée' &&
              educationHistory.niveau_enseignement_secondaire__c === '12th year'
                ? 'bac_transcript'
                : 'grades',
            object: 'educationHistory',
            id: educationHistory.id_heroku__c,
            got: false
          });
        }
      }

      if (
        educationHistory.type_de_diplome__c === 'Enseignement supérieur' &&
        currentGrades.length < 1
      ) {
        gotEducationHistorysFiles = false;
        missingFiles.push({
          type: 'grades',
          object: 'educationHistory',
          id: educationHistory.id_heroku__c,
          got: false
        });
      }
    });

    if (resume && resume.length < 1) {
      missingFiles.push({
        type: 'resume',
        object: 'contact',
        got: false
      });
    }
    if (identity_card && identity_card.length < 1) {
      missingFiles.push({
        type: 'identity_card',
        object: 'contact',
        got: false
      });
    }

    if (contact.boursier__c === 'Oui') {
      if (scholarship && scholarship.length < 1) {
        missingFiles.push({
          type: 'scholarship',
          object: 'contact',
          got: false
        });
      }
    }
    setMissings(missingFiles);
    let hasContactEducationHistoriesWithFiles = contact.EducationHistorys?.some(
      (educationHistory) => educationHistory.Files && educationHistory.Files.length
    );

    if (hasContactEducationHistoriesWithFiles && missingFiles.length === 0) {
      gotAll = true;
    }
    setCanNext(gotAll);
  };

  const checkFileList = async () => {
    let missingList: any[] = [];
    let maps = await _.chain(missings)
      .groupBy('object')
      .map((list, object) => {
        _.map(list, (o) => {
          let condition;
          if (o.object === 'contact') {
            condition = (f: any) => f.type === o.type && f.object === o.object;
          } else if (o.object === 'educationHistory') {
            condition = (f: any) =>
              f.type === o.type && f.object === o.object && f.id === o.id;
          }
          let found = _.find(fileList, condition);
          let value;
          if (found && found.files.length > 0) {
            value = { ...o, got: true };
          } else {
            value = { ...o, got: false };
          }
          missingList.push(value);
          return value;
        });
      })
      .value();
    let gotAll = _.groupBy(missingList, 'got');
    setMissings(missingList);
    setCanNext(gotAll.false ? false : true);
  };

  const hasFiles = (files: PieceComplementaire[] | undefined, type: string) => {
    let found = _.filter(files, (f) => f.type__c === type);
    return found.length > 0 ? true : false;
  };

  const handleFileChangeMap = (
    files: File[],
    type: string,
    object: string,
    id?: any
  ) => {
    let list = [...fileList];
    if (files.length > 0) {
      let found = _.findIndex(list, (f) => f.type === type && f.id === id);
      if (found >= 0) {
        let data = [...list[found].files, ...files];
        list.splice(found, 1, { type: type, files: data, object, id });
      } else {
        list.push({ type: type, files: files, object, id });
      }
      setFileList(list);
    }
  };

  const deleteMap = (file: any, type: string, fileType: string, id?: any) => {
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contact?.id_heroku__c) {
          FilesService.removeFile(
            file?.id_heroku__c,
            contact?.id_heroku__c
          ).then((res) => {
            let found = _.find(fileList, (f) => f.type === type);
            if (getApplication) {
              getContact();
              getApplication();
            }
          });
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(
          liste,
          (f) => f.type === file.type && f.id === id
        );
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);
        break;
    }
  };

  const handleNext = () => {
    if (canNext) {
      send();
    }
  };

  return (
    <div className="missing-container general-container">
      <div className="missing-elements">
        <div ref={divRef} className="missing-header">
          <button
            className="btn btn-return"
            onClick={() => onStep(applicationData, -1)}>
            <img alt="left arrow" src={LeftArrow} />
            <span>{t('return')}</span>
          </button>
          <div className="miss-header-title">
            <div className="left-element">
              <h3>{t('applyTitle')}</h3>
            </div>
            <div>
              {canNext ? (
                <button className="btn btn-main" onClick={() => handleNext()}>
                  <span>{t('next')}</span>
                </button>
              ) : (
                <button className="btn btn-disabled">
                  <span>{t('next')}</span>
                </button>
              )}
            </div>
          </div>
          <h5>{t('missingTitle')}</h5>
          <p className="alert-msg">{t('missingAlertMessage')}</p>
        </div>
        <div
          className="missing-list-container"
          style={{ height: 'calc(98% - ' + height + 'px)' }}>
          <div className="missing-list">
            {contact.id_heroku__c &&
              contact.Files &&
              !hasFiles(contact.Files, 'resume') && (
                <div>
                  <h6>{t('CV')}</h6>
                  <UploadFile
                    key="resume-upload"
                    onChange={(data) =>
                      handleFileChange(data, 'resume', 'contact')
                    }
                    max={2}
                    current={findMax('resume', 2, contact.Files)}
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="resume"
                    onDelete={(data) =>
                      handleFileDelete(data, 'resume', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={applicationData.Files}
                    type="resume"
                    onDelete={(data) =>
                      handleFileDelete(data, 'resume', 'uploaded')
                    }></DisplayFiles>
                </div>
              )}
            {contact.id_heroku__c &&
              contact.Files &&
              !hasFiles(contact.Files, 'identity_card') && (
                <div>
                  <h6>{t('idCard')}</h6>
                  <UploadFile
                    key="identity_card-upload"
                    onChange={(data) =>
                      handleFileChange(data, 'identity_card', 'contact')
                    }
                    max={2}
                    current={findMax('identity_card', 2, contact.Files)}
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="identity_card"
                    onDelete={(data) =>
                      handleFileDelete(data, 'identity_card', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={applicationData.Files}
                    type="identity_card"
                    onDelete={(data) =>
                      handleFileDelete(data, 'identity_card', 'uploaded')
                    }></DisplayFiles>
                </div>
              )}
            <div>
            {contact.type_tech__c === 'Francais'  &&
                bac.length >= 1 && (
                  <React.Fragment>
                   {bac.map(b => {
                    let File = b?.Files?.filter(f => f.type__c === 'bac_transcript');
                    if(File && File.length < 1) {
                      return      <React.Fragment>
                      {contact.type_tech__c === 'Francais' ? (
                        <h5>
                          {t('bac')} - {b.option_du_bac__c}
                        </h5>
                      ) : (
                        <h5>{b.type_de_bac_intl__c}</h5>
                      )}
                      <h6>{t('grades')}</h6>
                      <UploadFile
                        key="grades-upload"
                        onChange={(data) =>
                          handleFileChangeMap(
                            data,
                            'bac_transcript',
                            'educationHistory',
                            b.id_heroku__c
                          )
                        }
                        current={findMaxMap(
                          'bac_transcript',
                          2,
                          b.Files,
                          b.id_heroku__c
                        )}
                        max={2}
                        type="pdf"></UploadFile>
                      <DisplayBlobFiles
                        files={_.filter(
                          [...fileList],
                          (f) => f.id === b.id_heroku__c
                        )}
                        type="bac_transcript"
                        onDelete={(data) =>
                          deleteMap(
                            data,
                            'bac_transcript',
                            'blob',
                            b.id_heroku__c
                          )
                        }></DisplayBlobFiles>
                      <DisplayFiles
                        files={applicationData.Files}
                        type="bac_transcript"
                        onDelete={(data) =>
                          deleteMap(
                            data,
                            'bac_transcript',
                            'uploaded',
                            b.id_heroku__c
                          )
                        }></DisplayFiles>
                    </React.Fragment>
                    }
                   })}
                  </React.Fragment>
                )}
              {
                contact.type_tech__c === 'International' &&
                bac.length >= 1 && (
                  <React.Fragment>
                   {bac.map(b => {
                    let File = b?.Files?.filter(f => f.type__c === 'bac_transcript' || f.type__c === 'grades');
                    let fileType = b.en_cours_ou_validee__c === 'Validée' && b.niveau_enseignement_secondaire__c === '12th year' ? 'bac_transcript' : 'grades';
                    if(File && File.length < 1) {
                      return      <React.Fragment>
                      <h5>{b.type_de_bac_intl__c}</h5>
                      <h6>{t('grades')}</h6>
                      <UploadFile
                        key="grades-upload"
                        onChange={(data) =>
                          handleFileChangeMap(
                            data,
                            fileType,
                            'educationHistory',
                            b.id_heroku__c
                          )
                        }
                        current={findMaxMap(
                          fileType,
                          2,
                          b.Files,
                          b.id_heroku__c
                        )}
                        max={2}
                        type="pdf"></UploadFile>
                      <DisplayBlobFiles
                        files={_.filter(
                          [...fileList],
                          (f) => f.id === b.id_heroku__c
                        )}
                        type={fileType}
                        onDelete={(data) =>
                          deleteMap(
                            data,
                            fileType,
                            'blob',
                            b.id_heroku__c
                          )
                        }></DisplayBlobFiles>
                      <DisplayFiles
                        files={applicationData.Files}
                        type={fileType}
                        onDelete={(data) =>
                          deleteMap(
                            data,
                            fileType,
                            'uploaded',
                            b.id_heroku__c
                          )
                        }></DisplayFiles>
                    </React.Fragment>
                    }
                   })}
                  </React.Fragment>
                )}
              {contact &&
                contact?.EducationHistorys &&
                contact?.EducationHistorys.length > 0 &&
                _.chain(contact.EducationHistorys)
                  .filter(
                    (e) => e.type_de_diplome__c === 'Enseignement supérieur'
                  )
                  .orderBy('createddate')
                  .map((educationHistory) => {
                    let grades = _.filter(
                      educationHistory.Files,
                      (f) => f.type__c === 'grades'
                    );
                    return (
                      <React.Fragment>
                        {grades.length < 1 && (
                          <React.Fragment>
                            {contact.type_tech__c !== 'International' ? (
                              <h5>
                                {educationHistory.intitule_de_formation__c} -{' '}
                                {educationHistory.specialite_domaine_fr__c}
                              </h5>
                            ) : (
                              <h5>
                                {educationHistory.intitul_de_formation_intl__c}{' '}
                                - {educationHistory.specialite__c}
                              </h5>
                            )}
                            <h6>
                              {t('grades')}
                              <Tooltip
                                message={t('gradesTranscript')}></Tooltip>
                            </h6>
                            <UploadFile
                              key="grades-upload"
                              onChange={(data) =>
                                handleFileChangeMap(
                                  data,
                                  'grades',
                                  'educationHistory',
                                  educationHistory.id_heroku__c
                                )
                              }
                              max={2}
                              current={findMaxMap(
                                'grades',
                                2,
                                educationHistory.Files,
                                educationHistory.id_heroku__c
                              )}
                              type="pdf"></UploadFile>
                            <DisplayBlobFiles
                              files={_.filter(
                                [...fileList],
                                (f) => f.id === educationHistory.id_heroku__c
                              )}
                              type="grades"
                              onDelete={(data) =>
                                deleteMap(
                                  data,
                                  'grades',
                                  'blob',
                                  educationHistory.id_heroku__c
                                )
                              }></DisplayBlobFiles>
                            <DisplayFiles
                              files={applicationData.Files}
                              type="grades"
                              onDelete={(data) =>
                                deleteMap(
                                  data,
                                  'grades',
                                  'uploaded',
                                  educationHistory.id_heroku__c
                                )
                              }></DisplayFiles>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    );
                  })
                  .value()}
              {canNext && (
                <div>
                  <p>{t('noMissingFile')}</p>
                </div>
              )}
            </div>
            {contact.id_heroku__c &&
              contact.Files &&
              contact.boursier__c === 'Oui' &&
              !hasFiles(contact.Files, 'scholarship') && (
                <div>
                  <h6>{t('scolarshipHolderJustif')}</h6>
                  <UploadFile
                    key="scholarship-upload"
                    onChange={(data) =>
                      handleFileChange(data, 'scholarship', 'contact')
                    }
                    max={1}
                    current={findMax('scholarship', 1, contact.Files)}
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="scholarship"
                    onDelete={(data) =>
                      handleFileDelete(data, 'scholarship', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={applicationData.Files}
                    type="scholarship"
                    onDelete={(data) =>
                      handleFileDelete(data, 'scholarship', 'uploaded')
                    }></DisplayFiles>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['home', 'common', 'application'])(MissingElement)
);
