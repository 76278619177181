import axios, { AxiosRequestConfig } from 'axios';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { catchError, delayWhen, retryWhen, tap } from 'rxjs/operators';
import AuthService from '../services/api/Auth/AuthService';
const { NODE_ENV, REACT_APP_BACK_URL } = process.env;
class JwtInterceptor {
  intercept(): void {
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.url?.includes('/auth/refresh')) {
        return this.addRefreshToken(config);
      } else {
        return this.addToken(config);
      }
    });
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // console.log(JSON.stringify(error));
        if (error.response) {
          if (
            error.response.status === 400 &&
            error.response.data === 'invalid signature'
          ) {
            AuthService.logout();
            axios.interceptors.response.eject(interceptor);
            return error;
          }
          if (error.response.status !== 401) {
            return Promise.reject(error);
          } else {
            // AuthService.logout();
          }

          if (error.response.message === 'jwt malformed') {
            AuthService.logout();
            axios.interceptors.response.eject(interceptor);
            return error;
          } else {
            /*
             * When response code is 401, try to refresh the token.
             * Eject the interceptor so it doesn't loop in case
             * token refresh causes the 401 response
             */
            axios.interceptors.response.eject(interceptor);
            return AuthService.refreshToken()
              .then((tokenRefreshResponse: any) => {
                AuthService.setToken(tokenRefreshResponse.data.accessToken);
                error.response.config.headers['Authorization'] =
                  'Bearer ' + tokenRefreshResponse.data.access_token;
                return axios(error.response.config);
              })
              .catch((err) => {
                AuthService.logout();
              });
          }
        } else {
          // AuthService.logout();
        }
      }
    );
  }

  // refreshToken = async () => {
  refreshAuthLogic = async (failedRequest: any) => {
    AuthService.refreshToken()
      .then((tokenRefreshResponse: any) => {
        AuthService.setToken(tokenRefreshResponse.data.accessToken);
        failedRequest.response.config.headers['Authorization'] =
          'Bearer ' + tokenRefreshResponse.data.accessToken;
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(
          '🚀 ~ file: jwt.interceptor.ts ~ line 67 ~ JwtInterceptor ~ AuthService.refreshToken ~ err',
          err
        );
      });
  };

  // Instantiate the interceptor (you can chain it as it returns the axios instance)

  addToken(req: AxiosRequestConfig) {
    let token = AuthService.getToken();
    if (token) {
      req.headers['Authorization'] = `Bearer ${token}`;
      return req;
    } else {
      return req;
    }
  }
  addRefreshToken(req: AxiosRequestConfig) {
    let token = AuthService.getToken();
    let refreshToken = AuthService.getRefreshToken();
    if (token && refreshToken) {
      req.headers['Authorization'] = `Bearer ${token} ${refreshToken}`;
      return req;
    } else {
      return req;
    }
  }
}
export default new JwtInterceptor();
