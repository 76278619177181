/* 
  This service is used to interact with the Formation routes of the API server
*/

import axios, { AxiosResponse } from 'axios';

const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class FormationService {
  /**
   * Get formations
   * @param {string} user_id the user id
   * @param {object} filter optional filter object
   * @returns list of formations
   */
  getFormations(user_id: string, filter?: any): Promise<AxiosResponse> {
    return axios.get(`${url}/formations/${user_id}`, {
      params: { filter: filter }
    });
  }

  getFormation(formation_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/${formation_id}/formation`);
  }
}
export default new FormationService();
