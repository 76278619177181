import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';

// Components
import Modal from '../../components/Modal/Modal';

// Interfaces
import { Contact } from '../../services/api/Contact/interface';
import { Application_hed } from '../../services/api/Candidature/interface';
import { me } from '../../services/api/Contact/interface';
import { Period } from '../../services/api/Period/interface';

// Services
import AuthService from '../../services/api/Auth/AuthService';
import CandidatureService from '../../services/api/Candidature/CandidatureService';
import PeriodService from '../../services/api/Period/PeriodService';

// Picklists
import formationPicklist from '../../assets/data/formation.json';

// Utils
import Spinner from '../../utils/Spinner/Spinner';
import Alert from '../../utils/Alert/Alert';

// Material UI
import ClearIcon from '@mui/icons-material/Clear';

// Helpers
import _ from 'lodash';

// Styles
import './Applications.scss';

interface ContainerProps {
  useContact: Contact;
}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Agent: React.FC<IProps> = (props) => {
  const { t, useContact } = props;
  const { i18n } = useTranslation();

  const history = useHistory();
  const [me, setMe] = useState<me>({});
  const [candidatures, setCandidatures] = useState<Application_hed[]>([]);
  const [candidaturesFiltered, setCandidaturesFiltered] = useState<
    Application_hed[]
  >([]);
  const [currentPeriod, setCurrentPeriod] = useState<Period>({});
  const [modalAcceptIsOpen, setModalAcceptIsOpen] = useState<boolean>(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState<boolean>(false);
  const [modalDeclineIsOpen, setModalDeclineIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Application_hed>();

  const filterList = [
    {
      label: 'alphabeticalOrder',
      value: 'alphabeticalOrder'
    },
    {
      label: 'applicationStatus',
      value: 'statut__c'
    },
    {
      label: 'currentPeriod',
      value: 'createddate'
    }
  ];
  const documentStatusTranslations = [
    {
      french: 'En cours de vérification',
      english: 'Being verified'
    },
    {
      french: 'Complet',
      english: 'Complete'
    },
    {
      french: 'Incomplet',
      english: 'Incomplete'
    },
    {
      french: 'Candidature hors délai',
      english: 'Late application'
    }
  ];

  const statusTranslations = [
    {
      french: 'Brouillon',
      english: 'Draft'
    },
    {
      french: 'Dépôt candidature',
      english: 'Application sent'
    },
    {
      french: 'Dossier complet',
      english: 'Complete application'
    },
    {
      french: 'Dossier incomplet',
      english: 'Incomplete application'
    },
    {
      french: 'Attente avis Etude en France',
      english: 'On hold - Etudes en France notice'
    },
    {
      french: 'Attente jury fin d’année',
      english: 'On hold - end of year jury'
    },
    {
      french: 'Attente jury fin d’année (partenaire)',
      english: 'End of year jury pending'
    },
    {
      french: 'Etude du dossier',
      english: 'File review'
    },
    {
      french: 'Convoqué Entretien',
      english: 'Invited to interview'
    },
    {
      french: 'Entretien',
      english: 'Interview'
    },
    {
      french: 'Admis',
      english: 'Accepted'
    },
    {
      french: 'Admis sous réserve',
      english: 'Accepted under conditions'
    },
    {
      french: 'Non admis',
      english: 'Refused'
    },
    {
      french: 'Sans suite',
      english: 'No further action'
    },
    {
      french: 'Abandon',
      english: 'Withdrawal'
    },
    {
      french: 'Admis ayant accepté la proposition',
      english: 'Acceptation confirmed'
    },
    {
      french: 'Refus',
      english: 'Declined'
    },
    {
      french: 'Entretien planifié',
      english: 'Interview planned'
    },
    {
      french: 'Démissionnaire',
      english: 'Resigned'
    },
  ];

  useEffect(() => {
    getMe();
  }, []);
  useEffect(() => {
    getApplications();
    getCurrentPeriod();
  }, [me]);

  const getMe = async () => {
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };

  const sortApplicationsByCandidateName = (applications: Application_hed[]) => {
    return applications.sort((a: any, b: any) => {
      let paramA =
        a.Contact?.['lastname']?.toLocaleLowerCase() +
          a.Contact?.['firstname']?.toLocaleLowerCase() ?? 0;
      let paramB =
        b.Contact?.['lastname']?.toLocaleLowerCase() +
          b.Contact?.['firstname']?.toLocaleLowerCase() ?? 0;
      return paramA > paramB ? 1 : paramA < paramB ? -1 : 0;
    });
  };

  const getApplications = async () => {
    let candidatureReponse = await CandidatureService.getCandidaturesAgent();
    const sortedCandidatures = sortApplicationsByCandidateName(
      candidatureReponse.data
    );
    setCandidatures(candidatureReponse.data);
    setCandidaturesFiltered(sortedCandidatures);
  };

  const getCurrentPeriod = async () => {
    let currentPeriodResponse = await PeriodService.getCurrentPeriod();
    setCurrentPeriod(currentPeriodResponse.data);
  };

  const classNames = (candidature: Application_hed) => {
    let statutColor = '';
    if (candidature && candidature.statut__c) {
      if (
        candidature.statut__c === 'Brouillon' ||
        candidature.statut__c === 'En attente' ||
        candidature.statut__c === 'Attente avis Etude en France' ||
        candidature.statut__c === 'Attente jury fin d’année' ||
        candidature.statut__c === 'Attente jury fin d’année (partenaire)' ||
        candidature.statut__c === 'Etude du dossier' ||
        candidature.statut__c === 'Convoqué Entretien' ||
        candidature.statut__c === "Liste d'attente"
      ) {
        statutColor = 'orange';
      } else if (
        candidature.statut__c === 'Admis' ||
        candidature.statut__c === 'Dépôt candidature' ||
        candidature.statut__c === 'Dossier complet' ||
        candidature.statut__c === 'Entretien planifié' ||
        candidature.statut__c === 'Entretien' ||
        candidature.statut__c === 'Admis sous réserve' ||
        candidature.statut__c === 'Admis ayant accepté la proposition'
      ) {
        statutColor = 'green';
      } else if (
        candidature.statut__c === 'Dossier incomplet' ||
        candidature.statut__c === 'Démissionnaire' ||
        candidature.statut__c === 'Non admis' ||
        candidature.statut__c === 'Sans suite' ||
        candidature.statut__c === 'Abandon' ||
        candidature.statut__c === 'Refus'
      ) {
        statutColor = 'red';
      }
    }
    return statutColor;
  };

  const resetApplicationFilter = () => {
    setCandidaturesFiltered(candidatures);
  };

  const handleFilter = (e: any): void => {
    resetApplicationFilter();
    const value = e.target.value;
    let filteredCandidatureList: Application_hed[];

    // Sort applications by status
    if (value === 'statut__c') {
      filteredCandidatureList = candidatures.sort((a: any, b: any) =>
        a.statut__c > b.statut__c ? 1 : a.statut__c < b.statut__c ? -1 : 0
      );
    }
    // Sort by current period (current fiscal year)
    else if (value === 'createddate') {
      filteredCandidatureList = candidatures.filter(
        (candidature: Application_hed) => {
          if (
            candidature.createddate &&
            currentPeriod.startdate &&
            currentPeriod.enddate
          ) {
            return (
              currentPeriod.startdate <= candidature.createddate &&
              currentPeriod.enddate >= candidature.createddate
            );
          }
        }
      );
    }
    // Default sort by alphabetical order based on candidate name
    else {
      filteredCandidatureList = sortApplicationsByCandidateName(candidatures);
    }
    setCandidaturesFiltered(() => [...filteredCandidatureList]);
  };

  const handleModalClose = async (): Promise<void> => {
    setSelected(undefined);
    setModalAcceptIsOpen(false);
    setModalDeleteIsOpen(false);
    setModalDeclineIsOpen(false);
  };

  const openModalDelete = (data: Application_hed) => {
    setSelected(data);
    setModalDeleteIsOpen(true);
  };

  const openModalAccept = (data: Application_hed) => {
    setSelected(data);
    setModalAcceptIsOpen(true);
  };

  const openModalDecline = (data: Application_hed) => {
    setSelected(data);
    setModalDeclineIsOpen(true);
  }

  const removeApplication = (candidature: any) => {
    if (candidature.Contact?.id_heroku__c && candidature.id_heroku__c) {
      Spinner.setSpinner(true);
      CandidatureService.cancelCandidature(candidature.Contact?.id_heroku__c, candidature.id_heroku__c)
        .then((reponse) => {
          Spinner.setSpinner(false);
          getApplications();
          handleModalClose();
          Alert.setAlert({
            open: true,
            type: 'success',
            duration: 3000,
            message: t('successDelete')
          });
        })
        .catch((error) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            open: true,
            type: 'error',
            duration: 3000,
            message: t('failDelete')
          });
        });
    }
  };

  const acceptApplication = (candidature: Application_hed) => {
    Spinner.setSpinner(true);
    if (candidature.Contact?.id_heroku__c && candidature.id_heroku__c) {
      CandidatureService.acceptCandidature(
        candidature.Contact?.id_heroku__c,
        candidature.id_heroku__c
      )
        .then((res) => {
          Spinner.setSpinner(false);
          getApplications();
          handleModalClose();
          Alert.setAlert({
            open: true,
            type: 'success',
            duration: 3000,
            message: t('successAccept')
          });
        })
        .catch((error) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            open: true,
            type: 'error',
            duration: 3000,
            message: t('failAccept')
          });
        });
    }
  };

  const declineApplication = (candidature: Application_hed) => {
    Spinner.setSpinner(true);
    if (candidature.Contact?.id_heroku__c && candidature.id_heroku__c) {
      CandidatureService.declineCandidature(
        candidature.Contact?.id_heroku__c,
        candidature.id_heroku__c
      )
        .then((res) => {
          Spinner.setSpinner(false);
          getApplications();
          handleModalClose();
          Alert.setAlert({
            open: true,
            type: 'success',
            duration: 3000,
            message: t('successDecline')
          });
        })
        .catch((error) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            open: true,
            type: 'error',
            duration: 3000,
            message: t('failDecline')
          });
        });
    }
  };

  return (
    <div className="applied general-container">
      <div className="agent-list">
        <div className="agent-header">
          <div className="left">
            <p>{t('applicationInProgress')}</p>
            <h3>{candidatures.length}</h3>
          </div>
        </div>
        <div className="agent-title-filter">
          <div className="agent-history">
            <h4>{t('applicationHistory')}</h4>
          </div>
          <div className="agent-filters">
            {/* <input type="search" placeholder={t('find')} /> */}
            <select onChange={handleFilter}>
              {filterList.map((filter) => (
                <option value={filter.value} key={filter.label}>
                  {t(filter.label)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="application-list">
        {candidaturesFiltered.length > 0 ? (
          candidaturesFiltered.map((candidature: Application_hed) => {
            let name: any = '';
            if (i18n.language === 'fr') {
              name = candidature.Formation?.nom_de_la_formation__c;
            }
            if (i18n.language === 'en') {
              name = candidature.Formation?.nom_formation_anglais__c;
            }
            return (
              <div className="application-card" key={candidature.id_heroku__c}>
                <div className="application-card-header">
                  <div>
                    <p>
                      {candidature.name} / {candidature.Contact?.lastname}{' '}
                      {candidature.Contact?.firstname}
                    </p>
                    <h5>
                      {candidature.Formation?.cole__c} -{' '}
                      {candidature.Formation?.campus__c} / {name}
                    </h5>
                  </div>
                  <div className="action-buttons">
                  <button
                        className="remove-application"
                        onClick={() => openModalDelete(candidature)}>
                        <ClearIcon></ClearIcon>
                      </button>
                      {candidature.statut__c === 'Brouillon' && (
                        <div>
                          <button
                            className="btn btn-outlined"
                            onClick={() =>
                              history.push(
                                `/agent/${candidature.Contact?.id_heroku__c}/candidature-process/${candidature.id_heroku__c}`
                              )
                            }>
                            <span>{t('finalizeApplication')} </span>
                          </button>
                        </div>
                      )}
                      {candidature.statut__c === 'Dossier incomplet' && (
                        <div>
                          <button
                            className="btn btn-outlined"
                            onClick={() =>
                              history.push(
                                `/agent/${candidature.Contact?.id_heroku__c}/incomplete-folder/${candidature.id_heroku__c}`
                              )
                            }>
                            <span>{t('modifydocs')} </span>
                          </button>
                        </div>
                      )}
                        {(candidature.statut__c === 'Admis' ||
                        candidature.statut__c === 'Admis sous réserve') && (
                        <div>
                          <div>
                            <button
                              className="btn btn-outlined"
                              onClick={() => openModalDecline(candidature)}>
                              <span>{t('declineApply')} </span>
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outlined"
                              onClick={() => openModalAccept(candidature)}>
                              <span>{t('acceptApply')} </span>
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="application-card-content">
                  <div className="application-info">
                    <h6>{t('languageEducation')}</h6>
                    <p>
                      {formationPicklist?.picklistFieldValues[
                        'language__c'
                      ].values.map((value: any) => {
                        if (
                          value.value === candidature.Formation?.language__c
                        ) {
                          return i18n.language === 'en'
                            ? value.value
                            : value.label;
                        }
                      })}
                    </p>
                  </div>
                  <div className="application-info">
                    <h6>{t('documentsStatusTitle')}</h6>
                    <p>
                      {documentStatusTranslations.map((status: any) => {
                        if (status.french === candidature.dossier__c) {
                          return i18n.language === 'fr'
                            ? status.french
                            : status.english;
                        }
                      })}
                    </p>
                  </div>
                  <div className="application-info">
                    <h6>{t('applyStatusTitle')}</h6>
                    <p className={classNames(candidature)}>
                      <>
                        {i18n.language === 'fr' &&
                        candidature.statut__c ===
                          'Attente jury fin d’année (partenaire)'
                          ? 'En attente Jury fin d’année'
                          : i18n.language === 'fr' && candidature.statut__c}
                        {i18n.language === 'en' &&
                          statusTranslations.map((status) => {
                            if (status.french === candidature.statut__c) {
                              return status.english;
                            }
                          })}
                      </>
                    </p>
                  </div>
                  <div className="application-info">
                    <h6>{t('depositPaid')}</h6>
                    <p>{candidature.acompte_pay__c ? t('yes') : t('no')}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h5>{t('noApplications')} </h5>
        )}
      </div>
      <Modal
        title={t('deleteApply')}
        classe="delete-apply-modal"
        closeModal={() => handleModalClose()}
        modalIsOpen={modalDeleteIsOpen}>
        <h5>{t('deleteSure')}</h5>
        <h5>{t('deleteCondition')}</h5>
        <div className="modal-buttons">
          <button
            onClick={() => removeApplication(selected)}
            className="btn btn-yes">
            {t('yes')}
          </button>
          <button onClick={() => handleModalClose()} className="btn btn-no">
            {t('no')}
          </button>
        </div>
      </Modal>
      <Modal
        title={t('acceptApplyTitle')}
        classe="delete-apply-modal"
        closeModal={() => handleModalClose()}
        modalIsOpen={modalAcceptIsOpen}>
        <h5>{t('acceptSure')}</h5>
        <div className="modal-buttons">
          <button
            onClick={() => {
              if (selected) {
                return acceptApplication(selected);
              }
            }}
            className="btn btn-yes">
            {t('yes')}
          </button>
          <button onClick={() => handleModalClose()} className="btn btn-no">
            {t('no')}
          </button>
        </div>
      </Modal>
      <Modal
        title={t('declineApplyTitle')}
        classe="delete-apply-modal"
        closeModal={() => handleModalClose()}
        modalIsOpen={modalDeclineIsOpen}>
        <h5>{t('declineSure')}</h5>
        <div className="modal-buttons">
          <button
            onClick={() => {
              if (selected) {
                return declineApplication(selected);
              }
            }}
            className="btn btn-yes">
            {t('yes')}
          </button>
          <button onClick={() => handleModalClose()} className="btn btn-no">
            {t('no')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(
  withTranslation(['agent', 'common', 'application'])(Agent)
);
