/**
 * This component displays a form to add a language skill other than french or english
 */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CompetenceLinguistique } from '../../../../../services/api/CompetenceLinguistique/interface';
import { Language } from '../../../../../services/api/Languages/interface';

import './Competence.scss';
import languagePicklist from '../../../../../assets/data/hed_contact_language.json';

interface ContainerProps {
  competence: CompetenceLinguistique;
  onChange: (data: any) => void;
  handleDelete?: (data: Language) => void;
  language: Language;
  languages: Language[];
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Competence: React.FC<IProps> = (props) => {
  const { t, competence, onChange, languages, language, handleDelete } = props;

  const [form, setForm] = useState<CompetenceLinguistique>(competence);
  const [otherLanguageSfid, setOtherLanguageSfid] = useState<string>();

  const [years, setYears] = useState<string[]>([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    buildYears();
  }, []);
  useEffect(() => {
    setForm(language);
  }, [language.index]);

  useEffect(() => {
    const otherLanguageSfid = languages.find(language => language.name === 'Autres')?.sfid;
    setOtherLanguageSfid(otherLanguageSfid)
  }, [languages]);

  const handleChange = (e: any): void => {
    let newForm = {
      ...form,
      [e.target.name]: e.target.value
    };
    setForm(newForm);
    onChange(newForm);
  };
  const onDelete = () => {
    if (handleDelete) {
      handleDelete(form);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefautl();
  };
  const buildYears = () => {
    let given = parseInt(moment().format('YYYY'));
    let diff = given - 1901;
    let array = [''];
    for (let index = 0; index <= diff; index++) {
      let year = given - index;
      array.push(year.toString());
    }
    setYears(array);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <h6 className="form-h6">
            {t('language')} <span className="form-required">*</span>
          </h6>
          <select
            value={form?.hed__language__c}
            name="hed__language__c"
            onBlur={handleChange}
            onChange={handleChange}>
            <option value={''}> </option>
            {languages.length > 0 &&
              languages.map((language) => {
                if (language.is_active__c === true) {
                  return (
                    <option value={language.sfid} key={language.sfid}>
                      {i18n.language === 'fr'
                        ? language.name
                        : language.language_en__c}
                    </option>
                  );
                }
              })}
          </select>
        </div>
        {form.hed__language__c === otherLanguageSfid && (
          <div>
            <h6 className="form-h6">{t('otherLanguage')}</h6>
            <input
              name="autre_langue__c"
              value={form.autre_langue__c}
              onBlur={handleChange}
              onChange={handleChange}
            />
          </div>
        )}
        <div>
          <h6 className="form-h6">
            {t('level')}
            <span className="form-required">*</span>
          </h6>
          <select
            value={form?.hed__fluency__c}
            name="hed__fluency__c"
            onBlur={handleChange}
            onChange={handleChange}>
            <option value=""></option>
            {i18n.language === 'fr' &&
              _.chain(
                languagePicklist.picklistFieldValues['hed__Fluency__c'].values
              )
                // .pick(1)
                .map((p) => {
                  if (p && p.value) {
                    return <option value={p.value} key={p.value}> {p.value} </option>;
                  }
                })
                .value()}
            {i18n.language === 'en' &&
              _.chain(
                languagePicklist.picklistFieldValues['hed__Fluency__c'].values
              )
                // .pick(1)
                .map((p) => {
                  if (p && p.value) {
                    return <option value={p.value} key={p.value}> {p.label} </option>;
                  }
                })
                .value()}
          </select>
        </div>
      </form>
      {handleDelete && (
        <div className="delete-line">
          <button className="btn-underline" onClick={onDelete}>
            <span>{t('word.delete')}</span>
          </button>
        </div>
      )}
      <hr></hr>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(Competence)
);
