/**
 * This component displays the internatioal or exchange choice buttons on create account
 */
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import logoECH from '../../../../assets/images/junia-icon-echange.png';
import logoINT from '../../../../assets/images/junia-icon-internationnal.png';
import './InternationalSystemEducation.scss';
import { Contact } from '../../../../services/api/Contact/interface';

interface ContainerProps {
  contact: Contact;
  onChange: (data: Contact, canNext: boolean) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const InternationalSystemEducation: React.FC<IProps> = (props) => {
  const { t, contact, onChange } = props;

  const [isEchangeStudent, setIsEchangeStudent] = useState<null | boolean>(
    null
  );

  const handleEducationSystemChange = (value: boolean) => {
    let updatedContact = { ...contact };
    setIsEchangeStudent(value);
    onChange({ type_tech__c: value ? 'Echange' : 'International' }, true);
  };
  return (
    <div>
      <div className="education-sytem">
        <button
          className={`card-button ${
            isEchangeStudent === false ? 'selected' : ''
          }`}
          type="button"
          onClick={() => handleEducationSystemChange(false)}>
          <div className="card-button-icon">
            <img src={logoINT} alt="icone France" />
          </div>
          <div className="card-button-text">
            <p>{t('international_candidate_title')}</p>
            <span>{t('international_candidate_description')}</span>
          </div>
        </button>
        <button
          className={`card-button ${
            isEchangeStudent === true ? 'selected' : ''
          }`}
          type="button"
          onClick={() => handleEducationSystemChange(true)}>
          <div className="card-button-icon">
            <img src={logoECH} alt="icone France" />
          </div>
          <div className="card-button-text">
            <p>{t('international_exchange_title')}</p>
            <span>{t('international_exchange_description')}</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(
    InternationalSystemEducation
  )
);
