/* 
  This service is used to fetch picklists from the server 
*/

import axios, { AxiosResponse } from 'axios';

const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
/**
 * This class contains all the methods that can be used to fetch ContentDocument Id and Title and contentVersion bodyfrom the server api
 */
class ContentDocumentService {
  /**
   * Method to fetch ContentDocument Id and Title
   * @returns a list of all the ContentDocument Id and Title 
   */
  getContentDocument(contact_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/content_document/${contact_id}`);
  }
  /**
   * Method to fetch ContentVersion body
   * @returns a the contentVersion body
   */
  getContentVersion(content_document_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/content_document/content_version/${content_document_id}`);
  }
}
export default new ContentDocumentService();
