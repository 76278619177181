import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
// import translationEN from './locales/en/translation.json';
// import translationFR from './locales/fr/translation.json';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
const language = localStorage.getItem('i18nextLng');
import LngDetector from 'i18next-browser-languagedetector';
import 'moment/locale/fr';
import ContactService from './services/api/Contact/ContactService';
import AuthService from './services/api/Auth/AuthService';
import Spinner from './utils/Spinner/Spinner';
// the translations
// const resources = {
//   en: {
//     translation: translationEN
//   },
//   fr: {
//     translation: translationFR
//   }
// };
const changeLanguage = async (lng) => {
  localStorage.setItem('i18nextLng', lng);
  i18n.changeLanguage(lng);
  moment.locale(lng);
  try {
    let loggedIn = AuthService.isLoginSubject$.getValue()
    if(loggedIn){
      Spinner.setSpinner(true)
      let me = await AuthService.me()
      await ContactService.patchContact(me.data.id_heroku__c, {portal_language_tech__c:i18n.language.toUpperCase()})
      Spinner.setSpinner(false)

    }
  } catch (error) {

  }
};
i18n
  .use(LngDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      console.log(language, namespace)
      import(`./public/assets/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          console.log(error)
          callback(error, null);
        });
    })
  )
  .init({
    // resources,
    useSuspense: false,
    react: {
      nsMode: 'fallback'
    },
    // ns: ['translation', 'publications'],
    detection: {
      order: ['localStorage', 'navigator']
    },

    fallbackNS: 'common',
    defaultNS: 'common',
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'fr',
    backend: {
      loadPath: '/src/public/assets/locales/{{lng}}/{{ns}}.json'
    }
  });
export default { i18n, changeLanguage };
