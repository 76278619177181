import React, { createElement, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { 
  withRouter,
  RouteComponentProps,
  useParams,
  useHistory,
  Link } from 'react-router-dom';
import i18n from '../../i18n';

import junialogo from '../../assets/images/junialogoviolet.png';
import flagUK from '../../assets/images/flagUK.png';
import flagFR from '../../assets/images/flagFR.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './CreatePassword.scss';
import AuthService from '../../services/api/Auth/AuthService';
import Alert from '../../utils/Alert/Alert';
import Spinner from '../../utils/Spinner/Spinner';

interface ContainerProps {}

type ChildProps = {};

type AuthForm = {
  password: string;
  confirmPassword: string;
  cgu_acceptees_portail__c: boolean;
};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const formLoginInitialState = {
  password: '',
  confirmPassword: '',
  cgu_acceptees_portail__c: false
};

const formRegisterFields = {
  confirmPassword: ''
};

const CreatePassword: React.FC<IProps> = (props) => {
  const { t } = props;
  const [active, setActive] = useState(localStorage.getItem('i18nextLng'));

  const [form, setForm] = useState<AuthForm>(formLoginInitialState);
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [passwordHidden, setPasswordHidden] = useState<boolean>(true);
  let { email, token } = useParams<{ email: string; token: string }>();
  const history = useHistory();
  useEffect(() => {
    let newForm = { ...form, email: email, token: token };
    setForm(newForm);
  }, [isLoginForm]);

  const handleChange = (e: any): void => {
    let value = e.target.value;
    let check = ['cgu_acceptees_portail__c'];

    if (check.includes(e.target.name)) {
      value = e.target.checked;
    }
    setForm({
      ...form,
      [e.target.name]: value
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isLoginForm) {
      Spinner.setSpinner(true);
      AuthService.createPassword(form)
        .then((res) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            duration: 3000,
            message: t('word.resetPassword'),
            open: true,
            type: 'success'
          });
          history.push('/');
        })
        .catch((err) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            duration: 3000,
            message: t(
              err.response.data.message
                ? err.response.data.message
                : err.response.data
            ),
            open: true,
            type: 'error'
          });
        });
    }
  };

  return (
    <div className="authenticate">
      <div className="authenticate-container">
        <div className="authenticate__header">
          <img src={junialogo} alt="junia logo" />
          <h4>{t('page_title')}</h4>
          <div className="authenticate__header__language-cont">
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('fr');
                setActive('fr');
              }}
              className={active === 'fr' ? 'fr' : ''}>
              <img src={flagFR} alt="drapeau français" />
            </button>
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('en');
                setActive('en');
              }}
              className={active === 'en' ? 'en' : ''}>
              <img src={flagUK} alt="drapeau anglais" />
            </button>
          </div>
        </div>
        <form className="authenticate__form">
          <div className="input-container">
            <label htmlFor="password">{t('word.password')}</label>
            <div className="hide-show">
              <input
                type={passwordHidden ? 'password' : 'text'}
                name="password"
                minLength={12}
                onChange={handleChange}
                value={form.password}
              />
              <button
                className="btn icon-hide"
                onClick={() => setPasswordHidden(!passwordHidden)}
                type="button">
                {passwordHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            </div>

            <label htmlFor="password">{t('word.confirmPassword')}</label>
            <div className="hide-show">
              <input
                type={passwordHidden ? 'password' : 'text'}
                name="confirmPassword"
                minLength={12}
                onChange={handleChange}
                value={form.confirmPassword}
              />
              <button
                className="btn icon-hide"
                onClick={() => setPasswordHidden(!passwordHidden)}
                type="button">
                {passwordHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            </div>
          </div>
          <div className="cgu">
            <input
              name="cgu_acceptees_portail__c"
              type="checkbox"
              onChange={handleChange}
              checked={form.cgu_acceptees_portail__c ? true : false}
            />
            {t('cguSentence')}
          </div>
          <button
            className="btn btn-main btn-fullwidth"
            type="submit"
            onClick={handleSubmit}>
            <span>{t('create_password')}</span>
          </button>
          <Link className="authenticate__form__link" to="/">
            {t('connect')}
          </Link>
        </form>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['login', 'common'])(CreatePassword));
