import { he } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import LeftArrow from '../../../../assets/images/junia-icon-fleche-3.png';
import { Application_hed } from '../../../../services/api/Candidature/interface';
import { FileToUpload } from '../../../../services/api/Files/interface';

import './Payment.scss';

interface ContainerProps {
  applicationData: Application_hed;

  onStep: (
    application: Application_hed,
    step: number,
    files?: FileToUpload[]
  ) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Payment: React.FC<IProps> = (props) => {
  const { t, onStep, applicationData } = props;
  const history = useHistory();
  const changeRoute = (path: string) => {
    history.push(path);
  };

  return (
    <div className="payment-container">
      <div className="payment-txt">
        <h5>
          {t('payment.payTitle')}
          <span></span>
        </h5>

        <p>{t('payment.payText1')}</p>
        <p>{t('payment.payText2')}</p>
      </div>
      <div className="payment-btn">
        <button
          className="btn btn-main btn-fullwidth"
          onClick={() => onStep(applicationData, +1)}>
          <span>{t('payButton')}</span>
        </button>
        <button
          className="btn btn-outlined btn-fullwidth"
          onClick={() => changeRoute('/application')}>
          <span>{t('saveAlt')}</span>
        </button>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['home', 'common'])(Payment));
