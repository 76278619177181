import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import API from '..';
import { Auth, Contact } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class ContactService {
  register(register: Auth): Promise<{ data: any }> {
    return axios.post(`${url}/contact`, register);
  }

  createFromAgent(user_id: string, contact: Contact): Promise<{ data: any }> {
    return axios.post(`${url}/contact/agent/${user_id}`, contact);
  }

  getContact(user_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/contact/${user_id}`);
  }

    hasMissingPieces(user_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/contact/${user_id}/pieces`);
  }

  patchContact(
    user_id: string,
    contact: Contact,
    files?: any[],
    typesFile?: string[]
  ): Promise<AxiosResponse> {
    let formData = new FormData(); //formdata object

    _.map(files, (o) => {
      if (typesFile && typesFile.includes(o.type)) {
        _.map(o.files, (file) => {
          formData.append(o.type, file);
        });
      }
    });
    _.map(contact, (value: any, key: any) => {
      if (value) {
        formData.append(key, value);
      }
    });
    return axios.patch(`${url}/contact/${user_id}`, formData);
  }
}
export default new ContactService();
