import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
const { NODE_ENV, REACT_APP_BACK_URL } = process.env;

interface AlertType {
  open: boolean;
  type: any;
  duration: number;
  message: string;
}

class Alert {
  alert$ = new Subject<AlertType | null>();

  setAlert(alert: AlertType): void {
    this.alert$.next(alert);
  }
}
export default new Alert();
