import _ from 'lodash';
import validator from 'validator';
import { inscriptionPatterns, inscriptionValidatorDefinition } from './definitions';

const unicodes = [
  {
    code: '&#39;',
    value: "'"
  }
];

export const userInputsValidators = (value: any, inputName: string) => {
  if (inputName === 'username')
    return validator.isLength(value, { min: 1, max: 20 });

  return true;
};

export const inscriptionValidator = async (
  value: any,
  inputName: string,
  form: any
) => {
  const inscriptionValidations: any = inscriptionValidatorDefinition();
  let validations = await _.map(inscriptionValidations[inputName], (func) => {
    let result = func(value);
    if (!result.valid) {
      return { ...result, reason: `${inputName}.${result.reason}` };
    } else {
      return result;
    }
  });
  let notValid = _.find(validations, (validation) => !validation.valid);
  if (notValid) {
    return notValid;
  } else {
    return { valid: true, reason: '' };
  }
};


export const pattern = async (
  value: any,
  inputName: string,
  form: any
) => {
  const patternList: any = inscriptionPatterns();
  let newValue = value
  let validations = await _.map(patternList[inputName], (func) => {
    newValue = func(newValue);
    console.log("🚀 ~ file: validator.ts ~ line 50 ~ validations ~ result", newValue)
  });
  return newValue
};

export const checkValid: any = (value: string, data: any) => {
  let found = data[value];
  if (!found) {
    return true;
  } else {
    return false;
  }
};

export const checkRequested = (requestList: string[], values: any) => {
  let canNext = true;
  _.map(requestList, (req) => {
    let value = values[req];
    // console.log("🚀 ~ file: validator.ts ~ line 56 ~ _.map ~ req", req)
    // console.log("🚀 ~ file: validator.ts ~ line 55 ~ _.map ~ values[req]", values[req])
    if (!values[req] || values[req].trim() === '' || values[req].length === 0) {
      canNext = false;
      return false;
    }
  });
  return canNext;
};

export const checkEmail = (email: string | undefined): boolean => {
  const emailRegex: RegExp = /\S+@\S+\.\S+/;
  return email != undefined && emailRegex.test(email);
}

export const formatUnicode = (value: string) => {
  let newValue: string = value;
  _.map(unicodes, (unicode) => {
    newValue = newValue.replace(unicode.code, unicode.value);
  });
  return newValue;
};
