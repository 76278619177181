/**
 * This component is the required form to create a new candidate as an Agent
 */
import React, { useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Interface
import { Contact } from '../../../../services/api/Contact/interface';

// Helpers
import { checkRequested, checkEmail } from '../../../../utils/validator/validator';

// Styles
import './CandidateInfoFromAgent.scss';

interface ContainerProps {
  contact: Contact;
  onChange: (data: any, canNext: boolean) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const CandidateInfoFromAgent: React.FC<IProps> = (props) => {
  const { t, contact, onChange } = props;

  const [form, setForm] = useState<Contact>({
    lastname: '',
    firstname: '',
    email: ''
  });

  const required = [
      'lastname',
      'firstname',
      'email'
    ];

  const handleChange = (event: any) => {
    let value = event.target.value;
    
    let newForm = {
      ...form,
      [event.target.name]: value
    };
    setForm(newForm);

    let canNext = checkRequested(required, newForm) && checkEmail(newForm.email);
    onChange(newForm , canNext);
  };

  return (
    <div>
      <div>
        <h6 className="form-h6">
          {t('lastname')}
          <span className="form-required">*</span>
        </h6>
        <input
          name="lastname"
          value={form.lastname}
          onBlur={handleChange}
          onChange={handleChange}
        />
      </div>{' '}
      <div>
        <h6 className="form-h6">
          {t('firstname')}
          <span className="form-required">*</span>
        </h6>
        <input
          name="firstname"
          value={form.firstname}
          onBlur={handleChange}
          onChange={handleChange}
        />
      </div>{' '}
      <div>
        <h6 className="form-h6">
          {t('email')}
          <span className="form-required">*</span>
        </h6>
        <input
          name="email"
          type="email"
          value={form.email}
          onBlur={handleChange}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(CandidateInfoFromAgent)
);
