import React, { useEffect, useState } from 'react';
import './DisplayBlobFiles.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import UploadImg from '../../assets/images/junia-icon-fichiers.png';
import Alert from '../../utils/Alert/Alert';
import _ from 'lodash';
import {
  FileToUpload,
  PieceComplementaire
} from '../../services/api/Files/interface';

interface ContainerProps {
  onDelete?: (file: FileToUpload) => void;
  files?: FileToUpload[];
  type: string;
}

// type ChildProps = {
//   passToParent: (varToParent: string) => void;
// };

interface IProps extends ContainerProps, WithTranslation {}
const DisplayBlobFiles: React.FC<IProps> = (props) => {
  const { t, onDelete, files, type } = props;

  const onDeleteClick = (file: FileToUpload) => {
    if (onDelete) {
      onDelete(file);
    }
  };
  return (
    <div className="uploaded-container">
      {files && files.length > 0
        ? _.chain(files)
            .filter((t) => t.type === type)
            .map((file: FileToUpload) => {
              return (
                <React.Fragment key={file.type}>
                  {_.map(file.files, (f, index) => {
                    return (
                      <div className="uploaded" key={f.name}>
                        <p className="uploaded-file" key={`${type}-${index}`}>
                          {f.name}
                        </p>
                        {onDelete && (
                          <button
                            className="btn btn-underline"
                            id="DisplayFiles"
                            onClick={() =>
                              onDeleteClick({ type: file.type, files: [f] })
                            }>
                            <span>{t('word.remove')} </span>
                          </button>
                        )}
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })
            .value()
        : ''}
    </div>
  );
};

export default withTranslation(['common'])(DisplayBlobFiles);
