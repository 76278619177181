/**
 * This component lists the agent's candidates, and the add candidate action button
 */
import React, { useEffect, useState } from 'react';
import './Candidats.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { Contact } from '../../../../services/api/Contact/interface';
import AuthService from '../../../../services/api/Auth/AuthService';
import CandidatureService from '../../../../services/api/Candidature/CandidatureService';
import { me } from '../../../../services/api/Contact/interface';
import moment from 'moment';
import _ from 'lodash';

interface ContainerProps {
  useContact: Contact;
}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const AgentCandidats: React.FC<IProps> = (props) => {
  const { t } = props;

  const history = useHistory();
  const [me, setMe] = useState<me>({});
  const [candidats, setCandidats] = useState<Contact[]>([]);

  useEffect(() => {
    getMe();
  }, []);
  useEffect(() => {
    getCandidats();
  }, [me]);

  const getMe = async () => {
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };
  const getCandidats = async () => {
    try {
      let candidatReponse = await CandidatureService.getCandidatsAgent();
      const sortedCandidates = sortCandidateByName(candidatReponse.data)
      setCandidats(sortedCandidates);
    } catch (err) {
      console.log(
        '🚀 ~ file: Candidats.tsx ~ line 49 ~ getCandidats ~ err',
        err
      );
    }
  };

  const addCandidate = () => {
    return history.push(`/agent/create-candidate`);
  };

  const openCandidature = async (candidate_id: any) => {
    return history.push(`/agent/${candidate_id}/choice`);
  };

  const sortCandidateByName = (candidats: Contact[]) => {
    return candidats.sort((a: any, b: any) => {
      let paramA = (
        a?.['lastname']?.trim().split(' ').join('').toLowerCase() +
          a?.['firstname']?.trim().split(' ').join('').toLowerCase() || 0
      ).toString();
      let paramB = (
        b?.['lastname']?.trim().split(' ').join('').toLowerCase() +
          b?.['firstname']?.trim().split(' ').join('').toLowerCase() || 0
      ).toString();
      return paramA > paramB ? 1 : paramA < paramB ? -1 : 0;
    });
  };

  return (
    <div className="agent-list general-container">
      <div className="agent-applicant-header">
        <button className="btn-custom" onClick={addCandidate}>
          <div className="left">
            <h5>+</h5>
            <p>{t('addApplicant')}</p>
          </div>
        </button>
      </div>
      <div className="agent-table">
        <div className="agent-title-filter">
          <div className="agent-history">
            <h4>{t('applicants')}</h4>
          </div>
        </div>
        <table className="folder-table">
          <thead>
            <tr>
              <td>{t('lastname')}</td>
              <td>{t('firstname')}</td>
              <td>{t('birthdate')}</td>
              <td>{t('creationDate')}</td>
            </tr>
          </thead>
          <tbody>
            {candidats.length > 0 &&
              _.chain(candidats)
                .map((candidat: Contact) => {
                  return (
                    <tr onClick={() => openCandidature(candidat.id_heroku__c)} key={candidat.id_heroku__c}>
                      <td>
                        <p>{candidat.lastname}</p>
                      </td>
                      <td>
                        <p>{candidat?.firstname}</p>
                      </td>
                      <td>
                        <p>
                          {moment(candidat?.birthdate).format('DD/MM/YYYY')}
                        </p>
                      </td>
                      <td>
                        <p>
                          {moment(candidat?.createddate).format('DD/MM/YYYY')}
                        </p>
                      </td>
                    </tr>
                  );
                })
                .value()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['agent', 'common'])(AgentCandidats));
