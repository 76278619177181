import moment from 'moment';

class CheckMiddleware {
  getSchoolYear(): any {
    let currentYear = moment().year();
    let previousYear = moment().subtract(1, 'years').year();

    return {
      currentYear: {
        string: currentYear.toString(),
        number: currentYear
      },
      previousYear: { string: previousYear.toString(), number: previousYear },
      full: `${currentYear}/${previousYear}`
    };
  }
}

export default new CheckMiddleware();
