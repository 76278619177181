/**
 * This component displays infos on required documents for french inscription
 */
import React, { useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Contact } from '../../../../services/api/Contact/interface';
import './Recapitulatif.scss'

interface ContainerProps {
  contact: Contact;
  onChange: (data: null, canNext: boolean) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Recapitulatif: React.FC<IProps> = (props) => {
  const { t, onChange } = props;

  useEffect(() => {
    onChange(null, true)
  }, [])
  return (
    <div className='docs-recap'>
      <h6 className='docs-recap-title'>{t('recapitulatif.title')}</h6>
      <div className='docs-recap-list-container'>
        <div><span className='docs-recap-marker'></span><p>{t('recapitulatif.id')}</p></div>
        <div><span className='docs-recap-marker'></span><p>{t('recapitulatif.bacTranscript')}</p></div>
        <div><span className='docs-recap-marker'></span><p><span>{t('recapitulatif.grades1')}</span> {t('recapitulatif.grades2')}</p></div>
        <div><span className='docs-recap-marker'></span><p>{t('recapitulatif.otherDiploma')}</p></div>
        <div><span className='docs-recap-marker'></span><p>{t('recapitulatif.poursuite')}</p></div>
        <div><span className='docs-recap-marker'></span><p>{t('recapitulatif.cv')}</p></div>
      </div>
      <h6 className='docs-recap-title'>{t('recapitulatif.title2')}</h6>
      <div className='docs-recap-list-container'>
        <div><span className='docs-recap-marker'></span><p>{t('recapitulatif.recommandationLetter')}</p></div>
        <div><span className='docs-recap-marker'></span><p>{t('recapitulatif.languageCertification')}</p></div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(
    Recapitulatif
  )
);
