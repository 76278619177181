/**
 * This component lists the candidate's applications
 */
import React, { useEffect, useState } from 'react';
import './Application.scss';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { Application_hed } from '../../services/api/Candidature/interface';
import CandidatureService from '../../services/api/Candidature/CandidatureService';
import { me } from '../../services/api/Contact/interface';
import AuthService from '../../services/api/Auth/AuthService';
import ClearIcon from '@mui/icons-material/Clear';
import _ from 'lodash';
import moment from 'moment';
import Modal from '../../components/Modal/Modal';
import Spinner from '../../utils/Spinner/Spinner';
import Alert from '../../utils/Alert/Alert';
interface ContainerProps {}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Application: React.FC<IProps> = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();

  const [candidatures, setCandidatures] = useState<Application_hed[]>([]);
  const [me, setMe] = useState<me>({});
  const [modalAcceptIsOpen, setModalAcceptIsOpen] = useState<boolean>(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState<boolean>(false);
  const [modalDeclineIsOpen, setModalDeclineIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Application_hed>();
  const history = useHistory();
  const activeList = [
    'Brouillon',
    'En attente',
    'Dossier incomplet',
    'Attente avis Etude en France',
    "Attente jury fin d’année",
    "Attente jury fin d’année (partenaire)",
    'Etude du dossier',
    'Convoqué Entretien',
    "Liste d'attente",
    'Dépôt candidature',
    'Dossier complet',
    'Entretien planifié',
    'Entretien',
    'Admis sous réserve',
    'Admis'
  ];
  const historicList = [
    'Admis ayant accepté la proposition',
    'Démissionnaire',
    'Non admis',
    'Sans suite',
    'Abandon',
    'Refus'
  ];

  const statusTranslations = [
    {
      french: 'Brouillon',
      english: 'Draft'
    },
    {
      french: 'Dépôt candidature',
      english: 'Application sent'
    },
    {
      french: 'Dossier complet',
      english: 'Complete application'
    },
    {
      french: 'Dossier incomplet',
      english: 'Incomplete application'
    },
    {
      french: 'Attente avis Etude en France',
      english: 'On hold - Etudes en France notice'
    },
    {
      french: "Attente jury fin d’année",
      english: 'On hold - end of year jury'
    },
    {
      french: "Attente jury fin d’année (partenaire)",
      english: 'End of year jury pending'
    },
    {
      french: 'Etude du dossier',
      english: 'File review'
    },
    {
      french: 'Convoqué Entretien',
      english: 'Invited to interview'
    },
    {
      french: 'Entretien',
      english: 'Interview'
    },
    {
      french: 'Admis',
      english: 'Accepted'
    },
    {
      french: 'Admis sous réserve',
      english: 'Accepted under conditions'
    },
    {
      french: 'Non admis',
      english: 'Refused'
    },
    {
      french: 'Sans suite',
      english: 'No further action'
    },
    {
      french: 'Abandon',
      english: 'Withdrawal'
    },
    {
      french: 'Admis ayant accepté la proposition',
      english: 'Acceptation confirmed'
    },
    {
      french: 'Refus',
      english: 'Declined'
    },
    {
      french: 'Entretien planifié',
      english: 'Interview planned'
    }
  ]

  useEffect(() => {
    getMe();
  }, []);
  useEffect(() => {
    getCandidatures();
  }, [me]);

  const getMe = async () => {
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };
  const getCandidatures = async () => {
    if (me?.id_heroku__c) {
      let candidatureResponse = await CandidatureService.getCandidatures(
        me?.id_heroku__c
      );
      setCandidatures(candidatureResponse.data);
    }
  };
  const removeApplication = (candidature_id: any) => {
    if (me?.id_heroku__c) {
      Spinner.setSpinner(true);
      CandidatureService.cancelCandidature(me.id_heroku__c, candidature_id)
        .then((reponse) => {
          Spinner.setSpinner(false);
          getCandidatures();
          handleModalClose();
          Alert.setAlert({
            open: true,
            type: 'success',
            duration: 3000,
            message: t('successDelete')
          });
        })
        .catch((error) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            open: true,
            type: 'error',
            duration: 3000,
            message: t('failDelete')
          });
        });
    }
  };
  const handleModalClose = async (): Promise<void> => {
    setSelected(undefined);
    setModalAcceptIsOpen(false);
    setModalDeleteIsOpen(false);
    setModalDeclineIsOpen(false);
  };
  const openModalDelete = (data: Application_hed) => {
    setSelected(data);
    setModalDeleteIsOpen(true);
  };
  const openModalAccept = (data: Application_hed) => {
    setSelected(data);
    setModalAcceptIsOpen(true);
  };

  const openModalDecline = (data: Application_hed) => {
    setSelected(data);
    setModalDeclineIsOpen(true);
  }

  const classNames = (candidature: Application_hed) => {
    let statutColor = '';
    if (candidature && candidature.statut__c) {
      if (
        candidature.statut__c === 'Brouillon' ||
        candidature.statut__c === 'En attente' ||
        candidature.statut__c === 'Attente avis Etude en France' ||
        candidature.statut__c === "Attente jury fin d’année" ||
        candidature.statut__c === "Attente jury fin d’année (partenaire)" ||
        candidature.statut__c === 'Etude du dossier' ||
        candidature.statut__c === 'Convoqué Entretien' ||
        candidature.statut__c === "Liste d'attente"
      ) {
        statutColor = 'orange';
      } else if (
        candidature.statut__c === 'Admis' ||
        candidature.statut__c === 'Dépôt candidature' ||
        candidature.statut__c === 'Dossier complet' ||
        candidature.statut__c === 'Entretien planifié' ||
        candidature.statut__c === 'Entretien' ||
        candidature.statut__c === 'Admis sous réserve' ||
        candidature.statut__c === 'Admis ayant accepté la proposition'
      ) {
        statutColor = 'green';
      } else if (
        candidature.statut__c === 'Dossier incomplet' ||
        candidature.statut__c === 'Démissionnaire' ||
        candidature.statut__c === 'Non admis' ||
        candidature.statut__c === 'Sans suite' ||
        candidature.statut__c === 'Abandon' ||
        candidature.statut__c === 'Refus'
      ) {
        statutColor = 'red';
      }
    }
    return statutColor;
  };

  const acceptApplication = (application: Application_hed) => {
    console.log(
      '🚀 ~ file: Application.tsx ~ line 155 ~ acceptApplication ~ application',
      application
    );
    Spinner.setSpinner(true);
    if (me.id_heroku__c && application.id_heroku__c) {
      CandidatureService.acceptCandidature(
        me.id_heroku__c,
        application.id_heroku__c
      )
        .then((res) => {
          Spinner.setSpinner(false);
          getCandidatures();
          handleModalClose();
          Alert.setAlert({
            open: true,
            type: 'success',
            duration: 3000,
            message: t('successAccept')
          });
        })
        .catch((error) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            open: true,
            type: 'error',
            duration: 3000,
            message: t('failAccept')
          });
        });
    }
  };

  const declineApplication = (application: Application_hed) => {
    console.info(
      '🚀 ~ file: Application.tsx ~ line 200 ~ declineApplication ~ application',
      application
    );
    Spinner.setSpinner(true);
    if (me.id_heroku__c && application.id_heroku__c) {
      CandidatureService.declineCandidature(
        me.id_heroku__c,
        application.id_heroku__c
      )
        .then((res) => {
          Spinner.setSpinner(false);
          getCandidatures();
          handleModalClose();
          Alert.setAlert({
            open: true,
            type: 'success',
            duration: 3000,
            message: t('successDecline')
          });
        })
        .catch((error) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            open: true,
            type: 'error',
            duration: 3000,
            message: t('failDecline')
          });
        });
    }
  };

  return (
    <div className="applied general-container">
      {me.type_tech__c === 'Echange' ? (
        <h4>{t('word.applyPage')}</h4>
      ) : (
        <h4>{t('word.appliesPage')}</h4>
      )}
      <div className="application-list">
        {candidatures.length > 0 ? (
          _.chain(candidatures)
            .filter(
              (c) =>
                c.statut__c !== undefined &&
                c.statut__c !== null &&
                activeList.includes(c.statut__c)
            )
            .map((candidature) => {
              let name: any = '';
              if (i18n.language === 'fr') {
                name = candidature.Formation?.nom_de_la_formation__c;
              }
              if (i18n.language === 'en') {
                name = candidature.Formation?.nom_formation_anglais__c;
              }
              let lastReservation;
              if (
                candidature.Reservations &&
                candidature.Reservations.length > 0
              ) {
                let found = _.last(candidature?.Reservations);
                if (found) {
                  lastReservation = found;
                }
              }
              return (
                <div className="application-card">
                  <div className="application-card-header">
                    <div>
                      <p>
                        {candidature.Formation?.cole__c} -{' '}
                        {candidature.Formation?.campus__c} / {candidature.name}
                      </p>
                      <h5>{name}</h5>
                      {/* <p>{candidature.Formation?.sp_cilait_s__c}</p> */}
                    </div>
                    <div className="action-buttons">
                      <button
                        className="remove-application"
                        onClick={() => openModalDelete(candidature)}>
                        <ClearIcon></ClearIcon>
                      </button>
                      {candidature.statut__c === 'Brouillon' && (
                        <div>
                          <button
                            className="btn btn-outlined"
                            onClick={() =>
                              history.push(
                                `/candidature-process/${candidature.id_heroku__c}`
                              )
                            }>
                            <span>{t('finalizeApplication')} </span>
                          </button>
                        </div>
                      )}
                      {(candidature.statut__c === 'Admis' ||
                        candidature.statut__c === 'Admis sous réserve') && (
                        <div>
                          <div>
                            <button
                              className="btn btn-outlined"
                              onClick={() => openModalDecline(candidature)}>
                              <span>{t('declineApply')} </span>
                            </button>
                          </div>
                          <div>
                            <button
                              className="btn btn-outlined"
                              onClick={() => openModalAccept(candidature)}>
                              <span>{t('acceptApply')} </span>
                            </button>
                          </div>
                        </div>
                      )}
                      {/* ROBIN : renvoyer etudiant vers pièces communes/complèmentaire === false pour modifier pièces */}
                      {candidature.statut__c === 'Dossier incomplet' && (
                        <div>
                          <button
                            className="btn btn-outlined"
                            onClick={() =>
                              history.push(
                                `/incomplete-folder/${candidature.id_heroku__c}`
                              )
                            }>
                            <span>{t('modifydocs')} </span>
                          </button>
                        </div>
                      )}
                      {candidature.statut__c === 'Convoqué Entretien' &&
                        me.type_tech__c === 'Francais' && (
                          <div>
                            {/* <button
                              className="btn btn-outlined"
                              onClick={() =>
                                history.push(
                                  `/choose-date/${candidature.id_heroku__c}`
                                )
                              }>
                              <span>{t('selectEntretien')} </span>
                            </button> */}
                          </div>
                        )}
                      {(candidature.statut__c === 'Entretien planifié' ||
                        candidature.statut__c === 'Entretien') && (
                        <div>
                          {candidature.lien_teams__c && (
                            <button
                              className="btn btn-outlined"
                              onClick={() =>
                                window.open(candidature.lien_teams__c)
                              }>
                              <span>{t('meetingPlaned')} </span>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="application-card-content">
                    <div className="application-info">
                      <h6>{t('applyDate')}</h6>
                      <p>
                        {moment(candidature?.createddate).format('DD/MM/YYYY')}
                      </p>
                    </div>
                    <div className="application-info">
                      <h6>{t('applyCloseAt')}</h6>
                      <p>
                        {moment(candidature.Formation?.date_de_fin__c).format(
                          'DD/MM/YYYY'
                        )}
                      </p>
                    </div>
                    <div className="application-info">
                      <h6>{t('applyStatusTitle')}</h6>
                      {candidature.statut__c === 'Entretien planifié' ||
                      candidature.statut__c === 'Entretien' ? (
                        <p className={classNames(candidature)}>
                          <>
                            {i18n.language === 'fr' && candidature.statut__c}
                            {i18n.language === 'en' &&
                              statusTranslations.map((status) => {
                                if (status.french === candidature.statut__c) {
                                  return status.english;
                                }
                              })}
                          </>
                          <br />
                          {candidature.date_et_heure_de_l_entretien__c &&
                            moment(
                              candidature.date_et_heure_de_l_entretien__c
                            ).format('DD/MM/YYYY HH:mm:ss')}
                          {lastReservation !== undefined &&
                            lastReservation.b25__local_start_time__c}
                        </p>
                      ) : (
                        <p className={classNames(candidature)}>
                          <>
                            {i18n.language === 'fr' && candidature.statut__c === 'Attente jury fin d’année (partenaire)' ? 
                              'En attente Jury fin d’année'
                              : i18n.language === 'fr' && candidature.statut__c}
                            {i18n.language === 'en' &&
                              statusTranslations.map((status) => {
                                if (status.french === candidature.statut__c) {
                                  return status.english;
                                }
                              })}
                          </>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
            .value()
        ) : (
          <h5>{t('noApplications')} </h5>
        )}
      </div>
      <h4>{t('history')}</h4>
      <div className="application-list">
        {candidatures.length > 0 ? (
          _.chain(candidatures)
            .filter(
              (c) =>
                c.statut__c !== undefined &&
                c.statut__c !== null &&
                historicList.includes(c.statut__c)
            )
            .map((candidature) => {
              let name: any = '';
              if (i18n.language === 'fr') {
                name = candidature.Formation?.nom_de_la_formation__c;
              }
              if (i18n.language === 'en') {
                name = candidature.Formation?.nom_formation_anglais__c;
              }
              return (
                <div className="application-card">
                  <div className="application-card-header">
                    <div>
                      <p>
                        {candidature.Formation?.cole__c} -{' '}
                        {candidature.Formation?.campus__c} / {candidature.name}
                      </p>
                      <h5>{name}</h5>
                      {/* <p>{candidature.Formation?.sp_cilait_s__c}</p> */}
                    </div>
                    {candidature.statut__c === 'Brouillon' && (
                      <div>
                        <button
                          className="btn btn-outlined"
                          onClick={() =>
                            history.push(
                              `/candidature-process/${candidature.id_heroku__c}`
                            )
                          }>
                          <span>{t('finalizeApplication')} </span>
                        </button>
                      </div>
                    )}
                    {/* {candidature.statut__c === 'Entretien' &&
                      candidature.reservation__c == null && (
                        <div>
                          <button
                            className="btn btn-outlined"
                            onClick={() =>
                              history.push(
                                `/choose-date/${candidature.id_heroku__c}`
                              )
                            }>
                            <span>{t('selectEntretien')} </span>
                          </button>
                        </div>
                      )} */}
                    <div className="action-buttons"></div>
                  </div>
                  <div className="application-card-content">
                    <div className="application-info">
                      <h6>{t('applyDate')}</h6>
                      <p>
                        {moment(candidature?.createddate).format('DD/MM/YYYY')}
                      </p>
                    </div>
                    <div className="application-info">
                      <h6>{t('applyCloseAt')}</h6>
                      <p>
                        {moment(candidature.Formation?.date_de_fin__c).format(
                          'DD/MM/YYYY'
                        )}
                      </p>
                    </div>
                    <div className="application-info">
                      <h6>{t('applyStatusTitle')}</h6>
                      <p className={classNames(candidature)}>
                        {i18n.language === 'fr' && candidature.statut__c}
                        {i18n.language === 'en' &&
                          statusTranslations.map((status) => {
                            if (status.french === candidature.statut__c) {
                              return status.english;
                            }
                          })}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
            .value()
        ) : (
          <h5>{t('noApplications')} </h5>
        )}
      </div>
      <Modal
        title={t('deleteApply')}
        classe="delete-apply-modal"
        closeModal={() => handleModalClose()}
        modalIsOpen={modalDeleteIsOpen}>
        <h5>{t('deleteSure')}</h5>
        <h5>{t('deleteCondition')}</h5>
        <div className="modal-buttons">
          <button
            onClick={() => removeApplication(selected?.id_heroku__c)}
            className="btn btn-yes">
            {t('yes')}
          </button>
          <button onClick={() => handleModalClose()} className="btn btn-no">
            {t('no')}
          </button>
        </div>
      </Modal>
      <Modal
        title={t('acceptApplyTitle')}
        classe="delete-apply-modal"
        closeModal={() => handleModalClose()}
        modalIsOpen={modalAcceptIsOpen}>
        <h5>{t('acceptSure')}</h5>
        <div className="modal-buttons">
          <button
            onClick={() => {
              if (selected) {
                return acceptApplication(selected);
              }
            }}
            className="btn btn-yes">
            {t('yes')}
          </button>
          <button onClick={() => handleModalClose()} className="btn btn-no">
            {t('no')}
          </button>
        </div>
      </Modal>
      <Modal
        title={t('declineApplyTitle')}
        classe="delete-apply-modal"
        closeModal={() => handleModalClose()}
        modalIsOpen={modalDeclineIsOpen}>
        <h5>{t('declineSure')}</h5>
        <div className="modal-buttons">
          <button
            onClick={() => {
              if (selected) {
                return declineApplication(selected);
              }
            }}
            className="btn btn-yes">
            {t('yes')}
          </button>
          <button onClick={() => handleModalClose()} className="btn btn-no">
            {t('no')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(
  withTranslation(['application', 'common'])(Application)
);
