/**
 * This component displays the folder grade grid for a candidate
 */
import React, { useEffect, useState, useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './FolderGrade.scss';
import { ApplicationsResponse } from '../../../../../../services/api/Jury/Jury';
import { me } from '../../../../../../services/api/Contact/interface';
import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import { Application_hed } from '../../../../../../services/api/Candidature/interface';
import Alert from '../../../../../../utils/Alert/Alert';
import CandidatureService from '../../../../../../services//api/Candidature/CandidatureService';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

interface ContainerProps {}

type ChildProps = {
  candidature: ApplicationsResponse;
  me: me;
  handleChange: (candidature: ApplicationsResponse) => void;
};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

//contenu des selects
const selectContents: any = {
  //parcours international
  ad_quation_du_parcours_pr_alable_par_rap__c: [0, 1, 2, 3, 4],
  r_sultats_scientifiques_ou_sp__c: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  r_sultats_autres_mati_res_n_1__c: [0, 1, 2, 3, 4, 5],
  r_sultats_scientifiques_ou_sp_cifiques__c: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  exp_rience_professionnelle_stage_s_j__c: [0, 1, 2, 3, 4],
  style_orthographe__c: [0, 1, 2, 3, 4],
  argumentation_sur_le_choix_de_formation__c: [0, 1, 2, 3, 4],
  argumentation_sur_le_projet_pro_tudes__c: [0, 1, 2, 3, 4],
  r_sultats_acad_miques_avant_n_1_mati_r__c: [0, 1, 2, 3, 4, 5],
  bonus__c_international: [0, 1, 2, 3],
  //parcours français
  moyenne_au_bac__c: [0, 2, 3, 4],
  n_1_r_sultat_tech_maths__c: [-1, 0, 1, 2, 3, 4],
  n_r_sultat_soft_skills__c__apprenti: [-1, 0, 1],
  n_1_r_sultat_soft_skills__c__classique: [-1, 0, 1, 2],
  n_1_r_sultat_l_v__c: [-1, 0, 1, 2],
  appr_ciations_ou_recommandations__c: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  n_11_r_sultat_l_v__c: [-1, 0, 1, 2, 3],
  r_sultat_tech_maths__c: [-1, 0, 2, 3, 4, 5, 6, 7, 8],
  n_11_r_sultat_soft_skills__c: [-1, 0, 1, 2],
  argumentation_du_choix_de_l_e_cole__c: [-1, 0, 2, 3, 4, 5],
  argumentation_du_projet_professionnel__c: [-1, 0, 2, 3, 4, 5],
  potentiel_et_motivation__c: [-1, 0, 2, 3, 4, 5],
  questionnaire_cv_et_lettre_de_motivati__c: [-1, 0, 2, 3, 4, 5],
  experiences__c: [0, 1, 2, 3, 4, 5],
  bonus__c_francais: [0, 1, 2, 3],
  malus__c: [0, -1, -2, -3]
};

const FolderGrade: React.FC<IProps> = (props) => {
  const history = useHistory();
  const { t, candidature, me, handleChange } = props;
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const [form, setForm] = useState<Application_hed>({});
  const [candidatureInadView, setCandidatureInadView] =
    useState<boolean>(false);
  const [exemptDecisionInternationalView, setExemptDecisionInternationalView] =
    useState<string>('');
  const [score, setScore] = useState<number>(0);
  const [scoreBonus, setScoreBonus] = useState<number>(0);
  const [checkboxSignature, setCheckboxSignature] = useState<boolean>(false);
  const [enableValidationButton, setEnableValidationButton] =
    useState<boolean>(true);
  const [readOnly, setReadonly] = useState<boolean>(false);

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight, divRef.current?.clientWidth]);

  useEffect(() => {
    setForm(candidature);
    if (
      candidature.statut__c !== 'Etude du dossier' ||
      me.sfid !== candidature.jury_dossier__c ||
      candidature.signature_jury_dossier__c ||
      candidature.signature_du_correcteur__c
    ) {
      setReadonly(true);
    }
  }, [candidature]);

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (
      element &&
      element.clientHeight &&
      element.clientHeight > 0 &&
      element &&
      element.clientWidth &&
      element.clientWidth > 0
    ) {
      setHeight(element.clientHeight + 66 + 17 + 48 + 70);
      setWidth(element.clientWidth);
    }
  };

  const scoreFieldRequired: string[] =
    candidature?.Contact?.type_tech__c === 'International'
      ? [
          'ad_quation_du_parcours_pr_alable_par_rap__c',
          'r_sultats_scientifiques_ou_sp__c',
          'r_sultats_autres_mati_res_n_1__c',
          'r_sultats_scientifiques_ou_sp_cifiques__c',
          'exp_rience_professionnelle_stage_s_j__c',
          'style_orthographe__c',
          'argumentation_sur_le_choix_de_formation__c',
          'argumentation_sur_le_projet_pro_tudes__c',
          'r_sultats_acad_miques_avant_n_1_mati_r__c'
        ]
      : [
          'appr_ciations_ou_recommandations__c',
          'argumentation_du_choix_de_l_e_cole__c',
          'n_1_r_sultat_l_v__c',
          'n_1_r_sultat_soft_skills__c',
          'n_1_r_sultat_tech_maths__c',
          'n_11_r_sultat_l_v__c',
          'n_11_r_sultat_soft_skills__c',
          'n_r_sultats_soft_skills_app__c',
          'potentiel_et_motivation__c',
          'argumentation_du_projet_professionnel__c',
          'questionnaire_cv_et_lettre_de_motivati__c',
          'r_sultat_tech_maths__c',
          'bonus__c',
          'malus__c',
          'experiences__c',
          'moyenne_au_bac__c'
        ];

  const calculatingScore = (
    form: any,
    fieldRequired: string[],
    setScoreState: (value: React.SetStateAction<number>) => void
  ) => {
    let formFiltered = _.pick(form, fieldRequired);
    if (Object.keys(formFiltered).length > 0) {
      let scoreFromFiltered: any = Object.values(formFiltered).reduce(
        (a: any, b: any) => (!a ? 0 : parseInt(a, 10)) + (!b ? 0 : parseInt(b, 10))
      );
      setScoreState(scoreFromFiltered);
    }
  };

  const onChange = (e: any): void => {
    //envoie d'une alerte si les scores ne sont pas sélectionnés
    e.target.value === '' || null
      ? Alert.setAlert({
          duration: 5000,
          type: 'error',
          open: true,
          message: 'veuillez donner une note'
        })
      : '';

    let value;
    if (e.target.value === '') {
      value = null;
    } else if (!isNaN(e.target.value)) {
      value = Number(e.target.value);
    } else {
      value = e.target.value;
    }

    let newForm = {
      ...form,
      [e.target.name]: value
    };

    setForm(newForm);
  };

  //rendre le bouton visible si tous les champs requis sont remplis et calculer le score
  useEffect(() => {
    calculatingScore(form, scoreFieldRequired, setScore);
  }, [form, checkboxSignature]);

  const sendFolderGrade = async (userId: string) => {
    let sendGrade = await CandidatureService.patchCandidature(userId, {
      ...form,
      id_heroku__c: candidature?.id_heroku__c
    });
    sendGrade.status === 203
      ? Alert.setAlert({
          duration: 3000,
          type: 'success',
          open: true,
          message: 'Merci, l’évaluation est bien enregistrée.'
        })
      : Alert.setAlert({
          duration: 3000,
          type: 'error',
          open: true,
          message: 'Une erreur est survenue'
        });
  };

  return (
    <div className="folder-grade">
      <div ref={divRef}>
        <div className="folder-header">
          <h5>{t('evalFolder')}</h5>
          <div className="validate">
            <div>
              <h6>{t('candidate')}</h6>
              <p>
                {candidature?.Contact?.firstname}{' '}
                {candidature?.Contact?.lastname}
              </p>
            </div>
            <div>
              {candidature.statut__c === 'Etude du dossier' &&
                candidature?.signature_jury_dossier__c === false &&
                candidature?.signature_du_correcteur__c === false &&
                candidature.jury_dossier__c === me.sfid && (
                  <button
                    className='btn btn-main'
                    onClick={() => {
                      sendFolderGrade(me?.id_heroku__c || '');
                    }}>
                    <span>{t('validate')}</span>
                  </button>
                )}                             
            </div>
          </div>
          <hr></hr>
        </div>
      </div>
      <div
        className="folder-grade-scroll">
        <div className="folder-grade-scroll-content">
          <div className="body">
            <div>
              <h4>{t('academicResult')}</h4>
              {candidature?.Contact?.type_tech__c === 'Francais' && (
                <>
                  {candidature?.Formation?.type_de_formation__c !==
                    'Apprenti' &&
                    candidature?.Formation?.type_de_formation__c !==
                      'Formation France apprenti' && (
                      <>
                        <h6>
                          <Tooltip message={t('tooltips.bacGrade')}></Tooltip>{' '}
                          {t('bacGrade')}
                        </h6>
                        <select
                          name="moyenne_au_bac__c"
                          value={form.moyenne_au_bac__c}
                          onChange={onChange}
                          onBlur={onChange}
                          disabled={readOnly}>
                          <option value={''}></option>
                          {selectContents.moyenne_au_bac__c.map(
                            (item: number, index: number) => {
                              return (
                                <option key={index} value={item}>
                                  {item.toString()}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </>
                    )}
                  <div>
                    <h5>
                      {candidature?.Formation?.type_de_formation__c !==
                        'Apprenti' &&
                        candidature?.Formation?.type_de_formation__c !==
                          'Formation France apprenti' && (
                          <Tooltip message={t('tooltips.yearn1')}></Tooltip>
                        )}
                      {t('yearn1')}
                    </h5>
                    <h6>
                      {candidature?.Formation?.type_de_formation__c !==
                        'Apprenti' &&
                      candidature?.Formation?.type_de_formation__c !==
                        'Formation France apprenti' ? (
                        <Tooltip message={t('tooltips.techMathn1')}></Tooltip>
                      ) : (
                        <Tooltip
                          message={t('tooltips.techMathn1App')}></Tooltip>
                      )}
                      {t('techMath')}
                    </h6>
                    <select
                      name="r_sultat_tech_maths__c"
                      value={form.r_sultat_tech_maths__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      {selectContents.r_sultat_tech_maths__c.map(
                        (item: number, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item.toString()}
                            </option>
                          );
                        }
                      )}
                    </select>

                    <h6>
                      {candidature?.Formation?.type_de_formation__c !==
                        'Apprenti' &&
                      candidature?.Formation?.type_de_formation__c !==
                        'Formation France apprenti' ? (
                        <Tooltip message={t('tooltips.softSkillsn1')}></Tooltip>
                      ) : (
                        <Tooltip
                          message={t('tooltips.softSkillsn1App')}></Tooltip>
                      )}
                      {t('softSkills')}
                    </h6>
                    <select
                      name="n_11_r_sultat_soft_skills__c"
                      value={form.n_11_r_sultat_soft_skills__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      {selectContents.n_11_r_sultat_soft_skills__c.map(
                        (item: number, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item.toString()}
                            </option>
                          );
                        }
                      )}
                    </select>
                    <h6>
                      <Tooltip message={t('tooltips.lvn1')}></Tooltip> {t('lv')}
                    </h6>
                    <select
                      name="n_11_r_sultat_l_v__c"
                      value={form.n_11_r_sultat_l_v__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      {selectContents.n_11_r_sultat_l_v__c.map(
                        (item: number, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item.toString()}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div>
                    <h5>{t('yearn')}</h5>
                    <h6>
                      <Tooltip message={t('tooltips.techMath')}></Tooltip>{' '}
                      {t('techMath')}
                    </h6>
                    <select
                      name="n_1_r_sultat_tech_maths__c"
                      value={form.n_1_r_sultat_tech_maths__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      {selectContents.n_1_r_sultat_tech_maths__c.map(
                        (item: number, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item.toString()}
                            </option>
                          );
                        }
                      )}
                    </select>

                    <h6>
                      {candidature?.Formation?.type_de_formation__c !==
                        'Apprenti' &&
                      candidature?.Formation?.type_de_formation__c !==
                        'Formation France apprenti' ? (
                        <Tooltip message={t('tooltips.softSkills')}></Tooltip>
                      ) : (
                        <Tooltip
                          message={t('tooltips.softSkillsApp')}></Tooltip>
                      )}
                      {t('softSkills')}
                    </h6>
                    {candidature?.Formation?.type_de_formation__c !==
                      'Apprenti' &&
                    candidature?.Formation?.type_de_formation__c !==
                      'Formation France apprenti' ? (
                      <select
                        name="n_1_r_sultat_soft_skills__c"
                        value={form.n_1_r_sultat_soft_skills__c}
                        onChange={onChange}
                        onBlur={onChange}
                        disabled={readOnly}>
                        <option value={''}></option>
                        {selectContents.n_1_r_sultat_soft_skills__c__classique.map(
                          (item: number, index: number) => {
                            return (
                              <option key={index} value={item}>
                                {item.toString()}
                              </option>
                            );
                          }
                        )}
                      </select>
                    ) : (
                      <select
                        name="n_r_sultats_soft_skills_app__c"
                        value={form.n_r_sultats_soft_skills_app__c}
                        onChange={onChange}
                        onBlur={onChange}
                        disabled={readOnly}>
                        <option value={''}></option>
                        {selectContents.n_r_sultat_soft_skills__c__apprenti.map(
                          (item: number, index: number) => {
                            return (
                              <option key={index} value={item}>
                                {item.toString()}
                              </option>
                            );
                          }
                        )}
                      </select>
                    )}
                    <h6>
                      <Tooltip message={t('tooltips.lv')}></Tooltip> {t('lv')}
                    </h6>

                    <select
                      name="n_1_r_sultat_l_v__c"
                      value={form.n_1_r_sultat_l_v__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      {selectContents.n_1_r_sultat_l_v__c.map(
                        (item: number, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item.toString()}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  <div>
                    <h4>
                      {candidature?.Formation?.type_de_formation__c !==
                        'Apprenti' &&
                      candidature?.Formation?.type_de_formation__c !==
                        'Formation France apprenti' ? (
                        <Tooltip message={t('tooltips.appReco')}></Tooltip>
                      ) : (
                        <Tooltip message={t('tooltips.appRecoApp')}></Tooltip>
                      )}
                      {t('appReco')}
                    </h4>
                    <select
                      name="appr_ciations_ou_recommandations__c"
                      value={form.appr_ciations_ou_recommandations__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      {selectContents.appr_ciations_ou_recommandations__c.map(
                        (item: number, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item.toString()}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </>
              )}

              {candidature?.Contact?.type_tech__c === 'International' && (
                <div>
                  <h6>
                    <Tooltip message={t('tooltips.adequation')}></Tooltip>{' '}
                    {t('adequation')}
                  </h6>
                  <select
                    name="ad_quation_du_parcours_pr_alable_par_rap__c"
                    value={form.ad_quation_du_parcours_pr_alable_par_rap__c}
                    onChange={(e: any) => {
                      onChange(e);
                      e.target.value === '0'
                        ? setCandidatureInadView(true)
                        : setCandidatureInadView(false);
                    }}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.ad_quation_du_parcours_pr_alable_par_rap__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  {/* 
                  //ne s'affiche que si la note ADÉQUATION DU PARCOURS PRÉALABLE... est égale à zéro 
                  */}
                  {candidatureInadView && (
                    <>
                      <h6>
                        <Tooltip message={t('tooltips.noAdequate')}></Tooltip>{' '}
                        {t('noAdequate')}
                      </h6>
                      <select
                        name="si_candidature_inad_quate__c"
                        value={form.si_candidature_inad_quate__c}
                        onChange={onChange}
                        onBlur={onChange}
                        disabled={readOnly}>
                        <option value={''}></option>
                        <option value={'Refus - parcours académique inéligible'}>
                          Refus - parcours académique inéligible
                        </option>
                        <option value={'Refus - proposition réorientation'}>
                          Refus - proposition réorientation
                        </option>
                      </select>
                    </>
                  )}

                  <h6>
                    <Tooltip message={t('tooltips.academicIntSpe')}></Tooltip>{' '}
                    {t('academicIntSpe')}
                  </h6>
                  <select
                    name="r_sultats_scientifiques_ou_sp__c"
                    value={form.r_sultats_scientifiques_ou_sp__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.r_sultats_scientifiques_ou_sp__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.academicIntOther')}></Tooltip>{' '}
                    {t('academicIntOther')}
                  </h6>
                  <select
                    name="r_sultats_autres_mati_res_n_1__c"
                    value={form.r_sultats_autres_mati_res_n_1__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.r_sultats_autres_mati_res_n_1__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.academicIntSpe')}></Tooltip>{' '}
                    {t('academicIntN1')}
                  </h6>
                  <select
                    name="r_sultats_scientifiques_ou_sp_cifiques__c"
                    value={form.r_sultats_scientifiques_ou_sp_cifiques__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.r_sultats_scientifiques_ou_sp_cifiques__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>
                    <Tooltip message={t('tooltips.academicIntOther')}></Tooltip>{' '}
                    {t('academicIntN1Other')}
                  </h6>
                  <select
                    name="r_sultats_acad_miques_avant_n_1_mati_r__c"
                    value={form.r_sultats_acad_miques_avant_n_1_mati_r__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.r_sultats_acad_miques_avant_n_1_mati_r__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              )}
              <h5>
                {candidature?.Contact?.type_tech__c === 'Francais' &&
                  candidature?.Formation?.type_de_formation__c !== 'Apprenti' &&
                  candidature?.Formation?.type_de_formation__c !==
                    'Formation France apprenti' && (
                    <Tooltip message={t('tooltips.motivation')}></Tooltip>
                  )}
                {t('motivation')}
              </h5>
              {candidature?.Contact?.type_tech__c === 'Francais' && (
                <>
                  <h6>
                    <Tooltip message={t('tooltips.cvLetter')}></Tooltip>{' '}
                    {t('cvLetter')}
                  </h6>
                  <select
                    name="questionnaire_cv_et_lettre_de_motivati__c"
                    value={form.questionnaire_cv_et_lettre_de_motivati__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.questionnaire_cv_et_lettre_de_motivati__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>

                  <h6>
                    <Tooltip message={t('tooltips.schoolChoice')}></Tooltip>{' '}
                    {t('schoolChoice')}
                  </h6>

                  <select
                    name="argumentation_du_choix_de_l_e_cole__c"
                    value={form.argumentation_du_choix_de_l_e_cole__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.argumentation_du_choix_de_l_e_cole__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>

                  <h6>
                    <Tooltip message={t('tooltips.proProject')}></Tooltip>{' '}
                    {t('proProject')}
                  </h6>
                  <select
                    name="argumentation_du_projet_professionnel__c"
                    value={form.argumentation_du_projet_professionnel__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.argumentation_du_projet_professionnel__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>

                  <h6>
                    <Tooltip message={t('tooltips.potential')}></Tooltip>{' '}
                    {t('potential')}
                  </h6>
                  <select
                    name="potentiel_et_motivation__c"
                    value={form.potentiel_et_motivation__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.potentiel_et_motivation__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>

                  {candidature?.Formation?.type_de_formation__c !==
                    'Apprenti' &&
                    candidature?.Formation?.type_de_formation__c !==
                      'Formation France apprenti' && (
                      <>
                        <h6>
                          <Tooltip message={t('tooltips.experience')}></Tooltip>{' '}
                          {t('experience')}
                        </h6>
                        <select
                          name="experiences__c"
                          value={form.experiences__c}
                          onChange={onChange}
                          onBlur={onChange}
                          disabled={readOnly}>
                          <option value={''}></option>
                          {selectContents.experiences__c.map(
                            (item: number, index: number) => {
                              return (
                                <option key={index} value={item}>
                                  {item.toString()}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </>
                    )}
                </>
              )}
              {candidature?.Contact?.type_tech__c === 'International' && (
                <>
                  <h6>
                    <Tooltip message={t('tooltips.proExpInt')}></Tooltip>{' '}
                    {t('proExpInt')}
                  </h6>
                  <select
                    name="exp_rience_professionnelle_stage_s_j__c"
                    value={form.exp_rience_professionnelle_stage_s_j__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.exp_rience_professionnelle_stage_s_j__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <h6>{t('recoLetter')}</h6>
                  <select
                    name="lettre_de_recommandation1__c"
                    value={form.lettre_de_recommandation1__c}
                    onChange={(e: any) => {
                      if (
                        e.target.value === 'Lettre académique' ||
                        e.target.value === 'Lettre entreprise'
                      ) {
                        setScoreBonus(2);
                        setForm((form) => ({
                          ...form,
                          point_bonus_lettre_recommandation__c: 2
                        }));
                      } else {
                        setScoreBonus(0);
                        setForm((form) => ({
                          ...form,
                          point_bonus_lettre_recommandation__c: 0
                        }));
                      }
                      onChange(e);
                    }}
                    disabled={readOnly}>
                    <option value=""></option>
                    <option value="Lettre académique">Lettre académique</option>
                    <option value="Lettre entreprise">Lettre entreprise</option>
                    <option value="Non fournie">Non fournie</option>
                  </select>
                  {/* <h6>{t('recoLetterBonus')}</h6> */}
                  {/* 
                  //Le bonus (+2) est directement ajouté au score si la lettre est fournie
                  */}
                  <h5>{t('cvLetterInt')}</h5>
                  <h6>{t('cvIntCare')}</h6>
                  <select
                    name="style_orthographe__c"
                    value={form.style_orthographe__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.style_orthographe__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>

                  <h6>{t('cvIntChoice')}</h6>
                  <select
                    name="argumentation_sur_le_choix_de_formation__c"
                    value={form.argumentation_sur_le_choix_de_formation__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.argumentation_sur_le_choix_de_formation__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>

                  <h6>{t('cvIntProject')}</h6>
                  <select
                    name="argumentation_sur_le_projet_pro_tudes__c"
                    value={form.argumentation_sur_le_projet_pro_tudes__c}
                    onChange={onChange}
                    onBlur={onChange}
                    disabled={readOnly}>
                    <option value={''}></option>
                    {selectContents.argumentation_sur_le_projet_pro_tudes__c.map(
                      (item: number, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item.toString()}
                          </option>
                        );
                      }
                    )}
                  </select>
                </>
              )}
            </div>
          </div>

          <div className="footer">
            {candidature?.Contact?.type_tech__c === 'Francais' && (
              <>
                {(candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
                  candidature?.Formation?.type_de_formation__c ===
                    'Formation France apprenti') && (
                  <>
                    <h6>
                      <Tooltip message={t('tooltips.bonus')}></Tooltip>{' '}
                      {t('bonus')}
                    </h6>
                    <select
                      name="bonus__c"
                      value={form.bonus__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      {selectContents.bonus__c_francais.map(
                        (item: number, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item.toString()}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </>
                )}
                <h6>
                  <Tooltip message={t('tooltips.malus')}></Tooltip> {t('malus')}
                </h6>
                <select
                  name="malus__c"
                  value={form.malus__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  {selectContents.malus__c.map(
                    (item: number, index: number) => {
                      return (
                        <option key={index} value={item}>
                          {item.toString()}
                        </option>
                      );
                    }
                  )}
                </select>
                {candidature?.Formation?.type_de_formation__c === 'Apprenti' ||
                candidature?.Formation?.type_de_formation__c ===
                  'Formation France apprenti' ? (
                  <div className="notes-flex">
                    <h6>{t('totalGrade')}</h6>
                    <input
                      name="note_totale_dossier50_app_fr__c"
                      value={`${score} / 50 points`}
                      readOnly
                    />
                    <h6>{t('finalGrade20')}</h6>
                    <input
                      name="note_totale_dossier20_app_fr__c"
                      value={`${score / 2.5} / 20 points`}
                      readOnly
                    />
                  </div>
                ) : (
                  // Score if formation type etudiant
                  <div className="notes-flex">
                    <h6>{t('totalGrade')}</h6>
                    <input
                      name="note_totale_dossier50_app_fr__c"
                      value={`${score} / 60 points`}
                      readOnly
                    />
                    <h6>{t('finalGrade20')}</h6>
                    <input
                      name="note_totale_dossier20_app_fr__c"
                      value={`${(score / 3).toFixed(2)} / 20 points`}
                      readOnly
                    />
                  </div>
                )}
                <h6>{t('commentFolder')}</h6>
                <textarea
                  name="commentaire__c"
                  value={form.commentaire__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}
                />
                <h6>{t('convocation')}</h6>
                <select
                  name="convoqu_entretien_fr__c"
                  value={form.convoqu_entretien_fr__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}>
                  <option value={''}></option>
                  <option value="Convoqué">Convoqué</option>
                  <option value="Non convoqué">Non convoqué</option>
                  <option value="Exempté">Exempté</option>
                  <option value="En attente">En attente</option>
                </select>
              </>
            )}
            {candidature?.Contact?.type_tech__c === 'International' && (
              <>
                <div className="notes-flex">
                  <h6>{t('folderGradeInt')}</h6>
                  <input
                    value={`${score} / 50 points`}
                    name="note_finale_dossier50__c"
                    readOnly
                  />
                </div>
                <h5>{t('decision')}</h5>
                <h6>
                  <Tooltip message={t('tooltips.convocationInt')}></Tooltip>{' '}
                  {t('convocationInt')}
                </h6>
                <select
                  name="convocation_entretien__c"
                  value={form.convocation_entretien__c}
                  onBlur={onChange}
                  onChange={(e) => {
                    onChange(e);
                    setExemptDecisionInternationalView(e.target.value);
                  }}
                  disabled={readOnly}>
                  <option value=""></option>
                  <option value="Convoqué">Convoqué</option>
                  <option value="Non convoqué">Non convoqué</option>
                  <option value="Exempté">Exempté</option>
                </select>
                {exemptDecisionInternationalView === 'Convoqué' ||
                form.convocation_entretien__c === 'Convoqué' ? (
                  <>
                    <h6>{t('questionInt')}</h6>
                    <textarea
                      name="si_convoqu__c"
                      value={form.si_convoqu__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}
                    />
                  </>
                ) : exemptDecisionInternationalView === 'Exempté' ||
                  form.convocation_entretien__c === 'Exempté' ? (
                  <>
                    {' '}
                    <h6>{t('ifExempt')}</h6>
                    <select
                      name="si_exempt_d_cision_d_admission__c"
                      value={form.si_exempt_d_cision_d_admission__c}
                      onChange={onChange}
                      onBlur={onChange}
                      disabled={readOnly}>
                      <option value={''}></option>
                      <option value="Admis">Admis</option>
                      <option value="Non admis">Non admis</option>
                    </select>
                  </>
                ) : (
                  ''
                )}
                <h6>
                  <Tooltip message={t('tooltips.commentFolderInt')}></Tooltip>{' '}
                  {t('commentFolderInt')}
                </h6>
                <input
                  name="commentaire_dossier__c"
                  value={form.commentaire_dossier__c}
                  onChange={onChange}
                  onBlur={onChange}
                  disabled={readOnly}
                />
              </>
            )}
            <h6>{t('jurySignature')}</h6>
            {candidature?.Contact?.type_tech__c === 'International' ? (
              <input
                type="checkbox"
                defaultChecked={form.signature_jury_dossier__c}
                value="true"
                onChange={(e) => {
                  setCheckboxSignature(!checkboxSignature);
                  setForm((form) => ({
                    ...form,
                    signature_jury_dossier__c: !checkboxSignature
                  }));
                }}
                name="signature_jury_dossier__c"
                disabled={readOnly}
              />
            ) : (
              <input
                type="checkbox"
                defaultChecked={form.signature_du_correcteur__c}
                value="true"
                onChange={(e) => {
                  setCheckboxSignature(!checkboxSignature);
                  setForm((form) => ({
                    ...form,
                    signature_du_correcteur__c: !checkboxSignature
                  }));
                }}
                name="signature_du_correcteur__c"
                disabled={readOnly}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['jury', 'common'])(FolderGrade));
