/**
 * This component displays the Personal Data policy informations
 */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import './PersonalDataPolicy.scss';

interface ContainerProps {}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const PersonalDataPolicy: React.FC<IProps> = (props) => {
  return (
    <div className="personal-data-policy">
      <div className="personal-data-policy-container">
        <h2>POLITIQUE DONNEES PERSONNELLES :</h2>
        <i>Mise à jour le 14 février 2022 susceptible d’évolutions.</i>
        <div>
          Le candidat étudiant est informé que JUNIA effectue un traitement de
          données et d’informations le concernant, ce incluant notamment des
          données personnelles.
        </div>
        <div>
          <strong>Est considérée comme donnée personnelle</strong> toute information se
          rapportant à une personne physique identifiée ou identifiable,
          directement ou indirectement.
        </div>
        <div>
          Ces données personnelles sont destinées à JUNIA, association
          enregistrée à la Préfecture et dont le siège social est situé 2 rue
          Norbert Ségard 59046 Lille (SIREN 783 707 003 00035), qui est
          responsable de traitement.
        </div>
        <div>
          Dans le cadre de l’utilisation de la plateforme d’admission accessible
          sur le Web :
          <a href="https://admissions.junia.com">
            https://admissions.junia.com
          </a>
          , l’Association JUNIA récolte vos données personnelles, sur la base
          légale de l’intérêt légitime, consistant aux fins suivantes :
        </div>
        <div className="personal-data-policy-list">
          - Etude de la complétude de votre dossier de candidature (hors
          parcoursup pour la France, échanges internationaux, candidat
          international).
        </div>
        <div className="personal-data-policy-list">
          - Examen de votre candidature.
        </div>
        <div className="personal-data-policy-list">
          - Acceptation ou non de votre candidature.
        </div>
        <div className="personal-data-policy-list">
          - En cas d’admission effective : transmission à la direction des
          études pour procéder à votre inscription administrative.
        </div>
        <div>
          JUNIA s’engage à ne récolter que les données personnelles et
          suffisantes à cet égard. Toutes les données obligatoires sont
          signalées par un astérisque sur les formulaires de collecte.
        </div>
        <div>
          Les données sont conservées pour la durée nécessaire à l’étude de
          votre dossier, et qui ne sauraient excéder :
        </div>
        <div className="personal-data-policy-list">
          - En cas d’acceptation de la candidature et inscription effective du
          candidat : 5 ans à compter de la fin de la scolarité de l’étudiant
        </div>
        <div className="personal-data-policy-list">
          - En cas d’acceptation de la candidature et non inscription effective
          du candidat : 1 an à compter de la non-inscription effective du
          candidat
        </div>
        <div className="personal-data-policy-list">
          - En cas de refus de la candidature : 1 an à compter de la
          notification de refus.
        </div>
        <div>
          Certaines des données personnelles peuvent être transmises à nos
          lycées partenaires pour les classes préparatoires, aux ambassades et
          consulats et campus France pour les candidats internationaux.
        </div>
        <div>
          Les données personnelles ne sont en aucun cas cédées à un tiers à des
          fins commerciales.
        </div>
        <div>
        <strong>Conformément à la réglementation en vigueur, vous disposez des droits
          suivants :</strong>
        </div>
        <div className="personal-data-policy-list">
          - Droit d’accès : Vous disposez de la faculté d’accéder aux données
          personnelles vous concernant.
        </div>
        <div className="personal-data-policy-list">
          - Droit de rectification ou d’effacement : vous pouvez demander la
          rectification, la mise à jour, le verrouillage, ou encore l’effacement
          des données personnelles vous concernant qui peuvent s’avérer le cas
          échéant inexactes, erronées, incomplètes ou obsolètes.
        </div>
        <div className="personal-data-policy-list">
          - Droit d’effacement : vous pouvez demander l’effacement des données
          personnelles vous concernant.
        </div>
        <div className="personal-data-policy-list">
          - Droit de limitation : vous pouvez demander la limitation des données
          personnelles vous concernant.
        </div>
        <div className="personal-data-policy-list">
          - Droit de retirer votre consentement à un traitement de vos données
          personnelles.
        </div>
        <div className="personal-data-policy-list">
          - Droit à la portabilité : vous pouvez demander à recevoir les données
          personnelles vous concernant dans un format structuré, couramment
          utilisé et lisible par machine.
        </div>
        <div className="personal-data-policy-list">
          - Droit d’opposition : dans certaines conditions, vous pouvez dans
          certains cas vous opposer au traitement des Données personnelles vous
          concernant.
        </div>
        <div className="personal-data-policy-list">
          - Droit d’introduire une réclamation auprès d’une autorité de contrôle
          (la CNIL pour la France).
        </div>
        <div>
          Vous pouvez également définir des directives générales et
          particulières relatives au sort des données à caractère personnel
          après votre décès. Le cas échéant, les héritiers d’une personne
          décédée peuvent exiger de prendre en considération le décès de leur
          proche et/ou de procéder aux mises à jour nécessaires.
        </div>
        <div><strong>Vous pouvez exercer ces droits de la manière suivante :</strong> </div>
        <div>
          Ce droit peut être exercé par voie postale auprès de JUNIA 2 rue
          Norbert Segard, 59046 Lille, ou par voie électronique à l’adresse
          email suivante :
          <a href="mailto:junia.dpo@junia.com">junia.dpo@junia.com</a>
        </div>
        <div>
          Votre demande sera traitée sous réserve que vous apportiez la preuve
          de votre identité, notamment par la production d’un scan de votre
          titre d’identité valide ou d’une photocopie signée de votre titre
          d’identité valide (en cas de demande adressée par écrit).
        </div>
        <div>
          JUNIA vous informe qu’il sera en droit, le cas échéant, de s’opposer
          aux demandes manifestement abusives (nombre, caractère répétitif ou
          systématique).
        </div>
        <div>
          JUNIA s’engage à vous répondre dans un délai raisonnable qui ne
          saurait dépasser 1 mois à compter de la réception de votre demande.
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['cgu', 'common'])(PersonalDataPolicy)
);
