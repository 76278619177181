import React, {
  createElement,
  useEffect,
  useRef,
  useState
} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import logoJunia from '../../assets/images/junialogoviolet.png';
import flagUK from '../../assets/images/flagUK.png';
import flagFR from '../../assets/images/flagFR.png';

import './CreateAccount.scss';
import Header from './components/Header/Header';
import Recapitulatif from './components/Recaputilatif/Recapitulatif';
import EducationSystem from './components/EducationSystem/EducationSystem';
import ExchangeChoice from './components/ExchangeChoice/ExchangeChoice';
import _ from 'lodash';
import { Contact } from '../../services/api/Contact/interface';
import AuthService from '../../services/api/Auth/AuthService';
import ContactService from '../../services/api/Contact/ContactService';
import PersonnalInfos from './components/PersonnalInfos/PersonnalInfos';
import Cursus from './components/Cursus/Cursus';
import Languages from './components/Languages/Languages';
import Experiences from './components/Experiences/Experiences';
import Trips from './components/Trips/Trips';
import InternationalSystemEducation from './components/InternationalSystemEducation/InternationalSystemEducation';
import { EducationHistory } from '../../services/api/EducationHistory/interface';
import EducationHistoryService from '../../services/api/EducationHistory/EducationHistoryService';
import { CompetenceLinguistique } from '../../services/api/CompetenceLinguistique/interface';
import CompetenceLinguistiqueService from '../../services/api/CompetenceLinguistique/CompetenceLinguistiqueService';
import { Experience } from '../../services/api/Experience/interface';
import ExperienceService from '../../services/api/Experience/ExperienceService';
import i18n from '../../i18n';
import { Trip } from '../../services/api/Trip/interface';
import TripService from '../../services/api/Trip/TripService';
import SpinnerComponent from '../../components/SpinnerComponent/SpinnerComponent';

interface ContainerProps {
  contact: Contact;
}

type ChildProps = {
  title: string;
  subtitle: string;
};

const inscriptionArray: any = {
  Francais: [
    {
      name: 'Non commencée',
      component: EducationSystem
    },
    {
      name: 'Recapitulatif',
      component: Recapitulatif
    },
    {
      name: 'Infos',
      component: PersonnalInfos
    },
    {
      name: 'Cursus',
      component: Cursus
    },
    {
      name: 'Langues',
      component: Languages
    },
    {
      name: 'Expériences Pro',
      component: Experiences
    },
    {
      name: 'Séjours',
      component: Trips
    }
  ],
  undefined: [
    {
      name: 'Non commencée',
      component: EducationSystem
    },
    {
      name: 'etape 2',
      component: InternationalSystemEducation
    }
  ],
  International: [
    {
      name: 'Non commencée',
      component: EducationSystem
    },
    {
      name: 'etape 2',
      component: InternationalSystemEducation
    },
    {
      name: 'Infos',
      component: PersonnalInfos
    },
    {
      name: 'Cursus',
      component: Cursus
    },
    {
      name: 'Langues',
      component: Languages
    },
    {
      name: 'Expériences Pro',
      component: Experiences
    },
    {
      name: 'Séjours',
      component: Trips
    }
  ],
  Echange: [
    {
      name: 'Non commencée',
      component: EducationSystem
    },
    {
      name: 'etape 2',
      component: InternationalSystemEducation
    },
    {
      name: 'Infos',
      component: PersonnalInfos
    },
    {
      name: 'Choix',
      component: ExchangeChoice
    },
    {
      name: 'Cursus',
      component: Cursus
    },
    {
      name: 'Langues',
      component: Languages
    },
    {
      name: 'Expériences Pro',
      component: Experiences
    },
    {
      name: 'Séjours',
      component: Trips
    }
  ]
};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const CreateAccount: React.FC<IProps> = (props) => {
  const { t, title, subtitle } = props;
  // const [selectedArray, setSelectedArray] = useState(inscriptionArray[0]);
  const [selectedStep, setSelectedStep] = useState(
    inscriptionArray['Francais'][0]
  );

  const [contact, setContact] = useState<Contact>({});
  const [form, setForm] = useState<Contact>({});
  const [canNext, setCanNext] = useState<boolean>(false);
  const [me, setMe] = useState<any>({});
  const [height, setHeight] = useState<number>(0);
  const [active, setActive] = useState(localStorage.getItem('i18nextLng'));
  const [educationHistorys, setEductionHistorys] = useState<EducationHistory[]>(
    []
  );
  const [competencesLing, setCompetencesLing] = useState<
    CompetenceLinguistique[]
  >([]);
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);

  const divRef = useRef<HTMLDivElement>(null);
  const componentref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const scrollToTop = () => {
    // document.getElementsByClassName('subscribe-list').scrollTop({
    //   top: 0,
    // });
    componentref.current?.scrollTo(0, 0);
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);

  useEffect(() => {
    if (me.id_heroku__c) {
      getContact();
      setStep();
    }
  }, [me.id_heroku__c]);

  useEffect(() => {
    // updateNextStep(contact);
    scrollToTop();
  }, [selectedStep]);

  useEffect(() => {
    let element = divRef.current;
    if (
      element &&
      element.clientHeight &&
      element.clientHeight > 0 &&
      element &&
      element.clientWidth &&
      element.clientWidth > 0
    ) {
    setHeight(element.clientHeight);
    }
  }, [selectedStep]);
  
  const menuItems = [
    {
      text: t('word.disconnect'),
      path: '/',
      event: () => {
        AuthService.logout();
      }
    }
  ];

  const updateNextStep = (data: Contact, step: number) => {
    if (data.type_tech__c) {
      let array;
      if(data.type_tech__c === 'Non défini') {
        array = inscriptionArray['undefined']
      } else {
        array =inscriptionArray[data.type_tech__c];
      }
      let found = _.findIndex(array, selectedStep);
      let newStep = found + step;

      let newSelected = array[newStep];
      let newForm = form;
      if (newSelected && newSelected.name) {
        // setContact({
        //   ...data,
        //   inscription_tech__c: newSelected.name
        // });
        setForm({
          ...data,
          inscription_tech__c: newSelected.name
        });
        // setCanNext(true);
      }
    } else {
      if (selectedStep && selectedStep.name) {
        // setContact({
        //   ...data,
        //   inscription_tech__c: selectedStep.name,
        //   type_tech__c: 'Francais'
        // });
        setForm({
          ...data,
          inscription_tech__c: selectedStep.name,
          type_tech__c: contact.type_tech__c ?? 'Francais'
        });
        // setCanNext(true);
      }
    }
  };

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element && element.clientHeight && element.clientHeight > 0) {
      setHeight(element.clientHeight);
    }
  };
  const getContact = async () => {
    setLoading(true);
    try {
      let contactResponse = await ContactService.getContact(me.id_heroku__c);
      setContact(contactResponse.data);
      updateNextStep(contactResponse.data, 0);
      setLoading(false);
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const getMe = async () => {
    try {
      const meRequest = await AuthService.me();
      setMe(meRequest.data);
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const setStep = async () => {
    try {
      if (me.type_tech__c) {
        let type_tech__c;
        if(me.type_tech__c === 'Non défini') {
          type_tech__c = 'undefined'
        } else {
          type_tech__c = me.type_tech__c
        }
        let found = _.find(
          inscriptionArray[type_tech__c],
          (o) => o.name === me.inscription_tech__c
        );
        if (found) {
          setSelectedStep(found);
        }
      } else {
        // let found = _.find(
        //   inscriptionArray['Echange'],
        //   (o) => o.name === me.inscription_tech__c
        // );
        // if (found) {
        //   setSelectedStep(found);
        // }
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const pushInFileList = (data: any) => {
    let files = [...fileList];
    if (data.type) {
      let found = _.findIndex(files, (o) => o.type === data.type);
      if (found >= 0) {
        files.splice(found, 1, data);
      } else {
        files.push(data);
      }
      setFileList(files);
    }
  };

  const handleNext = (data: any, next: boolean) => {
    switch (selectedStep.name) {
      case 'Cursus':
        if (data.cursus) {
          setEductionHistorys(data.cursus);
        }
        if (data.data) {
          if (data.files) {
            pushInFileList(data.files);
          }
          // setContact({
          //   ...contact,
          //   ...data.data
          // });
          setForm({
            ...form,
            ...data.data
          });
        }
        break;

      case 'Langues':
        if (data.languages) {
          setCompetencesLing(data.languages);
        }
        // if (data.data) {
        //   // pushInFileList(data.files);
        //   setContact({
        //     ...contact,
        //     ...data.data
        //   });
        //   setForm({
        //     ...form,
        //     ...data.data
        //   });
        // }
        break;
      case 'Expériences Pro':
        // setContact()

        setExperiences(data.experiences);
        setFileList(data.files);
        break;
      case 'Séjours':
        setTrips(data);
        break;
      case 'Infos':
        if (data.files) {
          pushInFileList(data.files);
        }
        // setContact({
        //   ...contact,
        //   ...data.data
        // });
        setForm({
          ...form,
          ...data.data
        });
        break;
      case 'Non commencée':
        setContact({
          ...contact,
          ...data
        });
        setForm({
          ...form,
          ...data
        });
        break;
      case 'Choix':
        setContact({
          ...contact,
          ...data
        });
        setForm({
          ...form,
          ...data
        });
        break;
      case 'etape 2':
        setContact({
          ...contact,
          ...data
        });
        setForm({
          ...form,
          ...data
        });
        break;
    }
    setCanNext(next);
  };

  const pagination = (step: number) => {
    if (contact.type_tech__c) {
      setLoading(true);
      let array;
      if(contact.type_tech__c === 'Non défini') {
        array = inscriptionArray['undefined']
      } else {
        array = inscriptionArray[contact.type_tech__c];
      }
      if (array) {
        let found = _.findIndex(array, selectedStep);
        let newStep = found + step;
        let newSelected = array[newStep];
        let newForm;
        let newContact;
        if (contact?.id_heroku__c) {
          if (step > 0) {
            switch (selectedStep.name) {
              case 'Cursus':
                if (educationHistorys.length > 0) {
                  sendEducationHistorys([...educationHistorys]);
                }
                break;
              case 'Langues':
                if (competencesLing.length > 0) {
                  sendCompetencesLinguistiques([...competencesLing]);
                }
                break;
              case 'Expériences Pro':
                sendExperiences([...experiences]);
                break;
              case 'Séjours':
                sendTrips([...trips]);
                break;
              case 'Infos':
                break;
            }
            newForm = {
              ...form
            };
            newContact = {
              ...contact
            };
            if (newSelected) {
              newForm.inscription_tech__c = newSelected.name;
              newContact.inscription_tech__c = newSelected.name;
            }
          } else {
            setEductionHistorys([]);

            setCompetencesLing([]);
            setExperiences([]);
            setTrips([]);
            setForm({});

            newForm = {
              inscription_tech__c: newSelected.name
            };
            newContact = {
              inscription_tech__c: newSelected.name
            };
          }
          if (newSelected) {
            let files = [...fileList];
            setFileList([]);
            newForm = {...newForm, etablissement_partenaire__c: undefined}
            ContactService.patchContact(contact?.id_heroku__c, newForm, files, [
              'identity_card',
              'scholarship',
              'resume',
              'bac_grades'
            ]).then(async (res) => {
              await getContact();
            });
            setCanNext(false);
            setSelectedStep(newSelected);
            // let newForm = { ...form, inscription_tech__c: newSelected.name };
            setForm(newForm);
            // setContact(newContact);
            updateNextStep(newForm, step);
          } else if (
            !newSelected &&
            contact.type_tech__c !== undefined &&
            form.type_tech__c !== undefined
          ) {
            if (step > 0) {
              let newForm = { ...form, inscription_tech__c: 'Terminée' };
              setForm(newForm);
              ContactService.patchContact(contact?.id_heroku__c, newForm).then(
                (res) => {
                  location.reload();
                }
              );
            }
          }
        }
      }
    }
  };

  const sendEducationHistorys = async (data: EducationHistory[]) => {
    let list = await _.map(data, async (educationHistory) => {
      if (contact && contact.id_heroku__c) {
        if (educationHistory.id_heroku__c) {
          await EducationHistoryService.patchContactEducationHistory(
            contact?.id_heroku__c,
            educationHistory,
            educationHistory.fileList,
            ['degree', 'grades', 'bac_transcript']
          );
        } else {
          await EducationHistoryService.createContactEducationHistory(
            contact.id_heroku__c,
            educationHistory,
            educationHistory.fileList,
            ['degree', 'grades', 'bac_transcript']
          );
        }
        return educationHistory;
      }
    });
    Promise.all(list).then((res) => {
      setEductionHistorys([]);
    });
  };

  const sendCompetencesLinguistiques = async (
    data: CompetenceLinguistique[]
  ) => {
    let list = await _.map(data, async (competenceLinguistique) => {
      if (contact && contact.id_heroku__c) {
        if (competenceLinguistique.id_heroku__c) {
          await CompetenceLinguistiqueService.patchCompetencesLinguistique(
            contact.id_heroku__c,
            competenceLinguistique,
            competenceLinguistique.fileList,
            ['language_degree']
          );
        } else {
          await CompetenceLinguistiqueService.createCompetencesLinguistique(
            contact.id_heroku__c,
            competenceLinguistique,
            competenceLinguistique.fileList,
            ['language_degree']
          );
        }
        return competenceLinguistique;
      }
    });
    Promise.all(list).then((res) => {
      setCompetencesLing([]);
    });
  };

  const sendExperiences = async (data: Experience[]) => {
    try {
      let list = await _.map(data, async (experience) => {
        if (contact && contact.id_heroku__c) {
          if (experience.id_heroku__c) {
            await ExperienceService.patchExperience(
              contact.id_heroku__c,
              experience
            );
          } else {
            let ret = await ExperienceService.createExperience(
              contact.id_heroku__c,
              experience
            );
          }
          return experience;
        }
      });
      Promise.all(list).then((res) => {
        setExperiences([]);
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 477 ~ sendExperiences ~ error',
        error
      );
    }
  };

  const sendTrips = async (data: Trip[]) => {
    try {
      let list = await _.map(data, async (trip) => {
        if (contact && contact.id_heroku__c) {
          if (trip.id_heroku__c) {
            await TripService.patchTrip(contact.id_heroku__c, trip);
          } else {
            await TripService.createTrip(contact.id_heroku__c, trip);
          }
          return trip;
        }
      });
      Promise.all(list).then((res) => {
        setTrips([]);
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 477 ~ sendExperiences ~ error',
        error
      );
    }
  };

  return (
    <div className="subscribe-layout">
      <div className="subscribe-height" ref={divRef}>
        <div className="subscribe-logo">
          <img src={logoJunia} alt="junia"></img>
          <div className="toolbox">
            {menuItems.map((item) => (
              <button
                key={item.text}
                className={`btn btn-outlined ${
                  location.pathname == item.path ? 'active' : ''
                }`}
                onClick={() => {
                  item.event();
                }}>
                {item.text}
              </button>
            ))}
            <div className="flag-container">
              <button
                type="button"
                onClick={() => {
                  i18n.changeLanguage('fr');
                  setActive('fr');
                }}
                className={`flag ${active === 'fr' ? 'fr' : ''}`}>
                <img src={flagFR} alt="drapeau français" />
              </button>
            </div>
            <div className="flag-container">
              <button
                type="button"
                onClick={() => {
                  i18n.changeLanguage('en');
                  setActive('en');
                }}
                className={`flag ${active === 'en' ? 'en' : ''}`}>
                <img src={flagUK} alt="drapeau anglais" />
              </button>
            </div>
          </div>
        </div>
        {selectedStep.name !== 'Terminée' && (
          <>
            {selectedStep.name !== 'Non commencée' && (
              <button
                className="return btn btn-outlined"
                type="button"
                onClick={() => pagination(-1)}>
                {/* <img alt="return" src={Return} /> */}
                <span>{t('word.return')}</span>
              </button>
            )}
            <div className="subscribe-header">
              <Header
                title={t(`title.${selectedStep.name}`)}
                subtitle={t(`subtitle.${selectedStep.name}`)}
                step={selectedStep}
              />
              <div className="subscribe-next">
                {canNext && !loading ? (
                  <button
                    type="button"
                    className="btn btn-main btn-fullwidth"
                    onClick={() => pagination(+1)}>
                    <span>{t('word.next')}</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-main btn-fullwidth btn-disabled">
                    <span>{t('word.next')}</span>
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className="subscribe-list-container"
        style={{
          height: 'calc(98% - ' + height + 'px)'
        }}>
        <div className="subscribe-list" ref={componentref}>
          {contact.id_heroku__c &&
            !loading &&
            createElement(selectedStep.component, {
              contact: { ...contact, ...form },
              onChange: handleNext,
              getContact: getContact
            })}
          {loading && <SpinnerComponent backdrop={false}></SpinnerComponent>}
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common'])(CreateAccount)
);
