/**
 * This component displays the avaiable formations to apply for
 */
import _ from 'lodash';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import AuthService from '../../../../services/api/Auth/AuthService';
import CandidatureService from '../../../../services/api/Candidature/CandidatureService';
import ContactService from '../../../../services/api/Contact/ContactService';
import { Contact, me } from '../../../../services/api/Contact/interface';
import FormationService from '../../../../services/api/Formation/FormationService';
import { Formation } from '../../../../services/api/Formation/interface';
import formationPicklist from '../../../../assets/data/formation.json';

import './Catalogue.scss';

interface ContainerProps {
  handleClick: () => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Catalogue: React.FC<IProps> = (props) => {
  const { t, handleClick } = props;
  const [height, setHeight] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [formations, setFormations] = useState<Formation[]>([]);
  const [formationSelected, setFormationSelected] = useState<Formation>({});
  const [me, setMe] = useState<me>({});
  const [contact, setContact] = useState<Contact>({});
  const history = useHistory();
  const [filter, setFilter] = useState<any>({
    campus__c: [],
    ann_e_d_acc_s__c: [],
    language__c: [],
    cole__c: [],
    type_de_formation__c: []
  });
  const [filterExc, setFilterExc] = useState<string[]>(['HEI', 'ISA', 'ISEN']);
  const { i18n } = useTranslation();
  const {candidate_id} = useParams<{candidate_id: string}>();


  const accessYearFR = [
    {
      value: '2nd',
      label: 'Bac + 2'
    },
    {
      value: '3rd',
      label: 'Bac +3'
    },
    {
      value: '4th',
      label: 'Bac + 4'
    }
  ];
  
  const formationTypeFR = [
    {
      value: 'Formation France étudiant',
      label : 'Sous statut Etudiant'
    },
    {
      value: 'Formation France apprenti',
      label: 'en Apprentissage'
    }   
  ]

  const handleFilter = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    getMe();
  }, []);
  useEffect(() => {
    if (me.id_heroku__c) {
      getFormations(filter);
      getContact();
    }
  }, [me]);

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);

  const getContact = async () => {
    try {
      console.log(me);
      let contactId = candidate_id ? candidate_id : me.id_heroku__c;
      if(contactId) {
        let contactResponse = await ContactService.getContact(contactId);
        setContact(contactResponse.data);
      } 
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const getMe = async () => {
    try {
      const meRequest = await AuthService.me();
      console.log(meRequest);
      setMe(meRequest.data);
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const getFormations = async (filter: any) => {
    let contactId = candidate_id ? candidate_id : me.id_heroku__c;
    if(contactId) {
      try {
        let formationResponse = await FormationService.getFormations(
          contactId,
          filter
        );
        setFormations(formationResponse.data);
      } catch (err) {
        console.log(
          '🚀 ~ file: Catalogue.tsx ~ getFormations ~ err',
          err
        );
      }
    } 
  };

  const updateFilter = (event: ChangeEvent<HTMLInputElement>) => {
    let filterName = event.target.name;
    let checkedFilters = { ...filter };
    if (event.target.checked) {
      let index = checkedFilters[filterName].findIndex(
        (f: string) => f === event.target.value
      );
      if (index < 0) {
        checkedFilters[filterName].push(event.target.value);
      }
    } else {
      let index = checkedFilters[filterName].findIndex(
        (f: string) => f === event.target.value
      );
      if (index >= 0) {
        checkedFilters[filterName].splice(index, 1);
      }
    }
    getFormations(checkedFilters);
    setFilter(checkedFilters);
  };

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element && element.clientHeight && element.clientHeight > 0) {
      setHeight(element.clientHeight + 80 + 70); //height + padding div + menu size
    }
  };

  const checkedFilter = (input: { name: string; value: string }) => {
    let found = filter[input.name].find((f: string) => f === input.value);
    return found ? true : false;
  };

  const selectFormation = (formation: Formation) => {
    if (formation.sfid) {
      setFormationSelected(formation);
    }
  };

  const handleNext = () => {
    if (me.id_heroku__c && formationSelected.sfid) {
      let contactId = candidate_id ? candidate_id : me.id_heroku__c;
      CandidatureService.checkCandidatureExistOnFormation(
        contactId,
        formationSelected.sfid
      ).then((res) => {
        if (res.data && res.data.id_heroku__c) {
          history.push(`/candidature-process/${res.data.id_heroku__c}`);
        } else {
          if (me.id_heroku__c && !candidate_id) {
            if (me.type_tech__c === 'International') {
              CandidatureService.createCandidature(contactId, {
                formation__c: formationSelected.sfid,
                statut__c: 'Brouillon',
                type_d_admission_internationale__c:
                  contact.candidat_international_partenaire__c === 'Oui'
                    ? 'Candidat international admission partenaire'
                    : 'Candidat international admission'
              }).then((res) => {
                if (res.data && res.data.id_heroku__c) {
                  history.push(`/candidature-process/${res.data.id_heroku__c}`);
                }
              });
            } else {
              CandidatureService.createCandidature(contactId, {
                formation__c: formationSelected.sfid,
                statut__c: 'Brouillon'
              }).then((res) => {
                if (res.data && res.data.id_heroku__c) {
                  history.push(`/candidature-process/${res.data.id_heroku__c}`);
                }
              });
            }
          } else if (candidate_id) {
            CandidatureService.createCandidature(contactId, {
              formation__c: formationSelected.sfid,
              statut__c: 'Brouillon',
              contact_agent__c: true,
              type_d_admission_internationale__c:
                contact.candidat_international_partenaire__c === 'Oui'
                  ? 'Candidat international admission partenaire'
                  : 'Candidat international admission'
            }).then((res) => {
              if (res.data && res.data.id_heroku__c) {
                history.push(`/agent/${candidate_id}/candidature-process/${res.data.id_heroku__c}`);
              }
            });
          }
        }
      });
    }
  };

  return (
    <div className="catalogue-container">
      <div className="catalogue">
        <div className="filtres desktop">
          <div className="erase-filtre">
            <p>{t('filter')}</p>
          </div>
          <div className="filtre-container">
            {(me.type_tech__c !== 'International' && !candidate_id) && (
              <>
                {me.type_tech__c !== 'Echange' ? (
                  <>
                    <div className="filtre-list">
                      <p className="filtre-title">{t('accessYear')}</p>
                      {formationPicklist &&
                        formationPicklist?.picklistFieldValues[
                          'Ann_e_d_acc_s__c'
                        ].values.map((value: any) => {
                          const filterFR = accessYearFR.map(
                            (year) => year.value
                          );
                          if (filterFR.includes(value.value)) {
                            if (i18n.language === 'fr') {
                              const label = accessYearFR.find(
                                (year: { value: string; label: string }) =>
                                  year.value === value.value
                              )?.label;
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="ann_e_d_acc_s__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'ann_e_d_acc_s__c',
                                        value: value.value
                                      })}
                                    />
                                    {label}
                                  </p>
                                </div>
                              );
                            }
                            if (i18n.language === 'en') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="ann_e_d_acc_s__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'ann_e_d_acc_s__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.value}
                                  </p>
                                </div>
                              );
                            }
                          }
                        })}
                    </div>
                    <div className="filtre-list">
                      <p className="filtre-title">{t('formationType')}</p>
                      {formationPicklist &&
                        formationPicklist?.picklistFieldValues[
                          'Type_de_formation__c'
                        ].values.map((value: any) => {
                          const filterFR = formationTypeFR.map(
                            (type) => type.value
                          );
                          if (filterFR.includes(value.value)) {
                            if (i18n.language === 'fr') {
                              const label = formationTypeFR.find(
                                (year: { value: string; label: string }) =>
                                  year.value === value.value
                              )?.label;
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="type_de_formation__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'type_de_formation__c',
                                        value: value.value
                                      })}
                                    />
                                    {label}
                                  </p>
                                </div>
                              );
                            }
                            if (i18n.language === 'en') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="type_de_formation__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'type_de_formation__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.value}
                                  </p>
                                </div>
                              );
                            }
                          }
                        })}
                    </div>
                    <div className="filtre-list">
                      <p className="filtre-title">{t('program')}</p>
                      {formationPicklist &&
                        formationPicklist?.picklistFieldValues[
                          'cole__c'
                        ].values.map((value: any) => {
                          if (
                            value.value !== 'A renseigner' &&
                            value.value !== 'JUNIA'
                          ) {
                            if (i18n.language === 'fr') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="cole__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'cole__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.label}
                                  </p>
                                </div>
                              );
                            }
                            if (i18n.language === 'en') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="cole__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'cole__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.value}
                                  </p>
                                </div>
                              );
                            }
                          }
                        })}
                    </div>
                    <div className="filtre-list">
                      <p className="filtre-title">{t('campus')}</p>
                      {formationPicklist &&
                        formationPicklist?.picklistFieldValues[
                          'campus__c'
                        ].values.map((value: any) => {
                          if (value.value !== 'A renseigner') {
                            if (i18n.language === 'fr') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="campus__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'campus__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.label}
                                  </p>
                                </div>
                              );
                            }
                            if (i18n.language === 'en') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="campus__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'campus__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.value}
                                  </p>
                                </div>
                              );
                            }
                          }
                        })}
                    </div>
                  </>
                ) : (
                  <div className="filtre-list">
                    <p className="filtre-title">{t('campus')}</p>
                    {formationPicklist &&
                      formationPicklist?.picklistFieldValues[
                        'cole__c'
                      ].values.map((value: any) => {
                        if (
                          value.value !== 'A renseigner' &&
                          value.value !== 'JUNIA'
                        ) {
                          if (i18n.language === 'fr') {
                            return (
                              <div>
                                <p>
                                  <input
                                    type="checkbox"
                                    onChange={updateFilter}
                                    name="cole__c"
                                    value={value.value}
                                    checked={checkedFilter({
                                      name: 'cole__c',
                                      value: value.value
                                    })}
                                  />
                                  {value.label}
                                </p>
                              </div>
                            );
                          }
                          if (i18n.language === 'en') {
                            return (
                              <div>
                                <p>
                                  <input
                                    type="checkbox"
                                    onChange={updateFilter}
                                    name="cole__c"
                                    value={value.value}
                                    checked={checkedFilter({
                                      name: 'cole__c',
                                      value: value.value
                                    })}
                                  />
                                  {value.value}
                                </p>
                              </div>
                            );
                          }
                        }
                      })}
                  </div>
                )}
              </>
            )}

            {(me.type_tech__c === 'International' || candidate_id) && (
              <>
                <div className="filtre-list">
                  <p className="filtre-title">{t('accessYear')}</p>
                  {formationPicklist &&
                    formationPicklist?.picklistFieldValues[
                      'Ann_e_d_acc_s__c'
                    ].values.map((value: any) => {
                      if (
                        value.value !== 'A renseigner' &&
                        value.value !== '5th'
                      ) {
                        if (i18n.language === 'fr') {
                          return (
                            <div>
                              <p>
                                <input
                                  type="checkbox"
                                  onChange={updateFilter}
                                  name="ann_e_d_acc_s__c"
                                  value={value.value}
                                  checked={checkedFilter({
                                    name: 'ann_e_d_acc_s__c',
                                    value: value.value
                                  })}
                                />
                                {value.label}
                              </p>
                            </div>
                          );
                        }
                        if (i18n.language === 'en') {
                          return (
                            <div>
                              <p>
                                <input
                                  type="checkbox"
                                  onChange={updateFilter}
                                  name="ann_e_d_acc_s__c"
                                  value={value.value}
                                  checked={checkedFilter({
                                    name: 'ann_e_d_acc_s__c',
                                    value: value.value
                                  })}
                                />
                                {value.value}
                              </p>
                            </div>
                          );
                        }
                      }
                    })}
                </div>
                <div className="filtre-list">
                  <p className="filtre-title">{t('educationLanguage')}</p>
                  {formationPicklist &&
                    formationPicklist?.picklistFieldValues[
                      'language__c'
                    ].values.map((value: any) => {
                      if (value.value !== 'A renseigner') {
                        if (i18n.language === 'fr') {
                          return (
                            <div>
                              <p>
                                <input
                                  type="checkbox"
                                  onChange={updateFilter}
                                  name="language__c"
                                  value={value.value}
                                  checked={checkedFilter({
                                    name: 'language__c',
                                    value: value.value
                                  })}
                                />
                                {value.label}
                              </p>
                            </div>
                          );
                        }
                        if (i18n.language === 'en') {
                          return (
                            <div>
                              <p>
                                <input
                                  type="checkbox"
                                  onChange={updateFilter}
                                  name="language__c"
                                  value={value.value}
                                  checked={checkedFilter({
                                    name: 'language__c',
                                    value: value.value
                                  })}
                                />
                                {value.value}
                              </p>
                            </div>
                          );
                        }
                      }
                    })}
                </div>
                <div className="filtre-list">
                  <p className="filtre-title">{t('program')}</p>
                  {formationPicklist &&
                    formationPicklist?.picklistFieldValues[
                      'cole__c'
                    ].values.map((value: any) => {
                      if (value.value !== 'A renseigner') {
                        return (
                          <div>
                            <p>
                              <input
                                type="checkbox"
                                onChange={updateFilter}
                                name="cole__c"
                                value={value.value}
                                checked={checkedFilter({
                                  name: 'cole__c',
                                  value: value.value
                                })}
                              />
                              {value.value}
                            </p>
                          </div>
                        );
                      }
                    })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="formations-container">
          <div ref={divRef} className="formations-header">
            <div className="formations-header-btn">
              <button className="btn btn-outlined" onClick={handleClick}>
                <span>{t('cancel')}</span>
              </button>
              <button
                className="filter-mobile btn btn-outlined"
                onClick={handleFilter}>
                <span>{t('filter')}</span>
              </button>
            </div>
            {(me.type_tech__c !== 'International' && !candidate_id) && (
              <>
                {me.type_tech__c !== 'Echange' ? (
                  <div className="filtres mobile">
                    {showFilters ? (
                      <>
                        <div className="filtre-list">
                          <p className="filtre-title">{t('accessYear')}</p>
                          {formationPicklist &&
                            formationPicklist?.picklistFieldValues[
                              'Ann_e_d_acc_s__c'
                            ].values.map((value: any) => {
                              const filterFR = accessYearFR.map(
                                (year) => year.value
                              );
                              if (filterFR.includes(value.value)) {
                                if (i18n.language === 'fr') {
                                  const label = accessYearFR.find(
                                    (year: { value: string; label: string }) =>
                                      year.value === value.value
                                  )?.label;
                                  return (
                                    <div>
                                      <p>
                                        <input
                                          type="checkbox"
                                          onChange={updateFilter}
                                          name="ann_e_d_acc_s__c"
                                          value={value.value}
                                          checked={checkedFilter({
                                            name: 'ann_e_d_acc_s__c',
                                            value: value.value
                                          })}
                                        />
                                        {label}
                                      </p>
                                    </div>
                                  );
                                }
                                if (i18n.language === 'en') {
                                  return (
                                    <div>
                                      <p>
                                        <input
                                          type="checkbox"
                                          onChange={updateFilter}
                                          name="ann_e_d_acc_s__c"
                                          value={value.value}
                                          checked={checkedFilter({
                                            name: 'ann_e_d_acc_s__c',
                                            value: value.value
                                          })}
                                        />
                                        {value.value}
                                      </p>
                                    </div>
                                  );
                                }
                              }
                            })}
                        </div>
                        <div className="filtre-list">
                          <p className="filtre-title">{t('formationType')}</p>
                          {formationPicklist &&
                            formationPicklist?.picklistFieldValues[
                              'Type_de_formation__c'
                            ].values.map((value: any) => {
                              const filterFR = formationTypeFR.map(
                                (type) => type.value
                              );
                              if (filterFR.includes(value.value)) {
                                if (i18n.language === 'fr') {
                                  const label = formationTypeFR.find(
                                    (year: { value: string; label: string }) =>
                                      year.value === value.value
                                  )?.label;
                                  return (
                                    <div>
                                      <p>
                                        <input
                                          type="checkbox"
                                          onChange={updateFilter}
                                          name="type_de_formation__c"
                                          value={value.value}
                                          checked={checkedFilter({
                                            name: 'type_de_formation__c',
                                            value: value.value
                                          })}
                                        />
                                        {label}
                                      </p>
                                    </div>
                                  );
                                }
                                if (i18n.language === 'en') {
                                  return (
                                    <div>
                                      <p>
                                        <input
                                          type="checkbox"
                                          onChange={updateFilter}
                                          name="type_de_formation__c"
                                          value={value.value}
                                          checked={checkedFilter({
                                            name: 'type_de_formation__c',
                                            value: value.value
                                          })}
                                        />
                                        {value.value}
                                      </p>
                                    </div>
                                  );
                                }
                              }
                            })}
                        </div>
                        <div className="filtre-list">
                          <p className="filtre-title">{t('program')}</p>
                          {formationPicklist &&
                            formationPicklist?.picklistFieldValues[
                              'cole__c'
                            ].values.map((value: any) => {
                              if (
                                value.value !== 'A renseigner' &&
                                value.value !== 'JUNIA'
                              ) {
                                if (i18n.language === 'fr') {
                                  return (
                                    <div>
                                      <p>
                                        <input
                                          type="checkbox"
                                          onChange={updateFilter}
                                          name="cole__c"
                                          value={value.value}
                                          checked={checkedFilter({
                                            name: 'cole__c',
                                            value: value.value
                                          })}
                                        />
                                        {value.label}
                                      </p>
                                    </div>
                                  );
                                }
                                if (i18n.language === 'en') {
                                  return (
                                    <div>
                                      <p>
                                        <input
                                          type="checkbox"
                                          onChange={updateFilter}
                                          name="cole__c"
                                          value={value.value}
                                          checked={checkedFilter({
                                            name: 'cole__c',
                                            value: value.value
                                          })}
                                        />
                                        {value.value}
                                      </p>
                                    </div>
                                  );
                                }
                              }
                            })}
                        </div>
                        <div className="filtre-list">
                          <p>{t('campus')}</p>
                          {formationPicklist &&
                            formationPicklist?.picklistFieldValues[
                              'campus__c'
                            ].values.map((value: any) => {
                              if (value.value !== 'A renseigner') {
                                if (i18n.language === 'fr') {
                                  return (
                                    <div>
                                      <p>
                                        <input
                                          type="checkbox"
                                          onChange={updateFilter}
                                          name="campus__c"
                                          value={value.value}
                                          checked={checkedFilter({
                                            name: 'campus__c',
                                            value: value.value
                                          })}
                                        />
                                        {value.label}
                                      </p>
                                    </div>
                                  );
                                }
                                if (i18n.language === 'en') {
                                  return (
                                    <div>
                                      <p>
                                        <input
                                          type="checkbox"
                                          onChange={updateFilter}
                                          name="campus__c"
                                          value={value.value}
                                          checked={checkedFilter({
                                            name: 'campus__c',
                                            value: value.value
                                          })}
                                        />
                                        {value.value}
                                      </p>
                                    </div>
                                  );
                                }
                              }
                            })}
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div className="filtres mobile">
                    {showFilters ? (
                      <div className="filtre-list">
                        <p>{t('campus')}</p>
                        {formationPicklist &&
                          formationPicklist?.picklistFieldValues[
                            'cole__c'
                          ].values.map((value: any) => {
                            if (
                              value.value !== 'A renseigner' &&
                              value.value !== 'JUNIA'
                            ) {
                              if (i18n.language === 'fr') {
                                return (
                                  <div>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onChange={updateFilter}
                                        name="cole__c"
                                        value={value.value}
                                        checked={checkedFilter({
                                          name: 'cole__c',
                                          value: value.value
                                        })}
                                      />
                                      {value.label}
                                    </p>
                                  </div>
                                );
                              }
                              if (i18n.language === 'en') {
                                return (
                                  <div>
                                    <p>
                                      <input
                                        type="checkbox"
                                        onChange={updateFilter}
                                        name="cole__c"
                                        value={value.value}
                                        checked={checkedFilter({
                                          name: 'cole__c',
                                          value: value.value
                                        })}
                                      />
                                      {value.value}
                                    </p>
                                  </div>
                                );
                              }
                            }
                          })}
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            )}
            {(me.type_tech__c === 'International' || candidate_id)  && (
              <div className="filtres mobile">
                {showFilters ? (
                  <>
                    <div className="filtre-list">
                      <p className="filtre-title">{t('accessYear')}</p>
                      {formationPicklist &&
                        formationPicklist?.picklistFieldValues[
                          'Ann_e_d_acc_s__c'
                        ].values.map((value: any) => {
                          if (
                            value.value !== 'A renseigner' &&
                            value.value !== '5th'
                          ) {
                            if (i18n.language === 'fr') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="ann_e_d_acc_s__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'ann_e_d_acc_s__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.label}
                                  </p>
                                </div>
                              );
                            }
                            if (i18n.language === 'en') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="ann_e_d_acc_s__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'ann_e_d_acc_s__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.value}
                                  </p>
                                </div>
                              );
                            }
                          }
                        })}
                    </div>
                    <div className="filtre-list">
                      <p className="filtre-title">{t('educationLanguage')}</p>
                      {formationPicklist &&
                        formationPicklist?.picklistFieldValues[
                          'language__c'
                        ].values.map((value: any) => {
                          if (value.value !== 'A renseigner') {
                            if (i18n.language === 'fr') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="language__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'language__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.label}
                                  </p>
                                </div>
                              );
                            }
                            if (i18n.language === 'en') {
                              return (
                                <div>
                                  <p>
                                    <input
                                      type="checkbox"
                                      onChange={updateFilter}
                                      name="language__c"
                                      value={value.value}
                                      checked={checkedFilter({
                                        name: 'language__c',
                                        value: value.value
                                      })}
                                    />
                                    {value.value}
                                  </p>
                                </div>
                              );
                            }
                          }
                        })}
                    </div>
                    <div className="filtre-list">
                      <p className="filtre-title">{t('program')}</p>
                      {formationPicklist &&
                        formationPicklist?.picklistFieldValues[
                          'cole__c'
                        ].values.map((value: any) => {
                          if (value.value !== 'A renseigner') {
                            return (
                              <div>
                                <p>
                                  <input
                                    type="checkbox"
                                    onChange={updateFilter}
                                    name="cole__c"
                                    value={value.value}
                                    checked={checkedFilter({
                                      name: 'cole__c',
                                      value: value.value
                                    })}
                                  />
                                  {value.value}
                                </p>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </>
                ) : null}
              </div>
            )}
            <div className="formations-header-title">
              <h3>{t('catalogue')}</h3>
              {formationSelected.sfid ? (
                <button className="btn btn-main" onClick={handleNext}>
                  <span>{t('start')}</span>
                </button>
              ) : (
                <button className="btn btn-disabled" disabled>
                  <span>{t('start')}</span>
                </button>
              )}
            </div>
            <p>{t('selectFormation')}</p>
            {me.type_tech__c === 'International' && (
              <div>
                <p>{t('workStudyNotOpenInt')}</p>
                {filter['ann_e_d_acc_s__c'].map((year: string) => {
                  if (year === '3rd' && i18n.language === 'fr') {
                    return <p>{t('thirdYearInfoTextInt')}</p>;
                  } else if (
                    year === '4th' &&
                    filter['language__c'].includes('French') &&
                    i18n.language === 'fr'
                  ) {
                    return <p>{t('fourthYearInfoTextInt')}</p>;
                  }
                })}
                {filter['language__c'].includes('English') &&
                  filter['ann_e_d_acc_s__c'].includes('1st') && (
                    <p>{t('firstYearLanguageInfoTextInt')}</p>
                  )}
              </div>
            )}
          </div>
          <div
            className="formations-list-container"
            style={{ height: 'calc(98% - ' + height + 'px)' }}>
            <div className="formations-list">
              {formations.length > 0 ? (
                _.map(formations, (formation) => {
                  let name;
                  if (i18n.language === 'fr') {
                    name = formation.nom_de_la_formation__c;
                  }

                  if (i18n.language === 'en') {
                    name = formation.nom_formation_anglais__c;
                  }
                  return (
                    <button
                      className={`formation ${
                        formation.sfid === formationSelected.sfid
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => selectFormation(formation)}>
                      <div className="formation-ecole-date">
                        <h6>
                          {formation.campus__c} - {formation.cole__c}
                        </h6>
                        <div className="date">
                          <h6>
                            {t('until')}{' '}
                            {moment(candidate_id ? formation.date_de_fin_agent__c : formation.date_de_fin__c).format(
                              'DD/MM/YYYY'
                            )}
                          </h6>
                        </div>
                      </div>
                      <h5>{name}</h5>
                    </button>
                  );
                })
              ) : (
                <div>
                  <p>{t('noFormations')}</p>
                  {contact.type_tech__c === 'Francais' && <p className='formation-closed'>{t('formationsClosed')} <span>{t('formationsClosedDate')}</span></p>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['home', 'common'])(Catalogue));
