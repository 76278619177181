import React, { ChangeEvent, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { Contact } from '../../services/api/Contact/interface';
import ContactService from '../../services/api/Contact/ContactService';

import FaqService from '../../services/api/Faq/FaqService';

import './Faq.scss';
import { FAQ } from '../../services/api/Faq/interface';
import _ from 'lodash';
import AuthService from '../../services/api/Auth/AuthService';

interface ContainerProps {}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Faq: React.FC<IProps> = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();

  const [contact, setContact] = useState<Contact>({});
  const [me, setMe] = useState<any>({});
  const [faq, setFaq] = useState<FAQ[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    getFaq();
    getMe();
  }, []);

  useEffect(() => {
    if (me.id_heroku__c) {
      getContact();
    }
  }, [me]);

  const getFaq = async (search?: string) => {
    const faq = await FaqService.getFaq(search);
    const meRequest = await AuthService.me();
    let faqJury: FAQ[] = [];
    let faqCandidatInter: FAQ[] = [];
    let faqCandidatClass: FAQ[] = [];
    faq.data.faqs?.map((res: any) =>{
      if(res.type__c.includes('Jury')){
        faqJury.push(res);
      }
      if(res.type__c.includes('International')){
        faqCandidatInter.push(res);
      }
      if(res.type__c.includes('Classique')){
        faqCandidatClass.push(res);
      }
    });
    if(meRequest.data.type === 'Jury'){
      faqJury.forEach((element: any) => (element.isActive = false));
      setFaq(faqJury);
    }else if(meRequest.data.type_tech__c === 'International' || meRequest.data.type_tech__c === 'Echange'){
      faqCandidatInter.forEach((element: any) => (element.isActive = false));
      setFaq(faqCandidatInter);
    }else if(meRequest.data.type_tech__c === 'Francais'){
      faqCandidatClass.forEach((element: any) => (element.isActive = false));
      setFaq(faqCandidatClass);
    }else{
      return null;
    }
  };
  const getContact = async () => {
    try {
      let contactResponse = await ContactService.getContact(me.id_heroku__c);
      setContact(contactResponse.data);
      // updateNextStep(contactResponse.data);
    } catch (error) {
      console.error(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };
  const getMe = async () => {
    try {
      const meRequest = await AuthService.me();
      setMe(meRequest.data);
    } catch (error) {
      console.error(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const changeActiveState = (id: any) => {
    const updatedFaq = faq.map((element) => {
      if (element.id === id) {
        element.isActive = !element.isActive;
      }
      return element;
    });
    setFaq(updatedFaq);
  };
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const history = useHistory();
  const changeRoute = (path: string) => {
    history.push(path);
  };

  const triggerSearch = () => {
    getFaq(search);
  };

  return (
    <div className="faqs-container">
      <div className="faqs">
        <div className="faq__header">
          <h3>{t('faqTitle')}</h3>
          <h5>{t('subheader')}</h5>
        </div>
        <div className="faq-search">
          <input
            placeholder={t('searchBar')}
            value={search}
            onChange={handleChangeSearch}></input>{' '}
          <button onClick={triggerSearch}>
            <SearchIcon fontSize="medium"></SearchIcon>
          </button>
        </div>
        <div className="faq__content">
          {Array.isArray(faq) && faq.length
            ? _.chain(faq)
                .sortBy('ordre_d_affichage__c')
                .map((data: any) => {
                  let label = '';
                  let description = '';
                  if (i18n.language === 'fr') {
                    label = data.label__c;
                    description = data.description__c;
                  }

                  if (i18n.language === 'en') {
                    label = data.label_en__c;
                    description = data.description_en__c;
                  }
                  return (
                    <div className="accordion-item" key={data.id}>
                      <div
                        className="accordion-title"
                        onClick={() => changeActiveState(data.id)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => changeActiveState(data.id)}>
                        <div>
                          <h6 className="question-title">
                            {data.isActive ? '-' : '+'}&nbsp;&nbsp;&nbsp;
                            {label}
                          </h6>
                        </div>
                      </div>
                      {data.isActive && (
                        <div className="accordion-content">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: description
                            }}></p>
                        </div>
                      )}
                    </div>
                  );
                })
                .value()
            : null}
        </div>
      </div>
      {contact.recordtypeid === '0125I000000kxgGQAQ' && (
        <div className="faqs-not-found">
          <h4>{t('notFound')}</h4>
          <button
            className="btn btn-outlined"
            onClick={() => changeRoute('contact')}>
            <span>{t('contactTeam')}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default withRouter(withTranslation(['faq', 'common'])(Faq));
