import React, { createElement, useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

// Components
import PersonnalInfos from '../../../CreateAccount/components/PersonnalInfos/PersonnalInfos';
import Cursus from '../../../CreateAccount/components/Cursus/Cursus';
import Languages from '../../../CreateAccount/components/Languages/Languages';
import Experiences from '../../../CreateAccount/components/Experiences/Experiences';
import Trips from '../../../CreateAccount/components/Trips/Trips';

// Services
import AuthService from '../../../../services/api/Auth/AuthService';
import ContactService from '../../../../services/api/Contact/ContactService';
import EducationHistoryService from '../../../../services/api/EducationHistory/EducationHistoryService';
import CompetenceLinguistiqueService from '../../../../services/api/CompetenceLinguistique/CompetenceLinguistiqueService';
import ExperienceService from '../../../../services/api/Experience/ExperienceService';
import TripService from '../../../../services/api/Trip/TripService';

// Interfaces
import { Contact } from '../../../../services/api/Contact/interface';
import { EducationHistory } from '../../../../services/api/EducationHistory/interface';
import { Trip } from '../../../../services/api/Trip/interface';
import { Experience } from '../../../../services/api/Experience/interface';
import { CompetenceLinguistique } from '../../../../services/api/CompetenceLinguistique/interface';

// Material UI & Utils
import { List, ListItem, ListItemText } from '@mui/material';
import Spinner from '../../../../utils/Spinner/Spinner';
import Alert from '../../../../utils/Alert/Alert';

// Styles
import './CandidateProfile.scss';

interface ContainerProps {}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const CandidateProfile: React.FC<IProps> = (props) => {
  const { t } = props;
  const [selected, setSelected] = useState<any>({});
  const [contact, setContact] = useState<Contact>({});
  const [form, setForm] = useState<Contact>({});
  const [educationHistorys, setEductionHistorys] = useState<EducationHistory[]>(
    []
  );
  const [competencesLing, setCompetencesLing] = useState<
    CompetenceLinguistique[]
  >([]);
  const [fileList, setFileList] = useState<any[]>([]);

  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [canNext, setCanNext] = useState<boolean>(false);
  const [me, setMe] = useState<any>({});
  const { candidate_id } = useParams<{ candidate_id: string }>();
  const [height, setHeight] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);

  const profileSectionsList = [
    {
      name: 'personnalInfos',
      component: PersonnalInfos
    },
    {
      name: 'cursus',
      component: Cursus
    },
    {
      name: 'languages',
      component: Languages
    },
    {
      name: 'experiences',
      component: Experiences
    },
    {
      name: 'trips',
      component: Trips
    }
  ];

  useEffect(() => {
    getMe();
    let item = profileSectionsList[0];
    setSelected(item);
  }, []);
  useEffect(() => {
    if (me.id_heroku__c) {
      getContact();
    }
  }, [me]);
  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);

  const getContact = async () => {
    try {
      let contactResponse = await ContactService.getContact(candidate_id);
      setContact(contactResponse.data);
    } catch (error) {
      console.log(
        '🚀 ~ file: CandidateProfile.tsx ~ getContact ~ error',
        error
      );
    }
  };
  const getMe = async () => {
    try {
      const meRequest = await AuthService.me();
      setMe(meRequest.data);
    } catch (error) {
      console.log('🚀 ~ file: CandidateProfile.tsx ~ getMe ~ error', error);
    }
  };

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element && element.clientHeight && element.clientHeight > 0) {
      setHeight(element.clientHeight + 48 + 70); //height + padding div + menu size
    }
  };

  const changeTab = (item: any) => {
    setSelected(item);
    setForm(contact);
  };

  const pushInFileList = (data: any) => {
    let files = [...fileList];
    if (data.type) {
      let found = files.findIndex((o) => o.type === data.type);
      if (found >= 0) {
        files.splice(found, 1, data);
      } else {
        files.push(data);
      }
      setFileList(files);
    }
  };

  const onChange = (data: any, next: boolean) => {
    switch (selected.name) {
      case 'cursus':
        if (data.cursus) {
          setEductionHistorys(data.cursus);
        }
        if (data.data) {
          setForm({
            ...form,
            ...data.data
          });
        }
        break;

      case 'languages':
        if (data.languages) {
          setCompetencesLing(data.languages);
        }
        break;
      case 'experiences':
        setExperiences(data.experiences);
        setFileList(data.files);
        break;
      case 'trips':
        setTrips(data);
        break;
      case 'personnalInfos':
        if (data.files) {
          pushInFileList(data.files);
        }
        setForm({
          ...form,
          ...data.data
        });
        break;
      case 'Non commencée':
        setForm({
          ...form,
          ...data
        });
        break;
      case 'etape 2':
        setForm({
          ...form,
          ...data
        });
        break;
    }
    setCanNext(next);
  };

  const sendEducationHistorys = async (data: EducationHistory[]) => {
    let list = data.map(async (educationHistory) => {
      if (contact && contact.id_heroku__c) {
        if (educationHistory.id_heroku__c) {
          await EducationHistoryService.patchContactEducationHistory(
            contact?.id_heroku__c,
            educationHistory,
            educationHistory.fileList,
            ['degree', 'grades', 'bac_transcript']
          );
        } else {
          await EducationHistoryService.createContactEducationHistory(
            contact.id_heroku__c,
            educationHistory,
            educationHistory.fileList,
            ['degree', 'grades', 'bac_transcript']
          );
        }
        return educationHistory;
      }
    });
    Promise.all(list).then((res) => {
      setEductionHistorys([]);
    });
  };

  const sendCompetencesLinguistiques = async (
    data: CompetenceLinguistique[]
  ) => {
    let list = data.map(async (competenceLinguistique) => {
      if (contact && contact.id_heroku__c) {
        if (competenceLinguistique.id_heroku__c) {
          await CompetenceLinguistiqueService.patchCompetencesLinguistique(
            contact.id_heroku__c,
            competenceLinguistique,
            competenceLinguistique.fileList,
            ['language_degree']
          );
        } else {
          await CompetenceLinguistiqueService.createCompetencesLinguistique(
            contact.id_heroku__c,
            competenceLinguistique,
            competenceLinguistique.fileList,
            ['language_degree']
          );
        }
        return competenceLinguistique;
      }
    });
    Promise.all(list).then((res) => {
      setCompetencesLing([]);
    });
  };

  const sendExperiences = async (data: Experience[]) => {
    try {
      let list = data.map(async (experience) => {
        if (contact && contact.id_heroku__c) {
          if (experience.id_heroku__c) {
            await ExperienceService.patchExperience(
              contact.id_heroku__c,
              experience
            );
          } else {
            await ExperienceService.createExperience(
              contact.id_heroku__c,
              experience
            );
          }
          return experience;
        }
      });
      Promise.all(list).then((res) => {
        setExperiences([]);
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: CandidateProfile ~ sendExperiences ~ error',
        error
      );
    }
  };

  const sendTrips = async (data: Trip[]) => {
    try {
      let list = data.map(async (trip) => {
        if (contact && contact.id_heroku__c) {
          if (trip.id_heroku__c) {
            await TripService.patchTrip(contact.id_heroku__c, trip);
          } else {
            await TripService.createTrip(contact.id_heroku__c, trip);
          }
          return trip;
        }
      });
      Promise.all(list).then((res) => {
        setTrips([]);
      });
    } catch (error) {
      console.log('🚀 ~ file: CandidateProfile ~ sendTrips ~ error', error);
    }
  };

  const save = () => {
    try {
      Spinner.setSpinner(true);
      if (contact?.id_heroku__c) {
        switch (selected.name) {
          case 'etape 2':
            break;
          case 'cursus':
            if (educationHistorys.length > 0) {
              sendEducationHistorys([...educationHistorys]);
            }
            break;
          case 'languages':
            if (competencesLing.length > 0) {
              sendCompetencesLinguistiques([...competencesLing]);
            }
            break;
          case 'experiences':
            sendExperiences([...experiences]);
            break;
          case 'trips':
            sendTrips([...trips]);
            break;
          case 'personnalInfos':
            break;
        }
        let files = [...fileList];
        setFileList([]);

        let newForm = { ...form, etablissement_partenaire__c: undefined };
        ContactService.patchContact(contact?.id_heroku__c, newForm, files, [
          'identity_card',
          'scholarship',
          'resume'
        ])
          .then(async (res) => {
            await getContact();
            Spinner.setSpinner(false);
            Alert.setAlert({
              message: t('success.update'),
              open: true,
              duration: 3000,
              type: 'success'
            });
          })
          .catch((err) => {
            Spinner.setSpinner(false);
            Alert.setAlert({
              message: t('error.update'),
              open: true,
              duration: 3000,
              type: 'error'
            });
          });
      }
    } catch (error) {
      console.log('🚀 ~ file: CandidateProfile ~ save ~ error', error);
    }
  };

  return (
    <div className="profil general-container">
      <div className="profil-switch-container" ref={divRef}>
        <h5>{t('word.profilePage')}</h5>
        <List className="profile-menu-desktop">
          {profileSectionsList.map((item) => (
            <ListItem
              key={item.name}
              onClick={() => changeTab(item)}
              className={selected.name === item.name ? 'active' : ''}>
              <ListItemText
                className="tab-item"
                primary={t(`word.${item.name}`)}
              />
            </ListItem>
          ))}
        </List>
        <div className="profile-menu-mobile">
          <select
            className="custom-select"
            onChange={(e) =>
              profileSectionsList.map((item) =>
                e.target.value === item.name ? changeTab(item) : null
              )
            }>
            {profileSectionsList.map((item) => (
              <option key={item.name} value={item.name}>
                {t(`word.${item.name}`)}
              </option>
            ))}
          </select>
        </div>
        <div className="save-container">
          <span>
            <span style={{ color: 'red', fontSize: '1rem' }}>*</span>{' '}
            {t('word.required')}
          </span>
          {canNext ? (
            <button className="btn btn-outlined " onClick={save}>
              {' '}
              {t('save')}{' '}
            </button>
          ) : (
            <button className="btn btn-outlined btn-disabled">
              {' '}
              {t('save')}
            </button>
          )}
        </div>
      </div>
      <div
        className="apply-list-container"
        style={{ height: 'calc(98% - ' + height + 'px)' }}>
        <div className="apply-list">
          {selected.component && contact.id_heroku__c && (
            <React.Fragment>
              {Array.isArray(selected.component) ? (
                <React.Fragment>
                  {selected.component.map((compo: any) => {
                    return createElement(compo, {
                      contact: contact,
                      onChange: onChange
                    });
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {createElement(selected.component, {
                    contact: contact,
                    onChange: onChange,
                    getContact: getContact
                  })}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['contact', 'common', 'form'])(CandidateProfile)
);
