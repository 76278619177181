import React, { createElement, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, withRouter, RouteComponentProps, useHistory  } from 'react-router-dom';
import i18n from '../../i18n';

import junialogo from '../../assets/images/junialogoviolet.png';
import flagUK from '../../assets/images/flagUK.png';
import flagFR from '../../assets/images/flagFR.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './ForgotPassword.scss';
import AuthService from '../../services/api/Auth/AuthService';
import Alert from '../../utils/Alert/Alert';
import Spinner from '../../utils/Spinner/Spinner';

interface ContainerProps {}

type ChildProps = {};

type AuthForm = {
  email: string;
};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const formLoginInitialState = {
  email: '',
  password: ''
};

const formRegisterFields = {
  confirmPassword: ''
};

const ForgotPassword: React.FC<IProps> = (props) => {
  const { t } = props;
  const [active, setActive] = useState(localStorage.getItem('i18nextLng'));
  const history = useHistory();

  const [form, setForm] = useState<AuthForm>(formLoginInitialState);
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [passwordHidden, setPasswordHidden] = useState<boolean>(true);

  useEffect(() => {
    if (isLoginForm) {
      setForm((prev) => {
        return {
          ...prev,
          ...formRegisterFields
        };
      });
    } else {
      let newForm = { ...form };
      setForm(newForm);
    }
  }, [isLoginForm]);

  const handleChange = (e: any): void => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isLoginForm) {
      Spinner.setSpinner(true);
      AuthService.forgotPassword(form)
        .then((res) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            duration: 3000,
            message: t(res.data.message),
            open: true,
            type: 'success'
          });
          history.push('/');
        })
        .catch((err) => {
          Spinner.setSpinner(false);
          Alert.setAlert({
            duration: 3000,
            message: t(
              err.response.data.message
                ? err.response.data.message
                : err.response.data
            ),
            open: true,
            type: 'error'
          });
        });
    }
  };

  return (
    <div className="authenticate">
      <div className="authenticate-container">
        <div className="authenticate__header">
          <img src={junialogo} alt="junia logo" />
          <h4>{t('page_title')}</h4>
          <div className="authenticate__header__language-cont">
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('fr');
                setActive('fr');
              }}
              className={active === 'fr' ? 'fr' : ''}>
              <img src={flagFR} alt="drapeau français" />
            </button>
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('en');
                setActive('en');
              }}
              className={active === 'en' ? 'en' : ''}>
              <img src={flagUK} alt="drapeau anglais" />
            </button>
          </div>
        </div>
        <form className="authenticate__form">
          <div className="input-container">
            <label htmlFor="email">{t('word.email')}:</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={form.email}
            />
          </div>

          <button
            className="btn btn-main btn-fullwidth"
            type="submit"
            onClick={handleSubmit}>
            <span>{t('new_password')}</span>
          </button>
          <Link className="authenticate__form__link" to="/">
            {t('connect')}
          </Link>
        </form>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['login', 'common'])(ForgotPassword));
