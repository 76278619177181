/* 
  This service is used to fetch picklists from the server 
*/

import axios, { AxiosResponse } from 'axios';
import API from '..';
import { Picklist } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
/**
 * This class contains all the methods that can be used to fetch picklists from the server api
 */
class PicklistsService {
  /**
   * Method to fetch hed_education_history picklists
   * @returns a list of all the picklists on the hed_education_history__c object
   */
  getHedEducationHistoryPicklists(): Promise<AxiosResponse> {
    return axios.get(`${url}/picklists/hed_education_history`);
  }
}
export default new PicklistsService();
