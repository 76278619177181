/**
 * This is the main component (top level) of the app
 * Routes are defined here
 * Logged in / Logged out logic is define here and displays different sets of routes based on logged status
 */
import './App.scss';
// import palette from './App.scss';
import React, {
  Component,
  ComponentProps,
  useEffect,
  useState,
  FC,
  Suspense
} from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
  withRouter,
  Switch
} from 'react-router-dom';
import AuthService from './services/api/Auth/AuthService';
import JwtInterceptor from './interceptors/jwt.interceptor';
/* import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ValidateAccount from './pages/ValidateAccount/ValidateAccount'; */
import Home from './pages/Home/Home';
import Authentication from './pages/Authentication/Authentication';
import Faq from './pages/Faq/Faq';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import Layout from './components/Layout/Layout';
import config from './config.json';

const { NODE_ENV, REACT_APP_BACK_URL } = process.env;
// import 'moment/locale/fr';
// import { ThemeProvider } from '@nivo/core';

import dotenv from 'dotenv';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Contact from './pages/Contact/Contact';
import Profil from './pages/Profil/Profil';
import Application from './pages/Application/Application';
import SnackbarAlert from './components/SnackbarAlert/SnackbarAlert';
import SpinnerComponent from './components/SpinnerComponent/SpinnerComponent';
import JuryList from './pages/JuryList/JuryList';
import _ from 'lodash';
import AgentApplications from './pages/Agent/Applications';
import AgentCandidats from './pages/Agent/components/Candidats/Candidats';
import AgentCreateCandidate from './pages/Agent/components/CreateCandidate/CreateCandidate';
import AgentCreateApplication from './pages/Agent/components/CreateApplication/CreateApplication';
import CandidatureProcess from './pages/CandidatureProcess/CandidatureProcess';
import AgentCandidateProfile from './pages/Agent/components/CandidateProfile/CandidateProfile';
import InscriptionConfirmed from './pages/InscriptionConfirmed/End/InscriptionConfirmed';
import ChooseDate from './pages/Application/components/ChooseDate/ChooseDate';
import { me } from './services/api/Contact/interface';
import OpenFolder from './pages/JuryList/components/OpenFolder/OpenFolder';
import Grades from './pages/JuryList/components/Grades/Grades';
import LayoutAgent from './components/LayoutAgent/LayoutAgent';
import LayoutJury from './components/LayoutJury/LayoutJury';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import CreatePassword from './pages/CreatePassword/CreatePassword';
import ProfileCreated from './pages/Agent/components/ProfileCreated/ProfileCreated';
import IncompleteFolder from './pages/IncompleteFolder/IncompleteFolder';
import CGU from './pages/CGU/CGU';
import Mentions from './pages/Mentions/Mentions';
import PersonalDataPolicy from './pages/PersonalDataPolicy/PersonalDataPolicy';
import JuryFile from './pages/JuryList/components/Files/Files';
import JuryContentDocument from './pages/JuryList/components/ContentDocument/ContentDocument';
import ContactService from './services/api/Contact/ContactService';
import { buildQueries } from '@testing-library/react';
dotenv.config();

interface IProps extends RouteComponentProps {}
const routeConfig: any = config;

const routes = [
  <Route
    exact
    key={'create-account'}
    path="/create-account"
    component={CreateAccount}
  />,
  <Route exact key={'home'} path="/home" component={Home} />,
  <Route
    exact
    key={'candidature-process'}
    path="/candidature-process/:formation"
    component={CandidatureProcess}
  />,
  <Route
    exact
    key={'incomplete-folder'}
    path="/incomplete-folder/:application"
    component={IncompleteFolder}
  />,
  <Route
    exact
    key={'inscriptionConfirmed'}
    path="/inscriptionConfirmed"
    component={InscriptionConfirmed}
  />,
  <Route exact key={'faq'} path="/faq" component={Faq} />,
  <Route exact key={'contact'} path="/contact" component={Contact} />,
  <Route exact key={'profil'} path="/profil" component={Profil} />,
  <Route
    exact
    key={'application'}
    path="/application"
    component={Application}
  />,
  <Route
    exact
    key={'choose-date'}
    path="/choose-date/:formation"
    component={ChooseDate}
  />,

  <Route exact key={'jury'} path="/jury" component={JuryList} />,
  <Route
    exact
    key={'jury-folder'}
    path="/jury/folder/:id"
    component={OpenFolder}
  />,
  <Route exact key={'jury-grade'} path="/jury/grade/:id" component={Grades} />,
  <Route exact key={'jury-files'} path="/jury/file/:file_id/:file_name" component={JuryFile} />,
  <Route exact key={'jury-content_document'} path="/jury/content_document/:file_id/:file_title/:file_extension" component={JuryContentDocument} />,

  <Route
    exact
    key={'agent/applications'}
    path="/agent/applications"
    component={AgentApplications}
  />,
  <Route
    exact
    key={'agent/candidats'}
    path="/agent/candidats"
    component={AgentCandidats}
  />,
  <Route
    exact
    key={'agent/choice'}
    path="/agent/:candidate_id/choice"
    component={ProfileCreated}
  />,
  <Route
    exact
    key={'agent/create-candidate'}
    path="/agent/create-candidate"
    component={AgentCreateCandidate}
  />,
  <Route
    exact
    key={'agent/create-application'}
    path="/agent/:candidate_id/create-application"
    component={AgentCreateApplication}
  />,
  <Route
    exact
    key={'agent/candidature-process'}
    path="/agent/:candidate_id/candidature-process/:formation"
    component={CandidatureProcess}
  />,
  <Route
  exact
  key={'agent/incomplete-folder'}
  path="/agent/:candidate_id/incomplete-folder/:application"
  component={IncompleteFolder}
/>,
  <Route
  exact
  key={'agent/candidate-profile'}
  path="/agent/:candidate_id/candidate-profile"
  component={AgentCandidateProfile}
/>,
  <Route exact key={'authentification'} path="/" component={Authentication} />,
  <Route
    exact
    key={'reset-password'}
    path="/reset-password"
    component={ResetPassword}
  />,

  <Route
    exact
    key={'create-password'}
    path="/create-password"
    component={CreatePassword}
  />,
  <Route exact key={'cgu'} path="/terms" component={CGU} />,
  <Route exact key={'legals'} path="/legals" component={Mentions} />
];
const App: React.FC<IProps> = () => {
  const [isLogged, setIsLogged] = useState(
    AuthService.isLoginSubject$.getValue()
  );
  const [currentLocation, setCurrentLocation] = useState<string>('');

  const [me, setMe] = useState<me>();
  useEffect(() => {
    AuthService.isLoginSubject$.pipe().subscribe(async (value: any) => {
      setIsLogged(value);
      if (value) {
        let meData = await AuthService.me();
        setMe(meData?.data);
      }
    });
  }, []);

  useEffect(() => {
    if (currentLocation === '/inscriptionConfirmed') {
      location.reload();
    }
    setCurrentLocation(location.pathname);
  }, [location.pathname]);

  JwtInterceptor.intercept();
  useEffect(() => {
    AuthService.isLoginSubject$.getValue();
    if (!localStorage.getItem('i18nextLng')) {
      localStorage.setItem('i18nextLng', 'fr');
    }
  }, []);

  return (
    <div>
      <Suspense
        fallback={<SpinnerComponent backdrop={true}></SpinnerComponent>}>
        <SnackbarAlert></SnackbarAlert>
        <SpinnerComponent backdrop></SpinnerComponent>
        <div>
          {!isLogged && (
            <Suspense
            fallback={<SpinnerComponent backdrop={true}></SpinnerComponent>}>
              <Route path="/">
                <UnprotectedRoute></UnprotectedRoute>
              </Route>
            </Suspense>
          )}
          {isLogged && me && (
            <Suspense
            fallback={<SpinnerComponent backdrop={true}></SpinnerComponent>}>
              <Route path="/">
                <ProtectedRoute me={me}></ProtectedRoute>
              </Route>
            </Suspense>
          )}
        </div>
      </Suspense>
    </div>
  );
};

export default withRouter(App);

const getRoutes = (routesArray: any[]) => {
  return _.map(routesArray, (route) => {
    let loadRoute = _.find(routes, (routeGlobal) => {
      return routeGlobal.key === route;
    });
    return loadRoute;
  });
};

const ProtectedRoute = ({ ...args }) => {
  let { me } = args;
  let studentRoutes = routeConfig.protected.student;
  let agentRoutes = routeConfig.protected.agent;
  let juryRoutes = routeConfig.protected.jury;
  let loadedRoutes: any;
  let LoadLayout;

  switch (me.type) {
    case 'Candidat':
      if (!['Terminée', 'Validée'].includes(me.inscription_tech__c)) {
        loadedRoutes = studentRoutes.inscription;
      } else {
        LoadLayout = Layout;
        ('');

        loadedRoutes = studentRoutes.use;
      }
      var build = () => {
        loadedRoutes.routes = _.chain(loadedRoutes.routes)
          .map((r) => {
            if (r !== 'inscriptionConfirmed') {
              return r;
            }
          })
          .filter((r) => r !== undefined)
          .value();
      };
      if (me.inscription_tech__c.includes(['Terminée'])) {
        // await ContactService.hasMissingPieces(me.id_heroku__c).then((res) => {
        // if (res.data) {
        if (me.missingFiles) {
          loadedRoutes.redirect = 'inscriptionConfirmed';
        } else {
          ContactService.patchContact(me.id_heroku__c, {
            inscription_tech__c: 'Validée'
          });
          build();
        }
      } else {
        build();
      }
      break;
    case 'Agent':
      LoadLayout = LayoutAgent;

      loadedRoutes = agentRoutes.use;
      loadedRoutes.routes = _.chain(loadedRoutes.routes)
        .map((r) => {
          if (r !== 'inscriptionConfirmed') {
            return r;
          }
        })
        .filter((r) => r !== undefined)
        .value();
      break;
    case 'Jury':
      LoadLayout = LayoutJury;

      loadedRoutes = juryRoutes.use;
      loadedRoutes.routes = _.chain(loadedRoutes.routes)
        .map((r) => {
          if (r !== 'inscriptionConfirmed') {
            return r;
          }
        })
        .filter((r) => r !== undefined)
        .value();
      break;

    default:
      break;
  }
  return (
    <>
      {LoadLayout ? (
        <LoadLayout>
          <Switch>
            {getRoutes(loadedRoutes.routes)}
            <Redirect to={`/${loadedRoutes.redirect}`} />
          </Switch>
        </LoadLayout>
      ) : (
        <>
          <Switch>
            {getRoutes(loadedRoutes.routes)}
            <Redirect to={`/${loadedRoutes.redirect}`} />
          </Switch>
        </>
      )}
    </>
  );
};

const UnprotectedRoute = ({ ...rest }) => (
  <Switch>
    <Route exact path="/" component={Authentication} />
    <Route
      exact
      path="/reset-password/:email/:token"
      component={ResetPassword}
    />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route
      exact
      path="/create-password/:email/:token"
      component={CreatePassword}
    />
    <Route exact key={'personal-data-policy'} path="/personal-data-policy" component={PersonalDataPolicy} />
    <Redirect to="/" />
  </Switch>
);
