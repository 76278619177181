import { he } from 'date-fns/locale';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import LeftArrow from '../../../../assets/images/junia-icon-fleche-3.png';
import { Application_hed } from '../../../../services/api/Candidature/interface';
import { me } from '../../../../services/api/Contact/interface';
import { FileToUpload } from '../../../../services/api/Files/interface';
import FormationPicklist from '../../../../assets/data/formation.json';

import './Recapitulatif.scss';

interface ContainerProps {
  previous: () => void;
  next: () => void;
  save: () => void;
  me?: me;
  applicationData: Application_hed;
  getApplication?: () => void;
  onStep: (
    application: Application_hed,
    step: number,
    files?: FileToUpload[]
  ) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Recapitulatif: React.FC<IProps> = (props) => {
  const {
    t,
    previous,
    next,
    save,
    onStep,
    applicationData,
    getApplication,
    me
  } = props;
  const [height, setHeight] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element && element.clientHeight && element.clientHeight > 0) {
      setHeight(element.clientHeight + 48 + 70); //height + padding div + menu size
    }
  };

  return (
    <div className="apply-container general-container">
      <div className="apply-elements">
        <div ref={divRef} className="apply-header">
          <button
            className="btn btn-return"
            onClick={() => onStep(applicationData, -1)}>
            <img alt="left arrow" src={LeftArrow} />
            <span>{t('return')}</span>
          </button>
          <div className="app-header-title">
            <div className="left-element">
              <h3>{t('applyTitle')}</h3>
            </div>
            <div>
              <button
                className="btn btn-main"
                onClick={() => onStep(applicationData, +1)}>
                <span>{t('finalise')}</span>
              </button>
            </div>
          </div>
          <h4>{t('recap')}</h4>
          <br></br>
          <div
            className="apply-list-container"
            style={{ height: 'calc(98% - ' + height + 'px)' }}>
            <div className="apply-list">
              {me?.type_tech__c !== 'Echange' ? (
                <>
                  <h5>{t('step.first')}</h5>
                  <h6>{t('name')}</h6>
                  {i18n.language === 'fr' && applicationData.Formation && (
                    <p>{applicationData.Formation.nom_de_la_formation__c}</p>
                  )}
                  {i18n.language === 'en' && applicationData.Formation && (
                    <p>{applicationData.Formation.nom_formation_anglais__c}</p>
                  )}
                </>
              ) : (
                <>
                  <h5>{t('step.exchange')}</h5>
                  <h6>{t('speciality')}</h6>
                  {applicationData.Formation && (
                    <p>{applicationData.specialite_principale_junia__c}</p>
                  )}
                  {/* {i18n.language === 'en' && applicationData.Formation && (
                    <p>{applicationData.Formation.nom_formation_anglais__c}</p>
                  )} */}
                </>
              )}
              <h6>{t('campus')}</h6>
              {applicationData.Formation && me?.type_tech__c === 'Echange' ? (
                <p>{applicationData.programme__c}</p>
              ) : (
                <p>{applicationData.Formation?.cole__c}</p>
              )}
              {/* {applicationData.Formation?.sp_cilait_s__c && (
                <>
                  <h6>{t('speciality')}</h6>
                  {applicationData.Formation && (
                    <p>{applicationData.Formation.sp_cilait_s__c}</p>
                  )}
                  {i18n.language === 'en' && applicationData.Formation && (
              <p>{applicationData.Formation.nom_formation_anglais__c}</p>
            )}
                </>
              )} */}
              {me?.type_tech__c !== 'Echange' && (
                <>
                  <>
                    <h6>{t('city')}</h6>
                    {applicationData.Formation && (
                      <p>{applicationData.Formation.campus__c}</p>
                    )}
                  </>
                  {applicationData.Formation?.ann_e_d_acc_s__c && (
                    <>
                      <h6>{t('entryLevel')}</h6>
                      {applicationData.Formation &&
                      me?.type_tech__c === 'Francais' ? (
                        <p>{applicationData.Formation.niveau__c}</p>
                      ) : (
                        <p>
                          {FormationPicklist?.picklistFieldValues[
                            'Ann_e_d_acc_s__c'
                          ].values.map((value: any) => {
                            if (
                              applicationData?.Formation &&
                              value.value ===
                                applicationData?.Formation.ann_e_d_acc_s__c
                            ) {
                              if (i18n.language === 'fr') {
                                return `${value.label} année`;
                              }
                              if (i18n.language === 'en') {
                                return `${value.value} year`;
                              }
                            }
                          })}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
              {/* {applicationData.Formation?.date_de_d_but__c && (
                <>
                  <h6>{t('schoolYear')}</h6>
                  {applicationData.Formation && (
                    <p>{applicationData.Formation.date_de_d_but__c}</p>
                  )}
                </>
              )} */}
              <h5>{t('step.second')}</h5>
              {_.chain(applicationData.Files)
                .filter((f) => f.type__c === 'poursuite')
                .value().length > 0 && (
                <>
                  <h6>{t('poursuite')}</h6>
                  {_.chain(applicationData.Files)
                    .filter((f) => f.type__c === 'poursuite')
                    .map((f) => {
                      return <p>{f.name} </p>;
                    })
                    .value()}
                </>
              )}
              {_.chain(applicationData.Files)
                .filter((f) => f.type__c === 'motivation')
                .value().length > 0 && (
                <>
                  <h6>{t('motivationLetter')}</h6>
                  {_.chain(applicationData.Files)
                    .filter((f) => f.type__c === 'motivation')
                    .map((f) => {
                      return <p>{f.name}</p>;
                    })
                    .value()}
                </>
              )}
              {_.chain(applicationData.Files)
                .filter((f) => f.type__c === 'recommendation')
                .value().length > 0 && (
                <>
                  <h6>{t('recoLetter')}</h6>
                  {_.chain(applicationData.Files)
                    .filter((f) => f.type__c === 'recommendation')
                    .map((f) => {
                      return <p>{f.name}</p>;
                    })
                    .value()}
                </>
              )}
              {_.chain(applicationData.Files)
                .filter((f) => f.type__c === 'plan')
                .value().length > 0 && (
                <>
                  <h6>{t('plan')}</h6>
                  {_.chain(applicationData.Files)
                    .filter((f) => f.type__c === 'plan')
                    .map((f) => {
                      return <p>{f.name}</p>;
                    })
                    .value()}
                </>
              )}
              {_.chain(applicationData.Files)
                .filter((f) => f.type__c === 'documents')
                .value().length > 0 && (
                <>
                  <h6>{t('otherDocuments')}</h6>
                  {_.chain(applicationData.Files)
                    .filter((f) => f.type__c === 'documents')
                    .map((f) => {
                      return <p>{f.name}</p>;
                    })
                    .value()}
                </>
              )}{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['recapitulatif', 'home', 'common'])(Recapitulatif)
);
