/* 
  This service is used to interacte with the Facilities routes of the API server
*/

import axios, { AxiosResponse } from 'axios';

const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class FacilitiesService {
  /**
   * Get all facilities
   * @returns a list of all the facilities
   */
  getFacilities(): Promise<AxiosResponse> {
    return axios.get(`${url}/facilities`);
  }

  /**
   * Get all exchange facilities
   * @returns a list of all the exchange facilities
   */
  getExchangeFacilities(): Promise<AxiosResponse> {
    return axios.get(`${url}/facilities/exchange`);
  }
}
export default new FacilitiesService();
