import axios, { AxiosResponse } from 'axios';
import {
  PaymentCreationResponse,
  PaymentFields,
  PaymentFinalizeResponse,
  PaymentStatusResponse,
  Transaction
} from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;

class PaymentService {
  create(paymentFields: Partial<PaymentFields>): Promise<AxiosResponse<PaymentCreationResponse>> {
    return axios.post(`${url}/transaction`, paymentFields);
  }

  finalize(transactionID?: string): Promise<AxiosResponse<PaymentFinalizeResponse>> {
    return axios.post(`${url}/transaction/finalize?id_heroku__c=${transactionID}`, {});
  }

  status(): Promise<AxiosResponse<PaymentStatusResponse>> {
    return axios.get(`${url}/transaction/status`);
  }

  refund(transactionID: string): Promise<AxiosResponse<Transaction>> {
    return axios.delete(`${url}/transaction?id_heroku__c=${transactionID}`);
  }
}
export default new PaymentService();
export * from './interface';
