import axios, { AxiosResponse } from 'axios';
import API from '..';
import { Trip } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class TripService {
  getTrips(user_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/${user_id}/sejouretranger`);
  }

  patchTrip(user_id: string, experience: Trip): Promise<AxiosResponse> {
    return axios.patch(
      `${url}/${user_id}/sejouretranger/${experience.id_heroku__c}`,
      experience
    );
  }
  createTrip(user_id: string, educationHistory: Trip): Promise<{ data: any }> {
    return axios.post(`${url}/${user_id}/sejouretranger`, educationHistory);
  }
  /**
   * Remove a stay abroad
   * @param user_id Heroku ID of the user
   * @param sejour_etranger_id Heroku ID of a stay abroad
   * @returns {Promise} an empty promise
   */
  remove(
    user_id: string,
    sejour_etranger_id: string
  ): Promise<AxiosResponse<void>> {
    return axios.delete(
      `${url}/${user_id}/sejouretranger/${sejour_etranger_id}`
    );
  }
  /**
   * Remove a stay abroad
   * @param user_id Heroku ID of the user
   * @param sejour_etranger_id Heroku ID of a stay abroad
   * @returns {Promise} an empty promise
   */
  // public remove(
  //   user_id: string,
  //   sejour_etranger_id: string
  // ): Promise<AxiosResponse<void>> {
  //   return axios.delete(
  //     `${url}/${user_id}/sejouretranger/${sejour_etranger_id}`
  //   );
  // }
}
export default new TripService();
