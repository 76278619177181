/**
 * This component displays the authentification forms (login or register)
 */
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { Link, withRouter, RouteComponentProps  } from 'react-router-dom';
import Languages from '../../i18n';

import junialogo from '../../assets/images/junialogoviolet.png';
import flagUK from '../../assets/images/flagUK.png';
import flagFR from '../../assets/images/flagFR.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import './Authentication.scss';
import AuthService from '../../services/api/Auth/AuthService';
import ContactService from '../../services/api/Contact/ContactService';
import { Auth } from '../../services/api/Contact/interface';
import Spinner from '../../utils/Spinner/Spinner';
import Alert from '../../utils/Alert/Alert';
import TranslationMiddleware from '../../services/middleware/TranslationMiddleware';

interface ContainerProps {}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const formLoginInitialState = {
  email: '',
  password: ''
};

const formRegisterFields = {
  confirmPassword: '',
  firstname: '',
  lastname: '',
  cgu_acceptees_portail__c: false,
  ne_pas_contacter__c: 'Non',
  // name: '',
  salutation: ''
};

const Authentication: React.FC<IProps> = (props) => {
  const { t } = props;
  const [active, setActive] = useState(localStorage.getItem('i18nextLng'));

  const [form, setForm] = useState<Auth>(formLoginInitialState);
  const [passwordHidden, setPasswordHidden] = useState<boolean>(true);
  const [isLoginForm, setIsLoginForm] = useState(true);
  const { i18n } = useTranslation();
  useEffect(() => {
    if (isLoginForm) {
      setForm((prev) => {
        return {
          ...prev,
          ...formRegisterFields
        };
      });
    } else {
      let newForm = { ...form };
      delete newForm.confirmPassword;
      setForm(newForm);
    }
  }, [isLoginForm]);

  const handleChange = (e: any): void => {
    let value = e.target.value;
    let check = ['cgu_acceptees_portail__c'];

    if (check.includes(e.target.name)) {
      value = e.target.checked;
    }

    if(e.target.name === 'ne_pas_contacter__c') {
      value = e.target.checked ? 'Oui' : 'Non'
    }

    setForm({
      ...form,
      [e.target.name]: value
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    
    
    if(form.email !== form.email.toLowerCase()){
      console.error('Email lowercase condition not respected');
      Alert.setAlert({
        open: true,
        type: 'error',
        duration: 3000,
        message: TranslationMiddleware.Translate('format_email_lowercase')
      });
    }else{
      if (isLoginForm) {
        AuthService.login(form);
      } else {
        Spinner.setSpinner(true);
        if (form.confirmPassword) {
          ContactService.register({
            ...form,
            portal_language_tech__c: i18n.language.toUpperCase()
          })
            .then((res) => {
              Spinner.setSpinner(false);
              Alert.setAlert({
                duration: 3000,
                message: t('word.registered'),
                open: true,
                type: 'success'
              });
              setIsLoginForm(true);
            })
            .catch((err) => {
              Spinner.setSpinner(false);
              Alert.setAlert({
                open: true,
                type: 'error',
                duration: 3000,
                message: `${
                  err.response && err.response.data
                    ? err.response.data.message
                      ? TranslationMiddleware.Translate(err.response.data.message)
                      : err.response.data
                    : 'error'
                }`
              });
            });
        }
      }
    }

    
    
  };

  const isValidRegisterForm = (): boolean => {
    return (
      !!form.email &&
      !!form.firstname &&
      !!form.lastname &&
      !!form.password &&
      !!form.confirmPassword &&
      !!form.cgu_acceptees_portail__c &&
      form.password === form.confirmPassword
    );
  };

  return (
    <div className="authenticate">
      <div className="authenticate-container">
        <div className="authenticate__header">
          <img src={junialogo} alt="junia logo" />
          <h5>{t('page_title')}</h5>
          <div className="authenticate__header__language-cont">
            <button
              type="button"
              onClick={() => {
                Languages.changeLanguage('fr');
                setActive('fr');
              }}
              className={active === 'fr' ? 'fr' : ''}>
              <img src={flagFR} alt="drapeau français" />
            </button>
            <button
              type="button"
              onClick={() => {
                Languages.changeLanguage('en');
                setActive('en');
              }}
              className={active === 'en' ? 'en' : ''}>
              <img src={flagUK} alt="drapeau anglais" />
            </button>
          </div>
        </div>
        <form className="authenticate__form">
          {!isLoginForm && (
            <React.Fragment>
              {/* <div className="input-container">
                <label htmlFor="email">{t('word.civilite')}</label>
                <input
                  type="salutation"
                  name="salutation"
                  onChange={handleChange}
                  value={form.salutation}
                />
              </div> */}
              <div className="input-container">
                <label htmlFor="email">{t('word.lastname')}</label>
                <input
                  type="lastname"
                  name="lastname"
                  onChange={handleChange}
                  value={form.lastname}
                />
              </div>
              <div className="input-container">
                <label htmlFor="email">{t('word.firstname')}</label>
                <input
                  type="firstname"
                  name="firstname"
                  onChange={handleChange}
                  value={form.firstname}
                />
              </div>
            </React.Fragment>
          )}
          <div className="input-container">
            <label htmlFor="email">{t('word.email')}</label>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={form.email}
            />
          </div>
          <div className="input-container">
            <label htmlFor="password">{t('word.password')}</label>
            <div className="hide-show">
              <input
                type={passwordHidden ? 'password' : 'text'}
                name="password"
                minLength={12}
                onChange={handleChange}
                value={form.password}
              />
              <button
                className="btn icon-hide"
                onClick={() => setPasswordHidden(!passwordHidden)}
                type="button">
                {passwordHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            </div>
          </div>
          {!isLoginForm && (
            <div className="input-container">
              <label htmlFor="password">{t('word.confirmPassword')}</label>
              <div className="hide-show">
                <input
                  type={passwordHidden ? 'password' : 'text'}
                  name="confirmPassword"
                  minLength={12}
                  onChange={handleChange}
                  value={form.confirmPassword}
                />
                <button
                  className="btn icon-hide"
                  onClick={() => setPasswordHidden(!passwordHidden)}
                  type="button">
                  {passwordHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </button>
              </div>
            </div>
          )}
          {!isLoginForm && (
            <>
            <div className="cgu">
              <input
                name="cgu_acceptees_portail__c"
                type="checkbox"
                onChange={handleChange}
                checked={form.cgu_acceptees_portail__c ? true : false}
              />
              {t('cguSentence')} <Link to="/personal-data-policy" target="_blank" rel="noopener noreferrer">{t('personalDataPolicyLink')}</Link>
            </div>
            <div className="cgu">
              <input
                name="ne_pas_contacter__c"
                type="checkbox"
                onChange={handleChange}
                checked={form.ne_pas_contacter__c === 'Oui' ? true : false}
              />
              {t('contactSentence')}
            </div>
            </>
          )}
          <button
            className="btn btn-main btn-fullwidth"
            type="submit"
            disabled={!isLoginForm ? !isValidRegisterForm() : false}
            onClick={handleSubmit}>
            <span>{isLoginForm ? t('word.signin') : t('word.register')}</span>
          </button>
          <button
            className="btn btn-outlined btn-fullwidth"
            type="button"
            onClick={() => setIsLoginForm(!isLoginForm)}>
            <span>
              {isLoginForm
                ? t('login:create_new_account')
                : t('login:have_account')}
            </span>
          </button>
          <Link className="authenticate__form__link" to="/forgot-password">
            {t('forgot_password')}
          </Link>
        </form>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['login', 'common'])(Authentication));
