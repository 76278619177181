/* 
  This service is used to interacte with the Education History routes of the API server
*/
import axios, { AxiosResponse } from 'axios';
import { id } from 'date-fns/locale';
import _ from 'lodash';
import API from '..';
import { EducationHistory } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class EducationHistoryService {
  getContactEducationHistory(user_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/${user_id}/educationhistory`);
  }

  /**
   * This method updates education histories and related files
   * @param user_id the user id
   * @param educationHistory the education history object
   * @param files the related files
   * @param typesFile types of the related files
   * @returns a promise that returns the updated education history
   */
  patchContactEducationHistory(
    user_id: string, 
    educationHistory: EducationHistory,
    files?: any[],
    typesFile?: string[]
  ): Promise<AxiosResponse> {
    let formData = new FormData(); //formdata object

    _.map(files, (o) => {
      if (typesFile && typesFile.includes(o.type)) {
        _.map(o.files, (file) => {
          formData.append(o.type, file);
        });
      }
    });
    _.map(educationHistory, (value: any, key: any) => {
      if (value !== null && value !== '') {
        formData.append(key, value);
      }
    });
    return axios.patch(
      `${url}/${user_id}/educationhistory/${educationHistory.id_heroku__c}`,
      formData
    );
  }

  /**
   * This method creates education histories and related files
   * @param user_id the user id
   * @param educationHistory the education history object
   * @param files the related files
   * @param typesFile types of the related files
   * @returns a promise that returns the created education history
   */
  createContactEducationHistory(
    user_id: string,
    educationHistory: EducationHistory,
    files?: any[],
    typesFile?: string[]
  ): Promise<AxiosResponse> {
    let formData = new FormData(); //formdata object

    _.map(files, (o) => {
      if (typesFile && typesFile.includes(o.type)) {
        _.map(o.files, (file) => {
          formData.append(o.type, file);
        });
      }
    });
    _.map(educationHistory, (value: any, key: any) => {
      if (value !== null && value !== '') {
        formData.append(key, value);
      }
    });
    return axios.post(`${url}/${user_id}/educationhistory`, formData);
  }

  /**
   * Remove an education history
   * @param user_id Heroku ID of the user
   * @param education_history_id Heroku ID of an education history
   * @returns {Promise} an empty promise
   */
  remove(
    user_id: string,
    education_history_id: string
  ): Promise<AxiosResponse<void>> {
    return axios.delete(
      `${url}/${user_id}/educationhistory/${education_history_id}`
    );
  }
}
export default new EducationHistoryService();
