/**
 * This component is the top level component for creating a new candidate as an Agent
 */
import React, { createElement, useEffect, useState, useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';

// Assets
import logoJunia from '../../../../assets/images/junialogoviolet.png';

// Components
import Header from '../../../CreateAccount/components/Header/Header';
import CandidateInfoFromAgent from '../../../CreateAccount/components/CandidateInfoFromAgent/CandidateInfoFromAgent';
import PersonnalInfos from '../../../CreateAccount/components/PersonnalInfos/PersonnalInfos';
import Cursus from '../../../CreateAccount/components/Cursus/Cursus';
import Languages from '../../../CreateAccount/components/Languages/Languages';
import Experiences from '../../../CreateAccount/components/Experiences/Experiences';
import Trips from '../../../CreateAccount/components/Trips/Trips';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import Alert from '../../../../utils/Alert/Alert';

// Services
import AuthService from '../../../../services/api/Auth/AuthService';
import ContactService from '../../../../services/api/Contact/ContactService';
import EducationHistoryService from '../../../../services/api/EducationHistory/EducationHistoryService';
import CompetenceLinguistiqueService from '../../../../services/api/CompetenceLinguistique/CompetenceLinguistiqueService';
import ExperienceService from '../../../../services/api/Experience/ExperienceService';
import TripService from '../../../../services/api/Trip/TripService';

// Interfaces
import { Contact, me } from '../../../../services/api/Contact/interface';
import { EducationHistory } from '../../../../services/api/EducationHistory/interface';
import { CompetenceLinguistique } from '../../../../services/api/CompetenceLinguistique/interface';
import { Experience } from '../../../../services/api/Experience/interface';
import { Trip } from '../../../../services/api/Trip/interface';

// Style
import './CreateCandidate.scss';

interface ContainerProps {}

type ChildProps = {};

const inscriptionArray: any = [
  {
    name: 'candidate_info',
    component: CandidateInfoFromAgent
  },
  {
    name: 'Infos',
    component: PersonnalInfos
  },
  {
    name: 'Cursus',
    component: Cursus
  },
  {
    name: 'Langues',
    component: Languages
  },
  {
    name: 'Expériences Pro',
    component: Experiences
  },
  {
    name: 'Séjours',
    component: Trips
  }
];

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const CreateCandidate: React.FC<IProps> = (props) => {
  const { t } = props;

  const [me, setMe] = useState<me>({});
  const [form, setForm] = useState<Contact>({});
  const [contact, setContact] = useState<Contact>({});
  const [selectedStep, setSelectedStep] = useState(inscriptionArray[0]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [educationHistorys, setEductionHistorys] = useState<EducationHistory[]>(
    []
  );
  const [competencesLing, setCompetencesLing] = useState<
    CompetenceLinguistique[]
  >([]);
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);

  const [canNext, setCanNext] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [height, setHeight] = useState<number>(0);

  const divRef = useRef<HTMLDivElement>(null);
  const componentref = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (me.id_heroku__c) {
      getContact();
      setStep();
    }
  }, [contact.id_heroku__c]);

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);

  useEffect(() => {
    let element = divRef.current;
    if (
      element?.clientHeight &&
      element.clientHeight > 0 &&
      element?.clientWidth &&
      element.clientWidth > 0
    ) {
      setHeight(element.clientHeight);
    }
  }, [selectedStep]);

  const menuItems = [
    {
      text: t('word.disconnect'),
      path: '/',
      event: () => {
        AuthService.logout();
      }
    }
  ];

  const getMe = async () => {
    try {
      let meResponse = await AuthService.me();
      setMe(meResponse.data);
    } catch (error) {
      console.log('🚀 ~ file: CreateAccount.tsx ~ getMe ~ error', error);
    }
  };

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element?.clientHeight && element?.clientHeight > 0) {
      setHeight(element.clientHeight);
    }
  };

  const pagination = async (step: number) => {
    setLoading(true);
    let newStep;

    // Create new Candidate
    if (selectedStep.name === 'candidate_info') {
      createContactFromAgent(form);
      newStep = 1;
    } else {
      let found = inscriptionArray.indexOf(selectedStep);
      newStep = found + step;
    }

    let newSelected = inscriptionArray[newStep];
    let newForm;
    let newContact;

    if (contact?.id_heroku__c) {
      if (step > 0) {
        switch (selectedStep.name) {
          case 'Cursus':
            if (educationHistorys.length > 0) {
              sendEducationHistorys([...educationHistorys]);
            }
            break;
          case 'Langues':
            if (competencesLing.length > 0) {
              sendCompetencesLinguistiques([...competencesLing]);
            }
            break;
          case 'Expériences Pro':
            sendExperiences([...experiences]);
            break;
          case 'Séjours':
            sendTrips([...trips]);
            break;
          case 'Infos':
            break;
        }
        newForm = {
          ...form
        };
        newContact = {
          ...contact
        };
        if (newSelected) {
          newForm.inscription_tech__c = newSelected.name;
          newContact.inscription_tech__c = newSelected.name;
        }
      } else {
        setEductionHistorys([]);

        setCompetencesLing([]);
        setExperiences([]);
        setTrips([]);
        setForm({});

        newForm = {
          inscription_tech__c: newSelected.name
        };
        newContact = {
          inscription_tech__c: newSelected.name
        };
      }
      if (newSelected) {
        let files = [...fileList];
        setFileList([]);
        ContactService.patchContact(contact?.id_heroku__c, newForm, files, [
          'identity_card',
          'scholarship',
          'resume',
          'bac_grades'
        ]).then(async (res) => {
          await getContact();
        });
        setCanNext(false);
        setSelectedStep(newSelected);
        setForm(newForm);
        updateNextStep(newForm, step);
      } else if (
        !newSelected &&
        contact.type_tech__c !== undefined &&
        form.type_tech__c !== undefined
      ) {
        if (step > 0) {
          let newForm = { ...form, inscription_tech__c: 'Terminée' };
          setForm(newForm);
          ContactService.patchContact(contact?.id_heroku__c, newForm).then(
            (res) => {
              Alert.setAlert({
                duration: 3000,
                message: t('createCandidateAccountEnd'),
                open: true,
                type: 'success'
              });
              history.push('/agent/candidats');
            }
          );
        }
      }
    }
  };

  const handleNext = (data: any, next: boolean) => {
    switch (selectedStep.name) {
      case 'candidate_info':
        setForm(data);
        break;
      case 'Infos':
        if (data.files) {
          pushInFileList(data.files);
        }
        setForm({
          ...form,
          ...data.data
        });
        break;
      case 'Cursus':
        if (data.cursus) {
          setEductionHistorys(data.cursus);
        }
        if (data.data) {
          if (data.files) {
            pushInFileList(data.files);
          }
          setForm({
            ...form,
            ...data.data
          });
        }
        break;
      case 'Langues':
        if (data.languages) {
          setCompetencesLing(data.languages);
        }
        break;
      case 'Expériences Pro':
        setExperiences(data.experiences);
        setFileList(data.files);
        break;
      case 'Séjours':
        setTrips(data);
        break;
    }
    setCanNext(next);
  };

  const pushInFileList = (data: any) => {
    let files = [...fileList];
    if (data.type) {
      let found = files.findIndex((o) => o.type === data.type);
      if (found >= 0) {
        files.splice(found, 1, data);
      } else {
        files.push(data);
      }
      setFileList(files);
    }
  };

  const getContact = async () => {
    setLoading(true);
    try {
      if (contact.id_heroku__c) {
        let contactResponse = await ContactService.getContact(
          contact?.id_heroku__c
        );
        setContact(contactResponse.data);
        updateNextStep(contactResponse.data, 0);
        setLoading(false);
      }
    } catch (error) {
      console.log('🚀 ~ file: CreateAccount.tsx ~ getContact ~ error', error);
    }
  };

  const createContactFromAgent = async (data: Contact) => {
    setLoading(true);
    try {
      if (me.id_heroku__c) {
        let contactResponse = await ContactService.createFromAgent(
          me?.id_heroku__c,
          data
        );
        Alert.setAlert({
          duration: 3000,
          message: t('createCandidateAccountSuccess'),
          open: true,
          type: 'success'
        });
        setContact(contactResponse.data);
        updateNextStep(contactResponse.data, 1);
        setLoading(false);
      }
    } catch (error) {
      let message = t('createCandidateAccountError');
      if (error instanceof Error) {
        message = error.message.includes('403')
          ? t('createCandidateAccountAlreadyExists')
          : message;
      }
      console.log(
        '🚀 ~ file: CreateAccount.tsx  ~ createContactForAgent ~ error',
        error
      );
      Alert.setAlert({
        open: true,
        type: 'error',
        duration: 5000,
        message: message
      });
      setLoading(false);
    }
  };

  const setStep = () => {
    if (contact.type_tech__c) {
      let found = inscriptionArray.find(
        (step: any) => step.name === contact.inscription_tech__c
      );
      if (found) {
        setSelectedStep(found);
      }
    }
  };

  const updateNextStep = (data: Contact, step: number) => {
    if (data.type_tech__c) {
      let found = inscriptionArray.indexOf(selectedStep);
      let newStep = found + step;

      let newSelected = inscriptionArray[newStep];
      if (newSelected?.name) {
        setForm({
          ...data,
          inscription_tech__c: newSelected.name
        });
      }
    } else {
      if (selectedStep?.name) {
        setForm({
          ...data,
          inscription_tech__c: selectedStep.name,
          type_tech__c: contact.type_tech__c ?? 'International'
        });
      }
    }
  };

  const sendEducationHistorys = async (data: EducationHistory[]) => {
    let list = await data.map(async (educationHistory) => {
      if (contact && contact.id_heroku__c) {
        if (educationHistory.id_heroku__c) {
          await EducationHistoryService.patchContactEducationHistory(
            contact?.id_heroku__c,
            educationHistory,
            educationHistory.fileList,
            ['degree', 'grades', 'bac_transcript']
          );
        } else {
          await EducationHistoryService.createContactEducationHistory(
            contact.id_heroku__c,
            educationHistory,
            educationHistory.fileList,
            ['degree', 'grades', 'bac_transcript']
          );
        }
        return educationHistory;
      }
    });
    Promise.all(list).then((res) => {
      setEductionHistorys([]);
    });
  };

  const sendCompetencesLinguistiques = async (
    data: CompetenceLinguistique[]
  ) => {
    let list = await data.map(async (competenceLinguistique) => {
      if (contact && contact.id_heroku__c) {
        if (competenceLinguistique.id_heroku__c) {
          await CompetenceLinguistiqueService.patchCompetencesLinguistique(
            contact.id_heroku__c,
            competenceLinguistique,
            competenceLinguistique.fileList,
            ['language_degree']
          );
        } else {
          await CompetenceLinguistiqueService.createCompetencesLinguistique(
            contact.id_heroku__c,
            competenceLinguistique,
            competenceLinguistique.fileList,
            ['language_degree']
          );
        }
        return competenceLinguistique;
      }
    });
    Promise.all(list).then((res) => {
      setCompetencesLing([]);
    });
  };

  const sendExperiences = async (data: Experience[]) => {
    let list = await data.map(async (experience) => {
      if (contact && contact.id_heroku__c) {
        if (experience.id_heroku__c) {
          await ExperienceService.patchExperience(
            contact.id_heroku__c,
            experience
          );
        } else {
          let ret = await ExperienceService.createExperience(
            contact.id_heroku__c,
            experience
          );
        }
        return experience;
      }
    });
    Promise.all(list).then((res) => {
      setExperiences([]);
    });
  };

  const sendTrips = async (data: Trip[]) => {
    let list = await data.map(async (trip) => {
      if (contact && contact.id_heroku__c) {
        if (trip.id_heroku__c) {
          await TripService.patchTrip(contact.id_heroku__c, trip);
        } else {
          await TripService.createTrip(contact.id_heroku__c, trip);
        }
        return trip;
      }
    });
    Promise.all(list).then((res) => {
      setTrips([]);
    });
  };

  return (
    <div className="subscribe-layout">
      <div className="subscribe-height" ref={divRef}>
        <div className="subscribe-logo">
          <img src={logoJunia} alt="junia"></img>
          <div className="toolbox">
            {menuItems.map((item) => (
              <button
                key={item.text}
                className={`btn btn-outlined ${
                  location.pathname == item.path ? 'active' : ''
                }`}
                onClick={() => {
                  item.event();
                }}>
                {item.text}
              </button>
            ))}
          </div>
        </div>
        {selectedStep.name !== 'Terminée' && (
          <>
            {selectedStep.name === 'candidate_info' && (
              <button
                className="return btn btn-outlined"
                type="button"
                onClick={() => history.push('/agent/candidats')}>
                <span>{t('word.cancel')}</span>
              </button>
            )}
            {selectedStep.name !== 'candidate_info' &&
              selectedStep.name !== 'Infos' && (
                <button
                  className="return btn btn-outlined"
                  type="button"
                  onClick={() => pagination(-1)}>
                  <span>{t('word.return')}</span>
                </button>
              )}
            <div className="subscribe-header">
              <Header
                title={t(`title.${selectedStep.name}`)}
                subtitle={t(`subtitle.${selectedStep.name}`)}
                step={selectedStep}
              />
              <div className="subscribe-next">
                {canNext && !loading ? (
                  <button
                    type="button"
                    className="btn btn-main btn-fullwidth"
                    onClick={() => pagination(+1)}>
                    <span>{t('word.next')}</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-main btn-fullwidth btn-disabled">
                    <span>{t('word.next')}</span>
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className="subscribe-list-container"
        style={{
          height: 'calc(98% - ' + (height + 70) + 'px)'
        }}>
        <div className="subscribe-list" ref={componentref}>
          {!loading &&
            createElement(selectedStep.component, {
              contact: { ...contact, ...form },
              onChange: handleNext,
              getContact: getContact
            })}
          {loading && <SpinnerComponent backdrop={false}></SpinnerComponent>}
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form', 'agent'])(CreateCandidate)
);
