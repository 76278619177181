import axios, { AxiosResponse } from 'axios';
import API from '..';
import { PieceComplementaire } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class FilesService {
  removeFile(id_heroku__c: string, user_id: string): Promise<AxiosResponse<void>> {
    return axios.delete(`${url}/file`, {
      params: { id_heroku__c: id_heroku__c, user_id: user_id }
    });
  }
  downloadFile(fileID?: string): Promise<AxiosResponse<string>> {
    return axios.get(`${url}/file/download?id_heroku__c=${fileID}`);
  }
  downloadZip(applicationID?: string | null): Promise<AxiosResponse<Buffer>> {
    return axios.get(`${url}/file/download/zip/${applicationID}`);
  }
}
export default new FilesService();
