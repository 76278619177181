/**
 * This component dispalys the languages form
 */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import UploadFile from '../../../../components/UploadFile/UploadFile';
import CompetenceLinguistiqueService from '../../../../services/api/CompetenceLinguistique/CompetenceLinguistiqueService';
import { CompetenceLinguistique } from '../../../../services/api/CompetenceLinguistique/interface';
import { Contact } from '../../../../services/api/Contact/interface';
import LanguagesService from '../../../../services/api/Languages/LanguagesService';
import {
  checkRequested
} from '../../../../utils/validator/validator';
import Competence from './Competence/Competence';

import './Languages.scss';
import languagePicklist from '../../../../assets/data/hed_contact_language.json';
import DisplayFiles from '../../../../components/DisplayFiles/DisplayFiles';
import FilesService from '../../../../services/api/Files/FilesService';
import {
  FileToUpload,
  PieceComplementaire
} from '../../../../services/api/Files/interface';
import DisplayBlobFiles from '../../../../components/DisplayBlobFiles/DisplayBlobFiles';
import { Language } from '../../../../services/api/Languages/interface';

interface ContainerProps {
  contact: Contact;
  onChange: (data: any, canNext: boolean) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Languages: React.FC<IProps> = (props) => {
  const { t, contact, onChange } = props;

  const [form, setForm] = useState<Contact>(contact);
  const [validation, setValidation] = useState<any>({});

  const required: any = {
    Francais: {},
    International: [],
    Echange: [],
    competence: [
      'hed__fluency__c',
      'hed__language__c',
      'certificat_officiel_de_langue__c'
    ],
    otherLanguages: [
      'hed__fluency__c',
      'hed__language__c',
    ]
  };

  const frenchCertificateChoices = [
    'DELF',
    'TCF',
    'Other',
    'No certificate'
  ]

  const [languages, setLanguages] = useState<Language[]>([]);

  const [competences, setCompetences] = useState<any[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const { i18n } = useTranslation();
  const [fileList, setFileList] = useState<FileToUpload[]>([]);

  useEffect(() => {
    buildYears();
    getLanguages();
  }, []);

  useEffect(() => {
    if (languages.length > 0) {
      getCompetences();
    }
  }, [languages]);

  const getLanguages = async () => {
    let languageResponse = await LanguagesService.getLanguages();
    setLanguages(languageResponse.data);
  };

  const getCompetences = async () => {
    if (contact && contact.id_heroku__c) {
      let competenceResponse =
        await CompetenceLinguistiqueService.getCompetencesLinguistique(
          contact.id_heroku__c
        );
      let competenceList = competenceResponse.data;
      let fr = _.find(languages, (l) => l.name === 'Français');
      let en = _.find(languages, (l) => l.name === 'Anglais');
      let gotFr = _.find(competenceList, (l) => l.langue1__c === 'Français');
      let gotEn = _.find(competenceList, (l) => l.langue1__c === 'Anglais');
      if (!gotEn) {
        competenceList.push({
          langue1__c: 'Anglais',
          competenceId: 1,
          hed__language__c: en?.sfid,
          Files: []
        });
      }
      if (contact.type_tech__c !== 'Francais' && !gotFr) {
        competenceList.push({
          langue1__c: 'Français',
          competenceId: 2,
          hed__language__c: fr?.sfid,
          Files: []
        });
      }
      if (competenceList.length > 0) {
        let formateList = _.map(competenceList, (competence, index) => {
          return { ...competence, competenceId: index + 1 };
        });
        let canNext = checkComp(formateList, required.competence);
        onChange({ languages: formateList }, canNext);
        setCompetences(formateList);
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefautl();
  };
  const buildYears = () => {
    let given = parseInt(moment().format('YYYY'));
    let diff = given - 1901;
    let array = [''];
    for (let index = 0; index <= diff; index++) {
      let year = given - index;
      array.push(year.toString());
    }
    setYears(array);
  };

  const addComp = (e: any) => {
    let comps = [...competences];
    comps.push({
      competenceId: comps.length + 1,
    });
    let canNext = checkComp(comps, required.competence);

    // canNext = canNext && checkRequested(required.competence, form);
    onChange({ languages: comps }, canNext);
    setCompetences(comps);
  };
  const compChange = (data: any, comp: any) => {
    let newList: any[] = _.map(competences, (o: CompetenceLinguistique) => {
      if (o.competenceId === comp.competenceId) {
        return { ...comp, ...data };
      } else {
        return o;
      }
    });
    setCompetences(newList);
    let canNext = checkComp(newList, required.otherLanguages);

    // canNext = canNext && checkRequested(required.competence, form);
    onChange({ languages: newList }, canNext);
  };

  const checkComp = (list: CompetenceLinguistique[], requireList: string[]) => {
    let canNext = true;
    list.forEach((languageComp: CompetenceLinguistique) => {
      if (contact.type_tech__c && canNext) {
        if (
          languageComp.langue1__c !== 'Français' &&
          languageComp.langue1__c !== 'Anglais'
        ) {
          canNext = checkRequested(required.otherLanguages, languageComp);
        } else {
          canNext = checkRequested(requireList, languageComp);
        }
      }
    });
    return canNext;
  };
  const findMax = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined
  ) => {
    if (files) {
      let foundCurrent = _.find([...fileList], (f) => f.type === type);
      let foundSave = _.filter([...files], (f) => f.type__c === type);
      let currentLength = foundCurrent ? foundCurrent.files.length : 0;
      let saveLength = foundSave ? foundSave.length : 0;
      return currentLength + saveLength;
    } else {
      return 0;
    }
  };
  const handleChange = (data: any, language: string) => {
    let found = _.find(competences, (o) => o.langue1__c === language);
    if (!found) {
      let newList: any[] = [
        ...competences,
        { [data.target.name]: data.target.value, langue1__c: language }
      ];
      setCompetences(newList);
      let canNext = checkComp(newList, required.competence);
    } else {
      let newList: any[] = _.map(competences, (o: CompetenceLinguistique) => {
        if (o.langue1__c === language) {
          return { ...o, [data.target.name]: data.target.value };
        } else {
          return o;
        }
      });
      setCompetences(newList);
      let canNext = checkComp(newList, required.competence);

      onChange({ languages: newList }, canNext);
    }
  };
  const handleDelete = (language: CompetenceLinguistique) => {
    if (language.id_heroku__c) {
      //delete from bdd
      if (contact.id_heroku__c) {
        CompetenceLinguistiqueService.remove(
          contact.id_heroku__c,
          language?.id_heroku__c
        ).then((res) => {
          getCompetences();
        });
      }
    } else {
      let languageList = [...competences];
      let index = _.findIndex(
        languageList,
        (t) => t.competenceId === language.competenceId
      );
      languageList.splice(index, 1);
      setCompetences(languageList);
      let canNext = checkComp(languageList, required.competence);
      onChange({ languages: languageList }, canNext);
    }
  };

  const handleFileDelete = (
    file: any,
    type: string,
    fileType: string,
    language: CompetenceLinguistique
  ) => {
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contact?.id_heroku__c) {
          FilesService.removeFile(
            file?.id_heroku__c,
            contact?.id_heroku__c
          ).then((res) => {
            let found = _.find(fileList, (f) => f.type === type);
            getCompetences();
          });
        }
        break;
      case 'blob':
        var competenceList: CompetenceLinguistique[] = [...competences];
        var index = _.findIndex(
          competenceList,
          (f) => f.id_heroku__c === language.id_heroku__c
        );
        if (index >= 0) {
          let filesList = competenceList[index].fileList;
          let typeIndex = _.findIndex(filesList, (f) => f.type === type);
          if (filesList && typeIndex >= 0) {
            var fileIndex = _.findIndex(
              filesList[typeIndex].files,
              (f: File) =>
                f.lastModified === file.files[0].lastModified &&
                f.name === file.files[0].name
            );
            if (
              index >= 0 &&
              competenceList &&
              competenceList[index] &&
              competenceList[index].fileList &&
              typeIndex >= 0 &&
              filesList
            ) {
              var array = [...filesList[typeIndex].files];
              array.splice(fileIndex, 1);
              filesList[typeIndex].files = array;
              setCompetences(competenceList);
            }
          }
        }
        // }
        break;
    }
  };
  const handleFileChange = (
    files: File[],
    type: string,
    language: CompetenceLinguistique
  ) => {
    let competencesList = [...competences];
    let found = _.findIndex(
      competencesList,
      (f) => f.competenceId === language.competenceId
    );
    if (found >= 0) {
      let data = [...competencesList[found].Files, ...files];
      competencesList.splice(found, 1, {
        ...language,
        fileList: [{ files: data, type: type }]
      });
    } else {
      competencesList.push({
        ...language,
        fileList: [{ files: files, type: type }]
      });
    }
    setCompetences(competencesList);
    let canNext = checkComp(competencesList, required.competence);
    onChange({ languages: competencesList }, canNext);
  };
  return (
    <div>
      <h5>{t('english')}</h5>
      <div>
        <h6 className="form-h6">
          {contact.type_tech__c === 'Francais' && <p className="required-text">{t('requiredEnglishCertificate')}</p>}
          {t('level')} <span className="form-required">*</span>
        </h6>
        <select
          name="hed__fluency__c"
          onChange={(e) => handleChange(e, 'Anglais')}
          onBlur={(e) => handleChange(e, 'Anglais')}
          value={
            _.find(
              competences,
              (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
            )?.hed__fluency__c
          }>
          <option value=""></option>
          {i18n.language === 'fr' &&
            _.chain(
              languagePicklist.picklistFieldValues['hed__Fluency__c'].values
            )
              // .pick(1)
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value} key={p.value}> {p.value} </option>;
                }
              })
              .value()}
          {i18n.language === 'en' &&
            _.chain(
              languagePicklist.picklistFieldValues['hed__Fluency__c'].values
            )
              // .pick(1)
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value} key={p.value}> {p.label} </option>;
                }
              })
              .value()}
        </select>
      </div>
      <div>
        <h6 className="form-h6">
          {t('englishCertif')} <span className="form-required">*</span>
        </h6>
        <select
          name="certificat_officiel_de_langue__c"
          onChange={(e) => handleChange(e, 'Anglais')}
          onBlur={(e) => handleChange(e, 'Anglais')}
          value={
            _.find(
              competences,
              (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
            )?.certificat_officiel_de_langue__c
          }>
          <option value=""></option>
          {languagePicklist.picklistFieldValues[
            'Certificat_officiel_de_langue__c'
          ].values.map((value) => {
            if (
              (contact.type_tech__c !== 'Francais' && (value.value === 'DELF' ||
              value.value === 'TCF'))
            ) {
              return;
            }
            if (i18n.language === 'fr') {
              return (
                <option value={value.value} key={value.value}>
                  {value.label}
                </option>
              );
            }
            if (i18n.language === 'en') {
              return (
                <option value={value.value} key={value.value}>
                  {value.value}
                </option>
              );
            }
          })}
        </select>
      </div>
      <div>
        <h6 className="form-h6">{t('graduationYear')}</h6>
        <select
          name="ann_e_d_obtention__c"
          onChange={(e) => handleChange(e, 'Anglais')}
          onBlur={(e) => handleChange(e, 'Anglais')}
          value={
            _.find(
              competences,
              (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
            )?.ann_e_d_obtention__c
          }>
          {years.length > 0 &&
            _.map(years, (year) => {
              return <option value={year} key={year}> {year} </option>;
            })}
        </select>
      </div>
      <div>
        <h6 className="form-h6">{t('score')}</h6>
        <input
          name="score__c"
          onChange={(e) => handleChange(e, 'Anglais')}
          onBlur={(e) => handleChange(e, 'Anglais')}
          value={
            _.find(
              competences,
              (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
            )?.score__c
          }
        />
      </div>
      <div>
        <h6 className="form-h6">{t('englishCertifOffi')}</h6>
        <UploadFile
          max={2}
          current={findMax(
            'language_degree',
            2,
            _.find(
              competences,
              (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
            )?.Files
          )}
          onChange={(data) =>
            handleFileChange(
              data,
              'language_degree',
              _.find(
                competences,
                (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
              )
            )
          }
          type="pdf"></UploadFile>
        <DisplayBlobFiles
          files={
            _.find(
              competences,
              (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
            )?.fileList
          }
          type="language_degree"
          onDelete={(data) =>
            handleFileDelete(
              data,
              'language_degree',
              'blob',
              _.find(
                competences,
                (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
              )
            )
          }></DisplayBlobFiles>
        <DisplayFiles
          files={
            _.find(
              competences,
              (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
            )?.Files
          }
          type="language_degree"
          onDelete={(data) =>
            handleFileDelete(
              data,
              'language_degree',
              'uploaded',
              _.find(
                competences,
                (o: CompetenceLinguistique) => o.langue1__c === 'Anglais'
              )
            )
          }></DisplayFiles>
      </div>
      {contact.type_tech__c !== 'Francais' && (
        <React.Fragment>
          {' '}
          <hr></hr>
          <h5>{t('french')}</h5>
          <div>
            <h6 className="form-h6">
              {t('level')} <span className="form-required">*</span>
            </h6>
            <select
              name="hed__fluency__c"
              onChange={(e) => handleChange(e, 'Français')}
              value={
                _.find(
                  competences,
                  (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                )?.hed__fluency__c
              }>
              <option value=""></option>
              {i18n.language === 'fr' &&
                _.chain(
                  languagePicklist.picklistFieldValues['hed__Fluency__c'].values
                )
                  // .pick(1)
                  .map((p) => {
                    if (p && p.value) {
                      return <option value={p.value} key={p.value}> {p.value} </option>;
                    }
                  })
                  .value()}
              {i18n.language === 'en' &&
                _.chain(
                  languagePicklist.picklistFieldValues['hed__Fluency__c'].values
                )
                  // .pick(1)
                  .map((p) => {
                    if (p && p.value) {
                      return <option value={p.value} key={p.value}> {p.label} </option>;
                    }
                  })
                  .value()}
            </select>
          </div>
          <div>
            <h6 className="form-h6">
              {t('frenchCertif')}<span className="form-required">*</span>
            </h6>
            <select
              name="certificat_officiel_de_langue__c"
              onChange={(e) => handleChange(e, 'Français')}
              value={
                _.find(
                  competences,
                  (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                )?.certificat_officiel_de_langue__c
              }>
              <option value=""></option>
              {languagePicklist.picklistFieldValues[
                'Certificat_officiel_de_langue__c'
              ].values.map((value) => {
                if (frenchCertificateChoices.includes(value.value)) {
                  if (i18n.language === 'fr') {
                    return (
                      <option value={value.value} key={value.value}>
                        {value.label}
                      </option>
                    );
                  }
                  if (i18n.language === 'en') {
                    return (
                      <option value={value.value} key={value.value}>
                        {value.value}
                      </option>
                    );
                  }
                }
                return;
              })}
            </select>
          </div>
          <div>
            <h6 className="form-h6">{t('graduationYear')}</h6>
            <select
              name="ann_e_d_obtention__c"
              onChange={(e) => handleChange(e, 'Français')}
              value={
                _.find(
                  competences,
                  (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                )?.ann_e_d_obtention__c
              }>
              {years.length > 0 &&
                _.map(years, (year) => {
                  return <option value={year} key={year}> {year} </option>;
                })}
            </select>
          </div>
          <div>
            <h6 className="form-h6">{t('score')}</h6>
            <input
              name="score__c"
              onChange={(e) => handleChange(e, 'Français')}
              value={
                _.find(
                  competences,
                  (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                )?.score__c
              }
            />
          </div>
          <div>
            <h6 className="form-h6">{t('frenchCertifOffi')}</h6>
            <UploadFile
              max={2}
              current={findMax(
                'language_degree',
                2,
                _.find(
                  competences,
                  (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                )?.Files
              )}
              onChange={(data) =>
                handleFileChange(
                  data,
                  'language_degree',
                  _.find(
                    competences,
                    (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                  )
                )
              }
              type="pdf"></UploadFile>
            <DisplayBlobFiles
              files={
                _.find(
                  competences,
                  (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                )?.fileList
              }
              type="language_degree"
              onDelete={(data) =>
                handleFileDelete(
                  data,
                  'language_degree',
                  'blob',
                  _.find(
                    competences,
                    (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                  )
                )
              }></DisplayBlobFiles>
            <DisplayFiles
              files={
                _.find(
                  competences,
                  (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                )?.Files
              }
              type="language_degree"
              onDelete={(data) =>
                handleFileDelete(
                  data,
                  'language_degree',
                  'uploaded',
                  _.find(
                    competences,
                    (o: CompetenceLinguistique) => o.langue1__c === 'Français'
                  )
                )
              }></DisplayFiles>
          </div>
        </React.Fragment>
      )}
      <hr></hr>
      {_.filter(
        competences,
        (o) => !['Anglais', 'Français'].includes(o.langue1__c)
      ).length > 0 && (
        <React.Fragment>
          <h5>{t('otherLanguages')}</h5>
          {_.chain(competences)
            .filter((o) => !['Anglais', 'Français'].includes(o.langue1__c))
            .map((competence, index) => {
              let formatedLanguage = { ...competence, index: index };
              return (
                <React.Fragment key={index}>
                  <h5>
                    {t('language')} N°{index + 1}
                  </h5>
                  <Competence
                    key={competence.id_heroku__c}
                    competence={formatedLanguage}
                    language={competence}
                    languages={languages}
                    handleDelete={handleDelete}
                    onChange={(data: any) =>
                      compChange(data, formatedLanguage)
                    }></Competence>
                </React.Fragment>
              );
            })
            .value()}
        </React.Fragment>
      )}
      <div className="add-button">
        <button onClick={addComp}>{t('addLanguage')}</button>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(Languages)
);
