/**
 * This components displays the choice to either start a new application or view/modify the candidate profile
 */
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory, useParams } from 'react-router-dom';

import './ProfileCreated.scss';

interface ContainerProps {
  handleClick: () => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const ProfileCreated: React.FC<IProps> = (props) => {
  const { t } = props;
  const history = useHistory();
  const {candidate_id} = useParams<{candidate_id: string}>();

  const routeChange = (path: string) => {
    history.push(path);
  };

  return (
    <div className="profile-created-container">
        <div>
          <button className="btn btn-main btn-fullwidth" onClick={() => routeChange(`/agent/${candidate_id}/create-application`)}>
            <span>{t('startNew')}</span>
          </button>
          <button className="btn btn-outlined btn-fullwidth" onClick={() => routeChange(`/agent/${candidate_id}/candidate-profile`)}>
            <span>{t('candidateProfile')}</span>
          </button>
        </div>
    </div>
  );
};

export default withRouter(withTranslation(['agent', 'common'])(ProfileCreated));
