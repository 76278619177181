import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { catchError, delayWhen, retryWhen, tap } from 'rxjs/operators';
const { NODE_ENV, REACT_APP_BACK_URL } = process.env;
import AlertUtil from '../../../utils/Alert/Alert';
import SpinnerUtil from '../../../utils/Spinner/Spinner';

import TranslationMiddleware from '../../middleware/TranslationMiddleware';

import API from '../';
import { Credentials } from '../api';
import { me } from '../Contact/interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;

class AuthService {
  isLoginSubject$ = new BehaviorSubject<boolean>(this.hasToken());

  auth = () => {
    axios.get(`${url}/test`);
    return true;
  };

  isLoggedIn(): Observable<boolean> {
    return this.isLoginSubject$.asObservable();
  }

  /**
   *  Login the user then tell all the subscribers about the new status
   */
  login(form: any): Observable<boolean> {
    SpinnerUtil.setSpinner(true);
    axios
      .post<Credentials, Credentials>(`${url}/auth`, form)
      .then((res: any) => {
        if (res.data.accessToken)
          localStorage.setItem('_cap_my-access-token', res.data.accessToken);
        this.isLoginSubject$.next(true);
        if (res.data.refreshToken)
          localStorage.setItem('_cap_my-refresh-token_', res.data.refreshToken);
        SpinnerUtil.setSpinner(false);
        AlertUtil.setAlert({
          open: true,
          type: 'success',
          duration: 2000,
          message: TranslationMiddleware.Translate('connected')
        });
      })
      .catch((err) => {
        this.isLoginSubject$.next(false);
        SpinnerUtil.setSpinner(false);
        AlertUtil.setAlert({
          open: true,
          type: 'error',
          duration: 2000,
          message: `Login failed: ${
            err.response && err.response.data
              ? err.response.data.message
                ? TranslationMiddleware.Translate(err.response.data.message)
                : err.response.data
              : 'error'
          }`
        });
      });

    return this.isLoggedIn();
  }

  /**
   * Log out the user then tell all the subscribers about the new status
   */
  logout(): Observable<boolean> {
    axios
      .post(`${url}/auth/logout`)
      .then((response) => {
        localStorage.removeItem('_cap_my-access-token');
        localStorage.removeItem('_cap_my-refresh-token_');
        this.isLoginSubject$.next(false);
        SpinnerUtil.setSpinner(false);
        AlertUtil.setAlert({
          open: true,
          type: 'success',
          duration: 2000,
          message: TranslationMiddleware.Translate('disconnected')
        });
      })
      .catch((err) => {
        SpinnerUtil.setSpinner(false);
        AlertUtil.setAlert({
          open: true,
          type: 'error',
          duration: 2000,
          message: `Login failed: ${
            err.response.data.message
              ? TranslationMiddleware.Translate(err.response.data.message)
              : err.response.data
          }`
        });
      });

    return this.isLoggedIn();
  }

  createPassword(form: any): Promise<any> {
    return axios.post(`${url}/auth/create_password`, form);
  }

  forgotPassword(form: any): Promise<any> {
    return axios.post(`${url}/auth/forgotPassword`, form);
  }

  resetPassword(form: any): Promise<any> {
    return axios.post(`${url}/auth/change_password`, form);
  }

  hasToken(): boolean {
    return !!localStorage.getItem('_cap_my-access-token');
  }

  getToken(): any {
    return localStorage.getItem('_cap_my-access-token');
  }
  setToken(token: string): any {
    return localStorage.setItem('_cap_my-access-token', token);
  }

  me(): Promise<AxiosResponse<me>> {
    return axios.get(`${url}/auth/me`);
  }
  getUsers(): Promise<any> {
    return axios.get(`${url}/users`);
  }

  refreshToken = async () => {
    return axios.post(`${url}/auth/refresh`);
  };
  getRefreshToken(): any {
    return localStorage.getItem('_cap_my-refresh-token_');
  }

  checkTokenValidity(): any {
    return axios.get(`${url}/admin/auth/checkToken`);
  }
}
export default new AuthService();
