import React, { useEffect, useState } from 'react';
import { setOriginalNode } from 'typescript';
import './SpinnerComponent.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import SpinnerUtil from '../../utils/Spinner/Spinner';
import { first, skip } from 'rxjs/operators';
import { Backdrop, CircularProgress, createStyles, Theme } from '@mui/material';

interface ContainerProps {
  backdrop: boolean;
}

type ChildProps = {};

interface IProps extends ContainerProps, ChildProps {}
const SpinnerComponent: React.FC<IProps> = (props) => {
  const { backdrop } = props;
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    return () => {};
  });
  SpinnerUtil.spinner$
    .pipe(skip(1))
    .pipe(first())
    .subscribe((value: boolean) => {
      setOpen(value);
    });
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className="Spinner">
        {backdrop ? (
          <Backdrop open={open} onClick={handleClose}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div className="loading">
            <CircularProgress color="primary" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SpinnerComponent;
