/**
 * This component displays a file for the jury to open tabs with files on big screen
 */
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  withRouter,
  RouteComponentProps,
  useParams,
  useHistory
} from 'react-router-dom';
import AuthService from '../../../../services/api/Auth/AuthService';
import { me } from '../../../../services/api/Contact/interface';
import ContentDocumentService from '../../../../services/api/ContentDocument/ContentDocumentService';
import Spinner from '../../../../utils/Spinner/Spinner';
import Alert from '../../../../utils/Alert/Alert';
import Return from '../../../../assets/images/junia-icon-fleche-3.png';
import './ContentDocument.scss';
import Constants from '../../../../constants/global';

interface ContainerProps {}

type ChildProps = {};

type UrlParams = {
  file_id: string;
  file_title: string;
  file_extension: string;
};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Files: React.FC<IProps> = (props) => {
  const { t } = props;
  const [me, setMe] = useState<me>();
  const [file, setFile] = useState<any>();
  const params = useParams<UrlParams>();
  const history = useHistory();

  useEffect(() => {
    getMe();
    downloadFile();
  }, []);

  const getMe = async (): Promise<void> => {
    try {
      Spinner.setSpinner(true);
      setMe((await AuthService.me())?.data);
    } catch (err) {
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
        history.goBack();
      }
    }
  };

  const downloadFile = async (): Promise<void> => {
    try {
      const file = await ContentDocumentService.getContentVersion(params.file_id);
      setFile({
        name: params.file_title+'.'+params.file_extension,
        b64source: file.data
      });
      Spinner.setSpinner(false);
    } catch (err) {
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
      }
      history.goBack();
    }
  };

  const goBack = () => {
    return history.goBack();
  };

  return (
    <div>
      <button className="grade-return" onClick={goBack}>
        <div className="return-icon">
          <img alt="retrun" src={Return} />
        </div>
        <span>{t('backToGrid')}</span>
      </button>
      {Constants.IMG_EXT.includes(file?.name?.split('.')?.pop() ?? '') ? (
        <>
          <img className="embed-img" src={file?.b64source} alt="fail to load" />
        </>
      ) : (
        <></>
      )}
      {Constants.DOC_EXT.includes(file?.name?.split('.')?.pop() ?? '') ? (
        <>
          <iframe
            className="embed-doc"
            title={file?.name}
            src={file?.b64source}></iframe>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default withRouter(withTranslation(['jury'])(Files));
