import axios, { AxiosResponse } from 'axios';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;

  class Booker25Service {
  getSlots(startTime: string, endTime: string): Promise<AxiosResponse> {
    return axios.get(`${url}/booker25?startTime=${startTime}&endTime=${endTime}`);
  }
  bookSlot(
    idReservation: string,
    idApplication: string
  ): Promise<AxiosResponse> {
    return axios.patch(
      `${url}/booker25?idReservation=${idReservation}&idApplication=${idApplication}`,
    );
  }
}
export default new Booker25Service();
