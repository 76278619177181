/**
 * This component displays the contact junia form and informations
 */
import React, {  useEffect, useState } from 'react';
import './Contact.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import AuthService from '../../services/api/Auth/AuthService';
import { me } from '../../services/api/Contact/interface';
interface ContainerProps {}
type ChildProps = {};
import casePicklist from '../../assets/data/case.json';
import { Case } from '../../services/api/Case/interface';
import _ from 'lodash';
import CaseService from '../../services/api/Case/CaseService';
import Spinner from '../../utils/Spinner/Spinner';
import Alert from '../../utils/Alert/Alert';

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Contact: React.FC<IProps> = (props) => {
  const { t } = props;
  const [me, setMe] = useState<me>({});
  const [form, setForm] = useState<Case>({});
  useEffect(() => {
    getMe();
  }, []);

  const history = useHistory();

  const routeChange = () => {
    let path = `faq`;
    history.push(path);
  };

  const getMe = () => {
    AuthService.me().then((res) => {
      setMe(res.data);
    });
  };

  const handleChange = (e: any) => {
    let newForm = { ...form };
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  };

  const send = (e: any) => {
    Spinner.setSpinner(true);
    CaseService.send(form)
      .then((res) => {
        Spinner.setSpinner(false);
        setForm({
          reason: '',
          description: ''
        });
        Alert.setAlert({
          message: t('contactSuccess'),
          duration: 2000,
          open: true,
          type: 'success'
        });
      })
      .catch((err) => {
        Spinner.setSpinner(false);

        console.log(
          '🚀 ~ file: Contact.tsx ~ line 51 ~ CaseService.send ~ err',
          err.response
        );
      });
  };

  return (
    <div className="contact general-container">
      <div className="contact-form-container">
        <h4>{t('contact')}</h4>
        <div className="contact-form">         
          {me.type_tech__c === 'Francais' && (
            <>
            <h5>{t('ask')}</h5>
              <div className="contact-form-div">
                <label htmlFor="theme">{t('theme')}</label>
                <select
                  name="reason"
                  value={form.reason}
                  onChange={handleChange}>
                  <option value={''}></option>
                  {_.chain(casePicklist.picklistFieldValues['Reason'].values)
                    .map((p) => {
                      if (p && p.value) {
                        return <option value={p.value}> {p.value} </option>;
                      }
                    })
                    .value()}
                </select>
              </div>

              <div className="contact-form-div">
                <label htmlFor="message">{t('message')}</label>
                <textarea
                  name="description"
                  value={form.description}
                  onChange={handleChange}></textarea>
              </div>
              <button className="btn btn-main btn-fullwidth" onClick={send}>
                <span>{t('word.send')}</span>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="contact-form-contact">
        <div className="contact-container">
          <div className="see-faq">
            <h5>{t('seeFAQ')}</h5>
            <button
              onClick={routeChange}
              className="btn btn-outlined btn-fullwidth">
              <span>{t('redirectFAQ')}</span>
            </button>
          </div>
          <div className="infos-contact">
            {me && (
              <React.Fragment>
                {me.type_tech__c === 'Francais' && (
                  <div className="contact-fr">
                    <div className="contact-card">
                      <p className="contact-title">Junia Lille</p>
                      <p>03 61 76 23 60</p>
                      <p>admissions.france@junia.com</p>
                      <span></span>
                    </div>
                    <div className="contact-card">
                      <p className="contact-title">
                        Junia Apprentissage Châteauroux
                      </p>
                      <p>02 18 47 50 10</p>
                      <p>manon.tini@junia.com</p>
                      <span></span>
                    </div>
                    <div className="contact-card">
                      <p className="contact-title">Junia Bordeaux</p>
                      <p>bordeaux.admissions@junia.com</p>
                      <span></span>
                    </div>
                  </div>
                )}
                {me.type_tech__c === 'International' && (
                  <div className="contact-int">
                    <p className="contact-title">International Admission</p>
                    <p>Sarah GOBERT</p>
                    <p>admission.international@junia.com</p>
                  </div>
                )}
                {me.type_tech__c === 'Echange' && (
                  <div className="contact-ech">
                    <p className="contact-title">
                      International Student Office
                    </p>
                    <p>incoming@junia.com</p>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['contact', 'common'])(Contact));
