import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory  } from 'react-router-dom';
import UploadFile from '../../../components/UploadFile/UploadFile';
import AuthService from '../../../services/api/Auth/AuthService';
import ContactService from '../../../services/api/Contact/ContactService';
import { Auth, Contact, me } from '../../../services/api/Contact/interface';
import { EducationHistory } from '../../../services/api/EducationHistory/interface';
import { PieceComplementaire } from '../../../services/api/Files/interface';
import CheckMiddleware from '../../../services/middleware/CheckMiddleware';
import {
  checkValid,
  inscriptionValidator
} from '../../../utils/validator/validator';

import './InscriptionConfirmed.scss';

interface ContainerProps {
  onChange: (data: any, canNext: boolean) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const InscriptionConfirmed: React.FC<IProps> = (props) => {
  const { t, onChange } = props;
  const missingFiles = {
    contact: [
      { name: 'resume', use: (data: any) => true },
      { name: 'identity_card', use: (data: any) => true },
      {
        name: 'scholarship',
        use: (contact: Contact) =>
          contact.boursier__c === 'Oui' && contact.type_tech__c === 'Francais'
            ? true
            : false
      }
    ],
    education_history: [
      {
        name: 'grades',
        use: (education_history: EducationHistory) =>
          education_history.type_de_diplome__c === 'Enseignement supérieur'
            ? true
            : false
      },
      {
        name: 'bac_transcript',
        use: (education_history: EducationHistory) =>
          education_history.type_de_diplome__c === 'Baccalauréat' ? true : false
      }
    ]
  };
  const [contact, setContact] = useState<Contact>();
  const [validate, setValidate] = useState<boolean>(false);
  const [bac, setBac] = useState<EducationHistory>({});
  const [me, setMe] = useState<me>({});
  const checkYear = CheckMiddleware.getSchoolYear();
  useEffect(() => {
    getMe();
  }, []);
  useEffect(() => {
    getContact();
  }, [me]);
  useEffect(() => {
    if (me.id_heroku__c) {
      ContactService.patchContact(me.id_heroku__c, {
        inscription_tech__c: 'Validée'
      }).then((res) => {
        setValidate(true);
      });
    }
  }, [me]);
  const getMe = async () => {
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };
  const getContact = async () => {
    if (me?.id_heroku__c) {
      try {
        let contactResponse = await ContactService.getContact(me?.id_heroku__c);
        let foundBac: EducationHistory = _.find(
          contactResponse.data?.EducationHistorys,
          (e) => e.type_de_diplome__c === 'Baccalauréat'
        );
        if (
          contactResponse.data.type_tech__c === 'Francais' &&
          foundBac &&
          foundBac.id_heroku__c
        ) {
          setBac(foundBac);
        }
        setContact(contactResponse.data);
      } catch (error) {
        console.log(
          '🚀 ~ file: MissingElement.tsx ~ line 84 ~ getContact ~ error',
          error
        );
      }
    }
  };
  const history = useHistory();

  const changeRoute = (path: string) => {
    if (validate) {
      history.push(path);
    }
  };
  const missingPieces = (files: PieceComplementaire[], require: any) => {
    let found = _.find(files, (r) => r.type__c === require.name);
    return found ? false : true;
  };
  const hasMissingPieces = (data: any, requireList: any) => {
    let hasmissing = false;
    _.map(requireList, (require) => {
      if (!hasmissing && require.use(data)) {
        hasmissing = missingPieces(data.Files, require);
      }
    });
    return hasmissing;
  };

  return (
    <div className="create-end">
      <div className="create-end-container">
        <div className="end-header">
          <h6>{t('created')}</h6>
          <p>{t('toStartApply')}</p>
          <div>
            <button
              onClick={() => changeRoute('profil')}
              className="btn btn-main btn-fullwidth">
              <span>{t('addMissingDocs')}</span>
            </button>
            <button
              onClick={() => changeRoute('home')}
              className="btn btn-outlined btn-fullwidth">
              <span>{t('reachCatalog')}</span>
            </button>
          </div>
        </div>
        <div className="end-missing-elements">
          <h6>{t('missingSummary')}</h6>
          <ul>
            {me.id_heroku__c &&
              contact?.id_heroku__c &&
              missingFiles.contact.map((require) => {
                if (contact.Files) {
                  let req = missingPieces(contact.Files, require);
                  if (req && require.use(contact)) {
                    return (
                      <li key={`missingDoc.${require.name}`}>
                        {t(`missingDoc.${require.name}`)}{' '}
                      </li>
                    );
                  }
                }
              })}
            {contact?.type_tech__c === 'Francais' &&
              bac &&
              bac.id_heroku__c &&
              hasMissingPieces(bac, missingFiles.education_history) && (
                <React.Fragment key={bac.id_heroku__c}>
                  <div className="diploma-name">
                    {t('Bac')} - {bac.option_du_bac__c}
                  </div>
                  {me.id_heroku__c &&
                    contact?.id_heroku__c &&
                    missingFiles.education_history.map((require) => {
                      if (bac.Files) {
                        let req = missingPieces(bac.Files, require);
                        if (req && require.use(bac)) {
                          return (
                            <li key={`missingDoc.${require.name}`}>
                              {t(`missingDoc.${require.name}`)}{' '}
                            </li>
                          );
                        }
                      }
                    })}
                </React.Fragment>
              )}
            {_.chain(contact?.EducationHistorys)
              .filter((e) => e.type_de_diplome__c === 'Enseignement supérieur')
              .map((educationHistory) => {
                if (
                  hasMissingPieces(
                    educationHistory,
                    missingFiles.education_history
                  )
                ) {
                  return (
                    <React.Fragment key={educationHistory.id_heroku__c}>
                      <div className="diploma-name">
                        {contact?.type_tech__c === 'Francais' && (
                          <>
                            {educationHistory?.intitule_de_formation__c} -{' '}
                            {educationHistory.specialite_domaine_fr__c}
                          </>
                        )}
                        {contact?.type_tech__c === 'Echange' && (
                          <>
                            {educationHistory.hed__degree_earned__c} -{' '}
                            {educationHistory.specialite__c}
                          </>
                        )}
                        {contact?.type_tech__c === 'International' && (
                          <>
                            {educationHistory?.intitul_de_formation_intl__c} -{' '}
                            {educationHistory.specialite__c}
                          </>
                        )}
                      </div>
                      {me.id_heroku__c &&
                        contact?.id_heroku__c &&
                        missingFiles.education_history.map((require) => {
                          if (educationHistory.Files) {
                            let req = missingPieces(
                              educationHistory.Files,
                              require
                            );
                            if (req && require.use(educationHistory)) {
                              return (
                                <li key={`missingDoc.${require.name}`}>
                                  {t(`missingDoc.${require.name}`)}{' '}
                                </li>
                              );
                            }
                          }
                        })}
                    </React.Fragment>
                  );
                }
              })
              .value()}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common'])(InscriptionConfirmed)
);
