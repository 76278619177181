import React, { Children, ReactElement, useEffect, useState } from 'react';
import './Modal.scss';
import CloseIcon from '@mui/icons-material/Close';
import { withTranslation, WithTranslation } from 'react-i18next';
import _ from 'lodash';
import PaymentService, {
  PaymentFields,
  Transaction
} from '../../services/api/Payment/PaymentService';
import { Dialog } from '@mui/material';

interface ContainerProps {
  title: string;
  modalIsOpen: boolean;
  classe: string;
  closeModal: () => void;
  children?: ReactElement[];
}

type ChildProps = {};

interface IProps extends ContainerProps, ChildProps, WithTranslation {}
const Modal: React.FC<IProps> = (props) => {
  const { t, modalIsOpen, closeModal, title, classe, children } = props;

  useEffect(() => {}, []);

  return (
    <Dialog
      open={modalIsOpen}
      className={classe}
      disableEnforceFocus
      fullWidth
      maxWidth={'lg'}>
      <div className="modal-header">
        <div className="modal-title">
          <h3>{title}</h3>
        </div>
        <div className="modal-close">
          <button className="btn-close" onClick={closeModal}>
            <CloseIcon></CloseIcon>
          </button>
        </div>
      </div>
      <div className="modal-content">{children}</div>
    </Dialog>
  );
};

export default withTranslation(['modal'])(Modal);
