/**
 * This component contains logic to display the curus forms (Bac & Higher degrees)
 */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Contact } from '../../../../services/api/Contact/interface';
import EducationHistoryService from '../../../../services/api/EducationHistory/EducationHistoryService';
import { EducationHistory } from '../../../../services/api/EducationHistory/interface';

import './Cursus.scss';
import CursusForm from './CursusForm/CursusForm';
import { checkRequested } from '../../../../utils/validator/validator';
import Baccalaureat from './Baccalaureat/Baccalaureat';
interface ContainerProps {
  contact: Contact;
  onChange: (data: any, canNext: boolean) => void;
  getContact?: () => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Cursus: React.FC<IProps> = (props) => {
  const { t, contact, onChange, getContact } = props;
  const required: any = {
    Francais: [
      'annee_scolaire__c',
      'intitule_de_formation__c',
      'annee_scolaire__c',
      'formation_suivie_par_apprentissage__c',
      'hed__educational_institution_name__c'
    ],
    International: [
      'specialite__c',
      'annee_niveau__c',
      'pays_de_l_tablissement__c',
      'hed__educational_institution_name__c',
      'langue_d_enseignement__c'
    ],
    Echange: [
      'specialite__c',
      'annee_niveau__c',
      'en_cours_ou_validee__c',
      'hed__educational_institution_name__c',
      'langue_d_enseignement__c',
      'pays_de_l_tablissement__c',
      'hed__degree_earned__c'
    ],
    bac: [
      'type_de_bac__c',
      'option_du_bac__c',
      'mention_du_bac__c',
      'annee_d_obtention_du_bac__c',
      'academie__c'
    ],
    internationalBac: [
      'annee_scolaire__c',
      'type_de_bac_intl__c',
      'niveau_enseignement_secondaire__c'
    ]
  };
  const [form, setForm] = useState<Contact>(contact);
  const [years, setYears] = useState<string[]>([]);
  const [educationHistoryList, setEducationHistoryList] = useState<
    EducationHistory[]
  >([]);
  const [showIntEducationSelection, setShowIntEducationSelection] =
    useState<Boolean>(true);

  useEffect(() => {
    getDates();
    getEducationHistory();

    return () => {
      setForm({});
    };
  }, []);
  useEffect(() => {
    setForm(contact);
  }, [contact]);

  const getDates = () => {
    let given = parseInt(moment().format('YYYY'));
    let diff = given - 1901;
    let array = [''];
    for (let index = 0; index <= diff; index++) {
      let year = given - index;
      array.push(year.toString());
    }
    setYears(array);
  };

  const handleDelete = (cursus: EducationHistory) => {
    if (cursus.id_heroku__c) {
      //delete from bdd
      if (contact.id_heroku__c && cursus.id_heroku__c) {
        EducationHistoryService.remove(
          contact.id_heroku__c,
          cursus?.id_heroku__c
        ).then((res) => {
          getEducationHistory();
        });
      }
    } else {
      let cursusList = [...educationHistoryList];
      let index = _.findIndex(
        cursusList,
        (t) => t.cursusId === cursus.cursusId
      );
      cursusList.splice(index, 1);
      if (contact.type_tech__c === 'International' && cursusList.length < 1) {
        setShowIntEducationSelection(true);
      }
      setEducationHistoryList(cursusList);
      let canNext = checkCursus(cursusList);
      onChange({ cursus: cursusList }, canNext);
    }
  };

  const getEducationHistory = async () => {
    if (contact && contact.id_heroku__c) {
      let educationHistoryResponse =
        await EducationHistoryService.getContactEducationHistory(
          contact.id_heroku__c
        );
      let cursusList: EducationHistory[] = educationHistoryResponse.data;
      let hasbac = _.find(
        cursusList,
        (cursus) => cursus.type_de_diplome__c === 'Baccalauréat'
      );
      let newList = [];
      let bac: EducationHistory;
      if (contact.type_tech__c === 'Francais' && !hasbac) {
        newList.push({
          cursusId: newList.length + 1,
          type_de_diplome__c: 'Baccalauréat'
        });
      }
      let hasHistory = _.find(
        cursusList,
        (cursus) => cursus.type_de_diplome__c === 'Enseignement supérieur'
      );
      let history: any = {};
      if (!hasHistory && contact.type_tech__c !== 'International') {
        history = {
          cursusId: newList.length + 1,
          type_de_diplome__c: 'Enseignement supérieur'
        };
      }
      if (cursusList.length > 0) {
        // Format list and sort by diplome type
        let formateList = cursusList
          .map((cursus, index) => {
            return { ...cursus, cursusId: index + 1 };
          })
          .sort((a, b) => {
            let diplomeTypeA = a.type_de_diplome__c ?? 0;
            let diplomeTypeB = b.type_de_diplome__c ?? 0;
            if (diplomeTypeA > diplomeTypeB) {
              return 1;
            }
            if (diplomeTypeA < diplomeTypeB) {
              return -1;
            }
            return 0;
          });
        if (!hasHistory && contact.type_tech__c !== 'International') {
          formateList.push({
            cursusId: formateList.length + 1,
            type_de_diplome__c: 'Enseignement supérieur'
          });
        }
        if (contact.type_tech__c === 'Francais' && !hasbac) {
          formateList.push({
            cursusId: newList.length + 1,
            type_de_diplome__c: 'Baccalauréat'
          });
        }
        let canNext = checkCursus(formateList);

        onChange({ cursus: formateList }, canNext);
        setEducationHistoryList([...formateList]);
        setShowIntEducationSelection(false);
      } else {
        if (!hasHistory && contact.type_tech__c !== 'International') {
          newList.push({
            cursusId: newList.length + 1,
            type_de_diplome__c: 'Enseignement supérieur'
          });
        }
        let canNext = checkCursus(newList);
        onChange({ cursus: newList }, canNext);
        setEducationHistoryList([...newList]);
      }
    }
  };

  const checkCursus = (list: EducationHistory[]) => {
    let canNext = true;
    if (list.length > 0) {
      let nexEducationHistoryList = _.map(list, (o: EducationHistory) => {
        if (contact.type_tech__c) {
          let requiredList;
          if (o.type_de_diplome__c === 'Enseignement supérieur') {
            requiredList = required[contact.type_tech__c];
          } else if (
            o.type_de_diplome__c === 'Baccalauréat' &&
            contact.type_tech__c === 'Francais'
          ) {
            requiredList = required.bac;
          } else if (
            o.type_de_diplome__c === 'Baccalauréat' &&
            contact.type_tech__c === 'International'
          ) {
            requiredList = required.internationalBac;
            if (o.niveau_enseignement_secondaire__c === '12th year') {
              requiredList.push('en_cours_ou_validee__c')
              requiredList.push('annee_d_obtention__c')
            }
          }
          if (o.type_de_diplome__c === 'Enseignement supérieur') {
            if (
              requiredList.includes('hed__degree_earned__c') &&
              o.hed__degree_earned__c === 'Other'
            ) {
              requiredList.push('autre_diplome__c');
            }
            if (o.noInstitution) {
              requiredList.push('ville_etablissement_fr__c');
            }
          } else {
            if (o.option_du_bac__c === 'Général (depuis 2021)') {
              requiredList.push('specialites_du_bac__c');
            }
          }
          if (canNext) {
            canNext = checkRequested(requiredList, o);
          }
        }
      });
    } else {
      canNext = false;
    }

    return canNext;
  };

  const handleCursusChange = async (id: number, cursus: any, next: boolean) => {
    let list = [...educationHistoryList];
    let canNext = true;
    let nexEducationHistoryList = await _.map(list, (educationHistory: EducationHistory) => {
      if (contact.type_tech__c) {
        if (educationHistory.cursusId === cursus.data.cursusId) {
          let requires;
          if (educationHistory.type_de_diplome__c === 'Enseignement supérieur') {
            requires = required[contact.type_tech__c];
          } else if (
            educationHistory.type_de_diplome__c === 'Baccalauréat' &&
            contact.type_tech__c === 'Francais'
          ) {
            requires = required.bac;
          } else if (
            educationHistory.type_de_diplome__c === 'Baccalauréat' &&
            contact.type_tech__c === 'International'
          ) {
            requires = required.internationalBac;
            if (educationHistory.niveau_enseignement_secondaire__c === '12th year') {
              requires.push('en_cours_ou_validee__c')
              requires.push('annee_d_obtention__c')
            }
          }
          if (cursus.data.type_de_diplome__c === 'Enseignement supérieur') {
            if (
              requires.includes('hed__degree_earned__c') &&
              cursus.data.hed__degree_earned__c === 'Other'
            ) {
              requires.push('autre_diplome__c');
            }
            if (cursus.data.noInstitution) {
              requires.push('ville_etablissement_fr__c');
            }
            if (
              cursus.data.intitule_de_formation__c !==
                'Je suis déjà étudiant JUNIA' &&
              contact.type_tech__c === 'Francais'
            ) {
              requires.push('niveau__c');
              requires.push('specialite_domaine_fr__c');
            }
            if (
              cursus.data.intitule_de_formation__c ===
                'Je suis déjà étudiant JUNIA' &&
              contact.type_tech__c === 'Francais'
            ) {
              requires.push('formation_en_cours_junia__c');
            }
          } else {
            if (cursus.data.option_du_bac__c === 'Général (depuis 2021)') {
              requires.push('specialites_du_bac__c');
            }
          }
          if (canNext) {
            canNext = checkRequested(requires, cursus.data);
          }
          return { ...cursus.data, fileList: cursus.files };
        } else {
          let requires;
          if (educationHistory.type_de_diplome__c === 'Enseignement supérieur') {
            requires = required[contact.type_tech__c];
          } else if (
            educationHistory.type_de_diplome__c === 'Baccalauréat' &&
            contact.type_tech__c === 'Francais'
          ) {
            requires = required.bac;
          } else if (
            educationHistory.type_de_diplome__c === 'Baccalauréat' &&
            contact.type_tech__c === 'International'
          ) {
            requires = required.internationalBac;
            if (educationHistory.niveau_enseignement_secondaire__c === '12th year') {
              requires.push('en_cours_ou_validee__c')
              requires.push('annee_d_obtention__c')
            }
          }
          if (educationHistory.type_de_diplome__c === 'Enseignement supérieur') {
            if (
              requires.includes('hed__degree_earned__c') &&
              educationHistory.hed__degree_earned__c === 'Other'
            ) {
              requires.push('autre_diplome__c');
            }
            if (educationHistory.noInstitution) {
              requires.push('ville_etablissement_fr__c');
            }
            if (
              educationHistory.intitule_de_formation__c !== 'Je suis déjà étudiant JUNIA' &&
              contact.type_tech__c === 'Francais'
            ) {
              requires.push('niveau__c');
              requires.push('specialite_domaine_fr__c');
            }
            if (
              educationHistory.intitule_de_formation__c ===
                'Je suis déjà étudiant JUNIA' &&
              contact.type_tech__c === 'Francais'
            ) {
              requires.push('formation_en_cours_junia__c');
            }
          } else {
            if (educationHistory.option_du_bac__c === 'Général (depuis 2021)') {
              requires.push('specialites_du_bac__c');
            }
          }
          if (canNext) {
            canNext = checkRequested(requires, educationHistory);
          }
          return educationHistory;
        }
      }
    });
    setEducationHistoryList(nexEducationHistoryList);

    onChange({ cursus: nexEducationHistoryList }, canNext);
  };

  const addEducationHistory = (e: any) => {
    if (contact.type_tech__c === 'International') {
      setShowIntEducationSelection(true);
    } else {
      let list = [...educationHistoryList];
      list.push({
        cursusId: list.length + 1,
        type_de_diplome__c: 'Enseignement supérieur'
      });
      setEducationHistoryList(list);
      let canNext = checkCursus(list);
      onChange({ data: form }, canNext);
    }
  };

  const handleGet = () => {
    if (getContact) getContact();
    getEducationHistory();
  };

  const getBac = () => {
    let bac = _.find(
      educationHistoryList,
      (ed) => ed.type_de_diplome__c === 'Baccalauréat'
    );
    if (bac) {
      return bac;
    } else {
      return { cursusId: educationHistoryList.length + 1 };
    }
  };

  const handleInternationalEducationChange = (value: string) => {
    let newList = [...educationHistoryList];
    if (value === 'Baccalauréat') {
      newList.push({
        cursusId: newList.length + 1,
        type_de_diplome__c: value
      });
    } else {
      newList.push({
        cursusId: newList.length + 1,
        type_de_diplome__c: value
      });
    }

    let canNext = checkCursus(newList);
    onChange({ cursus: newList }, canNext);
    setEducationHistoryList([...newList]);
    setShowIntEducationSelection(false);
  };

  return (
    <div>
      {/* FRANCAIS BACCALAUREAT */}
      {contact.type_tech__c === 'Francais' && (
        <div>
          {educationHistoryList.length > 0 &&
            getBac() &&
            getBac().cursusId &&
            getBac().cursusId !== undefined && (
              <Baccalaureat
                contactdata={contact}
                data={getBac()}
                getContact={handleGet}
                onChange={(data: any, next: boolean) =>
                  handleCursusChange(getBac().cursusId || 1, data, next)
                }></Baccalaureat>
            )}
          <p>{t('higherEducationSentence')}</p>
        </div>
      )}
      {/* FRANCAIS AND ECHANGE CANDIDATE EDUCATION SUPERIEUR */}
      {contact.type_tech__c !== 'International' &&
        educationHistoryList.length > 0 && (
          <React.Fragment>
            {_.chain(educationHistoryList)
              .filter(
                (ed) => ed.type_de_diplome__c === 'Enseignement supérieur'
              )
              .map((educationHistory, index) => {
                let formatedCursus = { ...educationHistory, index: index };
                return (
                  <React.Fragment key={`cursus-${index}`}>
                    {index > 0 ? (
                      <CursusForm
                        contactdata={contact}
                        cursus={formatedCursus}
                        getContact={handleGet}
                        onChange={(data: any, next: boolean) =>
                          handleCursusChange(index, data, next)
                        }
                        handleDelete={handleDelete}></CursusForm>
                    ) : (
                      <CursusForm
                        contactdata={contact}
                        cursus={formatedCursus}
                        getContact={handleGet}
                        onChange={(data: any, next: boolean) =>
                          handleCursusChange(index, data, next)
                        }></CursusForm>
                    )}
                  </React.Fragment>
                );
              })
              .value()}
            <div className="add-button">
              {' '}
              <button onClick={addEducationHistory}>
                {t('addHigherEducation')}
              </button>
            </div>
          </React.Fragment>
        )}

      {/* INTERNATIONAL CANDIDATE */}
      {contact.type_tech__c === 'International' && (
        <div>
          {educationHistoryList.length > 0 && (
            <>
              {educationHistoryList.map((educationHistory, index) => {
                if (educationHistory.type_de_diplome__c === 'Baccalauréat') {
                  let formatedCursus = { ...educationHistory, index: index };
                  return (
                    <div key={`cursus-${index}`}>
                      <Baccalaureat
                        contactdata={contact}
                        data={formatedCursus}
                        getContact={handleGet}
                        onChange={(data: any, next: boolean) =>
                          handleCursusChange(index, data, next)
                        }
                        handleDelete={handleDelete}></Baccalaureat>
                      <hr></hr>
                    </div>
                  );
                } else {
                  let formatedCursus = { ...educationHistory, index: index };
                  return (
                    <React.Fragment key={`cursus-${index}`}>
                      <CursusForm
                        contactdata={contact}
                        cursus={formatedCursus}
                        getContact={handleGet}
                        onChange={(data: any, next: boolean) =>
                          handleCursusChange(index, data, next)
                        }
                        handleDelete={handleDelete}></CursusForm>
                    </React.Fragment>
                  );
                }
              })}
              <div className="add-button">
                <button onClick={addEducationHistory}>
                  {t('addHigherEducation')}
                </button>
              </div>
            </>
          )}

          {showIntEducationSelection && (
            <>
              <div>
                <p className="info-text">{t('international_candidate_education_selection_1')}</p>
                <p className="info-text">{t('international_candidate_education_selection_2')}</p>
                <p className="info-text">{t('international_candidate_education_selection_3')}</p>
              </div>
              <div className="international-education-selection">
                <button
                  className="card-button"
                  type="button"
                  onClick={() =>
                    handleInternationalEducationChange('Baccalauréat')
                  }>
                  {t('secondaryEducation')}
                </button>
                <button
                  className="card-button"
                  type="button"
                  onClick={() =>
                    handleInternationalEducationChange('Enseignement supérieur')
                  }>
                  {t('higherEducation')}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(Cursus)
);
