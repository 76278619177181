/* 
  This service is used to interact with the Candidature routes of the API server
*/

import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import { FileToUpload } from '../Files/interface';
import { Application_hed } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class CandidatureService {
  /**
   * Get all applications of a user
   * @param {string} user_id the user id
   * @returns all user's applications
   */
  getCandidatures(user_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/${user_id}/applications`);
  }

  /**
   * Get a specific application of a user
   * @param {string} user_id the user id
   * @param {string} application_id  the application id
   * @returns a user's application
   */
  getCandidature(
    user_id: string,
    application_id: string
  ): Promise<AxiosResponse> {
    return axios.get(`${url}/${user_id}/application/${application_id}`);
  }

  /**
   * Get all applications and related agents
   * @returns all applications with related agents
   */
  getCandidaturesAgent(): Promise<AxiosResponse> {
    return axios.get(`${url}/applications/agent`);
  }

  /**
   * Get all the candidats related to the agent
   * @returns list of candidats related to the agent
   */
  getCandidatsAgent(): Promise<AxiosResponse> {
    return axios.get(`${url}/agent/candidats`);
  }

  /**
   * Get an application based on a formation
   * @param {string} user_id the user id
   * @param {string} formation the formation where find the user application
   * @returns the application 
   */
  checkCandidatureExistOnFormation(
    user_id: string,
    formation: string
  ): Promise<AxiosResponse> {
    return axios.get(`${url}/${user_id}/check_application/${formation}`);
  }

  /**
   * Creates an application
   * @param {string} user_id the user id
   * @param {object} candidature the application data
   * @returns the new created application
   */
  createCandidature(
    user_id: string,
    candidature: Application_hed
  ): Promise<AxiosResponse> {
    return axios.post(`${url}/${user_id}/application`, candidature);
  }

  /**
   * Cancels an application
   * @param {string} user_id the user id
   * @param {string} candidature_id the application id
   * @returns the canceled application
   */
  cancelCandidature(
    user_id: string,
    candidature_id: string
  ): Promise<AxiosResponse> {
    return axios.patch(`${url}/${user_id}/application/${candidature_id}/cancel`);
  }

  /**
   * Accepts an application
   * @param {string} user_id the user id
   * @param {string} candidature_id the application id
   * @returns a string "application"
   */
  acceptCandidature(
    user_id: string,
    candidature_id: string
  ): Promise<AxiosResponse> {
    return axios.patch(`${url}/${user_id}/application/${candidature_id}/accept`);
  }

  /**
   * Declines an application
   * @param {string} user_id the user id
   * @param {string} candidature_id the application id
   * @returns the declined application
   */
  declineCandidature(
    user_id: string,
    candidature_id: string
  ): Promise<AxiosResponse> {
    return axios.patch(`${url}/${user_id}/application/${candidature_id}/decline`);
  }

  /**
   * Get all applications that a user has accepted
   * @param {string} user_id the user id
   * @returns the applications that the user has accepted
   */
  getHasAccepted (user_id: string): Promise<AxiosResponse>{
    return axios.get(`${url}/${user_id}/check_has_accepted`)
  }

  /**
   * Updates an application of a user
   * @param {string} user_id the user id
   * @param {object} candidature the application datas
   * @param {object} files the related files
   * @param {string} fileList a string file list
   * @returns the updated application
   */
  patchCandidature(
    user_id: string,
    candidature: Application_hed,
    files?: FileToUpload[],
    fileList?: string[]
  ): Promise<AxiosResponse> {
    let formdata = new FormData();
    if (files && files.length > 0 && fileList && fileList.length > 0) {
      _.map(files, (file) => {
        if (fileList.includes(file.type)) {
          _.map(file.files, (f) => {
            formdata.append(file.type, f);
          });
        }
      });
    }
    _.map(candidature, (value: any, key: any) => {
      if (value !== null) formdata.append(key, value);
    });
    return axios.patch(
      `${url}/${user_id}/application/${candidature.id_heroku__c}`,
      formdata
    );
  }
}
export default new CandidatureService();
