import React, { createElement, useEffect, useState } from 'react';
import './ChooseDate.scss';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { 
  withRouter,
  RouteComponentProps,
  useHistory,
  useParams } from 'react-router-dom';
import WhiteLogo from '../../../../assets/images/logo-junia.png';
import Accordion from '../../../../components/Accordion/Accordion';

import { me } from '../../../../services/api/Contact/interface';
import AuthService from '../../../../services/api/Auth/AuthService';
import Booker25Service from '../../../../services/api/Booker25/Booker25Service';
import {
  Booker25,
  Booker25Value
} from '../../../../services/api/Booker25/interface';
import _ from 'lodash';
import Spinner from '../../../../utils/Spinner/Spinner';
import Alert from '../../../../utils/Alert/Alert';
import { Application_hed } from '../../../../services/api/Candidature/interface';
import CandidatureService from '../../../../services/api/Candidature/CandidatureService';
import ChooseDateConfirm from '../ChooseDateConfirm/ChooseDateConfirm';

interface ContainerProps {}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const ChooseDate: React.FC<IProps> = (props) => {
  const { t } = props;
  const history = useHistory();
  const [me, setMe] = useState<me>({});
  const [booker25List, setBooker25List] = useState<Booker25[]>([]);
  const [checked_id, setCheckedId] = useState<string>();
  const [formationData, setFormationData] = useState<Application_hed>({});
  let { formation } = useParams<{ formation: string }>();
  const [booked, setBooked] = useState<boolean>(false);
  const [reservation, setReservation] = useState<Booker25Value>({});

  useEffect(() => {
    if (formation) {
      getMe();
    } else {
      history.push('/application');
    }
  }, []);
  useEffect(() => {
    getSlots();
    getFormation();
  }, [me]);

  const getMe = async () => {
    Spinner.setSpinner(true);
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };

  const getFormation = async () => {
    if (me.id_heroku__c) {
      let applicationResponse = await CandidatureService.getCandidature(
        me.id_heroku__c,
        formation
      );
      let data: Application_hed = applicationResponse.data;
      if (data.statut__c === 'Entretien') {
        setFormationData(data);
      } else {
        history.push('/application');
      }
    }
  };
  const getSlots = async () => {
    let startDate = `${moment().format('yyyy')}-01-01`;
    let endDate = `${moment().format('yyyy')}-12-31`;
    if (me?.id_heroku__c) {
      let booker25Response = await Booker25Service.getSlots(startDate, endDate);
      setBooker25List(booker25Response.data);
    }
    Spinner.setSpinner(false);
  };

  const handleChange = (e: any): void => {
    setCheckedId(e.target.value);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (checked_id && checked_id != '') {
      Spinner.setSpinner(true);
      Booker25Service.bookSlot(checked_id, formationData.id_heroku__c || '')
        .then((res) => {
          setReservation(res.data);
          Spinner.setSpinner(false);
          setBooked(true);
          Alert.setAlert({
            type: 'success',
            duration: 3000,
            message: 'Créneaux réservé',
            open: true
          });
        })
        .catch((err) => {
          console.log(err);
          Spinner.setSpinner(false);
          if (err instanceof Error) {
            Alert.setAlert({
              type: 'error',
              duration: 3000,
              message: err.message,
              open: true
            });
            console.log(err.stack);
          }
        });
    }
    // Redirect next ici ROBIN
  };

/*

  return (
    <React.Fragment>
      {!booked ? (
        <React.Fragment>
          <div className="choose-date general-container">
            <div className="choose-left">
              <div className="white-logo">
                <img alt="junia logo" src={WhiteLogo} />
              </div>
              <div>
                <h6>{t('meetingRules.meetingTitle1')}</h6>
                <p>{t('meetingRules.meetingText1')}</p>
              </div>
              <div>
                <h6>{t('meetingRules.meetingTitle2')}</h6>
                {formationData?.Formation?.cole__c === 'HEI' &&
                  formationData?.Formation?.campus__c === 'Lille' && (
                    <>
                      <p>Junia HEI Lille - Ecole d&apos;ingénieur</p>
                      <p>13 Rue de Toul, 59000 Lille</p>
                    </>
                  )}
                {formationData?.Formation?.cole__c === 'HEI' &&
                  formationData?.Formation?.campus__c === 'Châteauroux' && (
                    <>
                      <p>JUNIA HEI Châteauroux</p>
                      <p>2 All. Jean Vaillé, 36000 Châteauroux</p>
                    </>
                  )}
                {formationData?.Formation?.cole__c === 'ISA' && (
                  <>
                    <p>Junia ISA Lille</p>
                    <p>2 Rue Norbert Segard, 59800 Lille</p>
                  </>
                )}
                {formationData?.Formation?.cole__c === 'ISEN' && (
                  <>
                    <p>Junia ISEN Lille</p>
                    <p>41 Bd Vauban, 59800 Lille</p>
                  </>
                )}
                {formationData?.Formation?.cole__c === 'JUNIA' &&
                  formationData?.Formation?.campus__c === 'Bordeaux' && (
                    <>
                      <p>JUNIA - École d&apos;Ingénieurs Bordeaux</p>
                      <p>BT, 10 Rue des Terres Neuves, 33130 Bègles</p>
                    </>
                  )}
              </div>
              <div>
                <h6>{t('meetingRules.meetingTitle3')}</h6>
                <p>{t('meetingRules.meetingText3p1')}</p>
                <p>{t('meetingRules.meetingText3p2')}</p>
                <p>{t('meetingRules.meetingText3p3')}</p>
              </div>
              <div>
                <h6>{t('meetingRules.meetingTitle4')}</h6>
                <p>{formationData.Formation?.name} </p>
              </div>
            </div>
            <div className="choose-right">
              <p>{t('applyMeeting')}</p>
              <h5>
                {t('chooseYourDate')}
                <span></span>
              </h5>
              <div className="calendar">
                {booker25List.length > 0 &&
                  _.chain(booker25List)
                    .map((booker25: Booker25) => {
                      return (
                        <Accordion
                          name={moment(booker25.date).format('dddd DD MMMM')}
                          type="calendar">
                          {booker25.slots.length > 0 ?
                            _.chain(booker25.slots)
                              .map((slot) => {
                                return (
                                  <div className="hours">
                                    <input
                                      type="checkbox"
                                      className="hour"
                                      value={slot.id}
                                      id={slot.id}
                                      checked={checked_id === slot.id}
                                      onChange={handleChange}
                                    />
                                    <label
                                      htmlFor={slot.id}
                                      className={`${
                                        checked_id === slot.id
                                          ? 'hourSelected'
                                          : ''
                                      }`}>
                                      {slot.b25__local_start_time__c} -{' '}
                                      {slot.b25__local_end_time__c}
                                    </label>
                                  </div>
                                );
                              })
                              .value() : ''
                            }
                        </Accordion>
                      );
                    })
                    .value()}
                {booker25List.length <= 0 && (
                  <div className="no-date-avi">
                    <p>
                      <i>{t('noDate')}</i>
                    </p>
                  </div>
                )}
              </div>
              <div className="calendar">
                {booker25List && booker25List.length == 0 && (
                  <p>Pas de créneaux disponible pour le moment</p>
                )}
              </div>
              <div className="valide-date">
                {checked_id ? (
                  <button
                    className="btn btn-main btn-fullwidth"
                    onClick={handleSubmit}>
                    <span>{t('valideDate')}</span>
                  </button>
                ) : (
                  <button className="btn btn-disabled btn-fullwidth">
                    <span>{t('valideDate')}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ChooseDateConfirm reservation={reservation}></ChooseDateConfirm>{' '}
        </React.Fragment>
      )}
    </React.Fragment>
  );
  */

  return (
    <div></div>
  );
};

export default withRouter(
  withTranslation(['application', 'common'])(ChooseDate)
);
