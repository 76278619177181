/**
 * This component displays the professional experiences form
 */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Contact } from '../../../../../services/api/Contact/interface';
import { Experience } from '../../../../../services/api/Experience/interface';
import contactPicklist from '../../../../../assets/data/contact_picklist.json';
import experienceProPicklist from '../../../../../assets/data/experience_pro.json';

import './ExperienceForm.scss';

interface ContainerProps {
  experience: Experience;
  contactData: Contact;
  onChange: (data: any) => void;
  handleDelete?: (data: Experience) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const ExperienceForm: React.FC<IProps> = (props) => {
  const { t, experience, onChange, contactData, handleDelete } = props;
  const { i18n } = useTranslation();

  const [form, setForm] = useState<Experience>(experience);
  const [contact, setContact] = useState<Contact>(contactData);

  const [expTypes, setExpTypes] = useState<string[]>([
    '',
    'Stage',
    'Emploi',
    'Alternance'
  ]);

  const [years, setYears] = useState<string[]>([]);

  useEffect(() => {
    setForm(experience);
  }, [experience.index]);

  useEffect(() => {
    buildYears();
  }, []);

  const buildYears = () => {
    let given = parseInt(moment().format('YYYY'));
    let diff = given - 1901;
    let array = [''];
    for (let index = 0; index <= diff; index++) {
      let year = given - index;
      array.push(year.toString());
    }
    setYears(array);
  };
  const handleChange = (e: any): void => {
    let newForm = {
      ...form,
      [e.target.name]: e.target.value
    };
    setForm(newForm);
    onChange(newForm);
  };
  const onDelete = () => {
    if (handleDelete) {
      handleDelete(form);
    }
  };

  return (
    <div>
      <div>
        <h6 className="form-h6">{t('type')}</h6>
        <select
          value={form.type_d_exp_rience__c}
          name="type_d_exp_rience__c"
          onBlur={handleChange}
          onChange={handleChange}>
          {i18n.language === 'fr' &&
            _.chain(
              experienceProPicklist.picklistFieldValues['Type_d_exp_rience__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value} key={p.value}> {p.label} </option>;
                }
              })
              .value()}
          {i18n.language === 'en' &&
            _.chain(
              experienceProPicklist.picklistFieldValues['Type_d_exp_rience__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value} key={p.value}> {p.value} </option>;
                }
              })
              .value()}
        </select>
      </div>
      <div>
        <h6 className="form-h6">{t('year')}</h6>
        <select
          value={form.annee_new__c}
          name="annee_new__c"
          onBlur={handleChange}
          onChange={handleChange}>
          {years.length > 0 &&
            _.map(years, (year) => {
              return (
                <option key={year} value={year}>
                  {' '}
                  {year}{' '}
                </option>
              );
            })}
        </select>
      </div>
      <div>
        <h6 className="form-h6">{t('duration')}</h6>
        <input
          value={form.duree__c}
          name="duree__c"
          onChange={handleChange}></input>
      </div>
      <div>
        <h6 className="form-h6">{t('employerName')}</h6>
        <input
          value={form.employeur__c}
          name="employeur__c"
          onChange={handleChange}
        />
      </div>
      {contact.type_tech__c === 'Francais' && (
        <div>
          <h6 className="form-h6">{t('city')}</h6>
          <input
            value={form.ville__c}
            onChange={handleChange}
            name="ville__c"
          />
        </div>
      )}
      <div>
        <h6 className="form-h6">{t('country')}</h6>
        <select
          name="pays__c"
          value={form.pays__c}
          onBlur={handleChange}
          onChange={handleChange}>
          <option value={''}></option>
          {i18n.language === 'fr' &&
            _.chain(
              contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value.replace(/&#39;/g, "'")} key={p.value}> {p.label.replace(/&#39;/g, "'")} </option>;
                }
              })
              .value()}
          {i18n.language === 'en' &&
            _.chain(
              contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value.replace(/&#39;/g, "'")} key={p.value}> {p.value.replace(/&#39;/g, "'")} </option>;
                }
              })
              .value()}
        </select>
      </div>
      {contact.type_tech__c !== 'International' && (
        <div>
          <h6 className="form-h6">{t('mission')}</h6>
          <input
            value={form.mission__c}
            name="mission__c"
            onChange={handleChange}
          />
        </div>
      )}
      {handleDelete && (
        <div className="delete-line">
          <button onClick={onDelete} className="btn-underline">
            <span>{t('word.delete')}</span>
          </button>
        </div>
      )}
      <hr></hr>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(ExperienceForm)
);
