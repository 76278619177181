/**
 * This component displays the exchange choice form for an exchange candidate
 */
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import countryCodes from '../../../../assets/data/country_code.json';
import './ExchangeChoice.scss';
import { Contact } from '../../../../services/api/Contact/interface';
import _ from 'lodash';
import { checkRequested } from '../../../../utils/validator/validator';
import FacilitiesService from '../../../../services/api/Facilities/FacilitiesService';
import { Facilities } from '../../../../services/api/Facilities/interface';
import Select from 'react-select';

interface ContainerProps {
  contact: Contact;
  onChange: (data: any, canNext: boolean) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const ExchangeChoice: React.FC<IProps> = (props) => {
  const { t, contact, onChange } = props;
  const required = {
    form: [
      'nom_de_l_universit_d_origine_nouveau__c',
      'adresse_de_l_universit__c',
      'facult_d_partement__c',
      'nom_du_coordinateur_institutionnel__c',
      'pr_nom_du_coordinateur_institutionnel__c',
      'indicatif_t_l_phone_coordinateur_instit__c',
      'telephone_coordinateur_institutionnel__c',
      'email_coordinateur_institutionnel__c'
    ]
  };

  const [form, setForm] = useState<Contact>(contact);
  const [universitiesList, setUniversitiesList] = useState<Facilities[]>([]);
  const [filteredUniversities, setFilteredUniversities] = useState<any[]>([]);
  const [universityInput, setUniversityInput] = useState<string>('');

  useEffect(() => {
    getExchangeFacilities();
    let canNext = checkRequested(required.form, contact);
    onChange({ data: contact }, canNext);
  }, []);

  const handleChange = (e: any): void => {
    let newForm = {
      ...form,
      [e.target.name]: e.target.value
    };
    setForm(newForm);
    let canNext = checkRequested(required.form, newForm);
    onChange(newForm, canNext);
  };

  const handleSubmit = (e: any) => {
    e.preventDefautl();
  };

  const getExchangeFacilities = async () => {
    try {
      const facilitiesResponse =
        await FacilitiesService.getExchangeFacilities();
      let facilities: Facilities[] = facilitiesResponse.data;
      setUniversitiesList(facilities);

      const university = facilities.find(
        (facility) => facility.sfid === form.nom_de_l_universit_d_origine_nouveau__c
      );
      if (university) {
        setUniversityInput(`${university.code_postal_ville__c ? university.code_postal_ville__c : ''} ${university.name}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const buildFilteredUniversities = (value: string) => {
    let filteredUniversities = universitiesList.filter((university: Facilities) => {
      let fullValue = `${university.ville__c} ${university.code_postal_ville__c} ${university.name}`;
      return (
        (university &&
          university.name &&
          university.name.toLowerCase().includes(value.toLowerCase())) ||
        (university &&
          university.code_postal_ville__c &&
          university.code_postal_ville__c
            .toLowerCase()
            .includes(value.toLowerCase())) ||
        (university &&
          university.ville__c &&
          university.ville__c.toLowerCase().includes(value.toLowerCase())) ||
        fullValue.toLowerCase().includes(value)
      );
    });

    const sortedFilteredUniversities = filteredUniversities
      .sort((a: any, b: any) => {
        const universityFullnameA = `${a.ville__c} ${a.code_postal_ville__c} ${a.name}`;
        const universityFullnamB = `${b.ville__c} ${b.code_postal_ville__c} ${b.name}`;

        return universityFullnameA > universityFullnamB ? 1 : universityFullnameA < universityFullnamB ? -1 : 0;
      })
      .slice(0, 20)
      .map((university) => {
        return {
          value: university.sfid,
          label: `${
            university.code_postal_ville__c ? university.code_postal_ville__c : ''
          } ${university.name}`
        };
      });
    setFilteredUniversities(sortedFilteredUniversities);
  };

  const handleKeyDownUniversity = (e: any) => {
    setUniversityInput(e);
    buildFilteredUniversities(e);
  };

  const handleUniversityChange = (value: any) => {
    let newForm = {
      ...form,
      nom_de_l_universit_d_origine_nouveau__c: value.value
    };
    setForm(newForm);
    let canNext = checkRequested(required.form, newForm);
    onChange(newForm, canNext);
  };

  return (
    <div>
      <h5>{t('exchangeProgram')}</h5>
      <form onSubmit={handleSubmit}>
        <div>
          <h6 className="form-h6">
            {t('universityName')} <span className="form-required">*</span>
          </h6>
          <Select
            className="select-react"
            name="nom_de_l_universit_d_origine_nouveau__c"
            options={filteredUniversities}
            inputValue={universityInput}
            isMulti={false}
            onInputChange={handleKeyDownUniversity}
            onChange={handleUniversityChange}
            placeholder={t('institution')}
          />
        </div>
        <div>
          <h6 className="form-h6">
            {t('universityAddress')} <span className="form-required">*</span>
          </h6>
          <input
            name="adresse_de_l_universit__c"
            value={form.adresse_de_l_universit__c}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
        <div>
          <h6 className="form-h6">
            {t('collegeDepartment')} <span className="form-required">*</span>
          </h6>
          <input
            name="facult_d_partement__c"
            value={form.facult_d_partement__c}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
        <hr></hr>
        <h5>{t('coordinator')}</h5>
        <div>
          <h6 className="form-h6">
            {t('coordinatorLastname')} <span className="form-required">*</span>
          </h6>
          <input
            name="nom_du_coordinateur_institutionnel__c"
            value={form.nom_du_coordinateur_institutionnel__c}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
        <div>
          <h6 className="form-h6">
            {t('coordinatorFirstname')} <span className="form-required">*</span>
          </h6>
          <input
            name="pr_nom_du_coordinateur_institutionnel__c"
            value={form.pr_nom_du_coordinateur_institutionnel__c}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
        <div>
          <div>
            <h6 className="form-h6">
              {t('coordinatorIndicative')}{' '}
              <span className="form-required">*</span>
            </h6>
            <select
              name="indicatif_t_l_phone_coordinateur_instit__c"
              value={form.indicatif_t_l_phone_coordinateur_instit__c}
              onBlur={handleChange}
              onChange={handleChange}>
              <option value={''}></option>
              {_.chain(countryCodes)
                // .pick(1)
                .map((p) => {
                  if (p && p.dial_code) {
                    return (
                      <option value={p.dial_code} key={p.code}>
                        {' '}
                        {p.name} ({p.dial_code}){' '}
                      </option>
                    );
                  }
                })
                .value()}
            </select>
          </div>
          <div>
            <h6 className="form-h6">
              {t('coordinatorPhone')} <span className="form-required">*</span>
            </h6>
            <input
              type="tel"
              name="telephone_coordinateur_institutionnel__c"
              value={form.telephone_coordinateur_institutionnel__c}
              onBlur={handleChange}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <h6 className="form-h6">
            {t('coordinatorMail')} <span className="form-required">*</span>
          </h6>
          <input
            name="email_coordinateur_institutionnel__c"
            value={form.email_coordinateur_institutionnel__c}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
        <div>
          <h6 className="form-h6">{t('erasmus')}</h6>
          <input
            name="code_erasmus__c"
            value={form.code_erasmus__c}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
      </form>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(ExchangeChoice)
);
