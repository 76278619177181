/* 
  This service is used to interacte with the Period routes of the API server
*/

import axios, { AxiosResponse } from 'axios';

const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class PeriodService {
  /**
   * Get all periods
   * @returns a list of all the periods
   */
  getPeriods(): Promise<AxiosResponse> {
    return axios.get(`${url}/periods`);
  }

  /**
   * Get current period (current fiscal year)
   * @returns the current period
   */
  getCurrentPeriod(): Promise<AxiosResponse> {
    return axios.get(`${url}/period/current`);
  }
}
export default new PeriodService();
