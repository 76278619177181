import React, { ReactElement, useEffect, useState } from 'react';
import './LayoutJury.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../assets/images/junialogoviolet.png';
import flagUK from '../../assets/images/flagUK.png';
import flagFR from '../../assets/images/flagFR.png';
import Burger from '../../assets/images/junia-icon-burger.png';
import AuthService from '../../services/api/Auth/AuthService';
import i18n from '../../i18n';
import GavelIcon from '@mui/icons-material/Gavel';
import ArticleIcon from '@mui/icons-material/Article';

interface ContainerProps {
  children?: ReactElement
}

// type ChildProps = {};

interface IProps extends ContainerProps, WithTranslation {}
const LayoutJury: React.FC<IProps> = (props) => {
  const { t } = props;
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [active, setActive] = useState(localStorage.getItem('i18nextLng'));

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const menuItems = [
    {
      text: t('word.homePage'),
      path: '/jury'
    },
    {
      text: t('word.faqPage'),
      path: '/faq'
    },
    {
      text: t('word.disconnect'),
      path: '/',
      event: () => {
        AuthService.logout();
      }
    }
  ];

  return (
    <div>
      <div className="items">
        <div className="logo">
          <img alt="logo junia" src={logo} />
        </div>
        <div className="menu desktop">
          <ul>
            {menuItems.map((item) => (
              <li
                key={item.text}
                className={location.pathname == item.path ? 'active' : ''}>
                <button
                  onClick={() =>
                    item.event ? item.event() : history.push(item.path)
                  }>
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
          <div className="footer">
            <div title="Legals">
              <button onClick={() => history.push('/legals')}>
                <GavelIcon></GavelIcon>
              </button>
            </div>
            <div title="Terms of Use">
              <button onClick={() => history.push('/terms')}>
                <ArticleIcon></ArticleIcon>
              </button>
            </div>
          </div>
        </div>
        <div className="menu mobile">
          <div className="burger">
            <button onClick={handleMenu}>
              <img alt="burger icon" src={Burger} />
            </button>
          </div>
          {showMenu ? (
            <ul>
              {menuItems.map((item) => {
                return (
                  <li
                    key={item.text}
                    className={location.pathname == item.path ? 'active' : ''}>
                    <button
                      onClick={() => {
                        item.event ? item.event() : history.push(item.path),
                          handleMenu;
                      }}>
                      {item.text}
                    </button>
                  </li>
                );
              })}
              <hr></hr>
              <li className={location.pathname == '/legals' ? 'active' : ''}>
                <button onClick={() => history.push('/legals')}>
                  {t('word.cgu')}
                </button>
              </li>
              <li className={location.pathname == '/terms' ? 'active' : ''}>
                <button onClick={() => history.push('/terms')}>
                  {t('word.legals')}
                </button>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
      <div className="layout-content">
        {/* <div className="flags">
          <button
            type="button"
            onClick={() => {
              i18n.changeLanguage('fr');
              setActive('fr');
            }}
            className={active === 'fr' ? 'fr' : ''}>
            <img src={flagFR} alt="drapeau français" />
          </button>
          <button
            type="button"
            onClick={() => {
              i18n.changeLanguage('en');
              setActive('en');
            }}
            className={active === 'en' ? 'en' : ''}>
            <img src={flagUK} alt="drapeau anglais" />
          </button>
        </div> */}
        {props.children}
      </div>
    </div>
  );
};

export default withTranslation()(LayoutJury);
