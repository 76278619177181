import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
const { NODE_ENV, REACT_APP_BACK_URL } = process.env;

class Spinner {
  spinner$ = new BehaviorSubject<boolean>(false);

  setSpinner(spinner: boolean): void {
    this.spinner$.next(spinner);
  }
}
export default new Spinner();
