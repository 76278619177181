/**
 * This component displays the home page for a candidate
 */
import React from 'react';
import './HomeCatalogue.scss';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import HomeImage from '../../../../assets/images/junia-image-accueil.png';
import Logo from '../../../../assets/images/logo-junia.png';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DoneIcon from '@mui/icons-material/Done';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {  me } from '../../../../services/api/Contact/interface';

interface ContainerProps {
  handleClick: () => void;
  canClick: boolean;
  me: me;
}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const HomeCatalogue: React.FC<IProps> = (props) => {
  const { t, handleClick, canClick, me } = props;
  const { i18n } = useTranslation();

  return (
    <div className="home">
      <div className="home-image">
        <img alt="homepage" src={HomeImage} />
      </div>
      <div className="home-content">
        <div className="white-logo">
          <img alt="white logo junia" src={Logo} />
        </div>
        <h3>{t('school')}</h3>
        <div className="home-button">
          {canClick ? (
            <button onClick={() => handleClick()}>
              <h4>{t('candidate1')}</h4>
              <h4>{t('candidate2')}</h4>
              <ArrowDownwardIcon
                style={{ fontSize: '3rem' }}></ArrowDownwardIcon>
            </button>
          ) : (
            <>
              {me.type_tech__c === 'Echange' ? (
                <button className="accepted-application">
                  {i18n.language === 'fr' ? (
                    <>
                      <h4>{t('candidateExch')}</h4>
                      <h4>{t('candidate2')}</h4>
                    </>
                  ) : (
                    <>
                      <h4>{t('candidate2')}</h4>
                      <h4>{t('candidateExch')}</h4>
                    </>
                  )}
                  <HourglassEmptyIcon
                    style={{
                      fontSize: '3rem',
                      color: '#fff'
                    }}></HourglassEmptyIcon>
                </button>
              ) : (
                <button className="accepted-application">
                  <h4>{t('accepted1')}</h4>
                  <h4>{t('accepted2')}</h4>
                  <DoneIcon
                    style={{ fontSize: '3rem', color: '#fff' }}></DoneIcon>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['home', 'common'])(HomeCatalogue));
