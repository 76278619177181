import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DisplayBlobFiles from '../../../../components/DisplayBlobFiles/DisplayBlobFiles';
import DisplayFiles from '../../../../components/DisplayFiles/DisplayFiles';
import UploadFile from '../../../../components/UploadFile/UploadFile';
import { Contact } from '../../../../services/api/Contact/interface';
import ExperienceService from '../../../../services/api/Experience/ExperienceService';
import { Experience } from '../../../../services/api/Experience/interface';
import FilesService from '../../../../services/api/Files/FilesService';
import {
  FileToUpload,
  PieceComplementaire
} from '../../../../services/api/Files/interface';
import {
  checkRequested
} from '../../../../utils/validator/validator';
import ExperienceForm from './Experience/ExperienceForm';

import './Experiences.scss';

interface ContainerProps {
  contact: Contact;
  onChange: (
    data: {
      experiences: Experience[];
      contact: Contact;
      files: FileToUpload[];
    },
    canNext: boolean
  ) => void;
  getContact?: () => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Experiences: React.FC<IProps> = (props) => {
  const { t, contact, onChange, getContact } = props;

  const [form, setForm] = useState<Contact>(contact);
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [fileList, setFileList] = useState<FileToUpload[]>([]);

  useEffect(() => {
    getExperiences();
  }, []);
  useEffect(() => {
    setFileList([]);
  }, [contact.Files]);

  const getExperiences = async () => {
    if (contact && contact.id_heroku__c) {
      let experienceResponse = await ExperienceService.getExperiences(
        contact.id_heroku__c
      );
      let experienceList = experienceResponse.data;
      if (experienceList.length > 0) {
        let formateList = _.map(experienceList, (experience, index) => {
          return { ...experience, expId: index + 1 };
        });
        setExperiences(formateList);
      } else {
        setExperiences([]);
      }
      onChange(
        { experiences: experiences, contact: form, files: fileList },
        true
      );
    }
  };

  const handleDelete = (experience: Experience) => {
    if (experience.id_heroku__c) {
      //delete from bdd
      if (contact.id_heroku__c) {
        ExperienceService.remove(
          contact.id_heroku__c,
          experience?.id_heroku__c
        ).then((res) => {
          getExperiences();
        });
      }
    } else {
      let experienceList = [...experiences];
      let index = _.findIndex(experienceList, (t) => t.id === experience.id);
      experienceList.splice(index, 1);
      setExperiences(experienceList);
      // let canNext = checkExperience(experienceList, required);
      onChange(
        { experiences: experiences, contact: form, files: fileList },
        true
      );
    }
  };

  const expChange = (data: any, comp: any) => {
    let newList = _.map(experiences, (o: Experience) => {
      if (o.expId === comp.expId) {
        return { ...comp, ...data };
      } else {
        return o;
      }
    });
    setExperiences(newList);

    onChange({ experiences: newList, contact: form, files: fileList }, true);
  };

  const addExp = (e: any) => {
    let exps = [...experiences];
    exps.push({ expId: exps.length + 1 });
    setExperiences(exps);
  };

  const handleFileChange = (files: File[], type: string) => {
    let list = [...fileList];
    let found = _.findIndex(list, (f) => f.type === type);
    if (found >= 0) {
      let data = [...list[found].files, ...files];
      list.splice(found, 1, { type: type, files: data });
    } else {
      list.push({ type: type, files: files });
    }

    setFileList(list);
    onChange({ experiences: experiences, contact: form, files: list }, true);
  };

  const handleFileDelete = (file: any, type: string, fileType: string) => {
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contact?.id_heroku__c) {
          FilesService.removeFile(
            file?.id_heroku__c,
            contact?.id_heroku__c
          ).then((res) => {
            if (getContact) {
              getContact();
              getExperiences();
            }
          });
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(liste, (f) => f.type === file.type);
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);
        break;
    }
  };

  const checkExperience = (list: Experience[], requireList: string[]) => {
    let canNext = true;
    let nexEducationHistoryList = _.map(list, (o: Experience) => {
      if (contact.type_tech__c && canNext) {
        canNext = checkRequested(requireList, o);
      }
    });
    return canNext;
  };

  const findMax = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined
  ) => {
    if (files) {
      let foundCurrent = _.find([...fileList], (f) => f.type === type);
      let foundSave = _.filter([...files], (f) => f.type__c === type);
      let currentLength = foundCurrent ? foundCurrent.files.length : 0;
      let saveLength = foundSave ? foundSave.length : 0;
      return currentLength + saveLength;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <div>
        <h6 className="form-h6">{t('yourCV')}</h6>
        <UploadFile
          max={2}
          current={findMax('resume', 2, contact.Files)}
          onChange={(data) => handleFileChange(data, 'resume')}
          type="pdf"></UploadFile>
        <DisplayBlobFiles
          files={fileList}
          type="resume"
          onDelete={(data) =>
            handleFileDelete(data, 'resume', 'blob')
          }></DisplayBlobFiles>
        <DisplayFiles
          files={contact.Files}
          type="resume"
          onDelete={(data) =>
            handleFileDelete(data, 'resume', 'uploaded')
          }></DisplayFiles>
      </div>
      <hr></hr>
      <h5>{t('proExperiences')}</h5>
      {experiences.length > 0 &&
        _.map(experiences, (experience, index) => {
          let formatedExperience = { ...experience, index: index };
          return (
            <React.Fragment key={index}>
              <h5>
                {t('experienceNumber')} N°{index + 1}
              </h5>

              <ExperienceForm
                contactData={contact}
                experience={experience}
                onChange={(data: any) => expChange(data, formatedExperience)}
                handleDelete={handleDelete}></ExperienceForm>
            </React.Fragment>
          );
        })}
      <div className="add-button">
        <button onClick={addExp}>{t('addExperience')}</button>
      </div>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(Experiences)
);
