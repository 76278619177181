import React, { useEffect, useState } from 'react';
import './Secure3DModal.scss';
import CloseIcon from '@mui/icons-material/Close';
import { withTranslation, WithTranslation } from 'react-i18next';
import _ from 'lodash';
import PaymentService, {
  PaymentFields,
  Transaction
} from '../../services/api/Payment/PaymentService';
import { Dialog } from '@mui/material';

interface ContainerProps {
  link3d: string;
  title: string;
  setModalIsOpen: (o: boolean) => void;
  modalIsOpen: boolean;
  closeModal: () => void;
}

type ChildProps = {};

interface IProps extends ContainerProps, ChildProps, WithTranslation {}
const Secure3DModal: React.FC<IProps> = (props) => {
  const { t, link3d, modalIsOpen, setModalIsOpen, closeModal, title } = props;

  useEffect(() => {}, []);

  return (
    <Dialog
      open={modalIsOpen}
      className="iframe"
      disableEnforceFocus
      fullWidth
      maxWidth={'lg'}>
      <div className="modal-header">
        <div className="modal-title">
          <h3>{title}</h3>
        </div>
        <div className="modal-close">
          <button className="btn-close" onClick={closeModal}>
            <CloseIcon></CloseIcon>
          </button>
        </div>
      </div>
      <div className="modal-content">
        <iframe title="3D secure" src={link3d}></iframe>
      </div>
    </Dialog>
  );
};

export default withTranslation(['modal'])(Secure3DModal);
