import { he } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Alert from '../../../../utils/Alert/Alert';
import Spinner from '../../../../utils/Spinner/Spinner';
import Secure3DModal from '../../../../components/Secure3DModal/Secure3DModal';
import PaymentService, {
  PaymentFields,
  Transaction,
  Payment
} from '../../../../services/api/Payment/PaymentService';

import './ProceedPayment.scss';
import CandidatureService from '../../../../services/api/Candidature/CandidatureService';
import { me } from '../../../../services/api/Contact/interface';
import { Application_hed } from '../../../../services/api/Candidature/interface';
import { FileToUpload } from '../../../../services/api/Files/interface';

interface ContainerProps {
  onStep: (
    application: Application_hed,
    step: number,
    files?: FileToUpload[]
  ) => void;
  me: me;
  applicationData: Application_hed;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const ProceedPayment: React.FC<IProps> = (props) => {
  const { t, onStep, me, applicationData } = props;

  const [paymentForm, setPaymentForm] = useState<Partial<PaymentFields>>({
    cc_number: '',
    cc_exp_month: '',
    cc_exp_year: '',
    cc_cvc: '',
    cc_name: ''
  });
  const [transaction, setTransaction] = useState<Partial<Transaction>>({});
  const [payment, setPayment] = useState<Partial<Payment>>({});
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modal, setModal] = useState('');

  const handlePayment = async (): Promise<void> => {
    Spinner.setSpinner(true);
    PaymentService.create(paymentForm)
      .then((res) => {
        updateTransaction(res.data.transaction);
        updatePayment(res.data.payment);
        Spinner.setSpinner(false);
        if (res.data.transaction.status__c === 'succeeded') {
          // PAS DE 3D SECURE
          Alert.setAlert({
            type: 'success',
            duration: 3000,
            message: t('paymentSuccessMessage'),
            open: true
          });
          onStep(applicationData, +1);
        } else {
          openModal();
        }
      })
      .catch((err) => {
        console.log(
          '🚀 ~ file: ProceedPayment.tsx ~ line 66 ~ handlePayment ~ err',
          err.response
        );
        Spinner.setSpinner(false);
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: t(err?.response.data.message),
          open: true
        });
        console.log(err.stack);
      });
  };

  const openModal = () => {
    setModal(modal);
    setModalIsOpen(true);
  };

  const handleModalClose = async (): Promise<void> => {
    setModal('');
    setModalIsOpen(false);
    await finalizePayment();
  };

  const lockPayment = (): boolean => {
    return !(
      paymentForm.cc_number?.length === 16 &&
      paymentForm.cc_cvc?.length === 3 &&
      !!paymentForm.cc_name?.length &&
      parseInt(paymentForm.cc_exp_month || '0') > 0 &&
      parseInt(paymentForm.cc_exp_month || '0') <= 12 &&
      parseInt(paymentForm.cc_exp_year || '0') >=
        new Date().getFullYear() - 2000 &&
      parseInt(paymentForm.cc_exp_year || '0') <=
        new Date().getFullYear() - 2000 + 30
    );
  };

  const finalizePayment = async (): Promise<void> => {
    try {
      Spinner.setSpinner(true);
      const res = await PaymentService.finalize(transaction.id_heroku__c);
      updateTransaction(res.data.transaction);
      updatePayment(res.data.payment);
      if (me.id_heroku__c) {
        let updated = await CandidatureService.patchCandidature(
          me.id_heroku__c,
          {
            id_heroku__c: applicationData.id_heroku__c,
            candidature_statut_tech__c: 'Validée',
            statut__c: 'Dépôt candidature'
          }
        );
      }
      Spinner.setSpinner(false);
      Alert.setAlert({
        type: 'success',
        duration: 3000,
        message: t('paymentSuccessMessage'),
        open: true
      });
      onStep(applicationData, +1);
    } catch (err) {
      // TOAST ERROR
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
        console.log(err.stack);
      }
    }
  };

  const updatePaymentForm = (key: string, value: string): void => {
    const newForm = { ...paymentForm };
    newForm[key] = value;
    setPaymentForm(newForm);
  };

  const updateTransaction = (transaction: any): void => {
    setTransaction(transaction);
  };
  const updatePayment = (payment: any): void => {
    setPayment(payment);
  };

  const cardNumberRegex = (value: string): boolean => {
    return /^[0-9]{0,16}$/.test(value);
  };

  const cardExpRegex = (value: string): boolean => {
    return /^[0-9]{0,2}$/.test(value);
  };

  const cardCvcRegex = (value: string): boolean => {
    return /^[0-9]{0,3}$/.test(value);
  };

  return (
    <div className="proceed-payment-container">
      <div className="payment-infos">
        <label htmlFor="card">{t('informations')}</label>
        <input
          className="input-custom-large"
          placeholder={t('cardNumber')}
          name="card"
          autoComplete="cc-number"
          value={paymentForm.cc_number}
          onChange={(ev) =>
            cardNumberRegex(ev.target.value)
              ? updatePaymentForm('cc_number', ev.target.value)
              : null
          }
          type="text"
        />
        <div className="year-cvc">
          <input
            className="input-custom"
            placeholder="MM"
            name="card"
            autoComplete="cc-exp-month"
            value={paymentForm.cc_exp_month}
            onChange={(ev) =>
              cardExpRegex(ev.target.value)
                ? updatePaymentForm('cc_exp_month', ev.target.value)
                : null
            }
          />
          <input
            className="input-custom"
            placeholder="YY"
            autoComplete="cc-exp-year"
            name="card"
            value={paymentForm.cc_exp_year}
            onChange={(ev) =>
              cardExpRegex(ev.target.value)
                ? updatePaymentForm('cc_exp_year', ev.target.value)
                : null
            }
          />
          <input
            className="input-custom"
            placeholder="CVC"
            name="card"
            autoComplete="cc-csc"
            value={paymentForm.cc_cvc}
            onChange={(ev) =>
              cardCvcRegex(ev.target.value)
                ? updatePaymentForm('cc_cvc', ev.target.value)
                : null
            }
          />
        </div>
        <input
          name="cardname"
          className="input-custom-large"
          autoComplete="cc-name"
          placeholder={t('cardOwner')}
          value={paymentForm.cc_name}
          onChange={(ev) => updatePaymentForm('cc_name', ev.target.value)}
        />
      </div>
      <div className="payment-btn">
        <button
          className="btn btn-main btn-fullwidth"
          onClick={handlePayment}
          disabled={lockPayment()}>
          <span>{t('pay')} 50 €</span>
        </button>
        <button
          className="btn btn-outlined btn-fullwidth"
          onClick={() => onStep(applicationData, -1)}>
          <span>{t('payAbort')}</span>
        </button>
      </div>
      {payment &&
        payment.next_action &&
        payment.next_action.redirect_to_url &&
        payment.next_action.redirect_to_url.url && (
          <Secure3DModal
            title="3D Secure"
            closeModal={() => handleModalClose()}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            link3d={
              payment.next_action?.redirect_to_url?.url
            }></Secure3DModal>
        )}
    </div>
  );
};

export default withRouter(
  withTranslation(['payments', 'common'])(ProceedPayment)
);
