import axios, { AxiosResponse } from 'axios';
import API from '..';
import { Experience } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class ExperienceService {
  getExperiences(user_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/${user_id}/experience_professionnelle`);
  }

  patchExperience(
    user_id: string,
    experience: Experience
  ): Promise<AxiosResponse> {
    return axios.patch(
      `${url}/${user_id}/experience_professionnelle/${experience.id_heroku__c}`,
      experience
    );
  }
  createExperience(
    user_id: string,
    educationHistory: Experience
  ): Promise<{ data: any }> {
    return axios.post(
      `${url}/${user_id}/experience_professionnelle`,
      educationHistory
    );
  }
  /**
   * Remove a profesionnal experience
   * @param user_id Heroku ID of the user
   * @param experience_professionnelle_id Heroku ID of a profesionnal experience
   * @returns {Promise} an empty promise
   */
  remove(
    user_id: string,
    experience_professionnelle_id: string
  ): Promise<AxiosResponse<void>> {
    return axios.delete(
      `${url}/${user_id}/experience_professionnelle/${experience_professionnelle_id}`
    );
  }
}
export default new ExperienceService();
