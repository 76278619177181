import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps  } from 'react-router-dom';
import { Contact } from '../../../../services/api/Contact/interface';

import './Header.scss';

interface ContainerProps {
  title: string;
  subtitle: string;
  step: any;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Header: React.FC<IProps> = (props) => {
  const { t, title, subtitle, step } = props;

  return (
    <div>
      <h3>{title}</h3>
      <h6>{subtitle}</h6>
      {step?.name !== 'Non commencée' && step?.name !== 'etape 2' && step?.name !== 'Recapitulatif' ? (
        <span>
          <span style={{ color: 'red', fontSize: '1rem' }}>*</span>{' '}
          {t('word.required')}
        </span>
      ) : null}
    </div>
  );
};

export default withRouter(withTranslation(['faq', 'common'])(Header));
