import React, { ChangeEvent, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { Contact } from '../../services/api/Contact/interface';
import ContactService from '../../services/api/Contact/ContactService';

import FaqService from '../../services/api/Faq/FaqService';

import './CGU.scss';
import { FAQ } from '../../services/api/Faq/interface';
import _ from 'lodash';
import AuthService from '../../services/api/Auth/AuthService';

interface ContainerProps {}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const CGU: React.FC<IProps> = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();

  const [contact, setContact] = useState<Contact>({});
  const [me, setMe] = useState<any>({});
  const [faq, setFaq] = useState<FAQ[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    getFaq();
    getMe();
  }, []);
  useEffect(() => {
    if (me.id_heroku__c) {
      getContact();
    }
  }, [me]);

  const getFaq = async (search?: string) => {
    const faq = await FaqService.getFaq(search);
    if (faq.data.faqs) {
      faq.data.faqs.forEach((element: any) => (element.isActive = false));
    }
    setFaq(faq.data.faqs);
  };
  const getContact = async () => {
    try {
      let contactResponse = await ContactService.getContact(me.id_heroku__c);
      setContact(contactResponse.data);
      // updateNextStep(contactResponse.data);
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };
  const getMe = async () => {
    try {
      const meRequest = await AuthService.me();
      setMe(meRequest.data);
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const changeActiveState = (id: any) => {
    const updatedFaq = faq.map((element) => {
      if (element.id === id) {
        element.isActive = !element.isActive;
      }
      return element;
    });
    setFaq(updatedFaq);
  };
  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const history = useHistory();
  const changeRoute = (path: string) => {
    history.push(path);
  };

  const triggerSearch = () => {
    getFaq(search);
  };

  return (
    <div className="mentions-container general-container">
      <p>{t('underConstruct')}</p>
    </div>
  );
};

export default withRouter(withTranslation(['cgu', 'common'])(CGU));
