import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import UploadFile from '../../../../../components/UploadFile/UploadFile';
import { Contact } from '../../../../../services/api/Contact/interface';
import './Baccalaureat.scss';
import { EducationHistory } from '../../../../../services/api/EducationHistory/interface';
import {
  FileToUpload,
  PieceComplementaire
} from '../../../../../services/api/Files/interface';
import FilesService from '../../../../../services/api/Files/FilesService';
import DisplayFiles from '../../../../../components/DisplayFiles/DisplayFiles';
import PicklistsService from '../../../../../services/api/Picklists/PicklistsService';
import { Picklist } from '../../../../../services/api/Picklists/interface';
import DisplayBlobFiles from '../../../../../components/DisplayBlobFiles/DisplayBlobFiles';
import Tooltip from '../../../../../components/Tooltip/Tooltip';

interface ContainerProps {
  data: EducationHistory;
  contactdata: Contact;
  onChange: (data: any, next: boolean) => void;
  getContact?: () => void;
  handleDelete?: (data: EducationHistory) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Baccalaureat: React.FC<IProps> = (props) => {
  const { t, data, onChange, contactdata, getContact, handleDelete } = props;

  const [form, setForm] = useState<EducationHistory>(data);
  const [contact, setContact] = useState<Contact>(contactdata);
  const [fileList, setFileList] = useState<FileToUpload[]>([]);

  const [fileObject, setFileObject] = useState<any>({});
  const [anneesObtention, setAnneeObtention] = useState<string[]>(['']);
  const [educationHistoryPicklist, setEducationHistoryPicklist] =
    useState<Picklist>();

  const { i18n } = useTranslation();

  useEffect(() => {
    buildYears();
    getEducationHistoryPicklist();
  }, []);
  useEffect(() => {
    setForm(data);
  }, [data]);

  const getEducationHistoryPicklist = async () => {
    try {
      const hedEducationHistoryPicklistResponse =
        await PicklistsService.getHedEducationHistoryPicklists();
      let hedEducationHistoryPicklist: Picklist =
        hedEducationHistoryPicklistResponse.data;
      setEducationHistoryPicklist(hedEducationHistoryPicklist);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e: any): void => {
    let newForm = {
      ...form,
      [e.target.name]: e.target.value
    };
    setForm(newForm);
    if (contact?.type_tech__c) {
      // let canNext = checkRequested(required[contact.type_tech__c], newForm);
      onChange({ data: newForm, file: fileObject }, true);
    }
  };

  const onDelete = () => {
    if (handleDelete) {
      handleDelete(form);
    }
  };

  const buildYears = () => {
    let yearList = [];
    let year = moment().add(1, 'years').format('YYYY');
    for (let index = 0; index <= 13; index++) {
      let previousYear = moment(year).subtract(index, 'years').format('YYYY');
      yearList.push(previousYear);
    }
    setAnneeObtention(yearList);
  };

  const handleFileChange = (files: File[], type: string) => {
    let list = [...fileList];
    if (files.length > 0) {
      let found = _.findIndex(list, (f) => f.type === type);
      if (found >= 0) {
        let data = [...list[found].files, ...files];
        list.splice(found, 1, { type: type, files: data });
      } else {
        list.push({ type: type, files: files });
      }

      setFileList(list);
      if (contact.type_tech__c) {
        // let canNext = checkRequested(required[contact.type_tech__c], form);
        onChange({ data: form, files: list }, true);
      }
    }
  };

  const handleFileDelete = (file: any, type: string, fileType: string) => {
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contact?.id_heroku__c) {
          FilesService.removeFile(
            file?.id_heroku__c,
            contact?.id_heroku__c
          ).then((res) => {
            let found = _.find(fileList, (f) => f.type === type);
            if (getContact) {
              getContact();
              // setFileList([])
            }
          });
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(liste, (f) => f.type === file.type);
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);
        break;
    }
  };

  const findMax = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined
  ) => {
    if (files) {
      let foundCurrent = _.find([...fileList], (f) => f.type === type);
      let foundSave = _.filter([...files], (f) => f.type__c === type);
      let currentLength = foundCurrent ? foundCurrent.files.length : 0;
      let saveLength = foundSave ? foundSave.length : 0;
      return currentLength + saveLength;
    } else {
      return 0;
    }
  };

  return (
    <div>
      {/* FRANCAIS CANDIDATE BACCALAURATE */}
      {contact.type_tech__c === 'Francais' ? (
        <div>
          <div>
            <h5>{t('bac')}</h5>
            <div>
              <h6 className="form-h6">
                {t('bacType')} <span className="form-required">*</span>
              </h6>
              <select
                name="type_de_bac__c"
                value={form.type_de_bac__c}
                onChange={handleChange}>
                <option value={''}></option>
                {educationHistoryPicklist &&
                  _.chain(
                    educationHistoryPicklist.picklistFieldValues[
                      'Type_de_bac__c'
                    ].values
                  )
                    .map((p) => {
                      if (p && p.value) {
                        return (
                          <option key={p.value} value={p.value}>
                            {' '}
                            {p.label}{' '}
                          </option>
                        );
                      }
                    })
                    .value()}
              </select>
            </div>
            <div>
              <h6 className="form-h6">
                {t('bacOption')} <span className="form-required">*</span>
              </h6>
              <select
                name="option_du_bac__c"
                value={form.option_du_bac__c}
                onChange={handleChange}>
                <option value={''}></option>
                {educationHistoryPicklist &&
                  _.chain(
                    educationHistoryPicklist.picklistFieldValues[
                      'Option_du_bac__c'
                    ].values
                  )
                    .map((p) => {
                      if (p && p.value) {
                        return (
                          <option key={p.value} value={p.value}>
                            {' '}
                            {p.label}{' '}
                          </option>
                        );
                      }
                    })
                    .value()}
              </select>
            </div>

            {/* Condition champ spécialité {bacTypes === 'Général (depuis 2021)'} */}
            {form.option_du_bac__c === 'Général (depuis 2021)' && (
              <div>
                <h6 className="form-h6">{t('specialty')}</h6>
                <select
                  name="specialites_du_bac__c"
                  value={form.specialites_du_bac__c}
                  onChange={handleChange}>
                  <option value={''}></option>
                  {educationHistoryPicklist &&
                    _.chain(
                      educationHistoryPicklist.picklistFieldValues[
                        'Specialites_du_bac__c'
                      ].values
                    )
                      .map((p) => {
                        if (p && p.value) {
                          return (
                            <option key={p.value} value={p.value}>
                              {' '}
                              {p.label}{' '}
                            </option>
                          );
                        }
                      })
                      .value()}
                </select>
              </div>
            )}
            <div>
              <h6 className="form-h6">
                {t('bacMention')} <span className="form-required">*</span>
              </h6>
              <select
                name="mention_du_bac__c"
                value={form.mention_du_bac__c}
                onChange={handleChange}>
                <option value={''}></option>
                {educationHistoryPicklist &&
                  _.chain(
                    educationHistoryPicklist.picklistFieldValues[
                      'Mention_du_bac__c'
                    ].values
                  )
                    .map((p) => {
                      if (p && p.value) {
                        return (
                          <option key={p.value} value={p.value}>
                            {' '}
                            {p.label}{' '}
                          </option>
                        );
                      }
                    })
                    .value()}
              </select>
            </div>
            <div>
              <h6 className="form-h6">
                {t('graduationYear')} <span className="form-required">*</span>
              </h6>
              <select
                name="annee_d_obtention_du_bac__c"
                value={form.annee_d_obtention_du_bac__c}
                onChange={handleChange}>
                <option value={''}></option>
                {_.chain(anneesObtention)
                  .map((p) => {
                    return (
                      <option key={p} value={p}>
                        {p}
                      </option>
                    );
                  })
                  .value()}
              </select>
            </div>
            <div>
              <h6 className="form-h6">
                {t('academy')} <span className="form-required">*</span>
              </h6>
              <select
                name="academie__c"
                value={form.academie__c}
                onChange={handleChange}>
                {' '}
                <option value={''}></option>
                {educationHistoryPicklist &&
                  _.chain(
                    educationHistoryPicklist.picklistFieldValues['Academie__c']
                      .values
                  )
                    .map((p) => {
                      if (p && p.value) {
                        return (
                          <option key={p.value} value={p.value}>
                            {' '}
                            {p.label}{' '}
                          </option>
                        );
                      }
                    })
                    .value()}
              </select>
            </div>
            <div>
              <h6 className="form-h6">{t('bacTranscript')}</h6>
              <UploadFile
                max={2}
                current={findMax('bac_transcript', 2, form.Files)}
                onChange={(data) => handleFileChange(data, 'bac_transcript')}
                type="pdf"></UploadFile>
              <DisplayBlobFiles
                files={fileList}
                type="bac_transcript"
                onDelete={(data) =>
                  handleFileDelete(data, 'bac_transcript', 'blob')
                }></DisplayBlobFiles>
              <DisplayFiles
                files={form.Files}
                type="bac_transcript"
                onDelete={(data) =>
                  handleFileDelete(data, 'bac_transcript', 'uploaded')
                }></DisplayFiles>
            </div>
            <hr></hr>
          </div>

          <h5>{t('higherEducation')}</h5>
        </div>
      ) : (
        // INTERNATIONAL CANDIDATE BACCALAUREATE
        <div>
          <div>
            <h6 className="form-h6">
              {t('schoolYear')} <span className="form-required">*</span>
            </h6>
            <select
              name="annee_scolaire__c"
              value={form.annee_scolaire__c}
              onChange={handleChange}>
              <option value={''}></option>
              {educationHistoryPicklist &&
                educationHistoryPicklist.picklistFieldValues[
                  'Annee_scolaire__c'
                ].values.map((p: any) => {
                  if (p && p.value) {
                    return (
                      <option key={p.value} value={p.value}>
                        {p.label}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
          <div>
            <h6 className="form-h6">
              {t('level')} <span className="form-required">*</span>
            </h6>
            <select
              name="niveau_enseignement_secondaire__c"
              value={form.niveau_enseignement_secondaire__c}
              onChange={handleChange}>
              <option value={''}></option>
              {educationHistoryPicklist &&
                educationHistoryPicklist.picklistFieldValues[
                  'Niveau_Enseignement_secondaire__c'
                ].values.map((value: any) => {
                  if (i18n.language === 'fr') {
                    return (
                      <option key={value.value} value={value.value}>
                        {value.label}
                      </option>
                    );
                  }
                  if (i18n.language === 'en') {
                    return (
                      <option key={value.value} value={value.value}>
                        {value.value}
                      </option>
                    );
                  }
                })}
            </select>
          </div>
          <div>
            <h6 className="form-h6">
              {t('degree')} <span className="form-required">*</span>
            </h6>
            <select
              name="type_de_bac_intl__c"
              value={form.type_de_bac_intl__c}
              onChange={handleChange}>
              <option value={''}></option>
              {educationHistoryPicklist?.picklistFieldValues[
                'Type_de_bac_INTL__c'
              ].values.map((value: any) => {
                if (i18n.language === 'fr') {
                  return (
                    <option
                      key={value.value}
                      value={value.value.replace(/&#39;/g, "'")}>
                      {value.label.replace(/&#39;/g, "'")}
                    </option>
                  );
                }
                if (i18n.language === 'en') {
                  return (
                    <option
                      key={value.value}
                      value={value.value.replace(/&#39;/g, "'")}>
                      {value.value.replace(/&#39;/g, "'")}
                    </option>
                  );
                }
              })}
            </select>
          </div>
          {form.niveau_enseignement_secondaire__c === '12th year' && (
            <>
              <div className="custom-checkbox">
                <input
                  className="custom-check"
                  id={`validyes-${form.cursusId}`}
                  type="checkbox"
                  name="en_cours_ou_validee__c"
                  onChange={handleChange}
                  value="Validée"
                  checked={form.en_cours_ou_validee__c === 'Validée'}
                />
                <label
                  htmlFor={`validyes-${form.cursusId}`}
                  className={
                    form.en_cours_ou_validee__c === 'Validée' ? 'selected' : ''
                  }>
                  {t('validated')}
                </label>
                <input
                  className="custom-check"
                  id={`validno-${form.cursusId}`}
                  type="checkbox"
                  name="en_cours_ou_validee__c"
                  onChange={handleChange}
                  value="En cours"
                  checked={form.en_cours_ou_validee__c === 'En cours'}
                />
                <label
                  htmlFor={`validno-${form.cursusId}`}
                  className={
                    form.en_cours_ou_validee__c === 'En cours' ? 'selected' : ''
                  }>
                  {t('inProgress')}
                </label>
              </div>
              <div>
                <h6 className="form-h6">
                  {t('graduationYear')} <span className="form-required">*</span>
                </h6>
                <select
                  name="annee_d_obtention__c"
                  value={form.annee_d_obtention__c}
                  onChange={handleChange}>
                  <option value={''}></option>
                  {anneesObtention.map((p) => {
                    return (
                      <option key={p} value={p}>
                        {p}
                      </option>
                    );
                  })}
                </select>
              </div>
            </>
          )}
          <div>
            <div>
              <h6 className="form-h6">
                {t('recordTranscript')}
                {contact?.inscription_tech__c === 'Terminée' && (
                  <span className="form-required">*</span>
                )}
                <Tooltip message={t('tooltip.bacTranscriptInt')}></Tooltip>
              </h6>
              <UploadFile
                max={2}
                current={findMax('grades', 2, form.Files)}
                onChange={(data) => handleFileChange(data, 'grades')}
                type="pdf"></UploadFile>
              <DisplayBlobFiles
                files={fileList}
                type="grades"
                onDelete={(data) =>
                  handleFileDelete(data, 'grades', 'blob')
                }></DisplayBlobFiles>
              <DisplayFiles
                files={form.Files}
                type="grades"
                onDelete={(data) =>
                  handleFileDelete(data, 'grades', 'uploaded')
                }></DisplayFiles>
            </div>
          </div>
          {form.en_cours_ou_validee__c === 'Validée' &&
            form.niveau_enseignement_secondaire__c === '12th year' && (
              <div>
                <div>
                  <h6 className="form-h6">
                    {t('ifGetDiploma')}
                    {contact?.inscription_tech__c === 'Terminée' && (
                      <span className="form-required">*</span>
                    )}
                    <Tooltip message={t('tooltip.bacTranscriptInt')}></Tooltip>
                  </h6>
                  <UploadFile
                    max={2}
                    current={findMax('bac_transcript', 2, form.Files)}
                    onChange={(data) =>
                      handleFileChange(data, 'bac_transcript')
                    }
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="bac_transcript"
                    onDelete={(data) =>
                      handleFileDelete(data, 'bac_transcript', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={form.Files}
                    type="bac_transcript"
                    onDelete={(data) =>
                      handleFileDelete(data, 'bac_transcript', 'uploaded')
                    }></DisplayFiles>
                </div>
              </div>
            )}
          {handleDelete && (
            <div className="delete-line">
              <button className="btn-underline" onClick={onDelete}>
                <span>{t('word.delete')}</span>
              </button>
            </div>
          )}
          <p className="info-text">{t('completedAcademicCurriculum')}</p>
          <p className="info-text">{t('addAcademicYear')}</p>
        </div>
      )}
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(Baccalaureat)
);
