import moment from 'moment';
import validator from 'validator';

const responseMaker = (func: any, type: string) => {
  if (func) {
    return { valid: true, reason: '' };
  } else {
    return { valid: false, reason: type };
  }
};

const compareDates = (first: any, second: any) => {
  console.log(
    '🚀 ~ file: definitions.ts ~ line 12 ~ compareDates ~ first',
    first
  );
  console.log(
    '🚀 ~ file: definitions.ts ~ line 12 ~ compareDates ~ second',
    second
  );
  return true;
};

export const inscriptionValidatorDefinition = () => {
  return {
    hed__gender__c: [
      //   (value: string) =>
      //     responseMaker(
      //       validator.isIn(value, ['Male', 'Female', 'Non-binary']),
      //       'contains'
      //     )
    ],
    lastname: [
      //   (value: string) =>
      //     responseMaker(validator.isLength(value, { min: 1 }), 'length'),
      //   (value: string) => responseMaker(validator.isAlpha(value), 'isAlpha')
    ],
    firstname: [
      // (value: string) =>
      //   responseMaker(validator.isLength(value, { min: 1 }), 'length'),
      // (value: string) => responseMaker(validator.isAlpha(value), 'isAlpha')
    ],
    birthdate: [
      (value: string) =>
        responseMaker(compareDates(value, moment()), 'tochiant')
    ],
    ville_de_naissance__c: [],
    d_partement_de_naissance__c: [],
    hed__citizenship__c: [],
    hed__dual_citizenship__c: [],
    mailingstreet: [],
    mailingpostalcode: [],
    mailingcity: [],
    indicatif_pays_t_l_phone_mobile__c: [],
    mobilephone: [],
    n_national_ine_ou_bea__c: [],
    boursier__c: [(value: string) => validator.isIn(value, ['Oui', 'Non'])],
    handicap_reconnu_mdph__c: []
  };
};

export const inscriptionPatterns = () => {
  return {
    hed__gender__c: [
      //   (value: string) =>
      //     responseMaker(
      //       validator.isIn(value, ['Male', 'Female', 'Non-binary']),
      //       'contains'
      //     )
    ],
    lastname: [
      //   (value: string) =>
      //     responseMaker(validator.isLength(value, { min: 1 }), 'length'),
      //   (value: string) => responseMaker(validator.isAlpha(value), 'isAlpha')
    ],
    firstname: [
      // (value: string) =>
      //   responseMaker(validator.isLength(value, { min: 1 }), 'length'),
      // (value: string) => responseMaker(validator.isAlpha(value), 'isAlpha')
    ],
    birthdate: [
      (value: string) =>{
      console.log("🚀 ~ file: definitions.ts ~ line 83 ~ inscriptionPatterns ~ value", value)
        let date = moment(value)
        console.log(date.get('years'))
        if(date.get('years') < 1900){
          date.year(1900)
        }
        return date.format('YYYY-MM-DD')
      }

    ],
    ville_de_naissance__c: [],
    d_partement_de_naissance__c: [],
    hed__citizenship__c: [],
    hed__dual_citizenship__c: [],
    mailingstreet: [],
    mailingpostalcode: [],
    mailingcity: [],
    indicatif_pays_t_l_phone_mobile__c: [],
    mobilephone: [],
    n_national_ine_ou_bea__c: [],
    boursier__c: [(value: string) => validator.isIn(value, ['Oui', 'Non'])],
    handicap_reconnu_mdph__c: []
  };
};
