import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Trip } from '../../../../../services/api/Trip/interface';
import contactPicklist from '../../../../../assets/data/contact_picklist.json';
import './TripForm.scss';

interface ContainerProps {
  trip: Trip;
  onChange: (data: any) => void;
  handleDelete?: (data: Trip) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const TripForm: React.FC<IProps> = (props) => {
  const { t, trip, onChange, handleDelete } = props;
  const { i18n } = useTranslation();

  const [form, setForm] = useState<Trip>(trip);
  const [years, setYears] = useState<string[]>([]);

  useEffect(() => {
    buildYears();
  }, []);

  useEffect(() => {
    setForm(trip);
  }, [trip.index]);

  const buildYears = () => {
    let given = parseInt(moment().format('YYYY'));
    let diff = given - 1901;
    let array = [''];
    for (let index = 0; index <= diff; index++) {
      let year = given - index;
      array.push(year.toString());
    }
    setYears(array);
  };
  const handleChange = (e: any): void => {
    let newForm = {
      ...form,
      [e.target.name]: e.target.value
    };
    setForm(newForm);
    onChange(newForm);
  };

  const onDelete = () => {
    if (handleDelete) {
      handleDelete(form);
    }
  };
  return (
    <div>
      <div>
        <h6 className="form-h6">{t('year')}</h6>
        <select
          value={form.ann_e__c}
          name="ann_e__c"
          onBlur={handleChange}
          onChange={handleChange}>
          {' '}
          {years.length > 0 &&
            _.map(years, (year) => {
              return (
                <option key={year} value={year}>
                  {' '}
                  {year}{' '}
                </option>
              );
            })}
        </select>
      </div>
      <div>
        <h6 className="form-h6">{t('duration')}</h6>
        <input
          value={form.duree__c}
          name="duree__c"
          onChange={handleChange}></input>
      </div>
      <div>
        <h6 className="form-h6">{t('country')}</h6>
        <select
          name="pays__c"
          value={form.pays__c}
          onBlur={handleChange}
          onChange={handleChange}>
          <option value={''}></option>

          {i18n.language === 'fr' &&
            _.chain(
              contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value.replace(/&#39;/g, "'")} key={p.value}> {p.label.replace(/&#39;/g, "'")} </option>;
                }
              })
              .value()}
          {i18n.language === 'en' &&
            _.chain(
              contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value.replace(/&#39;/g, "'")} key={p.value}> {p.value.replace(/&#39;/g, "'")} </option>;
                }
              })
              .value()}
        </select>
      </div>
      <div>
        <h6 className="form-h6">{t('stayPurpose')}</h6>
        <input value={form.objet__c} name="objet__c" onChange={handleChange} />
      </div>
      {handleDelete && (
        <div className="delete-line">
          <button className="btn-underline" onClick={onDelete}>
            <span>{t('word.delete')}</span>
          </button>
        </div>
      )}
      <hr></hr>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(TripForm)
);
