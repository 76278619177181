import fr from '../../public/assets/locales/fr/common.json'
import en from '../../public/assets/locales/en/common.json'

export interface IMapping {
    [propertyName: string]: string;
}

const frTranslation: IMapping = fr.word;
const enTranslation: IMapping = en.word;

class TranslationMiddleware {

    Translate(str: string): any {
        let language = localStorage.getItem('i18nextLng')
        if (language == 'fr'){
            return frTranslation[str]

        }
        else if (language == 'en')
            return enTranslation[str]
        return str
    }
}

export default new TranslationMiddleware();
