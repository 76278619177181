import React, { useEffect, useRef, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './Tooltip.scss';

interface ContainerProps {
  message: string;
}
type ChildProps = {};

interface IProps extends ContainerProps, ChildProps, WithTranslation {}
const Tooltip: React.FC<IProps> = (props) => {
  const { t, message } = props;
  const ref = useRef<HTMLDivElement>(null);

  function useOnClickOutside() {}
  const [isShown, setIsShown] = useState(false);

  return (
    <div className="tooltip">
      <button
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
        <InfoOutlinedIcon
          fontSize="small"
          style={{ color: 'var(--orange)' }}></InfoOutlinedIcon>
      </button>
      {isShown && (
        <div
          ref={ref}
          className={`tooltip-content ${!isShown ? 'collapsed' : ''}`}>
          <p className="tooltip-text">{message}</p>
        </div>
      )}
    </div>
  );
};
export default withTranslation(['modal'])(Tooltip);
