import axios, { AxiosResponse } from 'axios';
import * as i from './interface';
const url = process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;

class JuryService {
  getApplications(user_id?: string): Promise<AxiosResponse<i.ApplicationsResponse[]>> {
    return axios.get(`${url}/${user_id}/jury/applications`);
  }
  getBooks(user_id: string): Promise<AxiosResponse<i.BooksResponse[]>> {
    return axios.get(`${url}/${user_id}/jury/books`);
  }
  getApplication(user_id?: string, admission__c?: string): Promise<AxiosResponse<i.ApplicationsResponse>> {
    return axios.get(`${url}/${user_id}/jury/application/${admission__c}`);
  }
}
export default new JuryService();
export * from './interface';
