import React, { useEffect, useState } from 'react';
import './SnackbarAlert.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import AlertUtil from '../../utils/Alert/Alert';
import { skip, first, last } from 'rxjs/operators';
import { Alert, Snackbar } from '@mui/material';

interface ContainerProps {
  // open: boolean;
  // type: any;
  // duration: number;
  // message: string;
}
export interface AlertInterface {
  open: boolean;
  type: any;
  duration: number;
  message: string;
}

type ChildProps = {
  // handleClose: () => void;
};

interface IProps extends ContainerProps, ChildProps, WithTranslation {}
const SnackbarAlert: React.FC<IProps> = (props) => {
  const [test, setTest] = useState<Boolean>(false);
  const [alertData, setAlertData] = useState<AlertInterface | null>({
    open: false,
    type: 'error',
    duration: 0,
    message: ''
  });
  const { t } = props;

  useEffect(() => {
    AlertUtil.alert$.pipe(first()).subscribe((value: AlertInterface | null) => {
      if (value != null) {
        setAlertData(value);
      }
    });
    return () => {};
  });
  const handleClose = () => {
    setAlertData((prevState: any) => ({
      ...prevState,
      open: false
    }));
  };

  return (
    <div className="SnackbarAlert">
      <Snackbar
        open={alertData?.open}
        autoHideDuration={alertData?.duration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={handleClose}
          severity={alertData?.type}
          variant="outlined"
          style={{ backgroundColor: '#fff' }}>
          {alertData?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withTranslation()(SnackbarAlert);
