import React, { createElement, useEffect, useState } from 'react';
import './Home.scss';
import { useHistory, withRouter, RouteComponentProps  } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import HomeImage from '../../assets/images/junia-image-accueil.png';
import Logo from '../../assets/images/logo-junia.png';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HomeCatalogue from './components/HomeCatalogue/HomeCatalogue';
import Catalogue from './components/Catalogue/Catalogue';
import MissingElement from './components/MissingElement/MissingElement';
import Candidature from './components/Candidature/Candidature';
import Recapitulatif from './components/Recapitulatif/Recapitulatif';
import Payment from './components/Payment/Payment';
import ValidePayment from './components/ValidePayment/ValidePayment';
import ProceedPayment from './components/ProceedPayment/ProceedPayment';
import CandidatureService from '../../services/api/Candidature/CandidatureService';
import AuthService from '../../services/api/Auth/AuthService';
import { me } from '../../services/api/Contact/interface';
import { Application_hed } from '../../services/api/Candidature/interface';
import Alert from '../../utils/Alert/Alert';
interface ContainerProps {}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const HomePage: React.FC<IProps> = (props) => {
  const { t, location } = props;
  const [catalogue, setCatalogue] = useState(false);
  const [acceptedCandidature, setAcceptedCandidature] = useState<boolean>(true);
  const [me, setMe] = useState<me>({});
  const handleClick = () => {
    if (!acceptedCandidature) {
      setCatalogue(!catalogue);
    }
  };
  useEffect(() => {
    try {
      let state: any = location.state;
      if (state && state.catalogue && !acceptedCandidature) {
        setCatalogue(state.catalogue);
      }
    } catch (error) {
      console.log('🚀 ~ file: Home.tsx ~ line 40 ~ useEffect ~ error', error);
    }
    getMe();
  }, []);

  useEffect(() => {
    checkHasAccepted();
  }, [me]);

  const getMe = async () => {
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };

  const checkHasAccepted = async () => {
    if (me.id_heroku__c) {
      let has = await CandidatureService.getHasAccepted(me.id_heroku__c);
      console.log('has', has);
      setAcceptedCandidature(has.data ? true : false);
    }
  };

  return (
    <div className="home">
      {!catalogue ? (
        <HomeCatalogue
          handleClick={handleClick}
          canClick={!acceptedCandidature}
          me={me}></HomeCatalogue>
      ) : (
        <Catalogue handleClick={handleClick}></Catalogue>
      )}
    </div>
  );
};

export default withRouter(withTranslation(['home', 'common'])(HomePage));
