/**
 * This component displays the formations list to start an application for an agent's candidate
 */
import React, {  useEffect, useState } from 'react';

import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

//Components
import Catalogue from '../../../Home/components/Catalogue/Catalogue';

// Services
import AuthService from '../../../../services/api/Auth/AuthService';
import CandidatureService from '../../../../services/api/Candidature/CandidatureService';

// Interfaces
import { me } from '../../../../services/api/Contact/interface';

// Styles
import './CreateApplication.scss';

interface ContainerProps {}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const CreateApplication: React.FC<IProps> = (props) => {
  const { t, location } = props;
  const [catalogue, setCatalogue] = useState(false);
  const [acceptedCandidature, setAcceptedCandidature] = useState<boolean>(true);
  const [me, setMe] = useState<me>({});  
  const { candidate_id } = useParams<{candidate_id: string}>();

  useEffect(() => {
    try {
      let state: any = location.state;
      if (state && state.catalogue && !acceptedCandidature) {
        setCatalogue(state.catalogue);
      }
    } catch (error) {
      console.log('🚀 ~ file: Home.tsx ~ line 40 ~ useEffect ~ error', error);
    }
    getMe();
  }, []);

  useEffect(() => {
    checkHasAccepted();
  }, [me]);

  const handleClick = () => {
    if (!acceptedCandidature) {
      setCatalogue(!catalogue);
    }
  };

  const getMe = async () => {
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };

  const checkHasAccepted = async () => {
    if (candidate_id) {
      const hasAccepted = await CandidatureService.getHasAccepted(candidate_id);
      setAcceptedCandidature(hasAccepted.data ? true : false);
    }
  };

  return (
    <div className="home">
        <Catalogue handleClick={handleClick}></Catalogue>
    </div>
  );
};

export default withRouter(withTranslation(['home', 'common'])(CreateApplication));
