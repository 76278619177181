import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import API from '..';
import { CompetenceLinguistique } from './interface';
const url =
  process.env.REACT_APP_BACK_URL || process.env.REACT_APP_BACK_URL_DEV;
class CompetenceLinguistiqueService {
  getCompetencesLinguistique(user_id: string): Promise<AxiosResponse> {
    return axios.get(`${url}/${user_id}/contactlanguage`);
  }

  patchCompetencesLinguistique(
    user_id: string,
    educationHistory: CompetenceLinguistique,
    files?: any[],
    typesFile?: string[]
  ): Promise<AxiosResponse> {
    let formData = new FormData(); //formdata object

    _.map(files, (o) => {
      if (typesFile && typesFile.includes(o.type)) {
        _.map(o.files, (file) => {
          formData.append(o.type, file);
        });
      }
    });
    _.map(educationHistory, (value: any, key: any) => {
      if (value) {
        formData.append(key, value);
      }
    });
    return axios.patch(
      `${url}/${user_id}/contactlanguage/${educationHistory.id_heroku__c}`,
      formData
    );
  }
  createCompetencesLinguistique(
    user_id: string,
    educationHistory: CompetenceLinguistique,
    files?: any[],
    typesFile?: string[]
  ): Promise<AxiosResponse> {
    let formData = new FormData(); //formdata object

    _.map(files, (o) => {
      if (typesFile && typesFile.includes(o.type)) {
        _.map(o.files, (file) => {
          formData.append(o.type, file);
        });
      }
    });
    _.map(educationHistory, (value: any, key: any) => {
      if (value) {
        formData.append(key, value);
      }
    });
    return axios.post(`${url}/${user_id}/contactlanguage`, formData);
  }

  /**
   * Remove a contact language
   * @param user_id Heroku ID of the user
   * @param language_id Heroku ID of a contact language
   * @returns {Promise} an empty promise
   */
  remove(user_id: string, language_id: string): Promise<AxiosResponse<void>> {
    return axios.delete(`${url}/${user_id}/contactlanguage/${language_id}`);
  }
}
export default new CompetenceLinguistiqueService();
