/**
 * This component displays the personal informations
 */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  useTranslation,
  withTranslation,
  WithTranslation
} from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DisplayFiles from '../../../../components/DisplayFiles/DisplayFiles';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import UploadFile from '../../../../components/UploadFile/UploadFile';
import { Contact } from '../../../../services/api/Contact/interface';
import FilesService from '../../../../services/api/Files/FilesService';
import {
  FileToUpload,
  PieceComplementaire
} from '../../../../services/api/Files/interface';
import {
  checkRequested,
  formatUnicode
} from '../../../../utils/validator/validator';

import './PersonnalInfos.scss';
import contactPicklist from '../../../../assets/data/contact_picklist.json';
import countryCodes from '../../../../assets/data/country_code.json';
import DisplayBlobFiles from '../../../../components/DisplayBlobFiles/DisplayBlobFiles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface ContainerProps {
  contact: Contact;
  onChange: (data: any, canNext: boolean) => void;
  getContact?: () => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

// MultiSelect props
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const PersonnalInfos: React.FC<IProps> = (props) => {
  const { t, contact, onChange, getContact } = props;
  const { i18n } = useTranslation();
  const required = {
    inscriptionFields: [
      'hed__gender__c',
      'lastname',
      'firstname',
      'birthdate',
      'ville_de_naissance__c',
      'pays_de_naissance__c',
      'hed__citizenship__c',
      'dual_citizenship_tech__c',
      'mailingstreet',
      'mailingpostalcode',
      'mailingcity',
      'mailingcountry',
      'indicatif_pays_t_l_phone_mobile__c',
      'mobilephone'
    ]
  };

  const [form, setForm] = useState<Contact>(contact);
  const [fileList, setFileList] = useState<FileToUpload[]>([]);
  const [formatedPisteIntl, setFormatedPisteIntl] = useState<string[]>([])
  const [isMultiSelectOpen, setIsMultiSelectOpen] = useState<boolean>(false);

  useEffect(() => {
    setForm(contact);
  }, [contact]);

  useEffect(() => {
    if(form.origine_de_la_piste_intl__c) {
      setFormatedPisteIntl(form.origine_de_la_piste_intl__c.split(';'))
    }
  }, [form]);

  useEffect(() => {
    setFileList([]);
  }, [contact.Files]);

  useEffect(() => {
    let list = [...required.inscriptionFields];
    if (contact.dual_citizenship_tech__c === 'Oui') {
      list.push('hed__dual_citizenship__c');
    }
    if (contact.type_tech__c === 'Francais') {
      list.push(
        'boursier__c',
        'handicap_reconnu_mdph__c',
        'n_national_ine_ou_bea__c'
      );
    } else if (contact.type_tech__c === 'International') {
      list.push(
        'candidat_international_partenaire__c',
        'inscrit_sur_le_portail_etudes_en_france__c',
        'origine_de_la_piste_intl__c'
      );
    }
    if (
      contact.pays_de_naissance__c === 'France' ||
      contact.pays_de_naissance__c === 'FRANCE'
    ) {
      list.push('d_partement_de_naissance__c');
    }
    let canNext = checkRequested(list, contact);
    onChange({ data: contact }, canNext);
  }, []);
  const handleChange = async (e: any) => {
    let value = e.target.value;

    let upperCaseList = [
      'mailingcity',
      'ville_de_naissance__c',
      'n_national_ine_ou_bea__c'
    ];

    //INE CHECK
    if (e.target.name === 'n_national_ine_ou_bea__c') {
      if (e.target.value?.length > 11) return;
    }

    if (upperCaseList.includes(e.target.name)) {
      value = value.toUpperCase();
    }
    let newForm = {
      ...form,
      [e.target.name]: value
    };
    if (e.target.name === 'origine_de_la_piste_intl__c') {
      setFormatedPisteIntl(value);
      newForm = { ...newForm, origine_de_la_piste_intl__c: value.join(';') };
      const isOtherInList = value.find((val: string) => val === 'Other');
      if (!isOtherInList) {
        newForm = { ...newForm, origine_de_la_piste_intl_autre__c: '' };
      }
      setIsMultiSelectOpen(false);
    }
    setForm(newForm);
    let list = [...required.inscriptionFields];
    if (newForm.dual_citizenship_tech__c === 'Oui') {
      list.push('hed__dual_citizenship__c');
    }
    if (
      newForm.pays_de_naissance__c === 'FRANCE' ||
      newForm.pays_de_naissance__c === 'France'
    ) {
      list.push('d_partement_de_naissance__c');
    }
    if (contact.type_tech__c === 'Francais') {
      list.push(
        'boursier__c',
        'handicap_reconnu_mdph__c',
        'n_national_ine_ou_bea__c'
      );
    } else if (contact.type_tech__c === 'International') {
      list.push(
        'candidat_international_partenaire__c',
        'inscrit_sur_le_portail_etudes_en_france__c',
        'origine_de_la_piste_intl__c'
      );
    }
    let canNext = checkRequested(list, newForm);
    onChange({ data: newForm }, canNext);
  };

  const [pistefr, setPistefr] = useState<string[]>([
    'Un membre de votre famille',
    'Un ancien élève',
    'Un de vos professeurs',
    'Salon',
    'Salon virtuel',
    'Journée Portes Ouvertes JUNIA',
    'Immersion JUNIA',
    'Site web JUNIA',
    'Publicité dans les guides étudiants',
    'Presse',
    'Réseaux sociaux',
    'Moteur de recherche Internet',
    'Autre'
  ]);
  const [piste, setPiste] = useState<string[]>([
    'Vos parents, membres de la famille',
    'Un ancien élève de l’école',
    'Un de vos professeurs',
    'Un agent',
    'Salon',
    'Salon virtuel',
    'Journée Portes Ouvertes en virtuel à Junia – HEI · ISA · ISEN',
    'Moteur de recherche sur internet (Google,…)',
    'Sites web Junia – HEI · ISA · ISEN',
    'Publicité dans les guides étudiants',
    'Campus France',
    'Presse',
    'Affichage public (publicité en extérieur)',
    'Réseaux sociaux',
    'Autre'
  ]);

  const handleSubmit = (e: any) => {
    e.preventDefautl();
  };

  const handleFileChange = (files: File[], type: string) => {
    let list = [...fileList];
    let found = _.findIndex(list, (f) => f.type === type);
    if (found >= 0) {
      let data = [...list[found].files, ...files];
      list.splice(found, 1, { type: type, files: data });
    } else {
      list.push({ type: type, files: files });
    }

    setFileList(list);
    let requestList = [...required.inscriptionFields];
    if (contact.dual_citizenship_tech__c === 'Oui') {
      requestList.push('hed__dual_citizenship__c');
    }
    if (contact.type_tech__c === 'Francais') {
      requestList.push(
        'boursier__c',
        'handicap_reconnu_mdph__c',
        'n_national_ine_ou_bea__c'
      );
    } else if (contact.type_tech__c === 'International') {
      requestList.push(
        'candidat_international_partenaire__c',
        'inscrit_sur_le_portail_etudes_en_france__c'
      );
    }
    if (
      contact.pays_de_naissance__c === 'FRANCE' ||
      contact.pays_de_naissance__c === 'France'
    ) {
      requestList.push('d_partement_de_naissance__c');
    }
    let canNext = checkRequested(requestList, contact);
    onChange({ data: form, files: { type: type, files: files } }, canNext);
  };

  const handleFileDelete = (file: any, type: string, fileType: string) => {
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contact?.id_heroku__c) {
          FilesService.removeFile(
            file?.id_heroku__c,
            contact?.id_heroku__c
          ).then((res) => {
            let found = _.find(fileList, (f) => f.type === type);

            if (getContact) {
              getContact();

              // setFileList([])
            }
          });
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(liste, (f) => f.type === file.type);
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);

        break;
    }
  };

  const findMax = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined
  ) => {
    if (files) {
      let foundCurrent = _.find([...fileList], (f) => f.type === type);
      let foundSave = _.filter([...files], (f) => f.type__c === type);
      let currentLength = foundCurrent ? foundCurrent.files.length : 0;
      let saveLength = foundSave ? foundSave.length : 0;
      return currentLength + saveLength;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <h6 className="form-h6">
        {t('gender')}
        <span className="form-required">*</span>
      </h6>
      <div className="custom-checkbox">
        <input
          name="hed__gender__c"
          type="radio"
          className="custom-check"
          value="Female"
          id="female"
          checked={form.hed__gender__c === 'Female'}
          onChange={handleChange}
        />
        <label
          htmlFor="female"
          className={form.hed__gender__c === 'Female' ? 'selected' : ''}>
          {t('female')}
        </label>
        <input
          name="hed__gender__c"
          type="radio"
          className="custom-check"
          value="Male"
          id="Male"
          checked={form.hed__gender__c === 'Male'}
          onChange={handleChange}
        />
        <label
          htmlFor="Male"
          className={form.hed__gender__c === 'Male' ? 'selected' : ''}>
          {t('male')}
        </label>
        <input
          name="hed__gender__c"
          type="radio"
          className="custom-check"
          value="Non-Binary"
          id="Non-Binary"
          checked={form.hed__gender__c === 'Non-Binary'}
          onChange={handleChange}
        />
        <label
          htmlFor="Non-Binary"
          className={form.hed__gender__c === 'Non-Binary' ? 'selected' : ''}>
          {t('nonBinary')}
        </label>
      </div>
      <div>
        <h6 className="form-h6">
          {t('lastname')}
          <span className="form-required">*</span>
        </h6>
        <input
          name="lastname"
          value={form.lastname}
          onBlur={handleChange}
          onChange={handleChange}
        />
      </div>
      <div>
        <h6 className="form-h6">
          {t('firstname')}
          <span className="form-required">*</span>
        </h6>
        <input
          name="firstname"
          value={form.firstname}
          onChange={handleChange}
          onBlur={handleChange}
        />
      </div>
      <div>
        <h6 className="form-h6">
          {t('birthday')}
          <span className="form-required">*</span>
        </h6>
        <input
          type="date"
          name="birthdate"
          value={moment(form.birthdate).format('YYYY-MM-DD')}
          min={'1900-01-01'}
          max={moment().format('YYYY-MM-DD')}
          onBlur={handleChange}
          onChange={handleChange}></input>
      </div>
      <div>
        <h6 className="form-h6">
          {t('birthCity')}
          <span className="form-required">*</span>
        </h6>
        <input
          name="ville_de_naissance__c"
          value={form.ville_de_naissance__c}
          onBlur={handleChange}
          onChange={handleChange}
        />
      </div>
      <div>
        <h6 className="form-h6">
          {t('birthCountry')}
          <span className="form-required">*</span>
        </h6>
        <select
          name="pays_de_naissance__c"
          value={form.pays_de_naissance__c}
          onBlur={handleChange}
          onChange={handleChange}>
          <option value={''}></option>

          {i18n.language === 'fr' &&
            _.chain(
              contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  let value = formatUnicode(p.value);
                  return (
                    <option key={value} value={value}>
                      {' '}
                      {formatUnicode(p.label).toUpperCase()}{' '}
                    </option>
                  );
                }
              })
              .value()}
          {i18n.language === 'en' &&
            _.chain(
              contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  let value = formatUnicode(p.value);
                  return (
                    <option key={value} value={value}>
                      {' '}
                      {value}{' '}
                    </option>
                  );
                }
              })
              .value()}
        </select>
      </div>
      {/* CONDITION AFFICHAGE : Si pays != France = Departement champ caché */}
      {form.pays_de_naissance__c === 'France' && (
        <div>
          <h6 className="form-h6">
            {t('birthDepartment')}
            <span className="form-required">*</span>
          </h6>
          <select
            name="d_partement_de_naissance__c"
            value={form.d_partement_de_naissance__c}
            onBlur={handleChange}
            onChange={handleChange}>
            <option value={''}></option>

            {_.chain(
              contactPicklist.picklistFieldValues['Departement__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  return <option value={p.value}> {p.label} </option>;
                }
              })
              .value()}
          </select>
        </div>
      )}
      <div>
        <h6 className="form-h6">
          {t('citizenship')}
          <span className="form-required">*</span>
        </h6>
        <select
          name="hed__citizenship__c"
          value={form.hed__citizenship__c}
          onBlur={handleChange}
          onChange={handleChange}>
          <option value={''}></option>

          {i18n.language === 'fr' &&
            _.chain(
              contactPicklist.picklistFieldValues['hed__Citizenship__c'].values
            )
              .orderBy('label')
              .map((p) => {
                if (p && p.value) {
                  let value = formatUnicode(p.value);
                  return (
                    <option key={value} value={value}>
                      {' '}
                      {formatUnicode(p.label).toUpperCase()}{' '}
                    </option>
                  );
                }
              })
              .value()}
          {i18n.language === 'en' &&
            _.chain(
              contactPicklist.picklistFieldValues['hed__Citizenship__c'].values
            )
              .map((p) => {
                if (p && p.value) {
                  let value = formatUnicode(p.value);
                  return (
                    <option key={value} value={value}>
                      {' '}
                      {value.toUpperCase()}{' '}
                    </option>
                  );
                }
              })
              .value()}
        </select>
      </div>
      <div>
        <h6 className="form-h6">
          {t('doubleCitizenship')} <span className="form-required">*</span>
        </h6>
        {/* hed__dual_citizenship__c */}
        <div className="custom-checkbox">
          <input
            name="dual_citizenship_tech__c"
            type="radio"
            className="custom-check"
            value="Oui"
            id="dualyes"
            checked={form.dual_citizenship_tech__c === 'Oui'}
            onChange={handleChange}
          />
          <label
            htmlFor="dualyes"
            className={
              form.dual_citizenship_tech__c === 'Oui' ? 'selected' : ''
            }>
            {t('yes')}
          </label>
          <input
            name="dual_citizenship_tech__c"
            type="radio"
            className="custom-check"
            value="Non"
            id="dualno"
            checked={form.dual_citizenship_tech__c === 'Non'}
            onChange={handleChange}
          />
          <label
            htmlFor="dualno"
            className={
              form.dual_citizenship_tech__c === 'Non' ? 'selected' : ''
            }>
            {t('no')}
          </label>
        </div>
      </div>
      {form.dual_citizenship_tech__c === 'Oui' ? (
        <div>
          <h6 className="form-h6">
            {t('doubleCitizenship')} <span className="form-required">*</span>
          </h6>
          <select
            name="hed__dual_citizenship__c"
            value={form.hed__dual_citizenship__c}
            onBlur={handleChange}
            onChange={handleChange}>
            <option value={''}></option>
            {i18n.language === 'fr' &&
              _.chain(
                contactPicklist.picklistFieldValues['hed__Dual_Citizenship__c']
                  .values
              )
                .map((p) => {
                  if (p && p.value) {
                    let value = formatUnicode(p.value).toUpperCase();
                    return (
                      <option key={value} value={value}>
                        {' '}
                        {formatUnicode(p.label).toUpperCase()}{' '}
                      </option>
                    );
                  }
                })
                .value()}
            {i18n.language === 'en' &&
              _.chain(
                contactPicklist.picklistFieldValues['hed__Dual_Citizenship__c']
                  .values
              )
                .map((p) => {
                  if (p && p.value) {
                    let value = formatUnicode(p.value).toUpperCase();
                    return (
                      <option key={value} value={value}>
                        {' '}
                        {value}{' '}
                      </option>
                    );
                  }
                })
                .value()}
          </select>
        </div>
      ) : null}

      <div>
        <h6 className="form-h6">
          {t('idCard')}{' '}
          {contact?.inscription_tech__c === 'Terminée' && (
            <span className="form-required">*</span>
          )}
        </h6>
        <UploadFile
          max={2}
          current={findMax('identity_card', 2, contact.Files)}
          onChange={(data) => handleFileChange(data, 'identity_card')}
          type="pdf"></UploadFile>
        <DisplayBlobFiles
          files={fileList}
          type="identity_card"
          onDelete={(data) =>
            handleFileDelete(data, 'identity_card', 'blob')
          }></DisplayBlobFiles>
        <DisplayFiles
          files={form.Files}
          type="identity_card"
          onDelete={(data) =>
            handleFileDelete(data, 'identity_card', 'uploaded')
          }></DisplayFiles>
      </div>
      <div>
        <h6 className="form-h6">
          {t('address')}
          <span className="form-required">*</span>
        </h6>
        <input
          name="mailingstreet"
          value={form.mailingstreet}
          onBlur={handleChange}
          onChange={handleChange}
        />
      </div>
      <div>
        <div>
          <h6 className="form-h6">
            {t('postalCode')}
            <span className="form-required">*</span>
          </h6>
          <input
            name="mailingpostalcode"
            value={form.mailingpostalcode}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
        <div>
          <h6 className="form-h6">
            {t('city')}
            <span className="form-required">*</span>
          </h6>
          <input
            name="mailingcity"
            value={form.mailingcity}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
        <div>
          <h6 className="form-h6">
            {t('country')}
            <span className="form-required">*</span>
          </h6>
          <select
            name="mailingcountry"
            value={form.mailingcountry}
            onBlur={handleChange}
            onChange={handleChange}>
            <option value={''}></option>
            {i18n.language === 'fr' &&
              _.chain(
                contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
              )
                // .pick(1)
                .map((p) => {
                  if (p && p.value) {
                    return (
                      <option
                        value={p.value.replace(/&#39;/g, "'")}
                        key={p.value}>
                        {p.label.replace(/&#39;/g, "'")}
                      </option>
                    );
                  }
                })
                .value()}
            {i18n.language === 'en' &&
              _.chain(
                contactPicklist.picklistFieldValues['Liste_pays_FR__c'].values
              )
                // .pick(1)
                .map((p) => {
                  if (p && p.value) {
                    return (
                      <option
                        value={p.value.replace(/&#39;/g, "'")}
                        key={p.value}>
                        {p.value.replace(/&#39;/g, "'")}
                      </option>
                    );
                  }
                })
                .value()}
          </select>
        </div>
      </div>
      <div>
        <div>
          <h6 className="form-h6">
            {t('indicative')} <span className="form-required">*</span>
          </h6>
          <select
            name="indicatif_pays_t_l_phone_mobile__c"
            value={form.indicatif_pays_t_l_phone_mobile__c}
            onBlur={handleChange}
            onChange={handleChange}>
            <option value={''}></option>
            {_.chain(countryCodes)
              // .pick(1)
              .map((p) => {
                if (p && p.dial_code) {
                  return (
                    <option value={p.dial_code} key={p.code}>
                      {' '}
                      {p.name} ({p.dial_code}){' '}
                    </option>
                  );
                }
              })
              .value()}
          </select>
        </div>
        <div>
          <h6 className="form-h6">
            {t('phone')} <span className="form-required">*</span>
          </h6>
          <input
            type="tel"
            name="mobilephone"
            value={form.mobilephone}
            onBlur={handleChange}
            onChange={handleChange}
          />
        </div>
        {form.type_tech__c === 'International' && (
          <>
            <div>
              <h6 className="form-h6">
                {t('frenchProcess')} <span className="form-required">*</span>
              </h6>
              <div className="custom-checkbox">
                <input
                  name="inscrit_sur_le_portail_etudes_en_france__c"
                  type="radio"
                  value="Oui"
                  className="custom-check"
                  onChange={handleChange}
                  id="frProcess"
                  checked={
                    form.inscrit_sur_le_portail_etudes_en_france__c === 'Oui'
                  }
                />
                <label
                  htmlFor="frProcess"
                  className={
                    form.inscrit_sur_le_portail_etudes_en_france__c === 'Oui'
                      ? 'selected'
                      : ''
                  }>
                  {t('yes')}
                </label>
                <input
                  name="inscrit_sur_le_portail_etudes_en_france__c"
                  type="radio"
                  value="Non"
                  className="custom-check"
                  onChange={handleChange}
                  id="notFrProcess"
                  checked={
                    form.inscrit_sur_le_portail_etudes_en_france__c === 'Non'
                  }
                />
                <label
                  htmlFor="notFrProcess"
                  className={
                    form.inscrit_sur_le_portail_etudes_en_france__c === 'Non'
                      ? 'selected'
                      : ''
                  }>
                  {t('no')}
                </label>
              </div>
            </div>
            {form.inscrit_sur_le_portail_etudes_en_france__c === 'Oui' && (
              <div>
                <h6 className="form-h6">{t('ifFrenchProcess')}</h6>
                <input
                  name="n_campus_france_si_concern__c"
                  value={form.n_campus_france_si_concern__c}
                  onBlur={handleChange}
                  onChange={handleChange}
                />
              </div>
            )}
            <div>
              <h6 className="form-h6">
                {t('internationalAdmission')}{' '}
                <span className="form-required">*</span>{' '}
                <Tooltip
                  message={t('tooltip.internationalAdmission')}></Tooltip>
              </h6>
              <div className="custom-checkbox">
                <input
                  name="candidat_international_partenaire__c"
                  type="radio"
                  value="Oui"
                  className="custom-check"
                  onChange={handleChange}
                  id="yesAdmission"
                  checked={form.candidat_international_partenaire__c === 'Oui'}
                />
                <label
                  htmlFor="yesAdmission"
                  className={
                    form.candidat_international_partenaire__c === 'Oui'
                      ? 'selected'
                      : ''
                  }>
                  {t('yes')}
                </label>
                <input
                  name="candidat_international_partenaire__c"
                  type="radio"
                  value="Non"
                  className="custom-check"
                  onChange={handleChange}
                  id="noAdmission"
                  checked={form.candidat_international_partenaire__c === 'Non'}
                />
                <label
                  htmlFor="noAdmission"
                  className={
                    form.candidat_international_partenaire__c === 'Non'
                      ? 'selected'
                      : ''
                  }>
                  {t('no')}
                </label>
              </div>
            </div>
          </>
        )}
      </div>
      {form.type_tech__c === 'Francais' && (
        <>
          <div>
            <h6 className="form-h6">
              {t('ineNumber')} <span className="form-required">*</span>
            </h6>
            <input
              name="n_national_ine_ou_bea__c"
              value={form.n_national_ine_ou_bea__c}
              onBlur={handleChange}
              onChange={handleChange}
            />
          </div>
          <div>
            <h6 className="form-h6">
              {t('scolarshipHolder')} <span className="form-required">*</span>
            </h6>
            <div className="custom-checkbox">
              <input
                name="boursier__c"
                type="radio"
                value="Oui"
                className="custom-check"
                onChange={handleChange}
                id="isBoursier"
                checked={form.boursier__c === 'Oui'}
              />
              <label
                htmlFor="isBoursier"
                className={form.boursier__c === 'Oui' ? 'selected' : ''}>
                {t('yes')}
              </label>
              <input
                name="boursier__c"
                type="radio"
                value="Non"
                className="custom-check"
                onChange={handleChange}
                id="notBoursier"
                checked={form.boursier__c === 'Non'}
              />
              <label
                htmlFor="notBoursier"
                className={form.boursier__c === 'Non' ? 'selected' : ''}>
                {t('no')}
              </label>
            </div>
          </div>
          {form.boursier__c === 'Oui' && (
            <div>
              <h6 className="form-h6">{t('scolarshipHolderJustif')}</h6>
              <UploadFile
                max={1}
                current={findMax('scholarship', 1, contact.Files)}
                onChange={(data) => handleFileChange(data, 'scholarship')}
                type="pdf"></UploadFile>
              <DisplayBlobFiles
                files={fileList}
                type="scholarship"
                onDelete={(data) =>
                  handleFileDelete(data, 'scholarship', 'blob')
                }></DisplayBlobFiles>
              <DisplayFiles
                files={form.Files}
                type="scholarship"
                onDelete={(data) =>
                  handleFileDelete(data, 'scholarship', 'uploaded')
                }></DisplayFiles>
            </div>
          )}
          <div>
            <h6 className="form-h6">
              {t('handicap')} <span className="form-required">*</span>
            </h6>
            <div className="custom-checkbox">
              <input
                name="handicap_reconnu_mdph__c"
                type="radio"
                value="Oui"
                className="custom-check"
                onChange={handleChange}
                id={'isHandicape'}
                checked={form.handicap_reconnu_mdph__c === 'Oui'}
              />
              <label
                htmlFor="isHandicape"
                className={
                  form.handicap_reconnu_mdph__c === 'Oui' ? 'selected' : ''
                }>
                {t('yes')}
              </label>
              <input
                name="handicap_reconnu_mdph__c"
                type="radio"
                value="Non"
                className="custom-check"
                onChange={handleChange}
                id={'notHandicape'}
                checked={form.handicap_reconnu_mdph__c === 'Non'}
              />
              <label
                htmlFor="notHandicape"
                className={
                  form.handicap_reconnu_mdph__c === 'Non' ? 'selected' : ''
                }>
                {t('no')}
              </label>
            </div>
          </div>
        </>
      )}
      <div>
        {contact.type_tech__c === 'Francais' && (
          <>
            <hr></hr>
            <div>
              <h6 className="form-h6">{t('knowJunia')}</h6>
              <select
                name="origine_de_la_piste_fr__c"
                onChange={handleChange}
                onBlur={handleChange}
                value={form.origine_de_la_piste_fr__c}>
                <option value={''}></option>
                {pistefr.length > 0 &&
                  _.map(pistefr, (pifr) => {
                    return (
                      <option value={pifr} key={pifr}>
                        {' '}
                        {pifr}{' '}
                      </option>
                    );
                  })}
              </select>
            </div>
            {form.origine_de_la_piste_fr__c === 'Autre' && (
              <div>
                <h6 className="form-h6">{t('other')}</h6>
                <input
                  name="origine_de_la_piste_fr_autre__c"
                  value={form.origine_de_la_piste_fr_autre__c}
                  onBlur={handleChange}
                  onChange={handleChange}
                />
              </div>
            )}
          </>
        )}
        {contact.type_tech__c === 'International' && (
          <>
            <hr></hr>
            <div>
              <h6 className="form-h6">{t('knowJunia')} {t('multipleChoices')} <span className="form-required">*</span></h6> 
              <Select
                name="origine_de_la_piste_intl__c"
                className='multi-select'
                onClick={(e) => setIsMultiSelectOpen(false)}
                onOpen={(e) => setIsMultiSelectOpen(true)}
                onChange={handleChange}
                onBlur={handleChange}
                value={formatedPisteIntl}
                MenuProps={MenuProps}
                open={isMultiSelectOpen}
                IconComponent={KeyboardArrowDownIcon}
                multiple>
                {contactPicklist.picklistFieldValues['Origine_de_la_piste_INTL__c'].values.map((piste: any) => {
                    if(i18n.language === 'fr') {
                      return (
                        <MenuItem value={piste.value} key={piste.value}>{piste.label}
                        </MenuItem>
                      );
                    }
                    if(i18n.language === 'en') {
                      return (
                        <MenuItem value={piste.value} key={piste.value}>{piste.value}
                        </MenuItem>
                      );
                    }
                })}
              </Select>
            </div>
            {form.origine_de_la_piste_intl__c?.includes('Other') && (
              <div>
                <h6 className="form-h6">{t('other')}</h6>
                <input
                  name="origine_de_la_piste_intl_autre__c"
                  value={form.origine_de_la_piste_intl_autre__c}
                  onBlur={handleChange}
                  onChange={handleChange}
                />
              </div>
            )}
          </>
        )}
      </div>
      {contact.type_tech__c === 'Echange' && (
        <div>
          <h6 className="form-h6">{t('exchangeHandicap')}</h6>
          <div className="custom-checkbox">
            <input
              name="handicap__c"
              type="radio"
              className="custom-check"
              value="Oui"
              id="handicaplyes"
              checked={form.handicap__c === 'Oui'}
              onChange={handleChange}
            />
            <label
              htmlFor="handicaplyes"
              className={form.handicap__c === 'Oui' ? 'selected' : ''}>
              {i18n.language === 'fr' ? 'Oui' : 'Yes'}
            </label>
            <input
              name="handicap__c"
              type="radio"
              className="custom-check"
              value="Non"
              id="handicapno"
              checked={form.handicap__c === 'Non'}
              onChange={handleChange}
            />
            <label
              htmlFor="handicapno"
              className={form.handicap__c === 'Non' ? 'selected' : ''}>
              {i18n.language === 'fr' ? 'Non' : 'No'}
            </label>
          </div>
          {form.handicap__c === 'Oui' && (
            <div>
              <h6 className="form-h6">{t('handicapSpecify')}</h6>
              <input
                name="precisions_handicap__c"
                value={form.precisions_handicap__c}
                onBlur={handleChange}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(PersonnalInfos)
);
