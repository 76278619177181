import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import logoFR from '../../../../assets/images/junia-icon-fr.png';
import logoETR from '../../../../assets/images/junia-icon-etranger.png';

import './EducationSystem.scss';
import { Contact } from '../../../../services/api/Contact/interface';

interface ContainerProps {
  contact: Contact;
  onChange: (data: Contact, canNext: boolean) => void;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const EducationSystem: React.FC<IProps> = (props) => {
  const { t, contact, onChange } = props;

  const [comeFromFrenchEducationSystem, setComeFromFrenchEducationSystem] =
    useState<null | boolean>(null);

  const handleEducationSystemChange = (value: boolean) => {
    let updatedContact = { ...contact };
    setComeFromFrenchEducationSystem(value);
    onChange({ type_tech__c: value ? 'Francais' : 'Non défini' }, true);
  };

  return (
    <div className="education-sytem">
      <button
        className={`card-button ${
          comeFromFrenchEducationSystem === true ? 'selected' : ''
        }`}
        type="button"
        onClick={() => handleEducationSystemChange(true)}>
        <div className="card-button-icon">
          <img src={logoFR} alt="icone France" />
        </div>
        <div className="card-button-text">
          <p>{t('french_system_title')}</p>
          <span>{t('french_system_description')}</span>
        </div>
      </button>
      <button
        className={`card-button ${
          comeFromFrenchEducationSystem === false ? 'selected' : ''
        }`}
        type="button"
        onClick={() => handleEducationSystemChange(false)}>
        <div className="card-button-icon">
          <img src={logoETR} alt="icone France" />
        </div>
        <div className="card-button-text">
          <p>{t('international_system_title')}</p>
          <span>{t('international_system_description')}</span>
        </div>
      </button>
    </div>
  );
};

export default withRouter(
  withTranslation(['createAccount', 'common', 'form'])(EducationSystem)
);
