import React, { createElement, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { 
  Link,
  withRouter,
  RouteComponentProps,
  useHistory,
  useParams
    } from 'react-router-dom';
import i18n from '../../i18n';

import junialogo from '../../assets/images/junialogoviolet.png';
import flagUK from '../../assets/images/flagUK.png';
import flagFR from '../../assets/images/flagFR.png';
import './ResetPassword.scss';
import AuthService from '../../services/api/Auth/AuthService';
import Spinner from '../../utils/Spinner/Spinner';
import Alert from '../../utils/Alert/Alert';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface ContainerProps {}

type ChildProps = {};

type AuthForm = {
  password?: string;
  confirmPassword?: string;
  token?: string;
  email?: string;
};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const formLoginInitialState = {
  email: '',
  password: ''
};

const formRegisterFields = {
  confirmPassword: ''
};

const ResetPassword: React.FC<IProps> = (props) => {
  const { t } = props;
  const [active, setActive] = useState('fr');
  const history = useHistory();
  const [form, setForm] = useState<AuthForm>(formLoginInitialState);
  const [passwordHidden, setPasswordHidden] = useState<boolean>(true);
  let { email, token } = useParams<{ email: string; token: string }>();
  useEffect(() => {
    if (!token || !email) {
      history.push('/');
    } else {
      setForm({ ...form, email: email, token: token });
    }
  }, []);

  const handleChange = (e: any): void => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    Spinner.setSpinner(true);

    AuthService.resetPassword(form)
      .then((res) => {
        Spinner.setSpinner(false);
        Alert.setAlert({
          duration: 3000,
          message: t('word.resetPassword'),
          open: true,
          type: 'success'
        });
        history.push('/');
      })
      .catch((err) => {
        Spinner.setSpinner(false);
        Alert.setAlert({
          duration: 3000,
          message: t(
            err.response.data.message
              ? err.response.data.message
              : err.response.data
          ),
          open: true,
          type: 'error'
        });
      });
  };

  return (
    <div className="forgot">
      <div className="forgot-container">
        <div className="forgot__header">
          <img src={junialogo} alt="junia logo" />
          <h5>{t('page_title')}</h5>
          <div className="forgot__header__language-cont">
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('fr');
                setActive('fr');
              }}
              className={active === 'fr' ? 'fr' : ''}>
              <img src={flagFR} alt="drapeau français" />
            </button>
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('en');
                setActive('en');
              }}
              className={active === 'en' ? 'en' : ''}>
              <img src={flagUK} alt="drapeau anglais" />
            </button>
          </div>
        </div>
        <form className="forgot__form">
          <div className="input-container">
            <label htmlFor="password">{t('word.password')}:</label>
            <div className="hide-show">
              <input
                type={passwordHidden ? 'password' : 'text'}
                name="password"
                minLength={12}
                onChange={handleChange}
                value={form.password}
              />
              <button
                className="btn icon-hide"
                onClick={() => setPasswordHidden(!passwordHidden)}
                type="button">
                {passwordHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="password">{t('word.confirmPassword')}:</label>
            <div className="hide-show">
              <input
                type={passwordHidden ? 'password' : 'text'}
                name="confirmPassword"
                minLength={12}
                onChange={handleChange}
                value={form.confirmPassword}
              />
              <button
                className="btn icon-hide"
                onClick={() => setPasswordHidden(!passwordHidden)}
                type="button">
                {passwordHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </button>
            </div>
          </div>

          <button
            className="btn btn-main btn-fullwidth"
            type="submit"
            onClick={handleSubmit}>
            <span>{t('new_password')}</span>
          </button>
          <Link className="forgot__form__link" to="/">
            {t('connect')}
          </Link>
        </form>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['login', 'common'])(ResetPassword));
