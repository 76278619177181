import React, { useEffect, useState } from 'react';
import './DisplayFiles.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import UploadImg from '../../assets/images/junia-icon-fichiers.png';
import Alert from '../../utils/Alert/Alert';
import _ from 'lodash';
import { PieceComplementaire } from '../../services/api/Files/interface';

interface ContainerProps {
  onDelete?: (file: PieceComplementaire) => void;
  files?: PieceComplementaire[];
  type: string;
}

// type ChildProps = {
//   passToParent: (varToParent: string) => void;
// };

interface IProps extends ContainerProps, WithTranslation {}
const DisplayFiles: React.FC<IProps> = (props) => {
  const { t, onDelete, files, type } = props;
  const [isOpen, setOpen] = useState(false);
  const types = {
    pdf: [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/heic',
      'application/pdf'
    ]
  };

  const onDeleteClick = (file: PieceComplementaire) => {
    if (onDelete) {
      onDelete(file);
    }
  };
  return (
    <div className="uploaded-container">
      {files && files.length > 0
        ? _.chain([...files])
            .filter((f) => f.type__c === type)
            .map((file: File, index) => {
              return (
                <div className="uploaded" key={index}>
                  {' '}
                  <p key={`identity_card-${index}`}>{file.name}</p>
                  {onDelete && (
                    <button
                      className="btn btn-underline"
                      id="DisplayFiles"
                      onClick={() => onDeleteClick(file)}>
                      <span>{t('word.remove')} </span>
                    </button>
                  )}
                </div>
              );
            })
            .value()
        : ''}
    </div>
  );
};

export default withTranslation(['common'])(DisplayFiles);
