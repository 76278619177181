import React, { createElement, useEffect, useRef, useState } from 'react';
import './IncompleteFolder.scss';
import { useHistory, withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import _ from 'lodash';
import { Formation } from '../../services/api/Formation/interface';
import FormationService from '../../services/api/Formation/FormationService';
import { Application_hed } from '../../services/api/Candidature/interface';
import CandidatureService from '../../services/api/Candidature/CandidatureService';
import { me } from '../../services/api/Contact/interface';
import ContactService from '../../services/api/Contact/ContactService';
import { Contact } from '../../services/api/Contact/interface';
import AuthService from '../../services/api/Auth/AuthService';
import {
  FileToUpload,
  PieceComplementaire
} from '../../services/api/Files/interface';
import PaymentService from '../../services/api/Payment/PaymentService';
import Spinner from '../../utils/Spinner/Spinner';
import DisplayFiles from '../../components/DisplayFiles/DisplayFiles';
import UploadFile from '../../components/UploadFile/UploadFile';
import DisplayBlobFiles from '../../components/DisplayBlobFiles/DisplayBlobFiles';
import FilesService from '../../services/api/Files/FilesService';
import Alert from '../../utils/Alert/Alert';
import EducationHistoryService from '../../services/api/EducationHistory/EducationHistoryService';
import CompetenceLinguistiqueService from '../../services/api/CompetenceLinguistique/CompetenceLinguistiqueService';
interface ContainerProps {}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const IncompleteFolder: React.FC<IProps> = (props) => {
  const { t } = props;
  const history = useHistory();
  useEffect(() => {}, []);
  const [applicationForm, setApplicationForm] = useState<Application_hed>({});
  const [me, setMe] = useState<me>({});
  const [contact, setContact] = useState<Contact>({});
  const [selectedState, setSelectedState] = useState<any>();
  const divRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);
  const [fileList, setFileList] = useState<FileToUpload[]>([]);

  useEffect(() => {
    handleChangeHeight();
  }, [divRef.current?.clientHeight]);
  let { application, candidate_id } = useParams<{ application: string, candidate_id: string  }>();
  useEffect(() => {
    if (!application) {
      let path = candidate_id ? '/agent/applications' : '/applications';
      history.push(path);
    } else {
      getMe();
    }
  }, []);
  useEffect(() => {
    if (me.id_heroku__c && application) {
      getContact();
      getApplication();
    }
  }, [me]);
  useEffect(() => {
    if (me.id_heroku__c && application) {
      getApplication();
    }
  }, [selectedState]);

  const getMe = async () => {
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };
  const getContact = async () => {
    try {
      const contactId = candidate_id ? candidate_id : me.id_heroku__c;
      if (contactId) {
        const contactResponse = await ContactService.getContact(contactId);
        setContact(contactResponse.data);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const getApplication = async () => {
    try {
      const contactId = candidate_id ? candidate_id : me.id_heroku__c;
      if (contactId) {
        const formationResponse = await CandidatureService.getCandidature(
          contactId,
          application
        );
        if (
          formationResponse.data &&
          formationResponse.data.id_heroku__c &&
          formationResponse.data.statut__c === 'Dossier incomplet'
        ) {
          setApplicationForm(formationResponse.data);
        } else {
          history.push('/application');
        }
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: CandidatureProcess.tsx ~ line 42 ~ getformation ~ error',
        error
      );
    }
  };

  const handleChangeHeight = () => {
    let element = divRef.current;
    if (element && element.clientHeight && element.clientHeight > 0) {
      setHeight(element.clientHeight + 8 + 70 + 48); //height + padding div + menu size
    }
  };

  const sendFiles = async () => {
    Spinner.setSpinner(true);
    try {
      let grouped = await _.chain(fileList)
        .groupBy('object')
        .map(async (files, name) => {
          let retour;
          let finished;
          let promised;
          if (contact.id_heroku__c) {
            switch (name) {
              case 'application':
                if (contact.type_tech__c === 'Francais') {
                  retour = await CandidatureService.patchCandidature(
                    contact.id_heroku__c,
                    { id_heroku__c: applicationForm.id_heroku__c },
                    fileList,
                    ['motivation', 'recommendation', 'poursuite']
                  );
                } else if (contact.type_tech__c === 'Echange'){
                  retour = await CandidatureService.patchCandidature(
                    contact.id_heroku__c,
                    { id_heroku__c: applicationForm.id_heroku__c },
                    fileList,
                    ['motivation', 'recommendation', 'plan']
                  );
                } else {
                  retour = await CandidatureService.patchCandidature(
                    contact.id_heroku__c,
                    { id_heroku__c: applicationForm.id_heroku__c },
                    fileList,
                    ['motivation', 'recommendation']
                  );
                }

                break;
              case 'contact':
                retour = await ContactService.patchContact(
                  contact.id_heroku__c,
                  {},
                  fileList,
                  ['resume', 'scholarship', 'identity_card']
                );
                break;
              case 'educationHistory':
                finished = await _.chain(files)
                  .groupBy('id')
                  .map(async (history, historyName) => {
                    if (contact.id_heroku__c) {
                      return await EducationHistoryService.patchContactEducationHistory(
                        contact.id_heroku__c,
                        { id_heroku__c: historyName },
                        history,
                        ['grades', 'degree', 'bac_transcript']
                      );
                    }
                  })
                  .value();
                promised = await Promise.all(finished).then((list) => {
                  retour = list;
                  return list;
                });
                // retour =
                break;
              case 'contactLanguage':
                finished = await _.chain(files)
                  .groupBy('id')
                  .map(async (contactLanguage, contactLanguageName) => {
                    if (contact.id_heroku__c) {
                      return await CompetenceLinguistiqueService.patchCompetencesLinguistique(
                        contact.id_heroku__c,
                        { id_heroku__c: contactLanguageName },
                        contactLanguage,
                        ['language_degree']
                      );
                    }
                  })
                  .value();
                promised = await Promise.all(finished).then((list) => {
                  retour = list;
                  return list;
                });
                // retour =
                break;
            }
          }
          return retour;
        })
        .value();
      Promise.all(grouped).then((list) => {
        if (contact.id_heroku__c) {
          CandidatureService.patchCandidature(contact.id_heroku__c, {
            id_heroku__c: applicationForm.id_heroku__c,
            statut__c: 'Dépôt candidature'
          })
            .then((res) => {
              Spinner.setSpinner(false);
              history.push('/applications');
              Alert.setAlert({
                message: t('modificationConfirmed'),
                duration: 3000,
                open: true,
                type: 'success'
              });
            })
            .catch((err) => {
              Spinner.setSpinner(false);
              Alert.setAlert({
                message: t('modificationError'),
                duration: 3000,
                open: true,
                type: 'error'
              });
            });
        }
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: IncompleteFolder.tsx ~ line 160 ~ sendFiles ~ error',
        error
      );
    }
  };

  const handleFileChange = (files: File[], type: string, object: string) => {
    let list = [...fileList];
    if (files.length > 0) {
      let found = _.findIndex(list, (f) => f.type === type);
      if (found >= 0) {
        let data = [...list[found].files, ...files];
        list.splice(found, 1, { type: type, files: data, object });
      } else {
        list.push({ type: type, files: files, object });
      }

      setFileList(list);
      if (contact.type_tech__c) {
        // let canNext = checkRequested(required[contact.type_tech__c], form);
        // onChange({ data: form, files: list }, true);
      }
    }
  };

  const handleFileChangeMap = (
    files: File[],
    type: string,
    object: string,
    id?: any
  ) => {
    let list = [...fileList];
    if (files.length > 0) {
      let found = _.findIndex(list, (f) => f.type === type && f.id === id);
      if (found >= 0) {
        let data = [...list[found].files, ...files];
        list.splice(found, 1, { type: type, files: data, object, id });
      } else {
        list.push({ type: type, files: files, object, id });
      }

      setFileList(list);
      if (contact.type_tech__c) {
        // let canNext = checkRequested(required[contact.type_tech__c], form);
        // onChange({ data: form, files: list }, true);
      }
    }
  };
  const findMax = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined
  ) => {
    if (files) {
      let foundCurrent = _.find([...fileList], (f) => f.type === type);
      let foundSave = _.filter([...files], (f) => f.type__c === type);
      let currentLength = foundCurrent ? foundCurrent.files.length : 0;
      let saveLength = foundSave ? foundSave.length : 0;
      return currentLength + saveLength;
    } else {
      return 0;
    }
  };

  const findMaxMap = (
    type: string,
    max: number,
    files: PieceComplementaire[] | undefined,
    id: any
  ) => {
    if (files) {
      let foundCurrent = _.find(
        [...fileList],
        (f) => f.type === type && f.id === id
      );
      let foundSave = _.filter([...files], (f) => f.type__c === type);
      let currentLength = foundCurrent ? foundCurrent.files.length : 0;
      let saveLength = foundSave ? foundSave.length : 0;
      return currentLength + saveLength;
    } else {
      return 0;
    }
  };

  const handleFileDelete = (file: any, type: string, fileType: string) => {
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contact?.id_heroku__c) {
          FilesService.removeFile(
            file?.id_heroku__c,
            contact?.id_heroku__c
          ).then((res) => {
            let found = _.find(fileList, (f) => f.type === type);
            if (getContact) {
              getContact();
              getApplication();
              // setFileList([])
            }
          });
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(liste, (f) => f.type === file.type);
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);
        break;
    }
  };

  const deleteMap = (file: any, type: string, fileType: string, id?: any) => {
    switch (fileType) {
      case 'uploaded':
        if (file?.id_heroku__c && contact?.id_heroku__c) {
          FilesService.removeFile(
            file?.id_heroku__c,
            contact?.id_heroku__c
          ).then((res) => {
            let found = _.find(fileList, (f) => f.type === type);
            if (getContact) {
              getContact();
              getApplication();
              // setFileList([])
            }
          });
        }
        break;
      case 'blob':
        var liste = [...fileList];
        var index = _.findIndex(
          liste,
          (f) => f.type === file.type && f.id === id
        );
        var fileIndex = _.findIndex(
          liste[index].files,
          (f: File) =>
            f.lastModified === file.files[0].lastModified &&
            f.name === file.files[0].name
        );
        var array: File[] = liste[index].files;
        array.splice(fileIndex, 1);
        liste[index].files = array;
        setFileList(liste);
        break;
    }
  };

  return (
    <div className="incomplete-folder general-container">
      <div className="incomplete-header" ref={divRef}>
        <div>
          <h4>{t('incompleteTitle')}</h4>
        </div>
        <div>
          <button className="btn btn-main" onClick={sendFiles}>
            <span>{t('incompleteValid')}</span>
          </button>
        </div>
      </div>
      <div
        className="apply-list-container"
        style={{ height: 'calc(98% - ' + height + 'px)' }}>
        <div className="apply-list">
          <div className="incomplete-comment">
            {applicationForm.commentaire_document_manquant__c && (
              <div className="incomplete-comment-text">
                <h5>{t('incompletComment')}</h5>
                <p>{applicationForm.commentaire_document_manquant__c}</p>
              </div>
            )}
          </div>
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            !applicationForm.passeport_pi_ce_d_identit__c && (
              <React.Fragment>
                <h5>{t('idCard')}</h5>
                <div>
                  <UploadFile
                    max={2}
                    current={findMax('identity_card', 2, contact.Files)}
                    onChange={(data) =>
                      handleFileChange(data, 'identity_card', 'contact')
                    }
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="identity_card"
                    onDelete={(data) =>
                      handleFileDelete(data, 'identity_card', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={contact.Files}
                    type="identity_card"
                    onDelete={(data) =>
                      handleFileDelete(data, 'identity_card', 'uploaded')
                    }></DisplayFiles>
                </div>
                <hr></hr>
              </React.Fragment>
            )}

          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            !applicationForm.cv__c && (
              <React.Fragment>
                <h5>{t('CV')}</h5>
                <div>
                  <UploadFile
                    max={2}
                    current={findMax('resume', 2, contact.Files)}
                    onChange={(data) =>
                      handleFileChange(data, 'resume', 'contact')
                    }
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="resume"
                    onDelete={(data) =>
                      handleFileDelete(data, 'resume', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={contact.Files}
                    type="resume"
                    onDelete={(data) =>
                      handleFileDelete(data, 'resume', 'uploaded')
                    }></DisplayFiles>
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            !applicationForm.lettre_de_motivation__c && (
              <React.Fragment>
                <h5>{t('motivLetter')}</h5>
                <div>
                  <UploadFile
                    max={2}
                    current={findMax('motivation', 2, applicationForm.Files)}
                    onChange={(data) =>
                      handleFileChange(data, 'motivation', 'application')
                    }
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="motivation"
                    onDelete={(data) =>
                      handleFileDelete(data, 'motivation', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={applicationForm.Files}
                    type="motivation"
                    onDelete={(data) =>
                      handleFileDelete(data, 'motivation', 'uploaded')
                    }></DisplayFiles>
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            !applicationForm.study_plan__c &&
            contact.type_tech__c === 'Echange' && (
              <React.Fragment>
                <h5>{t('plan')}</h5>
                <div>
                  <UploadFile
                    key="plan-upload"
                    onChange={(data) =>
                      handleFileChange(data, 'plan', 'application')
                    }
                    max={1}
                    current={findMax('plan', 1, applicationForm.Files)}
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="plan"
                    onDelete={(data) =>
                      handleFileDelete(data, 'plan', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={applicationForm.Files}
                    type="plan"
                    onDelete={(data) =>
                      handleFileDelete(data, 'plan', 'uploaded')
                    }></DisplayFiles>
                </div>
              </React.Fragment>
            )}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            !applicationForm.fiche_d_appr_ciation_acad_mique__c &&
            contact.type_tech__c === 'Francais' && (
              <React.Fragment>
                <h5>{t('poursuite')}</h5>
                <div>
                  <UploadFile
                    max={2}
                    current={findMax('poursuite', 2, applicationForm.Files)}
                    onChange={(data) =>
                      handleFileChange(data, 'poursuite', 'application')
                    }
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="poursuite"
                    onDelete={(data) =>
                      handleFileDelete(data, 'poursuite', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={applicationForm.Files}
                    type="poursuite"
                    onDelete={(data) =>
                      handleFileDelete(data, 'poursuite', 'uploaded')
                    }></DisplayFiles>
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            !applicationForm.lettre_de_recommandation__c &&
            applicationForm.Formation &&
            applicationForm.Formation.type_de_formation__c ===
              'Formation France apprenti' && (
              <React.Fragment>
                <h5>{t('recoLetter')}</h5>
                <div>
                  <UploadFile
                    max={2}
                    current={findMax(
                      'recommendation',
                      2,
                      applicationForm.Files
                    )}
                    onChange={(data) =>
                      handleFileChange(data, 'recommendation', 'application')
                    }
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="recommendation"
                    onDelete={(data) =>
                      handleFileDelete(data, 'recommendation', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={applicationForm.Files}
                    type="recommendation"
                    onDelete={(data) =>
                      handleFileDelete(data, 'recommendation', 'uploaded')
                    }></DisplayFiles>
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            !applicationForm.notification_de_bourse__c &&
            contact.boursier__c === 'Oui' && (
              <React.Fragment>
                <h5>{t('scolarshipHolderJustif')}</h5>
                <div>
                  <UploadFile
                    max={2}
                    current={findMax('scholarship', 2, contact.Files)}
                    onChange={(data) =>
                      handleFileChange(data, 'scholarship', 'contact')
                    }
                    type="pdf"></UploadFile>
                  <DisplayBlobFiles
                    files={fileList}
                    type="scholarship"
                    onDelete={(data) =>
                      handleFileDelete(data, 'scholarship', 'blob')
                    }></DisplayBlobFiles>
                  <DisplayFiles
                    files={contact.Files}
                    type="scholarship"
                    onDelete={(data) =>
                      handleFileDelete(data, 'scholarship', 'uploaded')
                    }></DisplayFiles>
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {/* {!applicationForm.lettre_de_motivation__c && (
            <React.Fragment>
              <h6>{t('languageCertifOffi')}</h6>
              <div></div>
            </React.Fragment>
          )} */}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            !applicationForm.relev_notes_du_bac__c && (
              <React.Fragment>
                <div>
                  {_.chain(contact.EducationHistorys)
                    .filter((e) => e.type_de_diplome__c === 'Baccalauréat')
                    .map((educationHistory) => {
                      return (
                        <div key={educationHistory.id_heroku__c}>
                          <h5>
                            {educationHistory.type_de_diplome__c ===
                            'Enseignement supérieur' ? (
                              <>
                                {educationHistory.intitule_de_formation__c} -{' '}
                                {
                                  educationHistory.hed__educational_institution_name__c
                                }
                              </>
                            ) : (
                              <>
                                {t('bac') + ' '}{' '}
                                {educationHistory.type_de_bac__c} -
                                {educationHistory.option_du_bac__c}
                              </>
                            )}
                          </h5>
                          <h6>{t('grade')}</h6>
                          <UploadFile
                            max={2}
                            current={findMaxMap(
                              'bac_transcript',
                              2,
                              educationHistory.Files,
                              educationHistory.id_heroku__c
                            )}
                            onChange={(data) =>
                              handleFileChangeMap(
                                data,
                                'bac_transcript',
                                'educationHistory',
                                educationHistory.id_heroku__c
                              )
                            }
                            type="pdf"></UploadFile>
                          <DisplayBlobFiles
                            files={_.filter(
                              [...fileList],
                              (f) => f.id === educationHistory.id_heroku__c
                            )}
                            type="bac_transcript"
                            onDelete={(data) =>
                              deleteMap(
                                data,
                                'bac_transcript',
                                'blob',
                                educationHistory.id_heroku__c
                              )
                            }></DisplayBlobFiles>
                          <DisplayFiles
                            files={educationHistory.Files}
                            type="bac_transcript"
                            onDelete={(data) =>
                              deleteMap(
                                data,
                                'bac_transcript',
                                'uploaded',
                                educationHistory.id_heroku__c
                              )
                            }></DisplayFiles>
                        </div>
                      );
                    })
                    .value()}
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            ((contact.type_tech__c === 'Francais' &&
              (!applicationForm.bulletins_post_bac__c ||
                !applicationForm.diplomes_post_bac__c)) ||
              (contact.type_tech__c !== 'Francais' &&
                (!applicationForm.dipl_me_s__c ||
                  !applicationForm.relev_s_de_notes__c))) && (
              <React.Fragment>
                <div>
                  {_.chain(contact.EducationHistorys)
                    .filter(
                      (e) => e.type_de_diplome__c === 'Enseignement supérieur'
                    )
                    .map((educationHistory) => {
                      return (
                        <div key={educationHistory.id_heroku__c}>
                          <h5>
                            {educationHistory.type_de_diplome__c ===
                            'Enseignement supérieur' ? (
                              <>
                                {educationHistory.intitule_de_formation__c} -{' '}
                                {
                                  educationHistory.hed__educational_institution_name__c
                                }
                              </>
                            ) : (
                              <>
                                {t('bac') + ' '}{' '}
                                {educationHistory.type_de_bac__c} -
                                {educationHistory.option_du_bac__c}
                              </>
                            )}
                          </h5>
                          {((contact.type_tech__c === 'Francais' &&
                            !applicationForm.bulletins_post_bac__c) ||
                            (contact.type_tech__c !== 'Francais' &&
                              !applicationForm.relev_s_de_notes__c)) && (
                            <>
                              {' '}
                              <h6>{t('grade')}</h6>
                              <UploadFile
                                max={2}
                                current={findMaxMap(
                                  'grades',
                                  2,
                                  educationHistory.Files,
                                  educationHistory.id_heroku__c
                                )}
                                onChange={(data) =>
                                  handleFileChangeMap(
                                    data,
                                    'grades',
                                    'educationHistory',
                                    educationHistory.id_heroku__c
                                  )
                                }
                                type="pdf"></UploadFile>
                              <DisplayBlobFiles
                                files={_.filter(
                                  [...fileList],
                                  (f) => f.id === educationHistory.id_heroku__c
                                )}
                                type="grades"
                                onDelete={(data) =>
                                  deleteMap(
                                    data,
                                    'grades',
                                    'blob',
                                    educationHistory.id_heroku__c
                                  )
                                }></DisplayBlobFiles>
                              <DisplayFiles
                                files={educationHistory.Files}
                                type="grades"
                                onDelete={(data) =>
                                  deleteMap(
                                    data,
                                    'grades',
                                    'uploaded',
                                    educationHistory.id_heroku__c
                                  )
                                }></DisplayFiles>
                            </>
                          )}
                          {educationHistory.type_de_diplome__c ===
                            'Enseignement supérieur' &&
                            ((contact.type_tech__c === 'Francais' &&
                              !applicationForm.diplomes_post_bac__c) ||
                              (contact.type_tech__c !== 'Francais' &&
                                !applicationForm.dipl_me_s__c)) && (
                              <>
                                <h6>{t('diploma')}</h6>
                                <UploadFile
                                  max={2}
                                  current={findMaxMap(
                                    'degree',
                                    2,
                                    educationHistory.Files,
                                    educationHistory.id_heroku__c
                                  )}
                                  onChange={(data) =>
                                    handleFileChangeMap(
                                      data,
                                      'degree',
                                      'educationHistory',
                                      educationHistory.id_heroku__c
                                    )
                                  }
                                  type="pdf"></UploadFile>
                                <DisplayBlobFiles
                                  files={_.filter(
                                    [...fileList],
                                    (f) =>
                                      f.id === educationHistory.id_heroku__c
                                  )}
                                  type="degree"
                                  onDelete={(data) =>
                                    deleteMap(
                                      data,
                                      'degree',
                                      'blob',
                                      educationHistory.id_heroku__c
                                    )
                                  }></DisplayBlobFiles>
                                <DisplayFiles
                                  files={educationHistory.Files}
                                  type="degree"
                                  onDelete={(data) =>
                                    deleteMap(
                                      data,
                                      'degree',
                                      'uploaded',
                                      educationHistory.id_heroku__c
                                    )
                                  }></DisplayFiles>
                              </>
                            )}
                        </div>
                      );
                    })
                    .value()}
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            contact.type_tech__c !== 'Francais' &&
            !applicationForm.certificat_de_fran_ais_exempt__c &&
            !applicationForm.certificat_officiel_de_fran_ais__c && (
              <React.Fragment>
                <div>
                  <h5>{t('language')}</h5>
                  {_.chain(contact.ContactLanguages)

                    .filter(
                      (c) =>
                        contact.type_tech__c !== 'Francais' &&
                        c.langue1__c !== undefined &&
                        'Français' === c.langue1__c
                    )
                    .map((contactLanguage) => {
                      return (
                        <div key={contactLanguage.id_heroku__c}>
                          <h6>
                            {contactLanguage.langue1__c} -{' '}
                            {contactLanguage.hed__fluency__c} -{' '}
                            {contactLanguage.certificat_officiel_de_langue__c}
                          </h6>
                          <UploadFile
                            max={2}
                            current={findMaxMap(
                              'language_degree',
                              2,
                              contactLanguage.Files,
                              contactLanguage.id_heroku__c
                            )}
                            onChange={(data) =>
                              handleFileChangeMap(
                                data,
                                'language_degree',
                                'contactLanguage',
                                contactLanguage.id_heroku__c
                              )
                            }
                            type="pdf"></UploadFile>
                          <DisplayBlobFiles
                            files={_.filter(
                              [...fileList],
                              (f) => f.id === contactLanguage.id_heroku__c
                            )}
                            type="language_degree"
                            onDelete={(data) =>
                              deleteMap(
                                data,
                                'language_degree',
                                'blob',
                                contactLanguage.id_heroku__c
                              )
                            }></DisplayBlobFiles>
                          <DisplayFiles
                            files={contactLanguage.Files}
                            type="language_degree"
                            onDelete={(data) =>
                              deleteMap(
                                data,
                                'language_degree',
                                'uploaded',
                                contactLanguage.id_heroku__c
                              )
                            }></DisplayFiles>
                        </div>
                      );
                    })
                    .value()}
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {applicationForm.id_heroku__c &&
            contact.id_heroku__c &&
            ((contact.type_tech__c != 'Francais' &&
              !applicationForm.exempt_certificat_officiel_de_langue__c &&
              !applicationForm.certification_d_anglais__c) ||
              (contact.type_tech__c === 'Francais' &&
                !applicationForm.certification_d_anglais__c)) && (
              <React.Fragment>
                <div>
                  <h5>{t('language')}</h5>
                  {_.chain(contact.ContactLanguages)

                    .filter(
                      (c) =>
                        c.langue1__c !== undefined && 'Anglais' === c.langue1__c
                    )
                    .map((contactLanguage) => {
                      return (
                        <div key={contactLanguage.id_heroku__c}>
                          <h6>
                            {contactLanguage.langue1__c} -{' '}
                            {contactLanguage.hed__fluency__c} -{' '}
                            {contactLanguage.certificat_officiel_de_langue__c}
                          </h6>
                          <UploadFile
                            max={2}
                            current={findMaxMap(
                              'language_degree',
                              2,
                              contactLanguage.Files,
                              contactLanguage.id_heroku__c
                            )}
                            onChange={(data) =>
                              handleFileChangeMap(
                                data,
                                'language_degree',
                                'contactLanguage',
                                contactLanguage.id_heroku__c
                              )
                            }
                            type="pdf"></UploadFile>
                          <DisplayBlobFiles
                            files={_.filter(
                              [...fileList],
                              (f) => f.id === contactLanguage.id_heroku__c
                            )}
                            type="language_degree"
                            onDelete={(data) =>
                              deleteMap(
                                data,
                                'language_degree',
                                'blob',
                                contactLanguage.id_heroku__c
                              )
                            }></DisplayBlobFiles>
                          <DisplayFiles
                            files={contactLanguage.Files}
                            type="language_degree"
                            onDelete={(data) =>
                              deleteMap(
                                data,
                                'language_degree',
                                'uploaded',
                                contactLanguage.id_heroku__c
                              )
                            }></DisplayFiles>
                        </div>
                      );
                    })
                    .value()}
                </div>
                <hr></hr>
              </React.Fragment>
            )}
          {/* {!applicationForm.lettre_de_motivation__c && (
            <React.Fragment>
              <h6>{t('recordTranscript')}</h6>
              <div></div>
            </React.Fragment>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(withTranslation(['form', 'home', 'application'])(IncompleteFolder));
