import React, { useEffect, useState } from 'react';
import './UploadFile.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import UploadImg from '../../assets/images/junia-icon-fichiers.png';
import Alert from '../../utils/Alert/Alert';
import _, { uniqueId } from 'lodash';

interface ContainerProps {
  onChange?: (data: any) => void;
  type: 'pdf';
  max: number;
  current: number;
}

// type ChildProps = {
//   passToParent: (varToParent: string) => void;
// };

interface IProps extends ContainerProps, WithTranslation {}
const UploadFile: React.FC<IProps> = (props) => {
  const { t, onChange, type, max, current } = props;
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState<any>();
  const types = {
    pdf: {
      types: [
        'image/jpeg',
        'image/png',
        'image/jpg',
        'image/heic',
        'application/pdf'
      ],
      names: ['.jpeg', '.png', '.jpg', '.pdf', '.heic']
    }
  };
  const id = uniqueId();

  const onFileChange = (e: any) => {
    if (Array.from(e.target.files).length > max - current) {
      e.preventDefault();
      Alert.setAlert({
        duration: 3000,
        message: t('maxFiles', { max: max }),
        open: true,
        type: 'error'
      });
      return;
    } else {
      _.map(e.target.files, (file) => {
        if (!types[type].types.includes(file.type)) {
          e.preventDefault();
          Alert.setAlert({
            duration: 3000,
            message: t('wrongType', { types: _.join(types[type].names, ', ') }),
            open: true,
            type: 'error'
          });
          setValue('');
          return;
        } else {
          if (file.size > 8000000) {
            Alert.setAlert({
              duration: 3000,
              message: t('tooHeavy', {
                name: file.name,
                size: (file.size / 1000000).toFixed(2)
              }),
              open: true,
              type: 'error'
            });
            setValue('');
          } else {
            setValue('');
            if (onChange) onChange(Object.values(e.target.files));
          }
        }
      });
    }
  };
  return (
    <div className="image-upload">
      <input
        type="file"
        id={`upload-file-${id}`}
        onChange={onFileChange}
        value={value}
        multiple
      />
      <label className="upload" htmlFor={`upload-file-${id}`}>
        <p>{t('word.importFile')}</p>
        <span>.jpeg, .png, .jpg, .pdf, .heic</span>
        <span>Max 5Mo</span>
        <img alt="upload icon" src={UploadImg} />
      </label>
    </div>
  );
};

export default withTranslation(['upload', 'common'])(UploadFile);
